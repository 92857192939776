import React from 'react'
import { connect } from 'react-redux'

import {
  RootStateTypeExtra,
  ShortlistPropertyType,
  UnitShortlistInterface,
} from '@store/types'

import { Level } from '@api/building'

import UnitThumbnail from './unit-thumbnail'

interface UnitsListProps {
  projectId: string
  shortlists: UnitShortlistInterface[]
  shortlistsData: Level[]
}

function UnitsList({ projectId, shortlists, shortlistsData }: UnitsListProps) {
  return (
    <>
      <p className="mb-[38px] text-center text-2xl font-semibold">
        {`${shortlists.length} Shortlisted ${
          shortlists.length > 1 ? 'Properties' : 'Property'
        }`}
      </p>
      <div className="no-scrollbar flex flex-auto flex-col gap-10 overflow-y-auto">
        {shortlists.map((item) =>
          item.type === ShortlistPropertyType.Unit ? (
            <UnitThumbnail
              key={item.propertyId}
              shortlistItem={item}
              projectId={projectId}
              levels={shortlistsData}
            />
          ) : null
        )}
      </div>
    </>
  )
}

export default connect(
  ({ projectIdentity: { projectUUID } }: RootStateTypeExtra) => ({
    projectId: projectUUID,
  })
)(UnitsList)
