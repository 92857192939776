import React from 'react'
import { connect } from 'react-redux'

import {
  InteractivePlanType,
  ProjectIdentity,
  RootStateTypeExtra,
} from '@store/types'

import Container from '@components/container'
import DataHandler from '@components/data-handler'
import { Status } from '@components/data-handler/data-handler'
import Filter from '@components/filter/house-and-land-filter'
import useGetFilterData from '@components/filter/use-get-filter-data'
import StageCard from '@components/stage-card'

import { SummaryCollectionInterface } from '@api/houseAndLand'
import {
  Polygon,
  selectFromResult as selectInteractiveFromResult,
  useGetInteractivePlanQuery,
} from '@api/interactive-plan'

import FirebaseControlQuery from '@firebaseUtil/firebase-control-query'
import { SessionMap } from '@firebaseUtil/types'

import { ChevronSvg, FunnelSvg } from '@svg/react'

import filterPackageSummary from '@adUtilities/filter-package-summary'
import { Stage } from '@adUtilities/types/houseAndLand'

import PrecinctSkeleton from './precinct-skeleton'

interface PolygonsCollection {
  [key: string]: Polygon[]
}
interface ExtendedStage extends Stage {
  groupId: string
}

export interface PrecinctViewProps {
  session: SessionMap | undefined
  projectIdentity: ProjectIdentity
  hideFilter: boolean
  packageSummary: SummaryCollectionInterface | null
  precinctLabel: string
}

const ARROW_TYPE = 'arrow'
const ARROW_SMALL_TYPE = 'arrow-small'

function PrecinctView({
  projectIdentity,
  session,
  hideFilter,
  packageSummary,
  precinctLabel,
}: PrecinctViewProps): React.ReactElement {
  const firebaseControlQuery = FirebaseControlQuery({
    ...projectIdentity,
    sessionKey: projectIdentity.sessionId,
  })
  const [stagePolygonsCollection, setStagePolygonsCollection] =
    React.useState<PolygonsCollection>({})
  const [activePrecinct, setActivePrecinct] = React.useState('')
  const [filterActivePrecinct, setFilterActivePrecinct] = React.useState('')

  const filterPayload = useGetFilterData({
    projectName: projectIdentity.projectId,
    precinctId: filterActivePrecinct,
  })

  const isStagePolygonExist = (label: string) => {
    const stageIDs = stagePolygonsCollection[activePrecinct]?.map(
      (res) => res.groupId.split('-')?.[1] || res.groupId
    )
    return stageIDs?.includes(label)
  }

  const stageList = React.useMemo(() => {
    const activePackageSummary = packageSummary?.[activePrecinct]
    if (session?.houseAndLand?.lotFilter?.apply && activePackageSummary) {
      const lotFilter = session?.houseAndLand?.lotFilter
      return (
        filterPackageSummary(
          packageSummary[activePrecinct],
          {
            ...lotFilter,
            storey: lotFilter.storey.map((item) => Number(item)),
          },
          projectIdentity?.statusLabels?.available
        )?.stages ?? {}
      )
    }
    return activePackageSummary?.stages || {}
  }, [
    packageSummary,
    activePrecinct,
    session?.houseAndLand?.lotFilter,
    session?.houseAndLand?.lotFilter?.apply,
  ])

  const getLabel = (poly: Polygon) => {
    const POLY_TYPE = poly.type || ''
    if (POLY_TYPE === ARROW_TYPE || POLY_TYPE === ARROW_SMALL_TYPE) {
      return poly.label || poly.groupId
    }
    return `${poly.label ?? ''} ${poly.groupId}`
  }

  const filteredStages: ExtendedStage[] = React.useMemo(
    () =>
      Object.values(stageList || {})
        .filter((res) => isStagePolygonExist(res.label))
        .map((stage) => {
          const polygon = stagePolygonsCollection[activePrecinct]?.find(
            (polygon) => polygon.groupId === stage.label
          )

          const currentLabel = polygon ? getLabel(polygon) : ''

          return {
            ...stage,
            label: currentLabel,
            groupId: polygon?.groupId || '',
          }
        }),
    [stageList, stagePolygonsCollection, activePrecinct]
  )

  const [isFilterOpen, toggleFilter] = React.useState(false)

  const interactivePayload = useGetInteractivePlanQuery(
    {
      projectName: projectIdentity.projectId,
      type: InteractivePlanType.Precinct,
      slug: filterActivePrecinct,
    },
    {
      selectFromResult: selectInteractiveFromResult,
      skip: !filterActivePrecinct,
    }
  )
  React.useEffect(() => {
    const { maps } = interactivePayload
    if (
      filterActivePrecinct &&
      maps.precinct &&
      Object.keys(maps.precinct)?.length > 0 &&
      maps.precinct?.[filterActivePrecinct] &&
      !stagePolygonsCollection[filterActivePrecinct]
    ) {
      setStagePolygonsCollection({
        ...stagePolygonsCollection,
        [filterActivePrecinct]:
          maps.precinct[filterActivePrecinct]?.[0]?.polygons || [],
      })
    }
  }, [interactivePayload, filterActivePrecinct])

  const handleStageSelect = async (stage: ExtendedStage) => {
    toggleFilter(false)
    await firebaseControlQuery.update({
      [`houseAndLand.activeStageId`]: stage.groupId,
    })
    setTimeout(async () => {
      await firebaseControlQuery.update({
        [`houseAndLand.sidePanelFolded`]: true,
      })
      await firebaseControlQuery.updateRoute('stages')
    }, 1000)
  }

  const goBackToArea = async () => {
    await firebaseControlQuery.updateRoute('area-view-house-and-land')
    await firebaseControlQuery.update({
      [`houseAndLand.activePrecinctId`]: '',
    })
  }

  const apiStatus = React.useMemo(() => {
    const { FULFILLED, REJECTED, PENDING } = Status
    const { status: filterStatus } = filterPayload
    const { status: interactiveStatus } = interactivePayload

    if (filterStatus === FULFILLED && interactiveStatus === FULFILLED) {
      return FULFILLED
    }

    if (filterStatus === REJECTED || interactiveStatus === REJECTED) {
      return REJECTED
    }

    return PENDING
  }, [filterPayload.status, interactivePayload.status])

  React.useEffect(() => {
    if (session) {
      const {
        connected,
        houseAndLand: { activePrecinctId: firebaseActivePrecinctId },
      } = session

      if (connected) {
        setActivePrecinct(firebaseActivePrecinctId)
        setFilterActivePrecinct(firebaseActivePrecinctId)
      }
    }
  }, [session])

  return (
    <Container>
      <DataHandler
        payloads={{
          ...interactivePayload,
          data: filteredStages,
          status: apiStatus,
        }}
        showDefaultNotFound={false}
        skeletonFrame={<PrecinctSkeleton />}
      >
        {session && (
          <Filter
            toggle={toggleFilter}
            isOpen={isFilterOpen}
            activePrecinct={filterActivePrecinct}
            setActivePrecinct={setFilterActivePrecinct}
            firebaseLotFilter={session.houseAndLand.lotFilter}
            firebaseActiveStage={session.houseAndLand.activeStageId}
            hideStages
          />
        )}
        <div className="h-full w-full px-4">
          <div className="h-top-bar w-full items-end text-title font-medium">
            <button
              onClick={goBackToArea}
              type="button"
              className="relative flex h-[40%] items-center text-default font-normal text-neutralColour"
            >
              <ChevronSvg className="absolute -left-2.5 h-8 w-8" />

              <span className="ml-6">Precincts</span>
            </button>
            <div className="flex h-[60%] w-full items-baseline justify-between text-neutralColour">
              <div className="text-title font-medium">
                {precinctLabel || activePrecinct || ''}
              </div>
              {!hideFilter && (
                <button
                  onClick={() => toggleFilter(true)}
                  type="button"
                  className="inline-flex items-center text-default"
                >
                  <span className="mr-1">Filters</span>
                  <FunnelSvg className="h-6 w-6" />
                </button>
              )}
            </div>
          </div>

          <div className="no-scrollbar h-page-body w-full overflow-auto">
            <div className="flex flex-col gap-[15px]">
              {filteredStages?.length > 0 ? (
                filteredStages.map((stage) => (
                  <StageCard
                    key={stage.id}
                    label={stage.label}
                    handleClick={() => handleStageSelect(stage)}
                    lotCount={stage.lots.length || 0}
                    onlyShowAvailable={Boolean(
                      session?.houseAndLand?.lotFilter?.showOnlyAvailable
                    )}
                  />
                ))
              ) : (
                <div className="flex w-full items-center justify-between rounded-lg bg-secondaryColour px-[30px] py-9 text-heading font-medium text-neutralColour">
                  No stages found
                </div>
              )}
            </div>
          </div>
        </div>
      </DataHandler>
    </Container>
  )
}

export default connect(
  ({
    projectIdentity: { showPrice, hideFilter },
    projectIdentity,
    firestore: { session },
    houseAndLand: { packageSummary },
    appConfig: { precinctLabel },
  }: RootStateTypeExtra) => ({
    session,
    projectIdentity,
    showPrice,
    hideFilter,
    packageSummary,
    precinctLabel,
  })
)(PrecinctView)
