import React from 'react'
import { connect } from 'react-redux'

import { NavigationMenuKey } from '@store/constants'
import { ProjectIdentity, RootStateTypeExtra } from '@store/types'

import { ProjectType } from '@utilities/adgroup-utilities/constants/common'

import FirebaseControlQuery from '@firebaseUtil/firebase-control-query'

interface EmptyStateProps {
  projectIdentity: ProjectIdentity
}

function EmptyState({ projectIdentity }: EmptyStateProps) {
  const firebaseControlQuery = FirebaseControlQuery({
    ...projectIdentity,
    sessionKey: projectIdentity.sessionId,
  })

  const {
    isSnaploaderActive: hasSnaploader,
    isEnvisionVRActive: hasEnvisionVR,
    type,
    navigationSettings,
  } = projectIdentity

  const handleNavigateToResidences = () => {
    if (type === ProjectType.HouseAndLand) {
      firebaseControlQuery.updateRoute('area-view-house-and-land')
      return
    }

    if (hasSnaploader) {
      firebaseControlQuery.updateRoute('snaploader-view')
      return
    }

    if (hasEnvisionVR) {
      firebaseControlQuery.updateRoute('3d-building')
      return
    }

    firebaseControlQuery.updateRoute('area-view')
  }

  const exploreLinkText = React.useMemo(() => {
    const dynamicResidencesText = navigationSettings?.find(
      (item) => item.key === NavigationMenuKey.RESIDENCES
    )
    if (dynamicResidencesText && dynamicResidencesText.label) {
      return `Explore ${dynamicResidencesText.label?.toLowerCase()}`
    }

    if (type === ProjectType.HouseAndLand) {
      return 'Explore Precinct'
    }

    return 'Explore Residences'
  }, [navigationSettings])

  return (
    <div className="flex h-full w-full flex-col items-center justify-center">
      <p className="mb-6 text-3xl font-semibold leading-none">
        Your Shortlist is empty
      </p>
      <p className="mb-11 text-[26px] leading-none">
        Add your favourite properties in it.
      </p>
      <button
        type="button"
        className="rounded-lg bg-mainColour px-12 py-5 text-[19px] font-semibold capitalize leading-none text-white"
        onClick={handleNavigateToResidences}
      >
        {exploreLinkText}
      </button>
    </div>
  )
}

export default connect(({ projectIdentity }: RootStateTypeExtra) => ({
  projectIdentity,
}))(EmptyState)
