import React from 'react'

import { ShortlistState } from '@store/actionSlices/shortlist'
import { ShortlistPropertyType } from '@store/types'

import { LoadingSvg } from '@components/adgroup-svg/react'
import PhoneInput from '@components/phone-input/v2'

import {
  SaveShortlistParams,
  useRegisterBuyerMutation,
  useSaveShortlistMutation,
} from '@api/shortlisted'

import { notifyError } from '@utilities/adgroup-utilities/notifier'

interface Buyer {
  firstName: string
  lastName: string
  phoneNumber: string
  email: string
}

const INITIAL_FORM_VALUE: Buyer = {
  firstName: '',
  lastName: '',
  email: '',
  phoneNumber: '',
}

interface ManualBuyerRegistrationProps {
  className?: string
  projectName: string
  shortlistData: ShortlistState
  onRegistrationComplete: (data: { email: string; isLinked: boolean }) => void
  onShowQRCode: () => void

  agentId?: string
  documentIds?: string[]
}

function ManualBuyerRegistration({
  className,
  projectName,
  shortlistData,
  onRegistrationComplete,
  onShowQRCode,

  agentId,
  documentIds,
}: ManualBuyerRegistrationProps) {
  const [formValue, setFormValue] = React.useState<Buyer>(INITIAL_FORM_VALUE)
  const [hasClickedSubmit, setHasClickedSubmit] = React.useState(false)
  const [isValidPhoneNumber, setIsValidPhoneNumber] = React.useState(false)
  const [error, setError] = React.useState<string>()

  const [saveShortlistApi, { isLoading: isSavingShortlist }] =
    useSaveShortlistMutation()

  const [registerBuyerApi, { isLoading: isRegisteringBuyer }] =
    useRegisterBuyerMutation()

  const isProcessing = isSavingShortlist || isRegisteringBuyer

  const handleInput: (
    index: keyof Buyer
  ) => React.ChangeEventHandler<HTMLInputElement> = React.useCallback(
    (index) => (e) => {
      setFormValue((prevVal) => ({
        ...prevVal,
        [index]: e.target.value,
      }))
    },
    []
  )

  const handlePhoneInput = React.useCallback((value: string) => {
    setFormValue((prevVal) => ({
      ...prevVal,
      phoneNumber: value,
    }))
  }, [])

  const validateForm = React.useCallback(() => {
    if (!isValidPhoneNumber) {
      return false
    }
    if (!formValue) {
      setError('Form cannot be empty')
      return false
    }
    return true
  }, [formValue, isValidPhoneNumber])

  const saveShortlistData = React.useCallback(async () => {
    const shortlistedProperties = shortlistData.shortlists.map<
      SaveShortlistParams['shortlistedProperties'][number]
    >((item) => ({
      propertyId: item.propertyId,
      archived: false,
      configuration:
        item.type === ShortlistPropertyType.Lot
          ? {
              packageId: item.configuration?.floorplan || undefined,
              floorPlanId: item.configuration?.floorplanOption || undefined,
              facadeId: item.configuration?.facade || undefined,
              facadeColorId: item.configuration?.facadeColor || undefined,
              internalThemeId: item.configuration?.internalTheme || undefined,
              internalColorSchemeId:
                item.configuration?.internalColorScheme || undefined,
            }
          : undefined,
    }))
    try {
      const buyerResponse = await registerBuyerApi({
        firstName: formValue.firstName,
        lastName: formValue.lastName,
        email: formValue.email,
        phoneNumber: formValue.phoneNumber,
      }).unwrap()
      const { _id: userId, email: registeredEmail } = buyerResponse.data || {}

      if (!buyerResponse.success || !userId || !registeredEmail) {
        throw new Error(buyerResponse.message)
      }
      const saveResponse = await saveShortlistApi({
        project: projectName,
        shortlistedProperties,
        agentId,
        documentIds,
        user: userId,
      }).unwrap()
      if (!saveResponse.success) {
        throw new Error(saveResponse.message)
      }
      onRegistrationComplete({
        email: registeredEmail,
        isLinked: registeredEmail !== formValue.email,
      })
    } catch (error) {
      notifyError('Failed to submit form')
      console.log(error)
    }
  }, [shortlistData, projectName, formValue])

  const handleSubmit: React.FormEventHandler = React.useCallback(
    (e) => {
      e.preventDefault()
      if (!validateForm()) {
        return
      }
      saveShortlistData()
    },
    [formValue, validateForm, saveShortlistData]
  )

  return (
    <div className={className}>
      <form onSubmit={handleSubmit} className="flex flex-col gap-6">
        <div className="flex flex-col gap-4">
          <div className="text-left">
            <h4 className="text-sm font-semibold">BUYER NAME</h4>
            <div className="flex gap-3">
              <input
                className="my-2 w-full rounded border-gray-200 px-6 py-2 text-base focus:border-mainColour focus:ring-0"
                type="text"
                name="firstName"
                placeholder="First Name"
                value={formValue.firstName}
                onChange={handleInput('firstName')}
                required
              />
              <input
                className="my-2 w-full rounded border-gray-200 px-6 py-2 text-base focus:border-mainColour focus:ring-0"
                type="text"
                name="lastName"
                placeholder="Last Name"
                value={formValue.lastName}
                onChange={handleInput('lastName')}
                required
              />
            </div>
          </div>
          <div className="text-left">
            <h4 className="text-sm font-semibold">BUYER PHONE</h4>
            <PhoneInput
              className="my-2"
              value={formValue.phoneNumber}
              onChange={handlePhoneInput}
              onValidityChange={(status) => setIsValidPhoneNumber(status)}
              showInputError={hasClickedSubmit}
              required
            />
          </div>
          <div className="text-left">
            <h4 className="text-sm font-semibold">BUYER EMAIL</h4>
            <input
              className="my-2 w-full rounded border-gray-200 px-6 py-2 text-base focus:border-mainColour focus:ring-0"
              type="email"
              name="email"
              placeholder="john.doe@gmail.com"
              value={formValue.email}
              onChange={handleInput('email')}
              required
            />
          </div>
        </div>
        {error ? (
          <div>
            <p>{error}</p>
          </div>
        ) : null}
        <div className="flex justify-between gap-4">
          <button
            type="button"
            className="p-2 font-medium underline"
            onClick={onShowQRCode}
          >
            Show QR code instead
          </button>
          <button
            type="submit"
            className={`rounded px-4 py-2 font-medium text-white shadow-standard ${
              isProcessing ? 'bg-blue-400' : 'bg-blue-500'
            }`}
            disabled={isProcessing}
            onClick={() => setHasClickedSubmit(true)}
          >
            <div className="flex items-center gap-2">
              {isProcessing && <LoadingSvg className="h-5 w-5" />}
              <span>Submit</span>
            </div>
          </button>
        </div>
      </form>
    </div>
  )
}

export default ManualBuyerRegistration
