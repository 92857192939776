import React from 'react'
import { connect } from 'react-redux'

import {
  GalleryControlInterface,
  GalleryInterface,
  GalleryItemInterface,
  PlayerControlInterface,
  PlayerState,
  ProjectIdentity,
  RootStateTypeExtra,
} from '@store/types'

import CarouselHandler from '@components/carousel-handler'
import CarouselOverlay from '@components/carousel-overlay'
import VideoPlayer from '@components/video-player'

import {
  UnitGalleryInterface,
  selectFromResult as selectFromUnitGalleryResult,
  useGetUnitGalleryQuery,
} from '@api/unit-gallery'

import useTabVisibility from '@utilities/tab-visibility'
import VideoPlayerFirebaseService from '@utilities/video-player-firebase-service'

import FirebaseControlQuery from '@firebaseUtil/firebase-control-query'
import { SessionMap } from '@firebaseUtil/types'

import { Unit, ViewLineImage } from '@adUtilities/types/apartment'

export interface UnitGalleryControlSessionInterface {
  galleryControl: GalleryControlInterface
  playerControl: PlayerControlInterface
}
interface UnitGalleryProps {
  session: SessionMap | undefined
  projectIdentity: ProjectIdentity
  unit: Unit
  getUnitGalleryItemLength: (unitGalleryItemLength: number) => void
  onlyShowUnitGalleries: boolean
  hasBlurredEffect?: boolean
  imgClass?: string
}

const NO_IMAGE_OBJECT: GalleryItemInterface = {
  id: 'no-image',
  label: 'No Image',
  imageSource: '',
}

function UnitGallery({
  session,
  projectIdentity,
  unit,
  getUnitGalleryItemLength,
  onlyShowUnitGalleries,
  hasBlurredEffect,
  imgClass,
}: UnitGalleryProps) {
  const BUCKET_URL = process.env.REACT_APP_BUCKET_LEGACY
  const [unitGallery, setUnitGallery] = React.useState<Array<GalleryInterface>>(
    []
  )

  const [showEnlargedView, setShowEnlargedView] = React.useState(false)

  const unitGalleryPayload = useGetUnitGalleryQuery(
    { projectName: projectIdentity.projectId, unitId: unit.id },
    { selectFromResult: selectFromUnitGalleryResult }
  )

  const { unitGalleryData, isLoaded, isFetching } = unitGalleryPayload
  const firebaseControlQuery = FirebaseControlQuery({
    ...projectIdentity,
    sessionKey: projectIdentity.sessionId,
  })

  const videoPlayerFirebaseService = VideoPlayerFirebaseService({
    galleryName: 'propertyGallery',
    projectIdentity,
  })

  const [activeSlideIndex, setActiveSlideIndex] = React.useState(0)
  const [isBuffering, setBufferState] = React.useState(false)
  const [scrubbarTimerState, setScrubbarTimerState] = React.useState(false)
  const [playerState, setPlayerState] = React.useState<PlayerState>(
    PlayerState.Stop
  )
  const [isMuted, setMuteState] = React.useState(true)
  const [volume, setVolume] = React.useState<number>(0)
  const [videoLengthInSeconds, setVideoLengthInSeconds] = React.useState(0)
  const [currentVideoPositionInSeconds, setCurrentVideoPositionInSeconds] =
    React.useState(0)
  const [isSeeking, setSeekingState] = React.useState(false)

  const hasVideo = React.useMemo(
    () => unitGallery[0]?.items?.some((item) => !!item.videoSource),
    [unitGallery]
  )

  const activeItem = React.useMemo(
    () => unitGallery[0]?.items[activeSlideIndex],
    [unitGallery, activeSlideIndex]
  )

  const buildGallery = () => {
    const projectIdHash = projectIdentity.projectUUID
    const galleryItems: Array<GalleryItemInterface> = []

    const {
      metas: { floorImage, viewLineImages, floorPdf },
    } = unit

    if (!onlyShowUnitGalleries && viewLineImages && viewLineImages.length > 0) {
      viewLineImages.forEach((viewLineImage: ViewLineImage) => {
        galleryItems.push({
          id: viewLineImage.id,
          imageSource: `${BUCKET_URL}/${projectIdHash}/views/${viewLineImage.src}`,
        })
      })
    }

    if (!onlyShowUnitGalleries && floorImage) {
      galleryItems.unshift({
        id: floorPdf,
        imageSource: `${BUCKET_URL}/${projectIdHash}/floorplans/preview/${floorImage}`,
      })
    }

    if (unitGalleryData.length > 0) {
      unitGalleryData.forEach((galleryItem: UnitGalleryInterface) => {
        const imageSource =
          galleryItem.type === 'video'
            ? galleryItem.thumbnail || ''
            : galleryItem.src
        const videoSource = galleryItem.type === 'video' ? galleryItem.src : ''
        galleryItems.push({ id: galleryItem.id, imageSource, videoSource })
      })
    }

    getUnitGalleryItemLength(galleryItems.length)

    if (!galleryItems.length) {
      setUnitGallery([{ items: [NO_IMAGE_OBJECT] }])
      return
    }

    if (galleryItems.length > 0) {
      setUnitGallery([{ items: galleryItems }])
    }
  }

  const handlePlayClick = React.useCallback(async () => {
    setPlayerState(PlayerState.Play)
    setScrubbarTimerState(false)
    await videoPlayerFirebaseService.updatePlayState()
  }, [])

  const handlePauseClick = React.useCallback(async () => {
    setPlayerState(PlayerState.Pause)
    setScrubbarTimerState(false)
    await videoPlayerFirebaseService.updatePauseState()
  }, [])

  const handleStopClick = React.useCallback(async () => {
    setScrubbarTimerState(false)
    setPlayerState(PlayerState.Stop)
    setVideoLengthInSeconds(0)
    setCurrentVideoPositionInSeconds(0)
    await videoPlayerFirebaseService.updateStopState()
  }, [])

  const handleSeekTo = React.useCallback(async (argInSeconds: number) => {
    setScrubbarTimerState(false)
    setSeekingState(true)
    await videoPlayerFirebaseService.updateSeekState(argInSeconds)
  }, [])

  const handleMuteClick = React.useCallback(async (arg: boolean) => {
    setMuteState(arg)
    await videoPlayerFirebaseService.updateMuteState(arg)
  }, [])

  const handleVolumeDownClick = React.useCallback(async () => {
    if (volume > 0) {
      const newVolume = volume - 1
      setMuteState(false)
      setVolume(newVolume)
      await videoPlayerFirebaseService.updateVolumeState(newVolume)
    }
  }, [volume])

  const handleVolumeUpClick = React.useCallback(async () => {
    if (volume < 10) {
      setMuteState(false)
      const newVolume = volume + 1
      setVolume(newVolume)
      await videoPlayerFirebaseService.updateVolumeState(newVolume)
    }
  }, [volume])

  const updateSyncState = React.useCallback(async () => {
    await videoPlayerFirebaseService.updateSyncState()
  }, [videoPlayerFirebaseService])

  useTabVisibility(
    React.useCallback(async () => {
      if (playerState === PlayerState.Play) {
        updateSyncState()
      }
    }, [playerState])
  )

  const handleChangeActiveItemIndex = React.useCallback(
    async (index: number) => {
      setActiveSlideIndex(index)
      setPlayerState(PlayerState.Stop)
      setScrubbarTimerState(false)
      await firebaseControlQuery.update({
        [`propertyGallery.galleryControlV2.autoPlayState`]: false,
        [`propertyGallery.galleryControlV2.activeSlideIndex`]: index,
        [`propertyGallery.playerControlV2.playerState`]: PlayerState.Stop,
        [`propertyGallery.playerControlV2.scrubbarTimerState`]: false,
        [`propertyGallery.playerControlV2.videoLengthInSeconds`]: 0,
        [`propertyGallery.playerControlV2.currentVideoPositionInSeconds`]: 0,
      })
    },
    [activeSlideIndex]
  )

  const handlePlayButtonClick = React.useCallback(async (index: number) => {
    setActiveSlideIndex(index)
    setPlayerState(PlayerState.Play)
    setScrubbarTimerState(false)
    await firebaseControlQuery.update({
      [`propertyGallery.galleryControlV2.autoPlayState`]: false,
      [`propertyGallery.galleryControlV2.activeSlideIndex`]: index,
      [`propertyGallery.playerControlV2.playerState`]: PlayerState.Play,
      [`propertyGallery.playerControlV2.scrubbarTimerState`]: false,
    })
  }, [])

  React.useEffect(() => {
    if (!session) {
      return
    }
    const {
      propertyGallery: {
        galleryControlV2: { activeSlideIndex: activeSlideIndexFirebase },
        playerControlV2: {
          playerState: playerStateFirebase,
          videoLengthInSeconds: videoLengthInSecondsFirebase,
          currentVideoPositionInSeconds: currentVideoPositionInSecondsFirebase,
          volume: volumeFirebase,
          scrubbarTimerState: scrubbarTimerStateFirebase,
          seekToValueInSeconds: seekToValueInSecondsFirebase,
          isBuffering: isBufferingFirebase,
          muted: muteStateFirebase,
        },
      },
    } = session
    setActiveSlideIndex(activeSlideIndexFirebase)
    setPlayerState(playerStateFirebase)
    setVideoLengthInSeconds(videoLengthInSecondsFirebase)
    setCurrentVideoPositionInSeconds(currentVideoPositionInSecondsFirebase)
    setVolume(volumeFirebase)
    setScrubbarTimerState(scrubbarTimerStateFirebase)
    setBufferState(isBufferingFirebase)
    setSeekingState(seekToValueInSecondsFirebase !== null)
    setMuteState(muteStateFirebase)
  }, [session?.propertyGallery])

  React.useEffect(() => {
    buildGallery()
  }, [unit])

  React.useEffect(() => {
    if (unitGalleryData.length > 0) {
      buildGallery()
    }
  }, [unitGalleryData])

  const enableEnlargedView = React.useMemo(
    () =>
      (unitGallery[0]?.items || []).filter(
        (item) => item.id !== NO_IMAGE_OBJECT.id
      ).length >= 1,
    [unitGallery[0]?.items]
  )

  React.useEffect(() => {
    updateSyncState()
  }, [enableEnlargedView, showEnlargedView])

  if (isFetching) {
    return (
      <div className="cursor-hand h-full w-full rounded-lg bg-slate-700/30 text-heading font-medium drop-shadow-lg">
        <div className="shimmer-container relative h-full opacity-50"></div>
      </div>
    )
  }

  if (unitGallery.length > 0) {
    return (
      <>
        <CarouselHandler
          carousel={{
            label: '',
            items: unitGallery[0]?.items || [],
          }}
          imgClass={imgClass}
          hasBlurredEffect={hasBlurredEffect}
          activeIndex={activeSlideIndex}
          showPlay={playerState === PlayerState.Stop}
          onItemClick={() => setShowEnlargedView(true)}
          onChangeActiveItemIndex={handleChangeActiveItemIndex}
          onPlayClick={handlePlayButtonClick}
        />
        {!showEnlargedView && hasVideo ? (
          <div
            className={`absolute bottom-0 z-40 w-full pb-4 transition-all duration-300 ${
              playerState !== PlayerState.Stop
                ? 'translate-y-0 opacity-100'
                : 'pointer-events-none fixed translate-y-full opacity-0'
            }`}
          >
            <VideoPlayer
              scrubbarTimerState={scrubbarTimerState}
              isBuffering={isBuffering}
              isMuted={isMuted}
              volume={volume}
              isLooping={false}
              playerState={playerState}
              videoLengthInSeconds={videoLengthInSeconds}
              currentVideoPositionInSeconds={currentVideoPositionInSeconds}
              isSeeking={isSeeking}
              handleStopClick={handleStopClick}
              handlePauseClick={handlePauseClick}
              handlePlayClick={handlePlayClick}
              handleMuteClick={handleMuteClick}
              handleVolumeUpClick={handleVolumeUpClick}
              handleVolumeDownClick={handleVolumeDownClick}
              handleSeekTo={handleSeekTo}
              showLoopOption={false}
            />
          </div>
        ) : null}
        {enableEnlargedView && showEnlargedView ? (
          <CarouselOverlay
            carouselData={{
              index: activeSlideIndex,
              item: activeItem,
            }}
            carouselItems={unitGallery[0]?.items || []}
            onItemClick={handleChangeActiveItemIndex}
            showPlay={playerState === PlayerState.Stop}
            onPlayClick={handlePlayButtonClick}
            showEnlargedView={showEnlargedView}
            onOverlayClick={() => setShowEnlargedView(false)}
          >
            {hasVideo ? (
              <div
                className={`absolute bottom-0 z-40 w-full px-20 pb-4 transition-all duration-300 ${
                  playerState !== PlayerState.Stop
                    ? 'translate-y-0 opacity-100'
                    : 'pointer-events-none fixed translate-y-full opacity-0'
                }`}
              >
                <VideoPlayer
                  scrubbarTimerState={scrubbarTimerState}
                  isBuffering={isBuffering}
                  isMuted={isMuted}
                  volume={volume}
                  isLooping={false}
                  playerState={playerState}
                  videoLengthInSeconds={videoLengthInSeconds}
                  currentVideoPositionInSeconds={currentVideoPositionInSeconds}
                  isSeeking={isSeeking}
                  handleStopClick={handleStopClick}
                  handlePauseClick={handlePauseClick}
                  handlePlayClick={handlePlayClick}
                  handleMuteClick={handleMuteClick}
                  handleVolumeUpClick={handleVolumeUpClick}
                  handleVolumeDownClick={handleVolumeDownClick}
                  handleSeekTo={handleSeekTo}
                  showLoopOption={false}
                />
              </div>
            ) : null}
          </CarouselOverlay>
        ) : null}
      </>
    )
  }

  if (hasBlurredEffect && isLoaded) {
    return (
      <div className="flex h-full w-full items-center justify-center rounded-lg bg-secondaryColour text-mainColour">
        No gallery found
      </div>
    )
  }

  return null
}

export default connect(
  ({ projectIdentity, firestore: { session } }: RootStateTypeExtra) => ({
    session,
    projectIdentity,
  })
)(UnitGallery)
