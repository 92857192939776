import React from 'react'
import { connect, useDispatch } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'

import { setProjectIdentity } from '@store/actionSlices/projectIdentity'
import { ShortlistState } from '@store/actionSlices/shortlist'
import { setWhiteLabel } from '@store/actionSlices/whiteLabel'
import { NavigationMenuKey, RouteKey } from '@store/constants'
import {
  MenuItemInterface,
  ProjectIdentity,
  RootStateTypeExtra,
  ShortlistPropertyType,
} from '@store/types'

import ImageHandler from '@components/image-handler'

import useGetShortlistData from '@pages/shortlist/useGetShortlistData'

import {
  selectFromResult as selectFromConfigResult,
  useGetConfigQuery,
} from '@api/config'
import {
  ExternalViewsData,
  selectFromResult as selectFromExternalViewsResult,
  useGetExternalViewsQuery,
} from '@api/external-views'
import { ShowcaseRoomConfig } from '@api/showcase-room'
import {
  selectFromWhiteLabelResult,
  useGetWhiteLabelsQuery,
} from '@api/white-label'

import useHiddenNavs from '@utilities/use-hidden-navs'

import FirebaseControlQuery from '@firebaseUtil/firebase-control-query'
import { SessionMap } from '@firebaseUtil/types'

import { DefaultLogoSvg, StarSvg } from '@svg/react'

import { ProjectType } from '@adUtilities/constants/common'

import MENU_ITEMS from '../menu-items'
import ExternalMenuItems from './external-menu-items'

interface ComponentPropsInterface {
  projectIdentity: ProjectIdentity
  shortlist: ShortlistState
  session: SessionMap | undefined
  showcaseRoomConfig: ShowcaseRoomConfig
  fullScreenMode?: boolean
}

const skipNavItems = ['session-settings', 'tools', 'shortlist']

function Navigation({
  projectIdentity,
  shortlist,
  session,
  fullScreenMode,
  showcaseRoomConfig,
}: ComponentPropsInterface) {
  const dispatch = useDispatch()
  const location = useLocation()
  const history = useHistory()
  const hiddenNavs = useHiddenNavs()
  const propertyType = React.useMemo<ShortlistPropertyType>(
    () =>
      projectIdentity.type === ProjectType.HouseAndLand
        ? ShortlistPropertyType.Lot
        : ShortlistPropertyType.Unit,
    []
  )

  const activeExternalViewItemIndex = React.useMemo(() => {
    const index = location?.state?.externalView
    return index === undefined ? null : index
  }, [location?.state?.externalView])

  useGetShortlistData({
    propertyType,
    session,
    projectIdentity,
    shortlists: shortlist.shortlists,
  })

  const firebaseControlQuery = FirebaseControlQuery({
    ...projectIdentity,
    sessionKey: projectIdentity.sessionId,
  })

  const [externalMenuItemsToggle, setExternalMenuItemsToggle] =
    React.useState(false)

  const [externalViews, setExternalViews] = React.useState<
    Array<ExternalViewsData>
  >([])

  const {
    configData,
    isLoaded: isConfigLoaded,
    isError: configHasError,
  } = useGetConfigQuery(
    { projectName: projectIdentity.projectId },
    {
      selectFromResult: selectFromConfigResult,
      skip: !projectIdentity.projectId,
    }
  )

  const {
    whiteLabelData,
    isLoaded: isWhiteLabelLoaded,
    isError: whiteLabelHasError,
  } = useGetWhiteLabelsQuery(
    { projectName: projectIdentity.projectId },
    {
      selectFromResult: selectFromWhiteLabelResult,
      skip: !projectIdentity.projectId,
    }
  )

  const getRouteLink = (navItem: MenuItemInterface): string => {
    const { type: projectType } = projectIdentity

    if (navItem.key !== NavigationMenuKey.RESIDENCES) {
      return navItem.route || ''
    }

    if (projectType === ProjectType.HouseAndLand) {
      return RouteKey.AREA_VIEW_HOUSE_AND_LAND
    }

    return RouteKey.AREA_VIEW
  }

  const externalViewsPayload = useGetExternalViewsQuery(
    { projectName: projectIdentity.projectId },
    {
      selectFromResult: selectFromExternalViewsResult,
      skip:
        projectIdentity?.navigationSettings.find(
          (item: MenuItemInterface) =>
            item.key === 'external-routes' && item.active
        ) === undefined,
    }
  )

  const handleMenuClick = async (item: MenuItemInterface) => {
    const { routeViaFirebase } = item
    if (!item.route) {
      return
    }
    const route = getRouteLink(item)
    history.push({
      pathname: `/${route}`,
      state: { from: location.pathname },
    })
    if (routeViaFirebase) {
      await firebaseControlQuery.updateRoute(route)
    }
  }

  const handleExternalViewMenuClick = async (
    item: MenuItemInterface,
    itemIndex: number
  ) => {
    const { route, routeViaFirebase } = item
    if (!route) {
      return
    }
    history.push({
      pathname: `/${route}`,
      state: { from: location.pathname, externalView: itemIndex },
    })
    if (routeViaFirebase) {
      await firebaseControlQuery.updateRoute(route)
    }
  }

  const navigationItems = React.useMemo(() => {
    const { navigationSettings } = projectIdentity
    if (!isConfigLoaded || configHasError) {
      return []
    }

    if (navigationSettings?.length < 1) {
      return MENU_ITEMS
    }

    return MENU_ITEMS.map((item: MenuItemInterface) => {
      const foundItem = navigationSettings.find(
        (navigationSetting: MenuItemInterface) =>
          navigationSetting.key === item.key
      )

      return foundItem
        ? {
            ...item,
            label: foundItem.label,
            active: foundItem.active,
            order: foundItem.order,
          }
        : item
    })
      .sort((a, b) => {
        if (!Number.isNaN(a.order ?? NaN) && !Number.isNaN(b.order ?? NaN)) {
          return Number(a.order) - Number(b.order)
        }
        return MENU_ITEMS.indexOf(a) - MENU_ITEMS.indexOf(b)
      })
      .filter((item: MenuItemInterface) => !!item.active)
  }, [hiddenNavs, projectIdentity])

  const shortlistMenuItem = React.useMemo(
    () =>
      navigationItems.find(
        (item: MenuItemInterface) => item.key === 'shortlist'
      ),
    [navigationItems]
  )
  const shortlistCount = React.useMemo(
    () => shortlist?.shortlists?.length || 0,
    [shortlist]
  )

  React.useEffect(() => {
    if (isConfigLoaded && !configHasError) {
      dispatch(
        setProjectIdentity({
          ...projectIdentity,
          ...configData,
          ...showcaseRoomConfig,
        })
      )
    }
  }, [isConfigLoaded, showcaseRoomConfig])

  React.useEffect(() => {
    if (isWhiteLabelLoaded && !whiteLabelHasError) {
      dispatch(setWhiteLabel(whiteLabelData))
    }
  }, [isWhiteLabelLoaded])

  React.useEffect(() => {
    const { externalViewsData, isLoaded: isExternalViewLoaded } =
      externalViewsPayload
    if (
      isExternalViewLoaded &&
      externalViewsData.length > 0 &&
      externalViews.length === 0
    ) {
      setExternalViews(externalViewsData)
    }
  }, [externalViewsPayload])

  const activeRoute = React.useMemo(() => {
    const paths = location.pathname.split('/')
    return paths[1] || ''
  }, [location])

  return (
    <nav
      className={`absolute flex h-9 w-full items-center justify-between bg-neutralColour px-8 text-xs font-semibold tracking-widest transition-all duration-300 ${
        fullScreenMode ? '-top-9' : 'top-0 z-20'
      }`}
    >
      <div>
        <button
          type="button"
          onClick={() =>
            handleMenuClick({
              key: 'vision',
              label: 'Vision',
              route: 'vision',
              routeViaFirebase: true,
              active: true,
              type: 'main-menu',
              order: 1,
            })
          }
        >
          {projectIdentity?.logo?.visible && projectIdentity?.logo?.url ? (
            <ImageHandler
              url={projectIdentity.logo.url}
              type="legacy"
              className="h-7"
              alt="logo"
              showFallbackImage={false}
              isImage
            />
          ) : (
            <DefaultLogoSvg className="h-7 w-7" />
          )}
        </button>
      </div>

      <ul className="absolute left-1/2 top-1/2 z-30 mx-auto flex -translate-x-1/2 -translate-y-1/2 cursor-pointer items-center bg-neutralColour uppercase">
        {navigationItems
          .filter(
            (item: MenuItemInterface) =>
              !hiddenNavs.includes(item.key) && !skipNavItems.includes(item.key)
          )
          .map((navItem: MenuItemInterface) =>
            navItem.key === 'external-routes' ? (
              <li className="relative shrink-0" key={navItem.key}>
                <button
                  title={navItem.label}
                  type="button"
                  className={`nav-menu-item uppercase ${
                    navItem.relatedRoutes?.includes(activeRoute)
                      ? 'bg-secondaryColour'
                      : ''
                  }`}
                  onMouseEnter={() => setExternalMenuItemsToggle(true)}
                  onMouseLeave={() => setExternalMenuItemsToggle(false)}
                >
                  {navItem.label}
                </button>
                <ExternalMenuItems
                  externalViews={externalViews}
                  externalMenuItemsToggle={externalMenuItemsToggle}
                  setExternalMenuItemsToggle={setExternalMenuItemsToggle}
                  activeExternalViewItemIndex={activeExternalViewItemIndex}
                  handleExternalViewMenuClick={handleExternalViewMenuClick}
                />
              </li>
            ) : (
              <li className="shrink-0" key={navItem.key}>
                <button
                  title={navItem.label}
                  className={`nav-menu-item ${
                    navItem.relatedRoutes?.includes(activeRoute)
                      ? 'bg-secondaryColour'
                      : ''
                  }`}
                  onClick={() => handleMenuClick(navItem)}
                  type="button"
                >
                  {navItem.label}
                </button>
              </li>
            )
          )}
      </ul>

      {shortlistMenuItem && shortlistMenuItem.active && (
        <div className="flex items-center">
          <button
            title={shortlistMenuItem.label}
            className="inline-flex items-center"
            type="button"
            onClick={() => handleMenuClick(shortlistMenuItem)}
          >
            <div className="mr-2 rounded-full bg-secondaryColour p-1">
              <StarSvg
                className={`h-2.5 w-2.5 stroke-mainColour ${
                  (shortlistCount ?? 0) > 0
                    ? 'fill-mainColour'
                    : 'fill-transparent'
                }`}
              />
            </div>
            <span className="font-semibold uppercase text-mainColour">
              {(shortlistCount ?? 0) > 0
                ? `${shortlistCount} SHORTLISTED`
                : shortlistMenuItem.label}
            </span>
          </button>
        </div>
      )}
    </nav>
  )
}

export default connect(
  ({
    projectIdentity,
    shortlist,
    firestore: { session },
    showcaseRoom: showcaseRoomConfig,
  }: RootStateTypeExtra) => ({
    projectIdentity,
    shortlist,
    session,
    showcaseRoomConfig,
  })
)(Navigation)
