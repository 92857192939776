import React from 'react'
import { useDispatch } from 'react-redux'

import {
  setPackageSummary,
  setPrecinctList,
} from '@store/actionSlices/houseAndLand'

import {
  selectMatrixDataFromResult,
  selectPrecinctListFromResult,
  useGetMatrixDataByPrecinctQuery,
  useGetPrecinctListQuery,
} from '@api/houseAndLand'

interface ComponentProps {
  projectName: string
  precinctId: string
}
const useGetFilterData = ({ projectName, precinctId }: ComponentProps) => {
  const dispatch = useDispatch()
  const { matrixData, isFetching, status, isError, isLoaded } =
    useGetMatrixDataByPrecinctQuery(
      {
        projectName,
        precinctIdOrLabel: precinctId,
      },
      {
        selectFromResult: selectMatrixDataFromResult,
        skip: precinctId === '',
      }
    )
  const precinctsPayload = useGetPrecinctListQuery(
    { projectName },
    {
      selectFromResult: selectPrecinctListFromResult,
    }
  )

  React.useEffect(() => {
    if (Object.keys(matrixData).length > 0) {
      dispatch(
        setPackageSummary({
          [precinctId]: matrixData,
        })
      )
    }
  }, [matrixData])

  React.useEffect(() => {
    const { precinctList } = precinctsPayload
    if (precinctList?.length > 0) {
      dispatch(setPrecinctList(precinctList))
    }
  }, [precinctsPayload])
  return { isFetching, status, isError, isLoaded, data: precinctsPayload }
}
export default useGetFilterData
