import React, { useEffect, useMemo, useRef, useState } from 'react'
import { connect } from 'react-redux'

import {
  ProjectIdentity,
  RootStateTypeExtra,
  ThemeConfigInterface,
} from '@store/types'

import Container from '@components/container/v2'
import { Status } from '@components/data-handler/data-handler'
import DataHandler from '@components/data-handler/v2'
import FILTER_INITIAL_STATE from '@components/filter/filterState'
import ImageHandler from '@components/image-handler'
import { CanvasInteractive } from '@components/showcase-canvas'
import { CanvasRefInterface } from '@components/showcase-canvas/canvas-interactive'
import { Polygon } from '@components/showcase-canvas/types'

import {
  BuildingInterface,
  selectFromResult as selectFromBuildingResult,
  useGetBuildingQuery,
} from '@api/building'
import {
  InteractivePlanData,
  MapContent,
  selectFromResult,
  useGetInteractivePlanQuery,
} from '@api/interactive-plan'

import styleUtil, { BaseThemeConfig } from '@utilities/style-util'

import FirebaseControlQuery from '@firebaseUtil/firebase-control-query'
import { SessionMap } from '@firebaseUtil/types'

import AreaViewApartmentsSkeleton from './area-view-skeleton'

export interface AreaViewProps {
  session: SessionMap | undefined
  projectIdentity: ProjectIdentity
}

const ARROW_TYPE = 'arrow'
const ARROW_SMALL_TYPE = 'arrow-small'

function AreaViewApartments({ session, projectIdentity }: AreaViewProps) {
  const firebaseControlQuery = FirebaseControlQuery({
    ...projectIdentity,
    sessionKey: projectIdentity.sessionId,
  })

  const themeData: BaseThemeConfig = styleUtil()

  const canvasContainerRef = useRef<HTMLDivElement>(null)
  const canvasRef = useRef<CanvasRefInterface>()

  const [isCanvasImageLoaded, setImageLoaded] = useState<boolean>(false)

  const [interactivePlan, setInteractivePlan] = useState<InteractivePlanData>({
    areaView: {
      image: '',
      polygons: [],
    },
    blocks: {},
    floorplan: {},
    precinct: {},
    stages: {},
  })

  const [building, setBuilding] = useState<BuildingInterface>({
    levels: [],
    blockOrders: [],
  })

  const [theme, setTheme] = useState<
    Pick<ThemeConfigInterface, 'font' | 'mainColour'>
  >({
    font: '',
    mainColour: '',
  })

  const [area, setArea] = useState<MapContent>({
    image: '',
    polygons: [],
  })

  const interactivePlanPayload = useGetInteractivePlanQuery(
    { projectName: projectIdentity.projectName },
    { selectFromResult }
  )

  const buildingPayload = useGetBuildingQuery(
    { projectName: projectIdentity.projectName },
    { selectFromResult: selectFromBuildingResult }
  )

  const apiStatus = useMemo(() => {
    const { FULFILLED, REJECTED, PENDING } = Status
    const { status: interactivePayloadStatus } = interactivePlanPayload
    const { status: buildingPayloadStatus } = buildingPayload

    if (
      interactivePayloadStatus === FULFILLED &&
      buildingPayloadStatus === FULFILLED
    ) {
      return FULFILLED
    }

    if (
      interactivePayloadStatus === REJECTED ||
      buildingPayloadStatus === REJECTED
    ) {
      return REJECTED
    }

    return PENDING
  }, [interactivePlanPayload.status, buildingPayload.status])

  const updateTriggerBuilding = (state: boolean) =>
    firebaseControlQuery.update({
      [`areaView.triggerBuilding`]: state,
    })

  const resetBuildingMap = () =>
    firebaseControlQuery.update({
      [`building.activeBlock`]: '',
      [`building.activeLevel`]: '',
      [`building.activeUnit`]: '',
      [`building.unitGalleryIndex`]: 0,
    })

  const resetBuildingView = () =>
    firebaseControlQuery.update({
      [`building.sidePanelFolded`]: true,
      [`building.unitFilter`]: FILTER_INITIAL_STATE,
    })

  const getLabel = (poly: Polygon) => {
    const polyType = poly.type || ''
    if (polyType === ARROW_TYPE || polyType === ARROW_SMALL_TYPE) {
      return poly.label || poly.groupId
    }
    return poly.label
  }

  const setInteractiveAction = (areaViewMap: MapContent) => ({
    ...areaViewMap,
    polygons: areaViewMap.polygons.map((poly) => ({
      ...poly,
      label: getLabel(poly),
      onClick: async () => {
        await updateTriggerBuilding(true)
      },
      onComplete: async () => {
        await firebaseControlQuery.update({
          [`building.activeBlock`]: poly.groupId,
        })
      },
      markerColour: projectIdentity?.markerColourSettings?.area,
    })),
  })

  useEffect(() => {
    if (interactivePlan.areaView?.image) {
      setArea(setInteractiveAction(interactivePlan.areaView))
    }
  }, [interactivePlan])

  useEffect(() => {
    if (
      !interactivePlan.areaView?.image &&
      interactivePlanPayload.maps.areaView
    ) {
      setInteractivePlan(interactivePlanPayload.maps)
    }
  }, [interactivePlanPayload, interactivePlan])

  const sortedLevels = useMemo(
    () =>
      [...buildingPayload.levels].sort(
        (a, b) => (a?.height || 0) - (b?.height || 0)
      ),
    [buildingPayload.levels]
  )

  useEffect(() => {
    const { levels, blockOrders } = buildingPayload

    if (building.levels.length === 0 && levels.length > 0) {
      setBuilding((prev) => ({
        ...prev,
        levels: sortedLevels,
        blockOrders,
      }))
    }
  }, [buildingPayload, building])

  useEffect(() => {
    const themeFromStorage = JSON.parse(
      localStorage.getItem('themeObject') || '{}'
    )

    if (themeFromStorage) {
      setTheme(themeFromStorage)
    }
  }, [])

  useEffect(() => {
    if (!session) {
      return
    }

    const {
      areaView: { triggerBuilding },
      building: { activeLevel, activeUnit },
    } = session

    if (activeLevel || activeUnit) {
      resetBuildingMap().catch((error) =>
        console.error('Something went wrong while resetting building ', error)
      )
    }

    if (triggerBuilding) {
      setTimeout(async () => {
        await updateTriggerBuilding(false)
        await resetBuildingView()
        await firebaseControlQuery.updateRoute('level-view')
      }, 1000)
    }
  }, [session])

  return (
    <Container>
      <DataHandler
        payload={{
          ...interactivePlanPayload,
          data: building,
          status: apiStatus,
        }}
        skeletonFrame={<AreaViewApartmentsSkeleton />}
      >
        {area.polygons.length > 0 ? (
          <>
            <ImageHandler
              key={area.image}
              url={area.image}
              type="new"
              className="image-blur background-cover-no-scroll absolute inset-0 z-2"
              noSpliceUrl
              showFallbackImage={false}
              bgProps={{
                gradiant: 0.5,
              }}
            />
            <div className="absolute inset-0 z-3">
              <div
                key="area-canvas"
                data-testid="area-canvas"
                ref={canvasContainerRef}
                className={`relative flex h-full w-full items-center justify-center overflow-hidden ${
                  isCanvasImageLoaded ? 'opacity-100' : 'opacity-0'
                }`}
              >
                <CanvasInteractive
                  ref={canvasRef}
                  id="area-canvas"
                  canvasData={area}
                  parentRef={canvasContainerRef}
                  theme={{
                    brandColour:
                      theme?.mainColour || themeData.mainColour || '',
                    font: theme?.font || themeData.font || '',
                  }}
                  adjustCanvasSizeWithContainer
                  setImageLoaded={setImageLoaded}
                />
              </div>
            </div>
          </>
        ) : (
          <>No Polygon</>
        )}
      </DataHandler>
    </Container>
  )
}

export default connect(
  ({ firestore: { session }, projectIdentity }: RootStateTypeExtra) => ({
    session,
    projectIdentity,
  })
)(AreaViewApartments)
