import React, { useEffect } from 'react'
import { connect } from 'react-redux'

import { ProjectIdentity, RootStateTypeExtra } from '@store/types'

import FirebaseControlQuery from '@firebaseUtil/firebase-control-query'
import { SessionMap } from '@firebaseUtil/types'

import { ArrowSvg } from '@svg/react'

interface ComponentProps {
  session: SessionMap | undefined
  projectIdentity: ProjectIdentity
}

function BackButton({ session, projectIdentity }: ComponentProps) {
  const firebaseControlQuery = FirebaseControlQuery({
    ...projectIdentity,
    sessionKey: projectIdentity.sessionId,
  })

  const [activeLevel, setActiveLevel] = React.useState<string>('')
  const [activeUnit, setActiveUnit] = React.useState<string>('')

  const handleBackNav = async () => {
    if (activeUnit) {
      await firebaseControlQuery.update({
        [`building.activeUnit`]: '',
      })
      return
    }

    if (activeLevel) {
      await firebaseControlQuery.update({
        [`building.activeLevel`]: '',
        [`building.activeUnit`]: '',
      })

      if (projectIdentity?.isSnaploaderActive) {
        await firebaseControlQuery.updateRoute('snaploader-view')
        return
      }
      if (projectIdentity?.isEnvisionVRActive) {
        await firebaseControlQuery.updateRoute('3d-building')
        return
      }
      if (projectIdentity?.hasThreeDViewer) {
        await firebaseControlQuery.updateRoute('areaview-3d')
        return
      }

      await firebaseControlQuery.updateRoute('level-view')
    }
  }

  useEffect(() => {
    if (!session) {
      return
    }

    const {
      building: { activeLevel, activeUnit },
    } = session

    setActiveLevel(activeLevel)
    setActiveUnit(activeUnit)
  }, [session])

  return (
    <button
      type="button"
      className="rounded-full border border-zinc-400 p-1"
      onClick={handleBackNav}
    >
      <ArrowSvg className="h-6 w-6 stroke-zinc-400" rotate="left" />
    </button>
  )
}

export default connect(
  ({ projectIdentity, firestore: { session } }: RootStateTypeExtra) => ({
    session,
    projectIdentity,
  })
)(BackButton)
