import React from 'react'
import { connect } from 'react-redux'

import { RootStateTypeExtra } from '@store/types'

import Container from '@components/container/v2'
import DataHandler from '@components/data-handler/v2'
import { Status } from '@components/data-handler/v2/data-handler'
import MapStack, { Layer } from '@components/showcase-stack/map-stack'

import {
  InteractiveMapData,
  selectStackFromResult as selectFromResult,
  useGetInteractiveMapQuery,
} from '@api/interactive-map'

import { SessionMap } from '@firebaseUtil/types'

import { DEFAULT_MAP_STACK_VALUE } from './constants'
import InteractiveMapSkeleton from './interactive-map-skeleton'
import LayerCategoryPanel from './layer-panel/layer-category-panel'

export interface LayerExtended extends Layer {
  category: string
  label: string
  controlSrc: string
  baseImageOverride: string
  categoryDefault: boolean
  actionRoute: string
  at?: number
}

export interface InteractiveMapProps {
  session: SessionMap | undefined
  projectName: string
}

function InteractiveMap({ session, projectName }: InteractiveMapProps) {
  const interactiveMapPayload = useGetInteractiveMapQuery(
    { projectName },
    { selectFromResult }
  )
  const defaultBackground = interactiveMapPayload?.stack?.src
  const interactiveMapRef = React.useRef<InteractiveMapData>()

  const getRelevantBackground = React.useCallback(
    (layers: LayerExtended[], layer: LayerExtended | undefined) => {
      let canSetLayerBackground = false
      layers.forEach((lyr) => {
        if (lyr.groupId === layer?.groupId) {
          if (lyr.visible && lyr.baseImageOverride) {
            canSetLayerBackground = true
          }
        }
      })
      if (canSetLayerBackground && layer?.baseImageOverride) {
        return layer.baseImageOverride
      }
      const visibleLayer = layers.find((lyr) => lyr.visible)
      return visibleLayer?.baseImageOverride || defaultBackground
    },
    [defaultBackground]
  )

  const getVisibleAnimationLayer = React.useCallback(
    (originalLayer: LayerExtended, layer?: LayerExtended) => {
      const newLayer = {
        ...(layer || originalLayer),
        visible: true,
      }
      if (layer && !layer?.visible) {
        newLayer.src = `${newLayer.src.split('?')[0]}?${Math.random()}`
        newLayer.animation = newLayer.animation
          ? `${newLayer.animation.split('?')[0]}?${Math.random()}`
          : ''
      }
      return newLayer
    },
    []
  )

  const parseFirebaseMapLayers = React.useCallback(
    (firebaseLayers: string[]) =>
      interactiveMapPayload?.stack?.layers.map((originalLayer) => {
        if (!originalLayer.controls) {
          return originalLayer
        }
        if (!firebaseLayers.includes(originalLayer.groupId)) {
          return {
            ...originalLayer,
            visible: false,
          }
        }
        if (originalLayer.visible) {
          return originalLayer
        }
        if (originalLayer.type.toLowerCase() === 'animation') {
          const firebaseLayer = interactiveMapRef.current?.layers?.find(
            (item) =>
              originalLayer.groupId === item.groupId &&
              firebaseLayers.includes(item.groupId)
          )
          return getVisibleAnimationLayer(originalLayer, firebaseLayer)
        }
        return {
          ...originalLayer,
          visible: true,
        }
      }),
    [getVisibleAnimationLayer, interactiveMapPayload?.stack?.layers]
  )

  const { interactiveMap, layerBackground } = React.useMemo<{
    interactiveMap: InteractiveMapData
    layerBackground: string
  }>(() => {
    let newInteractiveMap = {
      ...DEFAULT_MAP_STACK_VALUE,
    }
    if (!session || interactiveMapPayload.status !== Status.FULFILLED) {
      return {
        interactiveMap: newInteractiveMap,
        layer: undefined,
        layerBackground: '',
      }
    }
    if (interactiveMapPayload?.stack) {
      newInteractiveMap = {
        ...interactiveMapPayload.stack,
      }
    }
    const { interactiveMap: firebaseLayers } = session
    const layer = interactiveMapRef.current?.layers?.find(
      (item) => firebaseLayers.includes(item.groupId) && !item.visible
    )
    if (interactiveMapPayload?.stack?.layers) {
      newInteractiveMap.layers = parseFirebaseMapLayers(firebaseLayers)
    }
    const layerBackground = getRelevantBackground(
      newInteractiveMap.layers,
      layer
    )

    return {
      interactiveMap: newInteractiveMap,
      layerBackground,
    }
  }, [session?.interactiveMap, interactiveMapPayload.status])

  React.useEffect(() => {
    interactiveMapRef.current = interactiveMap
  }, [interactiveMap])

  const categoryBackground =
    session?.interactiveMapActiveCategory?.baseImageOverride || ''
  const backgroundImage = React.useMemo(
    () => categoryBackground || layerBackground || defaultBackground,
    [categoryBackground, layerBackground, defaultBackground]
  )
  const categories = React.useMemo<string[]>(() => {
    const newCategories: string[] = []
    interactiveMap?.layers?.forEach((layer: LayerExtended) => {
      if (!newCategories.includes(layer.category) && layer.controls) {
        newCategories.push(layer.category)
      }
    })

    return newCategories
  }, [interactiveMap?.layers])

  return (
    <div>
      <Container
        background={{
          url: backgroundImage,
          type: 'new',
          noSpliceUrl: true,
        }}
      >
        <DataHandler
          payload={{
            ...interactiveMapPayload,
            data: interactiveMap.layers,
          }}
          skeletonFrame={<InteractiveMapSkeleton />}
        >
          <div className="relative h-screen w-screen">
            {interactiveMap?.layers && (
              <>
                <MapStack
                  id="interactive-map"
                  background={backgroundImage}
                  layers={interactiveMap.layers}
                  heightClass="h-page-container"
                />
                {categories.length > 0 ? (
                  <LayerCategoryPanel
                    categories={categories}
                    interactiveMap={interactiveMap}
                  />
                ) : null}
              </>
            )}
          </div>
        </DataHandler>
      </Container>
    </div>
  )
}

export default connect(
  ({
    firestore: { session },
    projectIdentity: { projectName },
  }: RootStateTypeExtra) => ({
    session,
    projectName,
  })
)(InteractiveMap)
