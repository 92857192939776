import React from 'react'
import { connect } from 'react-redux'

import { ProjectIdentity, RootStateTypeExtra } from '@store/types'

import FirebaseControlQuery from '@firebaseUtil/firebase-control-query'
import { SessionMap } from '@firebaseUtil/types'

import { PlaySvg, StopSvg } from '@svg/react'

interface ComponentPropsInterface {
  className?: string
  galleryName: 'propertyGallery'
  session: SessionMap | undefined
  projectIdentity: ProjectIdentity
  isDisabled?: boolean
}

function SlideshowControl({
  className = '',
  galleryName,
  session,
  projectIdentity,
  isDisabled = false,
}: ComponentPropsInterface) {
  const firebaseControlQuery = FirebaseControlQuery({
    ...projectIdentity,
    sessionKey: projectIdentity.sessionId,
  })

  const [isSlideShowPlaying, setSlideShowPlayingState] = React.useState(false)

  const handleClick = () => {
    if (galleryName === 'propertyGallery') {
      firebaseControlQuery.update({
        [`${galleryName}.galleryControlV2.autoPlayState`]: !isSlideShowPlaying,
      })
    }
  }

  React.useEffect(() => {
    if (session && galleryName === 'propertyGallery') {
      const {
        [galleryName]: {
          galleryControlV2: { autoPlayState: slideShowPlayingStateFirebase },
        },
      } = session

      setSlideShowPlayingState(slideShowPlayingStateFirebase)
    }
  }, [session])

  return (
    <button
      type="button"
      disabled={isDisabled}
      onClick={handleClick}
      className={`inline-flex items-center gap-2.5 text-default ${className} ${
        isDisabled
          ? 'cursor-not-allowed opacity-50'
          : 'cursor-default opacity-100'
      }`}
    >
      <span>{isSlideShowPlaying ? 'Stop' : 'Start'} slideshow</span>{' '}
      {isSlideShowPlaying ? <StopSvg /> : <PlaySvg />}
    </button>
  )
}

export default connect(
  ({ projectIdentity, firestore: { session } }: RootStateTypeExtra) => ({
    session,
    projectIdentity,
  })
)(SlideshowControl)
