import React from 'react'
import { connect } from 'react-redux'
import { useLocation } from 'react-router-dom'

import {
  ExtendedUnitFilterOptions,
  MarkerColourInterface,
  ProjectIdentity,
  RootStateTypeExtra,
} from '@store/types'

import Container from '@components/container/v2'
import DataHandler from '@components/data-handler'
import BuildingFilter from '@components/filter/v2'
import ImageHandler from '@components/image-handler'
import { CanvasInteractive } from '@components/showcase-canvas'
import { CanvasRefInterface } from '@components/showcase-canvas/canvas-interactive'
import { Polygon } from '@components/showcase-canvas/types'

import {
  Level,
  selectFromResult as selectFromBuildingResult,
  useGetBuildingQuery,
} from '@api/building'
import {
  InteractivePlanData,
  MapContent,
  MappingBlockCollection,
  selectFromResult as selectFromResultInteractive,
  useGetInteractivePlanQuery,
} from '@api/interactive-plan'

import { getQueryStringParams } from '@utilities/helper'
import styleUtil from '@utilities/style-util'
import useBlockLevelData from '@utilities/use-block-level-data'

import FirebaseControlQuery from '@firebaseUtil/firebase-control-query'
import { SessionMap } from '@firebaseUtil/types'

import { ArrowSvg, FilterSvg, MapPinSvg } from '@svg/react'

import filterUnit from '@adUtilities/filter-unit'
import { Unit } from '@adUtilities/types/apartment'

import LevelViewSkeleton from './level-skeleton'

export interface LevelProps {
  session: SessionMap | undefined
  projectIdentity: ProjectIdentity
  blocks: MappingBlockCollection
  showPrice: boolean
  levelMarkerColour?: MarkerColourInterface
}

const ARROW_TYPE = 'arrow'
const ARROW_SMALL_TYPE = 'arrow-small'

function LevelView({
  session,
  projectIdentity,
  blocks: storeBlocks,
  showPrice,
  levelMarkerColour,
}: LevelProps) {
  const themeData = styleUtil()
  const location = useLocation()
  const urlParams = React.useRef(getQueryStringParams(location.search))
  const firebaseControlQuery = FirebaseControlQuery({
    ...projectIdentity,
    sessionKey: projectIdentity.sessionId,
  })

  const [interactivePlan, setInteractivePlan] =
    React.useState<InteractivePlanData>({
      areaView: {
        image: '',
        polygons: [],
      },
      blocks: {},
      floorplan: {},
      precinct: {},
      stages: {},
    })
  const unitFilter = session?.building?.unitFilter as ExtendedUnitFilterOptions
  const [levels, setLevels] = React.useState<Level[]>([])
  const [blockOrders, setBlockOrders] = React.useState<string[]>([])

  const canvasContainerRef = React.useRef<HTMLDivElement>(null)
  const canvasRef = React.useRef<CanvasRefInterface>()
  const [isFilterOpen, toggleFilter] = React.useState(false)
  const [rendering, setRendering] = React.useState(false)
  const [renderCanvas, setRenderCanvas] = React.useState(false)
  const [isCanvasImageLoaded, setImageLoaded] = React.useState(false)

  const blockKeys = React.useMemo(
    () =>
      blockOrders.length > 1 ? blockOrders : Object.keys(storeBlocks || {}),
    [blockOrders, storeBlocks]
  )
  const floorplanLength = React.useMemo(
    () => Object.keys(interactivePlan?.floorplan).length,
    [interactivePlan?.floorplan]
  )

  const fireStoreActiveBlock = session?.building?.activeBlock || undefined
  const [blockFocus, setBlockFocus] = React.useState(
    blockKeys.find((res) => fireStoreActiveBlock === res) || blockKeys[0]
  )

  const prevBlockFocusRef = React.useRef(blockFocus)
  const [activeBlock, setActiveBlock] = React.useState('')

  const [theme, setTheme] = React.useState({
    font: '',
    mainColour: '',
  })

  const interactivePayload = useGetInteractivePlanQuery(
    { projectName: projectIdentity.projectName },
    { selectFromResult: selectFromResultInteractive }
  )

  const buildingPayload = useGetBuildingQuery(
    { projectName: projectIdentity.projectName },
    { selectFromResult: selectFromBuildingResult }
  )
  useBlockLevelData({ projectIdentity })

  const levelDataByBlock = React.useCallback(
    (foundLevel: Level) =>
      blockKeys.length > 1
        ? foundLevel.data.filter((unit) => unit.blockId === blockFocus)
        : foundLevel.data,
    [blockKeys, blockFocus]
  )

  const getAvailableUnitCount = React.useCallback(
    (poly: Polygon) => {
      const foundLevel = levels.find((res) => poly.groupId === res.level)
      if (foundLevel) {
        return levelDataByBlock(foundLevel).filter(
          (res) => res.metas.status === 'available'
        ).length
      }
      return 0
    },
    [levels, levelDataByBlock]
  )

  const findRelevantLevel = React.useCallback(
    (levelName: string) => levels.find((res) => levelName === res.level),
    [levels]
  )

  const canUnitFilterByBlock = React.useCallback(
    (unit: Unit) => blockFocus && unit.blockId && unit.blockId !== blockFocus,
    [blockFocus]
  )

  const getLevelStatus = React.useCallback(
    (poly: Polygon) => {
      const { apply } = unitFilter
      const splicedGroupId = poly.groupId.split('-')
      const foundLevel = findRelevantLevel(splicedGroupId[1] || poly.groupId)

      let levelActive = false

      if (!apply || !foundLevel) {
        return levelActive
      }

      levelDataByBlock(foundLevel).forEach((unit) => {
        if (canUnitFilterByBlock(unit)) {
          return
        }
        if (splicedGroupId[1]) {
          if (unit.blockId !== splicedGroupId[0]) {
            return
          }
        }
        if (!levelActive) {
          levelActive = unitFilter?.apply
            ? filterUnit(
                unit,
                unitFilter,
                showPrice,
                projectIdentity?.statusLabels?.available
              )
            : true
        }
      })

      return levelActive
    },
    [
      unitFilter,
      findRelevantLevel,
      levelDataByBlock,
      canUnitFilterByBlock,
      showPrice,
    ]
  )

  const getLevelUnitCount = React.useCallback(
    (poly: Polygon) => {
      const splicedGroupId = poly.groupId.split('-')
      const foundLevel = findRelevantLevel(splicedGroupId[1] || poly.groupId)

      if (!foundLevel) return 0

      if (!unitFilter?.apply) {
        return levelDataByBlock(foundLevel).length
      }

      return levelDataByBlock(foundLevel).filter((unit: Unit) =>
        filterUnit(
          unit,
          unitFilter,
          showPrice,
          projectIdentity?.statusLabels?.available
        )
      ).length
    },
    [
      findRelevantLevel,
      levelDataByBlock,
      unitFilter,
      showPrice,
      projectIdentity?.statusLabels?.available,
    ]
  )

  const checkGroupId = React.useCallback((groupId: string) => {
    const splitGroupString = groupId.split('-')
    if (splitGroupString.length > 1) {
      const [activeBlock, activeLevel] = splitGroupString
      return {
        activeBlock,
        activeLevel,
      }
    }
    return {
      activeLevel: groupId,
      activeBlock: '',
    }
  }, [])

  const interactiveClick = React.useCallback(
    async (poly: Polygon) => {
      const { activeLevel, activeBlock } = checkGroupId(poly.groupId || '')
      setActiveBlock(activeBlock || (blockKeys.length > 1 ? blockFocus : ''))
      await firebaseControlQuery.update({
        [`building.activeBlock`]:
          activeBlock || (blockKeys.length > 1 ? blockFocus : ''),
        [`building.activeLevel`]: activeLevel,
        [`building.activeUnit`]: '',
      })
    },
    [checkGroupId, blockKeys, blockFocus]
  )
  const interactiveClickOnComplete = React.useCallback(async () => {
    await firebaseControlQuery.update({
      [`building.sidePanelFolded`]: true,
    })
    await firebaseControlQuery.updateRoute('building')
  }, [])

  const getLabel = React.useCallback((poly: Polygon) => {
    const POLY_TYPE = poly.type || ''
    if (POLY_TYPE === ARROW_TYPE || POLY_TYPE === ARROW_SMALL_TYPE) {
      return poly.label || poly.groupId
    }
    return `${poly.label} ${poly.groupId}`
  }, [])

  const setInteractiveAction = React.useCallback(
    (areaViewMap: MapContent) => ({
      ...areaViewMap,
      polygons: areaViewMap.polygons
        .filter((poly) =>
          findRelevantLevel(poly.groupId?.split('-')?.[1] || poly.groupId)
        )
        .map((poly) => ({
          ...poly,
          activeByDefault: getLevelStatus(poly),
          label: getLabel(poly),
          isHidden: getLevelUnitCount(poly) === 0,
          subLabel: poly?.subLabel
            ? `${getLevelUnitCount(poly)} ${poly.subLabel}`
            : '',
          postFix: `- Available Units (${getAvailableUnitCount(poly)})`,
          onClick: () => interactiveClick(poly),
          onComplete: () => interactiveClickOnComplete(),
          markerColour: levelMarkerColour,
        })),
    }),
    [
      findRelevantLevel,
      getLevelStatus,
      getLabel,
      getLevelUnitCount,
      getAvailableUnitCount,
      interactiveClick,
      interactiveClickOnComplete,
      levelMarkerColour,
    ]
  )

  const block = React.useMemo(() => {
    if (!(blockFocus === '' || blockFocus)) {
      return []
    }

    if (!storeBlocks[blockFocus]) {
      return (
        Object.values(storeBlocks)?.[0]?.map((res) =>
          setInteractiveAction(res)
        ) || []
      )
    }
    return (
      storeBlocks[blockFocus]?.map((res) => setInteractiveAction(res)) || []
    )
  }, [blockFocus, storeBlocks, setInteractiveAction])

  const getLevelListByBlockId = (blockId: string): Array<string> => {
    const levelList: Level[] = [...levels]
    if (unitFilter.showAvailable) {
      levelList.filter((lvl: Level) =>
        lvl.data.filter(
          (unit: Unit) =>
            unit.metas.status === projectIdentity?.statusLabels?.available
        )
      )
    }
    return levelList
      .filter((res) => res.data.some((unit) => unit.blockId === blockId))
      .map((res) => res.level)
  }

  const goBackToArea = async () => {
    await firebaseControlQuery.update({
      [`building.activeBlock`]: '',
      [`building.activeLevel`]: '',
    })
    await firebaseControlQuery.updateRoute('area-view')
  }

  React.useEffect(() => {
    const { maps } = interactivePayload
    if (!floorplanLength && maps.areaView) {
      setInteractivePlan(maps)
    }
  }, [interactivePayload, floorplanLength])

  React.useEffect(() => {
    const { status: interactivePayloadStatus } = interactivePayload
    if (interactivePayloadStatus !== 'fulfilled' || !floorplanLength) {
      return
    }
    const { levels: buildingData, blockOrders: blockOrdersData } =
      buildingPayload

    if (buildingData.length > 0) {
      const orderedBlockKeys: string[] =
        blockOrdersData.length > 1 ? blockOrdersData : Object.keys(storeBlocks)
      const storeActiveBlock =
        orderedBlockKeys.find(
          (res) =>
            (fireStoreActiveBlock ||
              activeBlock ||
              urlParams.current?.block) === res
        ) ||
        orderedBlockKeys[0] ||
        buildingData[0]?.data[0].blockId ||
        ''

      setLevels(buildingData)
      setBlockOrders(blockOrdersData)
      setBlockFocus(storeActiveBlock)
    }
  }, [
    buildingPayload,
    interactivePayload,
    floorplanLength,
    storeBlocks,
    fireStoreActiveBlock,
  ])

  React.useEffect(() => {
    if (!rendering) {
      canvasRef?.current?.setPolyActive()
    }
  }, [block, rendering])

  React.useEffect(() => {
    setActiveBlock(blockFocus)
    if (prevBlockFocusRef.current === blockFocus) {
      return
    }
    prevBlockFocusRef.current = blockFocus
    setTimeout(() => {
      canvasRef?.current?.setCanvas()
    }, 100)
  }, [blockFocus])

  React.useEffect(() => {
    setRenderCanvas(true)
    if (blockKeys.length > 1) {
      const [firstLevel] = getLevelListByBlockId(blockFocus)
      firebaseControlQuery.update({
        [`building.activeLevel`]: firstLevel,
      })
    }
  }, [projectIdentity?.statusLabels?.available])

  React.useEffect(() => {
    const themeFromStorage = JSON.parse(
      localStorage.getItem('themeObject') || '{}'
    )
    if (themeFromStorage) {
      setTheme(themeFromStorage)
    }
  }, [])

  return (
    <Container>
      <DataHandler
        payloads={{
          ...buildingPayload,
          data: buildingPayload.levels,
        }}
        skeletonFrame={<LevelViewSkeleton />}
      >
        <div className="absolute left-5 top-5 z-20">
          <div className="flex items-center gap-4">
            <button
              onClick={goBackToArea}
              type="button"
              className="rounded bg-white p-1 drop-shadow-40"
            >
              <ArrowSvg className="h-8 w-8" strokeColor="#000" />
            </button>
            <button
              data-testid="toggle-filter"
              onClick={() => toggleFilter(!isFilterOpen)}
              type="button"
              className="rounded bg-white p-2.5 drop-shadow-40"
            >
              <FilterSvg className="h-5 w-5" />
            </button>
            {blockKeys.length > 1 && (
              <button
                data-testid="toggle-filter"
                onClick={() => toggleFilter(!isFilterOpen)}
                type="button"
                className="flex items-center gap-1.5 rounded bg-white p-2 drop-shadow-40"
              >
                <MapPinSvg />
                <span>{blockFocus}</span>
              </button>
            )}
          </div>
        </div>
        <DataHandler
          payloads={{
            ...interactivePayload,
            data: floorplanLength,
          }}
          skeletonFrame={<LevelViewSkeleton />}
        >
          <ImageHandler
            key={block[0]?.image}
            url={block[0]?.image}
            type="new"
            className="background-cover image-blur absolute inset-0 z-2"
            noSpliceUrl
            showFallbackImage={false}
            bgProps={{
              gradiant: 0.5,
            }}
          />

          <div className="absolute inset-0 z-3">
            <div
              ref={canvasContainerRef}
              className={`relative m-auto flex h-full w-full items-center justify-center ${
                isCanvasImageLoaded ? 'opacity-100' : 'opacity-0'
              }`}
            >
              {canvasContainerRef && renderCanvas && block[0] && (
                <CanvasInteractive
                  id="level-canvas"
                  ref={canvasRef}
                  canvasData={block[0]}
                  parentRef={canvasContainerRef}
                  hasLabel
                  labelPrefix="Level:"
                  isRendering={setRendering}
                  theme={{
                    brandColour:
                      theme?.mainColour || themeData.mainColour || '',
                    font: theme?.font || themeData.font || '',
                  }}
                  adjustCanvasSizeWithContainer
                  setImageLoaded={setImageLoaded}
                />
              )}
            </div>
          </div>
        </DataHandler>

        <BuildingFilter
          isOpen={isFilterOpen}
          building={{
            levels,
            blockOrders,
          }}
          blocks={interactivePlan.blocks}
          firebaseUnitFilter={unitFilter}
          firebaseActiveBlock={activeBlock}
          toggle={toggleFilter}
          hideLevelOption
        />
      </DataHandler>
    </Container>
  )
}

export default connect(
  ({
    firestore: { session },
    blockLevel: { blocks },
    projectIdentity,
  }: RootStateTypeExtra) => ({
    session,
    projectIdentity,
    blocks,
    showPrice: projectIdentity.showPrice,
    levelMarkerColour: projectIdentity?.markerColourSettings,
  })
)(LevelView)
