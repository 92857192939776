import React from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'

import {
  GalleryItemInterface,
  PlayerState,
  ProjectIdentity,
  RootStateTypeExtra,
} from '@store/types'

import Container from '@components/container/v2'
import { Status } from '@components/data-handler/data-handler'
import DataHandler from '@components/data-handler/v2'
import VideoPlayerRemote from '@components/video-player'

import {
  selectFromResult as selectFromResultConfig,
  useGetConfigQuery,
} from '@api/config'
import {
  selectFromResult as selectFromVideoGalleryResult,
  useGetVideoGalleryQuery,
} from '@api/video-gallery'

import useTabVisibility from '@utilities/tab-visibility'
import VideoPlayerFirebaseService from '@utilities/video-player-firebase-service'

import FirebaseControlQuery from '@firebaseUtil/firebase-control-query'
import { SessionMap } from '@firebaseUtil/types'

import { PlayCircleSvg } from '@svg/react'

import AssetHandler from '@adUtilities/asset-handler/asset-handler'
import {
  CarouselProvider,
  Tab,
} from '@adUtilities/components/carousel-provider'
import VideoPlayerShowcase from '@adUtilities/components/video-player'
import { VideoPlayerRef } from '@adUtilities/components/video-player/video-player'
import { BucketSource, FileType } from '@adUtilities/constants/common'

import VideoSkeleton from './video-skeleton'

const VIDEO_PAGE_ROUTE = '/video-gallery'

export interface PagePropsInterface {
  projectIdentity: ProjectIdentity
  session: SessionMap | undefined
  fullScreenToggle?: boolean
  onFullScreenToggle?: React.Dispatch<React.SetStateAction<boolean | undefined>>
}

function VideoGallery({
  projectIdentity,
  session,
  fullScreenToggle,
  onFullScreenToggle,
}: PagePropsInterface) {
  const history = useHistory()
  const firebaseControlQuery = FirebaseControlQuery({
    ...projectIdentity,
    sessionKey: projectIdentity.sessionId,
  })

  const videoPlayerFirebaseService = VideoPlayerFirebaseService({
    galleryName: 'videoGallery',
    projectIdentity,
  })

  const videoPlayerRef = React.useRef<VideoPlayerRef>(null)
  const [activeSlideIndex, setActiveSlideIndex] = React.useState<number>(0)
  const [loadedSlides, setLoadedSlide] = React.useState<Array<number>>([])

  const [hideControls, setHideControls] = React.useState<boolean>(false)
  const [isVideoPlaying, setIsVideoPlaying] = React.useState<boolean>(false)
  const [playerState, setPlayerState] = React.useState<PlayerState>(
    PlayerState.Stop
  )
  const [videoLengthInSeconds, setVideoLengthInSeconds] = React.useState(0)
  const [currentVideoPositionInSeconds, setCurrentVideoPositionInSeconds] =
    React.useState(0)
  const [volume, setVolume] = React.useState<number>(0)
  const [scrubbarTimerState, setScrubbarTimerState] = React.useState(false)
  const [isBuffering, setBufferState] = React.useState(false)
  const [isLooping, setLoopState] = React.useState(true)
  const [isSeeking, setSeekingState] = React.useState(false)
  const [isMuted, setMuteState] = React.useState(true)

  const videoGalleryPayload = useGetVideoGalleryQuery(
    { projectName: projectIdentity.projectId },
    { selectFromResult: selectFromVideoGalleryResult }
  )

  const { configData: projectConfigData, status: projectConfigPayloadStatus } =
    useGetConfigQuery(
      { projectName: projectIdentity.projectName },
      { selectFromResult: selectFromResultConfig }
    )

  const videoItems: Array<GalleryItemInterface> = React.useMemo(() => {
    const { videoGalleryData = [] } = videoGalleryPayload

    if (videoGalleryData.length < 1) {
      return []
    }

    return videoGalleryData[0].items || []
  }, [videoGalleryPayload])

  const slides = React.useMemo(
    () =>
      videoItems.map((item) => ({
        id: item.id,
        label: item.title,
        src: item.imageSource,
        thumbnail: item.imageSource,
        title: item.title,
        videoSource: item.videoSource,
        bucketType: BucketSource.Legacy,
        fileType: FileType.Video,
        noSpliceUrl: false,
      })),
    [videoItems]
  )

  const handleCarouselProviderClick = () => setHideControls((state) => !state)

  const handlePlayOnShowcase = async () => {
    setPlayerState(PlayerState.Play)
    setScrubbarTimerState(false)
    setIsVideoPlaying(true)
    await videoPlayerFirebaseService.updatePlayState()
  }

  const handlePlayOnRemote = () => {
    onFullScreenToggle?.(true)
    setHideControls(false)
    videoPlayerRef.current?.updatePlayerState(PlayerState.Play)
  }

  const handleVideoPlayerClick = (state: boolean) => {
    setIsVideoPlaying(state)
    onFullScreenToggle?.(state)
    setHideControls(!state)
  }

  const handlePlayClick = React.useCallback(async () => {
    setPlayerState(PlayerState.Play)
    setScrubbarTimerState(false)
    await videoPlayerFirebaseService.updatePlayState()
  }, [videoPlayerFirebaseService])

  const handlePauseClick = React.useCallback(async () => {
    setPlayerState(PlayerState.Pause)
    setScrubbarTimerState(false)
    await videoPlayerFirebaseService.updatePauseState()
  }, [videoPlayerFirebaseService])

  const handleStopClick = React.useCallback(async () => {
    setScrubbarTimerState(false)
    setPlayerState(PlayerState.Stop)
    setVideoLengthInSeconds(0)
    setCurrentVideoPositionInSeconds(0)
    setIsVideoPlaying(false)
    setHideControls(false)
    await videoPlayerFirebaseService.updateStopState()
  }, [videoPlayerFirebaseService])

  const handleVolumeDownClick = React.useCallback(async () => {
    if (volume > 0) {
      const newVolume = volume - 1

      setMuteState(false)
      setVolume(newVolume)
      await videoPlayerFirebaseService.updateVolumeState(newVolume)
    }
  }, [volume, videoPlayerFirebaseService])

  const handleVolumeUpClick = React.useCallback(async () => {
    if (volume < 10) {
      setMuteState(false)
      const newVolume = volume + 1
      setVolume(newVolume)
      await videoPlayerFirebaseService.updateVolumeState(newVolume)
    }
  }, [volume, videoPlayerFirebaseService])

  const handleMuteClick = React.useCallback(
    async (arg: boolean) => {
      setMuteState(arg)
      await videoPlayerFirebaseService.updateMuteState(arg)
    },
    [videoPlayerFirebaseService]
  )

  const handleSeekTo = React.useCallback(
    async (argInSeconds: number) => {
      setScrubbarTimerState(false)
      setSeekingState(true)
      await videoPlayerFirebaseService.updateSeekState(argInSeconds)
    },
    [videoPlayerFirebaseService]
  )

  const handleLoopClick = React.useCallback(
    async (arg: boolean) => {
      setLoopState(arg)
      await videoPlayerFirebaseService.updateLoopState(arg)
    },
    [videoPlayerFirebaseService]
  )

  const updateSyncState = React.useCallback(async () => {
    await videoPlayerFirebaseService.updateSyncState()
  }, [videoPlayerFirebaseService])

  const handleOnItemClick = React.useCallback(
    async (index: number) => {
      if (activeSlideIndex === index) {
        return
      }
      setActiveSlideIndex(index)
      setPlayerState(PlayerState.Stop)
      setScrubbarTimerState(false)
      setIsVideoPlaying(false)
      handleStopClick()
      await firebaseControlQuery.update({
        [`videoGallery.galleryControlV2.activeSlideIndex`]: index,
        [`videoGallery.playerControlV2.playerState`]: PlayerState.Stop,
        [`videoGallery.playerControlV2.scrubbarTimerState`]: false,
      })
    },
    [firebaseControlQuery]
  )

  React.useEffect(() => {
    if (!session) {
      return
    }

    const {
      videoGallery: {
        galleryControlV2: { activeSlideIndex: activeSlideIndexFirebase },
        playerControlV2: {
          playerState: playerStateFirebase,
          loop: loopStateFirebase,
          videoLengthInSeconds: videoLengthInSecondsFirebase,
          currentVideoPositionInSeconds: currentVideoPositionInSecondsFirebase,
          volume: volumeFirebase,
          scrubbarTimerState: scrubbarTimerStateFirebase,
          seekToValueInSeconds: seekToValueInSecondsFirebase,
          isBuffering: isBufferingFirebase,
          muted: muteStateFirebase,
        },
      },
    } = session

    setActiveSlideIndex(activeSlideIndexFirebase)
    setPlayerState(playerStateFirebase)
    setIsVideoPlaying(playerStateFirebase !== PlayerState.Stop)
    setVideoLengthInSeconds(videoLengthInSecondsFirebase)
    setCurrentVideoPositionInSeconds(currentVideoPositionInSecondsFirebase)
    setVolume(volumeFirebase)
    setScrubbarTimerState(scrubbarTimerStateFirebase)
    setBufferState(isBufferingFirebase)
    setLoopState(loopStateFirebase)
    setSeekingState(seekToValueInSecondsFirebase !== null)
    setMuteState(muteStateFirebase)
  }, [session?.videoGallery])

  useTabVisibility(
    React.useCallback(async () => {
      if (playerState === PlayerState.Play) {
        updateSyncState()
      }
    }, [playerState])
  )

  React.useEffect(() => {
    if (videoItems.length > 0 && playerState === PlayerState.Play) {
      updateSyncState()
    }
  }, [videoItems.length, playerState])

  React.useEffect(
    () => () => onFullScreenToggle?.(undefined),
    [onFullScreenToggle]
  )

  React.useEffect(
    () => () => {
      const currentRoute = history.location.pathname || ''
      if (
        currentRoute !== VIDEO_PAGE_ROUTE &&
        playerState !== PlayerState.Stop
      ) {
        setScrubbarTimerState(false)
        videoPlayerFirebaseService.resetPlayerState()
      }
    },
    [playerState, history.location.pathname]
  )

  const apiStatus = React.useMemo(() => {
    const { FULFILLED, REJECTED, PENDING } = Status

    if (
      videoGalleryPayload.status === FULFILLED &&
      projectConfigPayloadStatus === FULFILLED
    ) {
      return FULFILLED
    }

    if (
      videoGalleryPayload.status === REJECTED ||
      projectConfigPayloadStatus === REJECTED
    ) {
      return REJECTED
    }

    return PENDING
  }, [videoGalleryPayload.status, projectConfigPayloadStatus])

  const effectType = projectConfigData?.gallerySettings?.type || ''
  const autoPlayIntervalInSeconds =
    projectConfigData?.gallerySettings?.intervalInSeconds || 0

  return (
    <Container fullScreenMode={fullScreenToggle}>
      <DataHandler
        payload={{
          ...videoGalleryPayload,
          data: videoGalleryPayload.videoGalleryData,
          status: apiStatus,
        }}
        skeletonFrame={<VideoSkeleton />}
      >
        <div
          className={`absolute top-8 z-2 ${isVideoPlaying || hideControls ? 'hidden' : ''}`}
        >
          <Tab
            items={slides.map((item) => ({ text: item.title || '' }))}
            handleOnItemClick={handleOnItemClick}
            activeItemIndex={activeSlideIndex}
            toggleControls={isVideoPlaying || hideControls}
          />
        </div>

        <CarouselProvider
          gallerySettings={{
            effectType,
            autoPlayIntervalInSeconds,
          }}
          slides={slides}
          activeSlideIndex={activeSlideIndex}
          onContainerClick={handleCarouselProviderClick}
          showControl={false}
          mousewheel={false}
          getCurrentSlideIndex={(index) => handleOnItemClick(index)}
          getSlideLoadingState={(index: number) =>
            setLoadedSlide((prev) => [...prev, index])
          }
          allowSlideSwiping={!isVideoPlaying}
        />

        {loadedSlides.includes(activeSlideIndex) && (
          <div className="absolute inset-0">
            <div className="flex h-full w-full items-center justify-center">
              {!isVideoPlaying && (
                <div className="flex flex-row gap-5">
                  <button
                    type="button"
                    className="text-md z-1 flex max-h-14 items-center rounded-md border border-mainColour bg-white px-2 py-2 font-medium tracking-widest text-mainColour"
                    onClick={handlePlayOnShowcase}
                  >
                    <PlayCircleSvg size="m" className="mr-2 h-6 w-6" />
                    <span>Play on Showcase</span>
                  </button>
                  <button
                    type="button"
                    className="text-md z-1 flex max-h-14 items-center rounded-md border border-mainColour bg-white px-2 py-2 font-medium tracking-widest text-mainColour"
                    onClick={handlePlayOnRemote}
                  >
                    <PlayCircleSvg size="m" className="mr-2 h-6 w-6" />
                    <span>Play on Remote</span>
                  </button>
                </div>
              )}

              <VideoPlayerShowcase
                ref={videoPlayerRef}
                showControls
                sources={slides.map((item) =>
                  AssetHandler({
                    url: item.videoSource || '',
                    type: item.bucketType,
                  })
                )}
                activeIndex={activeSlideIndex}
                getPlayerState={handleVideoPlayerClick}
                showLoopOption
                hidePlayButton
              />
            </div>

            <div
              className={`absolute bottom-0 z-40 w-full px-2 pb-4 transition-all duration-1000 ${
                playerState !== PlayerState.Stop
                  ? 'translate-y-0 opacity-100'
                  : 'pointer-events-none fixed translate-y-full opacity-0'
              }`}
            >
              <VideoPlayerRemote
                isLooping={isLooping}
                volume={volume}
                isMuted={isMuted}
                scrubbarTimerState={scrubbarTimerState}
                isBuffering={isBuffering}
                playerState={playerState}
                videoLengthInSeconds={videoLengthInSeconds}
                currentVideoPositionInSeconds={currentVideoPositionInSeconds}
                isSeeking={isSeeking}
                handlePlayClick={handlePlayClick}
                handlePauseClick={handlePauseClick}
                handleStopClick={handleStopClick}
                handleVolumeUpClick={handleVolumeUpClick}
                handleVolumeDownClick={handleVolumeDownClick}
                handleMuteClick={handleMuteClick}
                handleSeekTo={handleSeekTo}
                handleLoopClick={handleLoopClick}
              />
            </div>
          </div>
        )}
      </DataHandler>
    </Container>
  )
}

export default connect(
  ({ projectIdentity, firestore: { session } }: RootStateTypeExtra) => ({
    projectIdentity,
    session,
  })
)(VideoGallery)
