import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { ShowcaseRoomConfig } from '@api/showcase-room'

const initialState = {} as ShowcaseRoomConfig

const showcaseRoomSlice = createSlice({
  name: 'showcaseRoom',
  initialState,
  reducers: {
    setShowcaseRoomConfig: (
      state: ShowcaseRoomConfig,
      action: PayloadAction<ShowcaseRoomConfig>
    ) => ({
      ...state,
      ...action.payload,
    }),
  },
})

export const { setShowcaseRoomConfig } = showcaseRoomSlice.actions

export default showcaseRoomSlice.reducer
