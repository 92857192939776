import React from 'react'
import { connect, useDispatch } from 'react-redux'

import { addShortlist, setShortlists } from '@store/actionSlices/shortlist'
import {
  LotShortlistInterface,
  PackageConfigurationInterface,
  ProjectIdentity,
  RootStateTypeExtra,
  ShortlistItemInterface,
  ShortlistPropertyType,
} from '@store/types'

import { CheckSvg, StarSvg } from '@components/adgroup-svg/react'
import ModalCard from '@components/modals/v2'

import { LotInterface } from '@api/houseAndLand'

import FirebaseControlQuery from '@firebaseUtil/firebase-control-query'

import { notifyError, notifySuccess } from '@adUtilities/notifier'

interface ShortlistActionProps {
  projectIdentity: ProjectIdentity
  shortlists: ShortlistItemInterface[]
  actionType: 'icon' | 'button'
  lot: LotInterface
  stageId: string
  precinctId: string
  isShortlisted: boolean
  isLotShortlisted?: boolean
  packageConfiguration?: PackageConfigurationInterface
  availableStatusLabel: string
}

const MAXIMUM_PROPERTY_LENGTH_FOR_SHORTLIST =
  Number(process.env.REACT_APP_MAXIMUM_PROPERTY_LENGTH_FOR_SHORTLIST) || 4

function ShortlistAction({
  projectIdentity,
  shortlists,
  actionType,
  lot,
  stageId,
  precinctId,
  isShortlisted,
  isLotShortlisted,
  packageConfiguration,
  availableStatusLabel,
}: ShortlistActionProps) {
  const dispatch = useDispatch()
  const firebaseControlQuery = FirebaseControlQuery({
    ...projectIdentity,
    sessionKey: projectIdentity.sessionId,
  })
  const totalShortlist = shortlists.length

  const [actionableLot, setActionableLot] = React.useState<{
    active: boolean
    propertyId?: string
  }>()
  const [showErrorModal, setShowErrorModal] = React.useState(false)

  const handleToggleActionableLot = React.useCallback((show: boolean) => {
    setActionableLot((prevVal) => ({ ...prevVal, active: show }))
  }, [])

  const handleRemove = React.useCallback(() => {
    if (actionableLot?.propertyId) {
      const newShortlists = shortlists?.filter(
        (item) => actionableLot.propertyId !== item.propertyId
      )
      dispatch(setShortlists(newShortlists))

      firebaseControlQuery.update({
        'shortlist.properties': JSON.parse(JSON.stringify(newShortlists)),
      })
      setActionableLot(undefined)
    }
  }, [actionableLot, shortlists])

  const handleShortlist: React.MouseEventHandler = React.useCallback(
    (e) => {
      e.stopPropagation()

      if (lot.status !== availableStatusLabel) {
        notifyError(
          'This lot is not available, you can only shortlist available lot.'
        )
        return
      }

      if (isShortlisted && !packageConfiguration) {
        setActionableLot({
          active: true,
          propertyId: lot.id,
        })
        return
      }

      if (isShortlisted) {
        setShowErrorModal(true)
        return
      }

      if (totalShortlist >= MAXIMUM_PROPERTY_LENGTH_FOR_SHORTLIST) {
        notifyError(
          `You can shortlist a maximum of ${MAXIMUM_PROPERTY_LENGTH_FOR_SHORTLIST} lots.`
        )
        return
      }
      const shortlistData: LotShortlistInterface = {
        propertyId: lot.id,
        propertyName: lot.name,
        references: {
          precinctId,
          stageId,
        },
        configuration: packageConfiguration,
        type: ShortlistPropertyType.Lot,
      }
      dispatch(addShortlist(shortlistData))
      const updatedShortlists: ShortlistItemInterface[] = [
        ...shortlists,
        shortlistData,
      ]
      firebaseControlQuery.update({
        'shortlist.properties': JSON.parse(JSON.stringify(updatedShortlists)),
      })
      notifySuccess('Your selections have been added to your shortlist.')
    },
    [
      shortlists,
      totalShortlist,
      lot,
      stageId,
      precinctId,
      isShortlisted,
      packageConfiguration,
    ]
  )

  return (
    <>
      {actionType === 'icon' ? (
        <button type="button" title="Shortlist lot" onClick={handleShortlist}>
          <StarSvg
            className={`h-4 w-4 ${
              isShortlisted
                ? 'stroke-amber-500 text-amber-500'
                : 'stroke-zinc-400 text-zinc-400'
            }`}
          />
        </button>
      ) : (
        <div
          className={`flex cursor-pointer items-center justify-center gap-2 rounded-[7px] border border-mainColour px-2 py-4 text-mainColour ${
            isShortlisted ? 'bg-mainColour' : ''
          }`}
          role="none"
          onClick={handleShortlist}
        >
          {isShortlisted ? (
            <CheckSvg className="h-[16px] w-[16px] text-white" />
          ) : (
            <StarSvg
              className={`h-[16px] w-[16px] ${
                isLotShortlisted
                  ? 'stroke-amber-500 text-amber-500'
                  : 'stroke-zinc-400 text-zinc-400'
              }`}
            />
          )}
          <span
            className={`text-[19px] font-medium leading-[19px] ${
              isShortlisted ? 'text-white' : 'text-mainColour'
            }`}
          >
            {isShortlisted ? 'Added to Shortlist' : 'Save to Shortlist'}
          </span>
        </div>
      )}

      <ModalCard
        headerLabel="Remove from shortlist"
        toggle={handleToggleActionableLot}
        cancelAction={() => {}}
        okAction={handleRemove}
        isVisible={!!actionableLot?.active}
        okLabel="Remove"
        cancelLabel="Cancel"
        headerClassName="text-[21px] font-medium px-2 pb-2"
        closeClassName="ml-auto pb-2"
        footerClassName="flex flex-row-reverse gap-3 border-t border-gray-300 py-4 px-6"
        okClassName="rounded px-3 py-2 text-[#FF0000] bg-[#EEEEEE] text-[17px]"
        cancelClassName="rounded bg-blue-500 px-3 py-2 text-white transition-all duration-300 text-[17px]"
      >
        <div className="px-4 text-left">
          <p>Are you sure you want to clear all shortlists from this lot?</p>
        </div>
      </ModalCard>

      <ModalCard
        headerLabel="Duplicated shortlist"
        toggle={() => setShowErrorModal(false)}
        cancelAction={() => setShowErrorModal(false)}
        isVisible={showErrorModal}
        hideOk
        cancelLabel="Cancel"
        headerClassName="text-[21px] font-medium px-2 pb-2"
        closeClassName="ml-auto pb-2"
        footerClassName="flex flex-row-reverse gap-3 border-t border-gray-300 py-4 px-6"
        cancelClassName="rounded bg-blue-500 px-3 py-2 text-white transition-all duration-300 text-[17px]"
      >
        <div className="px-4 text-left">
          <p>Lot configuration has already been shortlisted</p>
        </div>
      </ModalCard>
    </>
  )
}

export default connect(
  ({ projectIdentity, shortlist: { shortlists } }: RootStateTypeExtra) => ({
    projectIdentity,
    shortlists,
  })
)(ShortlistAction)
