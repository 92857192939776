import React from 'react'
import { connect } from 'react-redux'
import { CSSTransition } from 'react-transition-group'

import { ShortlistState } from '@store/actionSlices/shortlist'
import {
  LotShortlistInterface,
  PackageConfigurationInterface,
  ProjectIdentity,
  RootStateTypeExtra,
} from '@store/types'

import {
  LotInterface,
  PackageSummaryInterface,
  selectPackagesFromResult,
  useGetPackagesByIdQuery,
} from '@api/houseAndLand'

import FirebaseControlQuery from '@firebaseUtil/firebase-control-query'

import { slugify } from '@adUtilities/helper'

import LotCardHeader from './lot-card-header'
import LotConfiguration from './lot-configuration'
import LotPackage, { LotPackageProps } from './lot-package'
import ShortlistAction from './shortlist-action'

export interface LotCardProps {
  projectIdentity: ProjectIdentity
  lot: LotInterface
  stageInfo: { id: string; label: string }
  activePrecinctId: string
  packages: Array<PackageSummaryInterface>
  activeStageId: string
  activePackageId: string
  active: boolean
  shortlists: ShortlistState['shortlists']
  onConfigurationValueChange?: (
    values: PackageConfigurationInterface,
    type?: keyof PackageConfigurationInterface,
    value?: string
  ) => void
  onConfigurationOptionSelect?: (data: {
    propertyType: keyof PackageConfigurationInterface
    value: string
  }) => void
  onConfigurationPropertyExpand?: (data: {
    propertyType: keyof PackageConfigurationInterface
    value: string
    expanded: boolean
  }) => void
  packageListTitleWhiteLabel: string
}

const INITIAL_PACKAGE_CONFIGURATION: PackageConfigurationInterface = {
  floorplan: '',
  floorplanOption: '',
  facade: '',
  facadeColor: '',
  internalTheme: '',
  internalColorScheme: '',
}

function LotCard({
  projectIdentity,
  lot,
  activeStageId,
  stageInfo,
  activePrecinctId,
  activePackageId,
  packages,
  active = false,
  shortlists,
  onConfigurationValueChange,
  onConfigurationOptionSelect,
  onConfigurationPropertyExpand,
  packageListTitleWhiteLabel,
}: LotCardProps) {
  const firebaseControlQuery = FirebaseControlQuery({
    ...projectIdentity,
    sessionKey: projectIdentity.sessionId,
  })

  const {
    statusLabels,
    statusColors,
    disableSoldProperty: disableSoldLot,
  } = projectIdentity || {}

  const packagePayload = useGetPackagesByIdQuery(
    { projectName: projectIdentity.projectName, lotId: lot.id },
    {
      selectFromResult: selectPackagesFromResult,
      skip: !active,
    }
  )

  const packageOptions = packagePayload.packages

  const defaultFloorplanId = React.useMemo(
    () => (packages.length > 0 ? packages[0].id : ''),
    [packages]
  )
  const validActivePackageId = React.useMemo(
    () => packages.find((item) => item.id === activePackageId)?.id,
    [activePackageId, packages]
  )

  const [packageConfiguration, setPackageConfiguration] =
    React.useState<PackageConfigurationInterface>({
      ...INITIAL_PACKAGE_CONFIGURATION,
      floorplan: active ? validActivePackageId || defaultFloorplanId : '',
    })

  const {
    isShortlisted,
    isLotShortlisted,
    shortlistedConfiguration,
    shortlistedLotConfiguration,
  } = React.useMemo(() => {
    const matchedShortlist = (shortlists as LotShortlistInterface[]).find(
      (item) =>
        item.propertyId === lot.id &&
        (item.configuration?.floorplan ?? '') ===
          (packageConfiguration.floorplan ?? '') &&
        (item.configuration?.floorplanOption ?? '') ===
          (packageConfiguration.floorplanOption ?? '') &&
        (item.configuration?.facade ?? '') ===
          (packageConfiguration.facade ?? '') &&
        (item.configuration?.facadeColor ?? '') ===
          (packageConfiguration.facadeColor ?? '') &&
        (item.configuration?.internalTheme ?? '') ===
          (packageConfiguration.internalTheme ?? '') &&
        (item.configuration?.internalColorScheme ?? '') ===
          (packageConfiguration.internalColorScheme ?? '')
    )

    const matchedLot = (shortlists as LotShortlistInterface[]).find(
      (item) => item.propertyId === lot.id
    )

    return {
      isShortlisted: !!matchedShortlist,
      isLotShortlisted: !!matchedLot,
      shortlistedConfiguration: matchedShortlist?.configuration,
      shortlistedLotConfiguration: matchedLot?.configuration,
    }
  }, [shortlists, lot.id, packageConfiguration])

  const activePackage = React.useMemo(
    () =>
      active
        ? packages.find((pkg) => pkg.id === packageConfiguration.floorplan)
        : undefined,
    [packageConfiguration?.floorplan, active, packages]
  )

  const disable = React.useMemo(() => {
    if (disableSoldLot && lot.status !== statusLabels?.available) {
      return true
    }
    return false
  }, [lot.status, disableSoldLot])

  const handleClick = React.useCallback(() => {
    if (activeStageId !== stageInfo.label) {
      firebaseControlQuery.update({
        [`houseAndLand.activeStageId`]: stageInfo.label,
      })
    }
    firebaseControlQuery.update({
      [`houseAndLand.activeLotId`]: lot.name,
    })
  }, [lot.id, lot.name, stageInfo.label, activeStageId])

  const handleConfigurationPropertyExpand = React.useCallback<
    NonNullable<LotPackageProps['onExpandProperty']>
  >(
    ({ propertyType, expanded }) => {
      onConfigurationPropertyExpand?.({
        propertyType,
        expanded,
        value: packageConfiguration[propertyType] || '',
      })
    },
    [onConfigurationPropertyExpand, packageConfiguration]
  )

  const handleChangePackageConfiguration = React.useCallback(
    (propertyType: keyof PackageConfigurationInterface, value: string) => {
      onConfigurationOptionSelect?.({ propertyType, value })
      let updatedConfiguration: PackageConfigurationInterface = {
        ...packageConfiguration,
        [propertyType]: value,
      }
      if (propertyType === 'floorplan') {
        updatedConfiguration = {
          ...INITIAL_PACKAGE_CONFIGURATION,
          [propertyType]: value,
        }
        firebaseControlQuery.update({
          [`houseAndLand.activePackageId`]: value,
        })
      }
      if (propertyType === 'facade') {
        updatedConfiguration = {
          ...packageConfiguration,
          [propertyType]: value,
          facadeColor: '',
        }
      }
      if (propertyType === 'internalTheme') {
        updatedConfiguration = {
          ...packageConfiguration,
          [propertyType]: value,
          internalColorScheme: '',
        }
      }
      setPackageConfiguration(updatedConfiguration)
    },
    [onConfigurationOptionSelect, packageConfiguration, lot.id, isShortlisted]
  )

  React.useEffect(() => {
    if (active) {
      setPackageConfiguration((prevVal) => ({
        ...prevVal,
        floorplan: validActivePackageId || '',
        ...(isShortlisted && shortlistedConfiguration),
      }))
      return
    }
    setPackageConfiguration({
      ...INITIAL_PACKAGE_CONFIGURATION,
      ...(isShortlisted && shortlistedConfiguration),
    })
  }, [active, validActivePackageId])

  React.useEffect(() => {
    onConfigurationValueChange?.(packageConfiguration)
  }, [onConfigurationValueChange, packageConfiguration])

  React.useEffect(() => {
    if (active && shortlistedLotConfiguration) {
      setPackageConfiguration((prevVal) => ({
        ...prevVal,
        ...shortlistedLotConfiguration,
      }))
    }
  }, [isLotShortlisted, active])

  return (
    <div
      role="none"
      id={lot.name}
      className={`overflow-hidden rounded-md transition-[border] ${
        active ? 'border-l-[7px] border-mainColour' : 'cursor-pointer'
      } bg-white ${disable ? 'pointer-events-none !bg-[#c2c2c2]' : ''}`}
      onClick={active ? undefined : handleClick}
    >
      <div className="px-6 py-4">
        <LotCardHeader
          lot={lot}
          totalPackage={packages.length}
          stageId={stageInfo.label}
          precinctId={activePrecinctId}
          packageConfiguration={packageConfiguration}
          isShortlisted={isLotShortlisted}
          availableStatusLabel={statusLabels?.available}
          statusColor={statusColors[slugify(lot.status || '')] || '#EF4444'}
        />
        <LotConfiguration
          {...(activePackage?.configuration || {})}
          area={lot.area}
          frontage={lot.frontage}
        />
        <CSSTransition
          in={active}
          classNames="transition-lot-package-expand-v2"
          timeout={500}
          unmountOnExit
        >
          <div className="overflow-hidden">
            <div className="mt-4 border-t-2 pb-2 pt-4">
              {packages.length ? (
                <LotPackage
                  packages={packages}
                  packageOptions={packageOptions}
                  values={packageConfiguration}
                  onChange={handleChangePackageConfiguration}
                  onExpandProperty={handleConfigurationPropertyExpand}
                  packageListTitleWhiteLabel={packageListTitleWhiteLabel || ''}
                />
              ) : null}
            </div>
            <ShortlistAction
              actionType="button"
              lot={lot}
              stageId={stageInfo.label}
              precinctId={activePrecinctId}
              isShortlisted={isShortlisted}
              isLotShortlisted={isLotShortlisted}
              packageConfiguration={packageConfiguration}
              availableStatusLabel={statusLabels?.available}
            />
          </div>
        </CSSTransition>
      </div>
    </div>
  )
}
export default connect(
  ({
    projectIdentity,
    shortlist: { shortlists },
    firestore: { session },
    whiteLabel,
  }: RootStateTypeExtra) => ({
    projectIdentity,
    shortlists,
    activeStageId: session?.houseAndLand?.activeStageId || '',
    activePrecinctId: session?.houseAndLand?.activePrecinctId || '',
    activePackageId: session?.houseAndLand?.activePackageId || '',
    packageListTitleWhiteLabel:
      whiteLabel?.showcase?.propertySidePanel?.packageListTitle || '',
  })
)(LotCard)
