import * as Sentry from '@sentry/react'

const {
  REACT_APP_SENTRY_DSN,
  REACT_APP_SENTRY_TRACES_SAMPLE_RATE,
  REACT_APP_SENTRY_REPLAYS_SESSION_SAMPLE_RATE,
  REACT_APP_SENTRY_REPLAYS_ON_ERROR_SAMPLE_RATE,
  REACT_APP_NODE_ENV,
} = process.env

const tracesSampleRate = Number(REACT_APP_SENTRY_TRACES_SAMPLE_RATE)
const replaysSessionSampleRate = Number(
  REACT_APP_SENTRY_REPLAYS_SESSION_SAMPLE_RATE
)
const replaysOnErrorSampleRate = Number(
  REACT_APP_SENTRY_REPLAYS_ON_ERROR_SAMPLE_RATE
)

Sentry.init({
  environment: REACT_APP_NODE_ENV || 'production',
  enabled: REACT_APP_NODE_ENV !== 'local',
  dsn: REACT_APP_SENTRY_DSN,
  integrations: [
    Sentry.browserTracingIntegration(), // For Performance Monitoring
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: true,
    }), // For Session Replays
  ],
  // Performance Monitoring
  tracesSampleRate,
  // Session Replay
  replaysSessionSampleRate,
  replaysOnErrorSampleRate,
})
