import React from 'react'

import { DataNotFound, ErrorHandler } from './errors'

export interface Payload {
  isLoaded: boolean
  isError: boolean
  status: string
  data: any
  errorStatus?: number
}

export interface DataHandlerProps<T extends string> {
  children: React.ReactNode
  skeletonFrame?: React.ReactNode
  payloads: { payload: Payload; name: T }[] | Payload
  skip?: { [key in T]?: boolean }
  message?: string
  showDefaultNotFound?: boolean
}

// eslint-disable-next-line no-shadow
export enum Status {
  UNINITIALIZED = 'uninitialized',
  PENDING = 'pending',
  FULFILLED = 'fulfilled',
  REJECTED = 'rejected',
}

function useDataHandlerAllComplete<T extends Payload>(payloads: T[]) {
  const activePayload = React.useMemo(() => {
    if (payloads.every((payload) => payload.status === Status.FULFILLED)) {
      return payloads[0]
    }

    const anyRejectedPayload = payloads.find(
      (payload) => payload.status === Status.REJECTED
    )
    if (anyRejectedPayload) {
      return anyRejectedPayload
    }

    return payloads.find(
      (payload) =>
        payload.status !== Status.FULFILLED &&
        payload.status !== Status.REJECTED
    ) as T
  }, [payloads])

  const checkData = <T,>(data: T) => {
    if (!data) {
      return false
    }
    if (Array.isArray(data)) {
      return data.length > 0
    }
    if (typeof data === 'object') {
      return Object.keys(data).length > 0
    }
    return true
  }

  return {
    ...activePayload,
    isAllComplete: payloads.every(
      (payload) => payload.status === Status.FULFILLED
    ),
    hasError: payloads.some((payload) => payload.status === Status.REJECTED),
    hasData: checkData(activePayload.data),
  }
}

function DataHandler<T extends string>({
  children,
  skeletonFrame,
  payloads,
  skip,
  message = 'This page needs to be setup',
  showDefaultNotFound = true,
}: DataHandlerProps<T>) {
  const hasMultiplePayloads = Array.isArray(payloads)

  const activePayload = useDataHandlerAllComplete(
    hasMultiplePayloads
      ? payloads
          ?.filter((item) => !skip?.[item.name])
          ?.map((item) => item.payload) || []
      : [payloads]
  )

  if (!activePayload.isLoaded && !activePayload.hasError) {
    if (skeletonFrame) {
      return <>{skeletonFrame}</>
    }

    return (
      <div className="fixed inset-0 z-10 bg-secondaryColour bg-opacity-50">
        <div className="absolute left-50% top-[45%] h-24 w-24 animate-spin rounded-full border-4 border-solid border-neutralColour border-t-transparent text-center"></div>
      </div>
    )
  }

  if (activePayload.isLoaded && !activePayload.hasData) {
    if (activePayload.hasError) {
      if (activePayload.errorStatus === 404) {
        return <DataNotFound message={message} />
      }
      return <ErrorHandler />
    }

    return showDefaultNotFound ? (
      <DataNotFound message={message} />
    ) : (
      <>{children}</>
    )
  }

  return <>{children}</>
}

export default DataHandler
