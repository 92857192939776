import React, { MouseEvent } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'

import {
  GalleryItemInterface,
  PlayerState,
  ProjectIdentity,
  RootStateTypeExtra,
} from '@store/types'

import Container from '@components/container'
import DataHandler from '@components/data-handler'
import GalleryItem from '@components/gallery-item'
import GallerySkeleton from '@components/gallery-skeleton'
import VideoPlayer from '@components/video-player'

import {
  selectFromResult as selectFromVideoGalleryResult,
  useGetVideoGalleryQuery,
} from '@api/video-gallery'

import useTabVisibility from '@utilities/tab-visibility'
import VideoPlayerFirebaseService from '@utilities/video-player-firebase-service'

import FirebaseControlQuery from '@firebaseUtil/firebase-control-query'
import { SessionMap } from '@firebaseUtil/types'

export interface PagePropsInterface {
  projectIdentity: ProjectIdentity
  session: SessionMap | undefined
}

const VIDEO_PAGE_ROUTE = '/video-gallery'

function VideoGallery({ projectIdentity, session }: PagePropsInterface) {
  const history = useHistory()

  const firebaseControlQuery = FirebaseControlQuery({
    ...projectIdentity,
    sessionKey: projectIdentity.sessionId,
  })

  const videoPlayerFirebaseService = VideoPlayerFirebaseService({
    galleryName: 'videoGallery',
    projectIdentity,
  })

  const [activeSlideIndex, setActiveSlideIndex] = React.useState(0)
  const [isBuffering, setBufferState] = React.useState(false)
  const [scrubbarTimerState, setScrubbarTimerState] = React.useState(false)
  const [playerState, setPlayerState] = React.useState<PlayerState>(
    PlayerState.Stop
  )
  const [isMuted, setMuteState] = React.useState(true)
  const [isLooping, setLoopState] = React.useState(true)
  const [volume, setVolume] = React.useState<number>(0)
  const [videoLengthInSeconds, setVideoLengthInSeconds] = React.useState(0)
  const [currentVideoPositionInSeconds, setCurrentVideoPositionInSeconds] =
    React.useState(0)
  const [isSeeking, setSeekingState] = React.useState(false)

  const videoGalleryPayload = useGetVideoGalleryQuery(
    { projectName: projectIdentity.projectId },
    { selectFromResult: selectFromVideoGalleryResult }
  )

  const videoItems: Array<GalleryItemInterface> = React.useMemo(() => {
    const { videoGalleryData = [] } = videoGalleryPayload

    if (videoGalleryData.length < 1) {
      return []
    }

    return videoGalleryData[0].items || []
  }, [videoGalleryPayload])

  const handleOnItemClick = React.useCallback(
    async (event: MouseEvent<HTMLDivElement>, index: number) => {
      event.stopPropagation()
      setActiveSlideIndex(index)
      setPlayerState(PlayerState.Stop)
      setScrubbarTimerState(false)
      await firebaseControlQuery.update({
        [`videoGallery.galleryControlV2.activeSlideIndex`]: index,
        [`videoGallery.playerControlV2.playerState`]: PlayerState.Stop,
        [`videoGallery.playerControlV2.scrubbarTimerState`]: false,
      })
    },
    [firebaseControlQuery]
  )

  const handleOnPlayButtClick = React.useCallback(
    async (event: MouseEvent<HTMLButtonElement>, index: number) => {
      event.stopPropagation()
      setActiveSlideIndex(index)
      setPlayerState(PlayerState.Play)
      setScrubbarTimerState(false)
      await firebaseControlQuery.update({
        [`videoGallery.galleryControlV2.activeSlideIndex`]: index,
        [`videoGallery.playerControlV2.playerState`]: PlayerState.Play,
        [`videoGallery.playerControlV2.scrubbarTimerState`]: false,
      })
    },
    [firebaseControlQuery]
  )

  const handlePlayClick = React.useCallback(async () => {
    setPlayerState(PlayerState.Play)
    setScrubbarTimerState(false)
    await videoPlayerFirebaseService.updatePlayState()
  }, [videoPlayerFirebaseService])

  const handlePauseClick = React.useCallback(async () => {
    setPlayerState(PlayerState.Pause)
    setScrubbarTimerState(false)
    await videoPlayerFirebaseService.updatePauseState()
  }, [videoPlayerFirebaseService])

  const handleStopClick = React.useCallback(async () => {
    setScrubbarTimerState(false)
    setPlayerState(PlayerState.Stop)
    setLoopState(true)
    setVideoLengthInSeconds(0)
    setCurrentVideoPositionInSeconds(0)
    await videoPlayerFirebaseService.updateStopState()
  }, [videoPlayerFirebaseService])

  const handleLoopClick = React.useCallback(
    async (arg: boolean) => {
      setLoopState(arg)
      await videoPlayerFirebaseService.updateLoopState(arg)
    },
    [videoPlayerFirebaseService]
  )

  const handleSeekTo = React.useCallback(
    async (argInSeconds: number) => {
      setScrubbarTimerState(false)
      setSeekingState(true)
      await videoPlayerFirebaseService.updateSeekState(argInSeconds)
    },
    [videoPlayerFirebaseService]
  )

  const handleMuteClick = React.useCallback(
    async (arg: boolean) => {
      setMuteState(arg)
      await videoPlayerFirebaseService.updateMuteState(arg)
    },
    [videoPlayerFirebaseService]
  )

  const handleVolumeDownClick = React.useCallback(async () => {
    if (volume > 0) {
      const newVolume = volume - 1

      setMuteState(false)
      setVolume(newVolume)
      await videoPlayerFirebaseService.updateVolumeState(newVolume)
    }
  }, [volume, videoPlayerFirebaseService])

  const handleVolumeUpClick = React.useCallback(async () => {
    if (volume < 10) {
      setMuteState(false)
      const newVolume = volume + 1
      setVolume(newVolume)
      await videoPlayerFirebaseService.updateVolumeState(newVolume)
    }
  }, [volume, videoPlayerFirebaseService])

  const updateSyncState = React.useCallback(async () => {
    await videoPlayerFirebaseService.updateSyncState()
  }, [videoPlayerFirebaseService])

  useTabVisibility(
    React.useCallback(async () => {
      if (playerState === PlayerState.Play) {
        updateSyncState()
      }
    }, [playerState])
  )

  React.useEffect(() => {
    if (!session) {
      return
    }

    const {
      videoGallery: {
        galleryControlV2: { activeSlideIndex: activeSlideIndexFirebase },
        playerControlV2: {
          playerState: playerStateFirebase,
          loop: loopStateFirebase,
          videoLengthInSeconds: videoLengthInSecondsFirebase,
          currentVideoPositionInSeconds: currentVideoPositionInSecondsFirebase,
          volume: volumeFirebase,
          scrubbarTimerState: scrubbarTimerStateFirebase,
          seekToValueInSeconds: seekToValueInSecondsFirebase,
          isBuffering: isBufferingFirebase,
          muted: muteStateFirebase,
        },
      },
    } = session

    setActiveSlideIndex(activeSlideIndexFirebase)
    setPlayerState(playerStateFirebase)
    setVideoLengthInSeconds(videoLengthInSecondsFirebase)
    setCurrentVideoPositionInSeconds(currentVideoPositionInSecondsFirebase)
    setVolume(volumeFirebase)
    setScrubbarTimerState(scrubbarTimerStateFirebase)
    setBufferState(isBufferingFirebase)
    setLoopState(loopStateFirebase)
    setSeekingState(seekToValueInSecondsFirebase !== null)
    setMuteState(muteStateFirebase)
  }, [session?.videoGallery])

  React.useEffect(() => {
    if (
      videoGalleryPayload.videoGalleryData.length > 0 &&
      playerState === PlayerState.Play
    ) {
      updateSyncState()
    }
  }, [videoGalleryPayload.videoGalleryData, playerState])

  React.useEffect(
    () => () => {
      const currentRoute = history.location.pathname || ''
      if (
        currentRoute !== VIDEO_PAGE_ROUTE &&
        playerState === PlayerState.Play
      ) {
        setScrubbarTimerState(false)
        videoPlayerFirebaseService.resetPlayerState()
      }
    },
    [videoPlayerFirebaseService, playerState]
  )

  return (
    <Container>
      <DataHandler
        payloads={{
          ...videoGalleryPayload,
          data: videoGalleryPayload.videoGalleryData,
        }}
        skeletonFrame={<GallerySkeleton />}
      >
        <div className="h-full w-full px-4">
          <div className="flex h-top-bar w-full items-center justify-center text-neutralColour">
            <div className="text-title font-medium">Videos</div>
          </div>
          <div className="no-scrollbar relative h-page-body w-full overflow-auto">
            <div className="no-scrollbar h-full overflow-y-auto">
              <div className="grid grid-cols-3 gap-4 px-4">
                {videoItems.map((item, index) => (
                  <GalleryItem
                    key={index as number}
                    item={item}
                    playerState={playerState}
                    isActive={index === activeSlideIndex}
                    onItemClick={(event: MouseEvent<HTMLDivElement>) =>
                      handleOnItemClick(event, index)
                    }
                    onPlayStopClick={(
                      event: MouseEvent<HTMLButtonElement>,
                      action: PlayerState
                    ) => {
                      if (action === PlayerState.Stop) {
                        handleStopClick()
                      } else {
                        handleOnPlayButtClick(event, index)
                      }
                    }}
                  />
                ))}
              </div>
            </div>
            <div
              className={`absolute bottom-0 z-40 w-full pb-4 transition-all duration-300 ${
                playerState !== PlayerState.Stop
                  ? 'translate-y-0 opacity-100'
                  : 'pointer-events-none fixed translate-y-full opacity-0'
              }`}
            >
              <VideoPlayer
                scrubbarTimerState={scrubbarTimerState}
                isBuffering={isBuffering}
                isMuted={isMuted}
                volume={volume}
                isLooping={isLooping}
                playerState={playerState}
                videoLengthInSeconds={videoLengthInSeconds}
                currentVideoPositionInSeconds={currentVideoPositionInSeconds}
                isSeeking={isSeeking}
                handleStopClick={handleStopClick}
                handlePauseClick={handlePauseClick}
                handlePlayClick={handlePlayClick}
                handleMuteClick={handleMuteClick}
                handleVolumeUpClick={handleVolumeUpClick}
                handleVolumeDownClick={handleVolumeDownClick}
                handleSeekTo={handleSeekTo}
                handleLoopClick={handleLoopClick}
              />
            </div>
          </div>
        </div>
      </DataHandler>
    </Container>
  )
}

const mapStateToProps = ({
  projectIdentity,
  firestore: { session },
}: RootStateTypeExtra) => ({
  projectIdentity,
  session,
})

export default connect(mapStateToProps)(VideoGallery)
