import React, { useMemo } from 'react'
import { connect } from 'react-redux'

import { ShortlistState } from '@store/actionSlices/shortlist'
import {
  ProjectIdentity,
  RootStateTypeExtra,
  ShortlistPropertyType,
  UnitShortlistInterface,
} from '@store/types'

import ShortlistHandler from '@utilities/shortlist-handler'

import FirebaseControlQuery from '@firebaseUtil/firebase-control-query'

import {
  BathSvg,
  BedSvg,
  CarSvg,
  PowderSvg,
  StarSvg,
  StudySvg,
} from '@svg/react'

import { slugify } from '@adUtilities/helper'
import { notifyError } from '@adUtilities/notifier'
import { Unit } from '@adUtilities/types/apartment'

interface ComponentPropsInterface {
  unit: Unit
  active: boolean
  shortlist: ShortlistState
  projectIdentity: ProjectIdentity
}

function UnitCard({
  unit,
  active = false,
  shortlist,
  projectIdentity,
}: ComponentPropsInterface) {
  const firebaseControlQuery = FirebaseControlQuery({
    ...projectIdentity,
    sessionKey: projectIdentity.sessionId,
  })
  const { disableSoldProperty, statusLabels, statusColors } =
    projectIdentity || {}

  const unitStatus = unit.metas.status

  const isSoldUnit = useMemo(
    () => disableSoldProperty && unitStatus !== statusLabels?.available,
    [unit, statusLabels]
  )

  const [removeShortlistProperty, setRemoveShortlistProperty] =
    React.useState(false)
  const property = React.useMemo<UnitShortlistInterface>(
    () => ({
      propertyId: unit.id,
      propertyName: unit.name,
      references: {
        blockId: unit.blockId,
        levelId: unit.metas.level,
      },
      type: ShortlistPropertyType.Unit,
    }),
    [unit]
  )

  const setShortlistErrorMessage = (message: string) => {
    notifyError(message)
  }

  const [isShortlisted, handleShortlistClick] =
    (property &&
      ShortlistHandler({
        property,
        shortlist,
        setShortlistErrorMessage,
        projectIdentity,
        toggleConfirmModal: () => setRemoveShortlistProperty(true),
        removeShortlistProperty,
        setRemoveShortlistProperty,
        available: unit.metas.status,
      })) ||
    []

  const handleUnitClick = async () => {
    await firebaseControlQuery.update({
      [`building.activeUnit`]: unit.name,
      [`building.activeLevel`]: unit.metas.level,
      [`propertyGallery.galleryControlV2.activeSlideIndex`]: 0,
    })
  }

  const statusColor = useMemo(
    () => statusColors[slugify(unitStatus || '')] || '#EF4444',
    [statusColors]
  )

  return (
    <div
      role="none"
      className={`mb-2.5 cursor-pointer rounded-md ${
        active ? 'border-l-8 border-mainColour' : ''
      } bg-white ${isSoldUnit ? 'pointer-events-none !bg-[#c2c2c2]' : ''}`}
      onClick={handleUnitClick}
    >
      <div className="px-6 py-4">
        <div className="mb-2 flex justify-between gap-1">
          <div className="flex min-w-0 items-center">
            <div className="flex-1 truncate">
              <span className="font-bold" title={unit.name}>
                {unit.name}
              </span>
            </div>
            <span
              className="ml-4 h-2 w-2 rounded-full"
              style={{
                backgroundColor: statusColor,
              }}
            ></span>
            <span
              className="ml-1 text-xs font-semibold uppercase tracking-widest"
              style={{
                color: statusColor,
              }}
            >
              {unitStatus}
            </span>
          </div>
          <button
            type="button"
            title="Shortlist unit"
            onClick={(e) => {
              e.stopPropagation()
              handleShortlistClick()
            }}
          >
            <StarSvg
              className={`h-4 w-4 ${
                isShortlisted
                  ? 'stroke-amber-500 text-amber-500'
                  : 'stroke-zinc-400 text-zinc-400'
              }`}
            />
          </button>
        </div>

        <div className="flex gap-3 text-zinc-500">
          {Number(unit.metas?.configurations?.bed) > 0 && (
            <div className="inline-flex items-center gap-1 text-sm" title="Bed">
              <BedSvg className="h-4 w-4" />
              {unit.metas?.configurations?.bed}
            </div>
          )}
          {Number(unit.metas?.configurations?.study) > 0 && (
            <div
              className="inline-flex items-center gap-1 text-sm"
              title="Study"
            >
              <StudySvg className="h-4 w-4" />
              {unit.metas?.configurations?.study}
            </div>
          )}
          {Number(unit.metas?.configurations?.bath) > 0 && (
            <div
              className="inline-flex items-center gap-1 text-sm"
              title="Bath"
            >
              <BathSvg className="h-4 w-4" />
              {unit.metas?.configurations?.bath}
            </div>
          )}
          {Number(unit.metas?.configurations?.powderRoom) > 0 && (
            <div
              className="inline-flex items-center gap-1 text-sm"
              title="Powder"
            >
              <PowderSvg className="h-4 w-4" />
              {unit.metas?.configurations?.powderRoom}
            </div>
          )}
          {Number(unit.metas?.configurations?.car) > 0 && (
            <div className="inline-flex items-center gap-1 text-sm" title="Car">
              <CarSvg className="h-4 w-4" />
              {unit.metas?.configurations?.car}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
export default connect(
  ({ shortlist, projectIdentity }: RootStateTypeExtra) => ({
    shortlist,
    projectIdentity,
  })
)(UnitCard)
