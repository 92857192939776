import React, { Ref, forwardRef } from 'react'
import ReactPlayer from 'react-player'

import { PlayerState } from '@store/types'

import { BufferSvg, PlayCircleSvg } from '@svg/react'

import {
  BufferState,
  EndState,
  PlayState,
  SeekState,
  SyncState,
} from '@adUtilities/constants/player'

import PlayerControls from './player-controls/player-controls'

export interface VideoPlayerRef {
  updatePlayerState: (arg: PlayerState) => void
  seekTo: (arg: number) => void
  toggleLoop: (arg: boolean) => void
  toggleMute: (arg: boolean) => void
  updateVolume: (arg: number) => void
}

interface ProgressProps {
  played: number
  playedSeconds: number
  loaded: number
  loadedSeconds: number
}

interface ComponentProps {
  sources: Array<string>
  showControls: boolean
  activeIndex?: number
  videoSyncKey?: number | null
  disablePlayButton?: boolean
  getPlayerState: (arg: boolean) => void
  showLoopOption?: boolean
  updateStateOnPlay?: (arg: PlayState) => void
  updateStateOnBuffer?: (args: BufferState) => void
  updateStateOnSeek?: (args: SeekState) => void
  updateStateOnSyncRequest?: (args: SyncState) => void
  updateStateOnEnd?: (args: EndState) => void
  isAppIdle?: boolean
  hidePlayButton?: boolean
}

const INITIAL_PROGRESS: ProgressProps = {
  played: 0,
  playedSeconds: 0,
  loaded: 0,
  loadedSeconds: 0,
}
const INITIAL_VOLUME = 0.1
const PROGRESS_INTERVAL = 250

function VideoPlayer(
  {
    sources,
    activeIndex,
    showControls,
    videoSyncKey = null,
    disablePlayButton = false,
    getPlayerState,
    showLoopOption,
    updateStateOnPlay,
    updateStateOnBuffer,
    updateStateOnSeek,
    updateStateOnSyncRequest,
    updateStateOnEnd,
    isAppIdle = false,
    hidePlayButton = false,
  }: ComponentProps,
  ref: Ref<VideoPlayerRef | undefined>
) {
  const playerRef = React.useRef<ReactPlayer>(null)

  const [currentVideoIndex, setCurrentVideoIndex] = React.useState(0)
  const [isPlayerVisible, setPlayerVisibility] = React.useState(false)
  const [isPlaying, setIsPlaying] = React.useState(false)
  const [isBuffering, setBufferState] = React.useState(false)
  const [isMuted, setMuteState] = React.useState(false)
  const [volume, setVolume] = React.useState(INITIAL_VOLUME)
  const [videoLengthInSeconds, setVideoLength] = React.useState(0)
  const [progress, setProgress] =
    React.useState<ProgressProps>(INITIAL_PROGRESS)
  const [isLooping, setLoopState] = React.useState(true)

  const updatePlayerState = React.useCallback(
    (arg: PlayerState) => {
      if (arg === PlayerState.Play) {
        setPlayerVisibility(true)
        setIsPlaying(true)
        if (getPlayerState) {
          getPlayerState(true)
        }
        return
      }

      if (arg === PlayerState.Pause) {
        setIsPlaying(false)
        return
      }

      if (arg === PlayerState.Stop) {
        setCurrentVideoIndex(activeIndex || 0)
        setLoopState(true)
        setVolume(INITIAL_VOLUME)
        setProgress(INITIAL_PROGRESS)
        setPlayerVisibility(false)
        setIsPlaying(false)
        if (getPlayerState) {
          getPlayerState(false)
        }
      }
    },
    [activeIndex]
  )

  const seekTo = React.useCallback(
    (valueInSeconds: number) => {
      if (playerRef.current) {
        playerRef.current.seekTo(valueInSeconds < 1 ? 1 : valueInSeconds)
      }
    },
    [playerRef]
  )

  const toggleMute = React.useCallback((arg: boolean) => setMuteState(arg), [])

  const updateVolume = React.useCallback((arg: number) => setVolume(arg), [])

  const toggleLoop = React.useCallback((arg: boolean) => setLoopState(arg), [])

  const handleOnPlay = React.useCallback(() => {
    if (playerRef.current && updateStateOnPlay) {
      updateStateOnPlay({
        videoLengthInSeconds: playerRef.current?.getDuration(),
        currentVideoPositionInSeconds: playerRef.current?.getCurrentTime(),
      })
    }
  }, [playerRef])

  const handleOnBuffer = React.useCallback(() => {
    setBufferState(true)
    if (updateStateOnBuffer) {
      updateStateOnBuffer({
        isBuffering: true,
      })
    }
  }, [])

  const handleOnBufferEnd = React.useCallback(() => {
    setBufferState(false)
    if (updateStateOnBuffer && playerRef.current) {
      updateStateOnBuffer({
        isBuffering: false,
        currentVideoPositionInSeconds: playerRef.current?.getCurrentTime(),
      })
    }
  }, [playerRef])

  const handleVideoEnd = React.useCallback(() => {
    const newIndex = (currentVideoIndex + 1) % sources.length
    setCurrentVideoIndex(newIndex)
    if (updateStateOnEnd) {
      updateStateOnEnd({
        videoLoopIndex: newIndex,
      })
    }
  }, [sources, currentVideoIndex])

  const handleOnSeek = React.useCallback(() => {
    if (updateStateOnSeek && playerRef.current) {
      updateStateOnSeek({
        currentVideoPositionInSeconds: playerRef.current?.getCurrentTime(),
      })
    }
  }, [playerRef])

  React.useEffect(() => {
    setCurrentVideoIndex(activeIndex || 0)
  }, [activeIndex])

  React.useEffect(() => {
    if (videoSyncKey && playerRef && updateStateOnSyncRequest) {
      updateStateOnSyncRequest({
        videoLengthInSeconds: playerRef.current?.getDuration() || 0,
        currentVideoPositionInSeconds: playerRef.current?.getCurrentTime() || 0,
      })
    }
  }, [videoSyncKey, playerRef])

  React.useImperativeHandle(ref, () => ({
    updatePlayerState,
    seekTo,
    toggleLoop,
    toggleMute,
    updateVolume,
  }))

  if (!hidePlayButton && !isPlayerVisible) {
    return (
      <button
        type="button"
        onClick={(e) => {
          if (!disablePlayButton) {
            updatePlayerState(PlayerState.Play)
          } else {
            e.preventDefault()
          }
        }}
        className={`z-1 ${
          disablePlayButton ? 'cursor-not-allowed' : 'cursor-pointer'
        }`}
      >
        <PlayCircleSvg size="s" className="h-36 w-36 text-tertiaryColour" />
      </button>
    )
  }

  if (isPlayerVisible) {
    return (
      <div className="relative z-1 flex h-full w-full items-center justify-center bg-black">
        {isBuffering && (
          <div className="absolute inset-0 flex items-center justify-center">
            <BufferSvg className="h-20 w-20 text-white drop-shadow-40" />
          </div>
        )}

        <ReactPlayer
          ref={playerRef}
          style={{ backgroundColor: '#000000' }}
          url={sources[currentVideoIndex]}
          playing={isPlaying}
          width="auto"
          height="auto"
          volume={volume}
          muted={isMuted}
          onPlay={handleOnPlay}
          onProgress={setProgress}
          onDuration={setVideoLength}
          onBuffer={handleOnBuffer}
          onBufferEnd={handleOnBufferEnd}
          onEnded={handleVideoEnd}
          loop={sources.length > 1 ? isLooping : true}
          progressInterval={PROGRESS_INTERVAL}
          onSeek={handleOnSeek}
          onError={(error) =>
            console.error('An error occurred while loading the video:', error)
          }
        />

        {showControls && (
          <PlayerControls
            isPlaying={isPlaying}
            isLooping={isLooping}
            volume={volume}
            videoLengthInSeconds={videoLengthInSeconds}
            playedSeconds={progress.playedSeconds}
            onPlayerStateUpdate={updatePlayerState}
            onVolumeChange={updateVolume}
            onSeek={seekTo}
            onToggleLoop={toggleLoop}
            showLoopOption={showLoopOption}
            isAppIdle={isAppIdle}
          />
        )}
      </div>
    )
  }

  return null
}

const ForwardedVideoPlayer = forwardRef<
  VideoPlayerRef | undefined,
  ComponentProps
>(VideoPlayer)

export default ForwardedVideoPlayer
