import { HouseAndLandPackageOptionType } from '@utilities/adgroup-utilities/types/houseAndLand'

const mappedPackageOptionType = (type: string) => {
  const types: { [type: string]: HouseAndLandPackageOptionType } = {
    facade: HouseAndLandPackageOptionType.Facade,
    facadeColor: HouseAndLandPackageOptionType.FacadeColor,
    floorplanOption: HouseAndLandPackageOptionType.FloorplanOption,
    internalColorScheme: HouseAndLandPackageOptionType.InternalColorScheme,
    internalTheme: HouseAndLandPackageOptionType.InternalTheme,
  }

  return types[type] || type
}

export default mappedPackageOptionType
