import React from 'react'
import { useDispatch } from 'react-redux'

import { setProjectIdentity } from '@store/actionSlices/projectIdentity'
import { ProjectIdentity } from '@store/types'

import { Modal } from '@components/modals'

import FirebaseControlQuery from '@firebaseUtil/firebase-control-query'

interface SessionCardProps {
  projectIdentity: ProjectIdentity
  isActive: boolean
  sessionName: string
  sessionId: string
  projectId: string
  projectUUID: string
  projectName: string
}

function SessionCard({
  projectIdentity,
  isActive,
  sessionName,
  sessionId,
  projectId,
  projectUUID,
  projectName,
}: SessionCardProps) {
  const firebaseControlQuery = FirebaseControlQuery({
    ...projectIdentity,
    sessionKey: projectIdentity.sessionId,
  })
  const dispatch = useDispatch()

  const [isModalActive, modalToggle] = React.useState(false)
  const [isDisconnecting, setIsDisconnecting] = React.useState(false)

  const disableDisconnect = React.useCallback(() => {
    const { disconnect, masterKey } = projectIdentity
    if (masterKey && isActive) {
      return true
    }
    return isActive && !disconnect
  }, [isActive, projectIdentity])

  const triggerFirebaseProjectSwitch = async ({
    projectIdFb,
    projectLabelFb,
    projectNameFb,
  }: {
    projectIdFb: string
    projectLabelFb: string
    projectNameFb: string
  }) => {
    await firebaseControlQuery.update({
      [`projectSwitch`]: {
        projectId: projectIdFb,
        projectLabel: projectLabelFb,
        projectName: projectNameFb,
      },
    })
    setTimeout(async () => {
      await firebaseControlQuery.update({
        [`projectSwitch`]: {
          projectId: '',
          projectLabel: '',
          projectName: '',
        },
      })
      window.location.reload()
    }, 500)
  }

  const findNextSession = () => {
    let filterdSessionList = projectIdentity.sessionList.filter(
      (res) =>
        res.sessionId !== sessionId &&
        res.projectId === projectIdentity.projectId
    )

    if (filterdSessionList.length === 0) {
      filterdSessionList = projectIdentity.sessionList.filter(
        (res) => res.sessionId !== sessionId
      )
    }
    return filterdSessionList
  }

  const switchNextRelevantSession = () => {
    const updatedProjectIdentity: ProjectIdentity = {
      ...projectIdentity,
      disconnectedSessions: [
        ...projectIdentity.disconnectedSessions,
        {
          projectId,
          sessionId,
        },
      ],
    }

    const [foundSession] = findNextSession()
    if (foundSession) {
      dispatch(
        setProjectIdentity({
          ...updatedProjectIdentity,
          sessionList: updatedProjectIdentity.sessionList.filter(
            (res) => res.sessionId !== sessionId
          ),
          projectId: foundSession.projectId || '',
          projectName: foundSession.projectId || '',
          projectLabel: foundSession.projectName || '',
          projectUUID: foundSession.projectUUID || '',
          sessionId: foundSession.sessionId || '',
        })
      )
      triggerFirebaseProjectSwitch({
        projectIdFb: foundSession.projectUUID || '',
        projectNameFb: foundSession.projectId || '',
        projectLabelFb: foundSession.projectName || '',
      })
      return
    }
    dispatch(
      setProjectIdentity({
        ...updatedProjectIdentity,
        sessionList: [],
        sessionId: '',
      })
    )
  }

  const disconnectSession = async () => {
    setIsDisconnecting(true)
    modalToggle(false)

    await firebaseControlQuery.refreshSession()
    setIsDisconnecting(false)
    switchNextRelevantSession()
  }

  const switchSession = () => {
    localStorage.setItem(
      'projectIdentity',
      JSON.stringify({
        ...projectIdentity,
        projectId,
        projectName,
        projectUUID,
        sessionId,
        projectLabel: projectName,
      })
    )
    window.location.reload()
  }

  return (
    <>
      <Modal
        toggleModal={(res) => modalToggle(res)}
        handleApply={() => modalToggle(false)}
        handleClose={() => {
          if (isActive) {
            disconnectSession()
            return
          }
          switchSession()
        }}
        isVisible={isModalActive}
        applyButtonText="Cancel"
        closeButtonText={isActive ? 'Disconnect' : 'Switch'}
        title={
          isActive
            ? 'Are you sure you want to disconnect?'
            : 'Do you want to switch to this session?'
        }
        theme={{
          textColor: 'text-mainColour',
          mainBg: 'bg-gradient',
          closeTextColor: isActive ? 'text-red-600' : 'text-mainColour',
          secondaryBg: 'bg-neutralColour',
        }}
      >
        <div className="p-4">
          {isActive
            ? 'You will be disconnected to the selected session and be placed in the next active session.'
            : 'You will be switched to the selected session.'}
        </div>
      </Modal>
      <div className="relative h-52 max-h-[250px] rounded bg-secondaryColour">
        <div className="h-auto">
          <div className="h-full px-6 pt-6 text-left text-mainColour">
            <div className="flex items-center text-default font-semibold leading-none">
              <span>
                {sessionName ? `${sessionName} (THIS SESSION)` : 'THIS SESSION'}
              </span>
              {isActive ? (
                <span className="relative ml-auto flex h-5 w-5">
                  <span className="absolute inline-flex h-full w-full animate-ping rounded-full bg-[#28A745] opacity-75"></span>
                  <span className="absolute inline-flex h-full w-full rounded-full bg-[#28A745] opacity-75"></span>
                </span>
              ) : (
                <span className="relative ml-auto flex h-5 w-5">
                  <span className="absolute inline-flex h-full w-full rounded-full bg-[#28A745] opacity-30"></span>
                </span>
              )}
            </div>
            <p className="mt-2 text-default font-normal leading-none">
              Session ID: {sessionId}
            </p>
            {sessionName && (
              <p className="mt-2 text-default font-normal leading-none">
                Session Name: {sessionName}
              </p>
            )}
          </div>
          <div className="absolute bottom-0 w-full p-6">
            {isActive && (
              <button
                type="button"
                onClick={() => modalToggle(true)}
                disabled={disableDisconnect()}
                className={`sm-h:py-2 flex w-full items-center justify-center rounded-lg bg-red-600 ${
                  disableDisconnect() ? 'opacity-20' : ''
                } py-4 text-center text-2xl leading-none text-neutralColour shadow-standard`}
              >
                Disconnect
                {isDisconnecting && (
                  <div className="ml-2 h-7 w-7 animate-spin rounded-full border-4 border-solid border-neutralColour border-t-transparent text-center"></div>
                )}
              </button>
            )}
            {!isActive && (
              <button
                type="button"
                onClick={() => modalToggle(true)}
                className="sm-h:py-2 flex w-full items-center justify-center rounded-lg bg-gradient py-4 text-center text-2xl leading-none text-neutralColour shadow-standard"
              >
                Switch to Session
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default SessionCard
