import React from 'react'
import { connect, useDispatch } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { CSSTransition, SwitchTransition } from 'react-transition-group'

import FILTER_INITIAL_STATE from '@src/components/filter/v2/filterStateHouseAndLand'

import { setFilter } from '@store/actionSlices/lotFilter'
import {
  PackageConfigurationInterface,
  ProjectIdentity,
  RootStateTypeExtra,
} from '@store/types'

import Container from '@components/container/v2'
import DataHandler from '@components/data-handler/v2'
import useGetFilterData from '@components/filter/use-get-filter-data'
import Filter from '@components/filter/v2/house-and-land-filter'

import {
  ActivePackageConfigurationType,
  LotInterface,
  PackageSummaryInterface,
  StageCollectionInterface,
  StageInterface,
  selectMatrixDataFromResult,
  useGetMatrixDataByPrecinctQuery,
} from '@api/houseAndLand'

import filterPackageSummary from '@utilities/adgroup-utilities/filter-package-summary'
import { HouseAndLandPackageOptionType } from '@utilities/adgroup-utilities/types/houseAndLand'
import { getQueryStringParams } from '@utilities/helper'
import mappedPackageOptionType from '@utilities/house-and-land-helpers'

import FirebaseControlQuery from '@firebaseUtil/firebase-control-query'
import { SessionMap } from '@firebaseUtil/types'

import LotView from './lot-view'
import PackageInfoModal from './lot-view/package-info-modal'
import LotsSidePanel from './lots-side-panel'
import LotSidePanelSkeleton from './lots-side-panel/lot-side-panel-skeleton'
import StageSkeleton from './stage-skeleton'
import StageView from './stage-view'

interface StagePagePropsInterface {
  session: SessionMap | undefined
  activePrecinctId: string
  activeStageId: string
  activeLotId: string
  activePackageId: string
  projectIdentity: ProjectIdentity
  onFullScreenToggle?: React.Dispatch<React.SetStateAction<boolean | undefined>>
}

const initialLastActiveOption = {
  type: '',
  id: '',
}

const AREA_VIEW = 'area-view-house-and-land'

function Stage({
  session,
  activePrecinctId,
  activeStageId,
  activeLotId,
  activePackageId,
  projectIdentity,
  onFullScreenToggle,
}: StagePagePropsInterface) {
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()
  const urlParams = React.useRef(getQueryStringParams(location.search))
  const [filterActivePrecinct, setFilterActivePrecinct] = React.useState('')
  const [hasSessionInitialized, setHasSessionInitialized] =
    React.useState(false)

  const firebaseControlQuery = FirebaseControlQuery({
    ...projectIdentity,
    sessionKey: projectIdentity.sessionId,
  })

  const availableStatusLabel = projectIdentity?.statusLabels?.available

  const matrixDataPayload = useGetMatrixDataByPrecinctQuery(
    {
      projectName: projectIdentity.projectName,
      precinctIdOrLabel: activePrecinctId,
    },
    {
      selectFromResult: selectMatrixDataFromResult,
      skip: !activePrecinctId,
    }
  )

  const filterPayload = useGetFilterData({
    projectName: projectIdentity.projectId,
    precinctId: filterActivePrecinct,
  })

  const [fullScreenToggle, setFullScreenToggle] = React.useState(false)
  const [lotSidePanelState, setLotSidePanelState] = React.useState(true)
  const [isFilterOpen, toggleFilter] = React.useState(false)
  const [packageInfoModalToggle, setPackageInfoModalToggle] =
    React.useState(false)
  const [lastActiveOption, setLastActiveOption] = React.useState<{
    type: string
    id: string
  }>(initialLastActiveOption)
  const [activePackageConfiguration, setActivePackageConfiguration] =
    React.useState<ActivePackageConfigurationType>()

  const activeMatrixData = matrixDataPayload.matrixData
  const activePackages = activeMatrixData.packages

  const activeStages: StageCollectionInterface | undefined =
    React.useMemo(() => {
      if (!activeMatrixData || !activeStageId) {
        return undefined
      }

      return activeMatrixData.stages || undefined
    }, [activeMatrixData, activeStageId])

  const activeStage: StageInterface | undefined = React.useMemo(() => {
    if (!activeStages || !activeStageId) {
      return undefined
    }

    return activeStages[activeStageId] || undefined
  }, [activeStageId, activeStages])

  const activePackage: PackageSummaryInterface | undefined =
    React.useMemo(() => {
      if (activePackages?.length === 0 || !activePackageId) {
        return undefined
      }

      return activePackages?.find(
        (item) => item.id === activePackageId || item.name === activePackageId
      )
    }, [activePackages, activePackageId])

  const activeLot: LotInterface | undefined = React.useMemo(() => {
    if (!activeMatrixData || !activeStages || !activeStageId || !activeLotId) {
      return undefined
    }

    return activeStages[activeStageId].lots.find(
      (lotItem: LotInterface) => lotItem.name === activeLotId
    )
  }, [activeMatrixData, activeStages, activeStageId, activeLotId])

  const lotFilter = React.useMemo(
    () => session?.houseAndLand?.lotFilter || FILTER_INITIAL_STATE,
    [session?.houseAndLand?.lotFilter]
  )

  const handleFullScreenToggle = (arg: boolean) => {
    setFullScreenToggle(arg)
    setLotSidePanelState(!arg)
    onFullScreenToggle?.(arg)
  }

  const handleLotConfigurationValueChange = React.useCallback(
    (data: PackageConfigurationInterface) => {
      setActivePackageConfiguration({
        [HouseAndLandPackageOptionType.FloorplanOption]:
          data.floorplanOption || '',
        [HouseAndLandPackageOptionType.Facade]: data.facade || '',
        [HouseAndLandPackageOptionType.FacadeColor]: data.facadeColor || '',
        [HouseAndLandPackageOptionType.InternalTheme]: data.internalTheme || '',
        [HouseAndLandPackageOptionType.InternalColorScheme]:
          data.internalColorScheme || '',
      })
      firebaseControlQuery.update({
        [`houseAndLand.activeFloorplanOptionId`]: data.floorplanOption || '',
        [`houseAndLand.activeFacadeId`]: data.facade || '',
        [`houseAndLand.activeFacadeColorId`]: data.facadeColor || '',
        [`houseAndLand.activeInternalThemeId`]: data.internalTheme || '',
        [`houseAndLand.activeInternalColorSchemeId`]:
          data.internalColorScheme || '',
      })
    },
    []
  )

  const handleLotConfigurationOptionSelect = React.useCallback(
    (data: {
      propertyType: keyof PackageConfigurationInterface
      value: string
    }) => {
      const { propertyType, value: id } = data
      const type = mappedPackageOptionType(propertyType)
      setLastActiveOption({ type, id })
      firebaseControlQuery.update({
        [`houseAndLand.lastActiveOption.type`]: type || '',
        [`houseAndLand.lastActiveOption.id`]: id || '',
        [`propertyGallery.galleryControlV2.pinnedItemIndex`]: null,
      })
    },
    []
  )

  const handleLotConfigurationPropertyExpand = React.useCallback<
    (data: {
      propertyType: keyof PackageConfigurationInterface
      value: string
      expanded: boolean
    }) => void
  >((data) => {
    const { propertyType, expanded, value: id } = data
    const type = mappedPackageOptionType(propertyType)
    if (expanded && id) {
      setLastActiveOption({ type, id })
      firebaseControlQuery.update({
        [`houseAndLand.lastActiveOption.type`]: type || '',
        [`houseAndLand.lastActiveOption.id`]: id || '',
      })
    }
  }, [])

  const handlePackageConfiguratiOnFirebaseChange = React.useCallback(
    (
      argActiveFloorplanOptionId: string,
      argActiveFacadeId: string,
      argActiveFacadeColorId: string,
      argActiveInternalThemeId: string,
      argActiveInternalColorSchemeId: string
    ) => {
      setActivePackageConfiguration({
        [HouseAndLandPackageOptionType.FloorplanOption]:
          argActiveFloorplanOptionId,
        [HouseAndLandPackageOptionType.Facade]: argActiveFacadeId,
        [HouseAndLandPackageOptionType.FacadeColor]: argActiveFacadeColorId,
        [HouseAndLandPackageOptionType.InternalTheme]: argActiveInternalThemeId,
        [HouseAndLandPackageOptionType.InternalColorScheme]:
          argActiveInternalColorSchemeId,
      })
    },
    []
  )

  const getFilterPackageSummary = () =>
    Object.keys(activeMatrixData || {}).length && availableStatusLabel
      ? filterPackageSummary(
          activeMatrixData,
          {
            ...lotFilter,
            storey: lotFilter.storey.map((item) => Number(item)),
          },
          availableStatusLabel
        )
      : undefined

  const filteredStages = React.useCallback(() => {
    const { apply, anyStage } = lotFilter

    if (apply && activeMatrixData) {
      const response = getFilterPackageSummary()
      if (!anyStage) {
        const currentStage = response?.stages?.[activeStageId]
        return currentStage ? [currentStage] : []
      }
      return Object.values(response?.stages ?? {})
    }

    return Object.values(activeStages || {})
  }, [lotFilter, activeMatrixData, activeStages, activePrecinctId])

  const filteredPackages = React.useCallback(() => {
    const { apply } = lotFilter

    if (apply && activeMatrixData) {
      const response = getFilterPackageSummary()
      return response?.packages || []
    }

    return activePackages
  }, [lotFilter, activePackages, activeMatrixData, activePrecinctId])

  const filteredLots = React.useCallback(() => {
    const { apply } = lotFilter

    if (apply && activeMatrixData) {
      const response = getFilterPackageSummary()
      const myStages = response?.stages || {}
      if (Object.values(myStages).length === 0) {
        return []
      }
      return myStages[activeStageId]?.lots || []
    }

    return activeStage?.lots || []
  }, [
    lotFilter,
    activeStageId,
    activeStage,
    activeMatrixData,
    activePrecinctId,
  ])

  const handleResetFirebaseStates = React.useCallback(async () => {
    await firebaseControlQuery.update({
      [`houseAndLand.lotInfoPopup`]: false,
      [`houseAndLand.externalLinkPopup`]: false,
      [`houseAndLand.activeLotId`]: '',
      [`houseAndLand.activePackageId`]: '',
      [`houseAndLand.activeFloorplanOptionId`]: '',
      [`houseAndLand.activeFacadeId`]: '',
      [`houseAndLand.activeFacadeColorId`]: '',
      [`houseAndLand.activeInternalThemeId`]: '',
      [`houseAndLand.activeInternalColorSchemeId`]: '',
      [`houseAndLand.lastActiveOption.type`]: '',
      [`houseAndLand.lastActiveOption.id`]: '',
    })
  }, [])

  React.useEffect(() => {
    const removeUnitOnRouteChange = () => {
      handleResetFirebaseStates().catch((error) => console.error(error))
    }
    const routeChangeUnsubscribe = history.listen(removeUnitOnRouteChange)
    return () => routeChangeUnsubscribe()
  }, [history])

  React.useEffect(() => {
    if (session?.connected && !session?.houseAndLand?.activePrecinctId) {
      firebaseControlQuery.updateRoute(AREA_VIEW)
    }
  }, [session?.connected, session?.houseAndLand?.activePrecinctId])

  React.useEffect(() => {
    if (!activeStageId && activeStages) {
      const stage = Object.values(activeStages)[0]
      firebaseControlQuery.update({
        [`houseAndLand.activeStageId`]: stage.label,
      })
    }
  }, [activeStages, activeStageId])

  React.useEffect(() => {
    setLastActiveOption(initialLastActiveOption)
  }, [activePrecinctId, activeLotId, activeStageId])

  React.useEffect(() => {
    if (session) {
      const {
        connected,
        houseAndLand: {
          activePrecinctId: activePrecinctIdFirebase,
          activeFloorplanOptionId: activeFloorplanOptionIdFirebase,
          activeFacadeId: activeFacadeIdFirebase,
          activeFacadeColorId: activeFacadeColorIdFirebase,
          activeInternalThemeId: activeInternalThemeIdFirebase,
          activeInternalColorSchemeId: activeInternalColorSchemeIdFirebase,
          lastActiveOption: {
            type: lastActiveOptionTypeFirebase,
            id: lastActiveOptionIdFirebase,
          },
          lotFilter: lotFilterFirestore,
        },
      } = session
      if (connected) {
        dispatch(setFilter(lotFilterFirestore))
        handlePackageConfiguratiOnFirebaseChange(
          activeFloorplanOptionIdFirebase,
          activeFacadeIdFirebase,
          activeFacadeColorIdFirebase,
          activeInternalThemeIdFirebase,
          activeInternalColorSchemeIdFirebase
        )

        if (!hasSessionInitialized) {
          setFilterActivePrecinct(activePrecinctIdFirebase)
          setHasSessionInitialized(true)
        }

        if (lastActiveOptionTypeFirebase) {
          setLastActiveOption({
            type: lastActiveOptionTypeFirebase,
            id: lastActiveOptionIdFirebase,
          })
        }
      }
    }
  }, [session?.connected, session?.houseAndLand])

  React.useEffect(
    () => () => onFullScreenToggle?.(undefined),
    [onFullScreenToggle]
  )

  React.useEffect(() => {
    if (!(urlParams.current?.lot && activeStage?.id)) {
      return
    }

    const matchedLot = activeStage.lots.find(
      (lotItem: LotInterface) => lotItem.name === urlParams.current?.lot
    )

    if (matchedLot) {
      firebaseControlQuery.update({
        [`houseAndLand.activeLotId`]: matchedLot.name,
      })
      urlParams.current = {
        ...urlParams.current,
        lot: undefined,
      }
    }
  }, [activeStage?.id])

  React.useEffect(() => {
    if (
      !(
        urlParams.current?.precinct ||
        urlParams.current?.stage ||
        urlParams.current?.lot
      )
    ) {
      return
    }
    history.replace(location.pathname)
  }, [location.pathname])

  React.useEffect(() => {
    firebaseControlQuery.update({
      [`houseAndLand.isDataLoaded`]: true,
    })
  }, [])

  return (
    <Container fullScreenMode={fullScreenToggle} className="flex">
      <div
        className={`h-full ${
          !lotSidePanelState
            ? '!w-0 opacity-0'
            : 'w-[297px] min-w-[297px] opacity-100'
        } flex-grow-0 transition-all duration-300 ease-in-out`}
      >
        {matrixDataPayload.isLoaded ? null : <LotSidePanelSkeleton />}
        {session?.connected && matrixDataPayload.isLoaded && activePackages ? (
          <LotsSidePanel
            stages={filteredStages()}
            packages={filteredPackages()}
            filterPopupToggle={isFilterOpen}
            setFilterPopupToggle={(arg: boolean) => toggleFilter(arg)}
            onLotConfigurationValueChange={handleLotConfigurationValueChange}
            onLotConfigurationOptionSelect={handleLotConfigurationOptionSelect}
            onLotConfigurationPropertyExpand={
              handleLotConfigurationPropertyExpand
            }
          />
        ) : null}

        {session && (
          <Filter
            key={session.houseAndLand.activeStageId}
            toggle={toggleFilter}
            isOpen={isFilterOpen}
            activePrecinct={filterActivePrecinct}
            setActivePrecinct={setFilterActivePrecinct}
            firebaseActiveStage={session.houseAndLand.activeStageId}
            isStageFetching={filterPayload.isFetching}
            firebaseLotFilter={session.houseAndLand.lotFilter}
          />
        )}
      </div>
      <DataHandler
        payload={{
          ...matrixDataPayload,
          data: activeMatrixData,
        }}
        skeletonFrame={<StageSkeleton />}
      >
        <div
          className={`z-10 h-full flex-1 ${
            activeLotId ? 'transition-all duration-300 ease-in-out' : ''
          }`}
        >
          <SwitchTransition>
            <CSSTransition
              timeout={200}
              classNames="transition-fade-out"
              key={activeLotId && activeLot ? activeLot.id : 'stage'}
            >
              {activeLotId && activeLot ? (
                <LotView
                  key={activeLot.id}
                  lot={activeLot}
                  activePackage={activePackage}
                  fullScreenToggle={fullScreenToggle}
                  setFullScreenToggle={handleFullScreenToggle}
                  lastActiveOption={lastActiveOption}
                  activePackageConfiguration={activePackageConfiguration}
                  handlePackageInfoModal={() => setPackageInfoModalToggle(true)}
                  activeMatrixData={activeMatrixData}
                  getVideoPlayerState={handleFullScreenToggle}
                />
              ) : (
                <div className="relative h-full w-full">
                  <StageView
                    originalLots={activeStage ? activeStage.lots : []}
                    filteredLots={filteredLots()}
                    getVideoPlayerState={handleFullScreenToggle}
                    fullScreenToggle={fullScreenToggle}
                    setFullScreenToggle={handleFullScreenToggle}
                  />
                </div>
              )}
            </CSSTransition>
          </SwitchTransition>
        </div>
        <div
          className={`absolute bottom-0 z-10 hidden h-full w-full transition duration-300 ease-in-out ${
            packageInfoModalToggle ? 'translate-y-0' : 'translate-y-full'
          }`}
        >
          <PackageInfoModal
            closeAction={() => setPackageInfoModalToggle(false)}
          />
        </div>
      </DataHandler>
    </Container>
  )
}

export default connect(
  ({ projectIdentity, firestore: { session } }: RootStateTypeExtra) => ({
    activePrecinctId: session?.houseAndLand?.activePrecinctId || '',
    activeStageId: session?.houseAndLand?.activeStageId || '',
    activeLotId: session?.houseAndLand?.activeLotId || '',
    activePackageId: session?.houseAndLand?.activePackageId || '',
    projectIdentity,
    session,
  })
)(Stage)
