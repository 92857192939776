import React from 'react'
import { connect } from 'react-redux'
import { Vector3 } from 'three'

import { ProjectIdentity, RootStateTypeExtra } from '@store/types'

import Model from '@components/adgroup-three-fiber'
import { ModelRefProps } from '@components/adgroup-three-fiber/model'
import Container from '@components/container/container'

import FirebaseControlQuery from '@firebaseUtil/firebase-control-query'

interface ThreeDViewProps {
  projectIdentity: ProjectIdentity
}

function ADThreeDView({ projectIdentity }: ThreeDViewProps) {
  const firebaseControlQuery = FirebaseControlQuery({
    ...projectIdentity,
    sessionKey: projectIdentity.sessionId,
  })

  const [position, setPosition] = React.useState<Vector3>(new Vector3())

  React.useEffect(() => {
    firebaseControlQuery.update({
      'threeDView.position': [position.x, position.y, position.z],
    })
    return () => {
      firebaseControlQuery.update({
        'threeDView.position': [0, 0, 0],
      })
    }
  }, [position])

  const modelRef = React.useRef<ModelRefProps>(null)

  const initPosition = new Vector3(
    23.360464697076594,
    187.38015293568947,
    716.761150170601
  )
  const initTarget = new Vector3(52.651, 161.888, -2.305)

  const handleModelOnClick = (activeLevel: string) => {
    firebaseControlQuery.update({
      activeRoute: 'building',
      [`building.activeLevel`]: activeLevel,
    })
  }

  const WindermereData = [
    {
      id: 'Basement 1',
      mesh: [
        {
          node: 'Block-Level1_1',
          material: 'Block-Level1',
        },
        {
          node: 'Block-Level1_2',
          material: 'Block-Level1-Windows',
        },
      ],
      hover: 'green',
      hoverActive: false,
      onClick: (activeLevel: string) => handleModelOnClick(activeLevel),
      position: [42.424, 37.233, -20.279],
    },
    {
      id: 'Basement 2',
      mesh: [
        {
          node: 'Block-Level2_1',
          material: 'Block-Level2',
        },
        {
          node: 'Block-Level2_2',
          material: 'Block-Level2-Windows',
        },
      ],
      hoverActive: false,
      hover: 'green',
      onClick: (activeLevel: string) => handleModelOnClick(activeLevel),
      position: [40.726, 86.337, -30.265],
    },
    {
      id: '2',
      mesh: [
        {
          node: 'Block-Level3_1',
          material: 'Block-Level3',
        },
        {
          node: 'Block-Level3_2',
          material: 'Block-Level3-Windows',
        },
      ],
      hover: 'green',
      hoverActive: false,
      onClick: (activeLevel: string) => handleModelOnClick(activeLevel),
      position: [52.651, 126.538, -2.306],
    },
    {
      id: '3',
      mesh: [
        {
          node: 'Block-Level4_1',
          material: 'Block-Level4',
        },
        {
          node: 'Block-Level4_2',
          material: 'Block-Level4-Windows',
        },
      ],
      hover: 'green',
      hoverActive: false,
      onClick: (activeLevel: string) => handleModelOnClick(activeLevel),
      position: [52.651, 161.888, -2.305],
    },
    {
      id: '4',
      mesh: [
        {
          node: 'Block-Level5_1',
          material: 'Block-Level5',
        },
        {
          node: 'Block-Level5_2',
          material: 'Block-Level5-Windows',
        },
      ],
      hover: 'green',
      hoverActive: false,
      onClick: (activeLevel: string) => handleModelOnClick(activeLevel),
      position: [91.494, 185.78, -103.129],
    },
    {
      id: 'Block-Plants',
      node: 'Block-Plants',
      material: 'Block-Plants',
      hoverActive: false,
      position: [1.675, 109.742, -26.246],
      rotation: [Math.PI, 0, Math.PI],
    },
    {
      id: '5',
      mesh: [
        {
          node: 'Block-PoolDeck_1',
          material: 'Block-PoolDeck',
        },
        {
          node: 'Block-PoolDeck_2',
          material: 'Water',
        },
        {
          node: 'Block-PoolDeck_3',
          material: 'Block-PoolDeck-Windows',
        },
      ],
      hover: 'green',
      hoverActive: false,
      onClick: (activeLevel: string) => handleModelOnClick(activeLevel),
      position: [436.236, 206.134, 25.451],
    },
    {
      mesh: [
        {
          node: 'Block-Shrubs',
          material: 'shrubs',
        },
        {
          node: 'Block-Shrubs_1',
          material: 'gum-trees',
        },
        {
          node: 'Block-Shrubs_2',
          material: 'palm-tree',
        },
        {
          node: 'Block-Shrubs_3',
          material: 'fern-1',
        },
        {
          node: 'Block-Shrubs_4',
          material: 'snake-plant',
        },
      ],
      hoverActive: false,
      position: [491.19, 15.758, 68.22],
      rotation: [Math.PI / 2, 0, -Math.PI / 2],
      scale: 48.722,
    },
    {
      node: 'Pool-Text',
      material: 'Block-PoolDeck-Logo',
      hoverActive: false,
      position: [365.31, 190.933, 73.147],
      rotation: [Math.PI / 2, 0, 0],
    },
  ]

  return (
    <Container>
      <Model
        loader={{
          bg: 'https://d3jmvqclqlks6d.cloudfront.net/the-windermere/Facade.jpg',
          icon: 'https://images.developmentid.com.au/47b93023-74af-4790-bc2f-0d84367f4d86/devsuite-project-logo/The-Windermere-Black-New.png',
          bar: {
            color: '#1594fe',
          },
        }}
        models={[
          {
            gltfSrc:
              'https://d1bw6x5263wwbc.cloudfront.net/3d/the-windermere-final/the-windermere-final-model.gltf',
            buildingMesh: WindermereData,
          },
        ]}
        hdrbackground
        ref={modelRef}
        color="skyblue"
        getPosition={setPosition}
        hdr="https://d1bw6x5263wwbc.cloudfront.net/3d/windermere/evening_road_01_puresky_4k.hdr"
        initPosition={initPosition}
        initTarget={initTarget}
      />
    </Container>
  )
}

const mapStateToProps = ({ projectIdentity }: RootStateTypeExtra) => ({
  projectIdentity,
})

export default connect(mapStateToProps)(ADThreeDView)
