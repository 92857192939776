import { useEffect, useMemo, useState } from 'react'

import { TokenInterface } from '@store/actionSlices/token'

import {
  selectShowcaseRoomConfigFromResult,
  selectShowcaseRoomsFromResult,
  useGetShowcaseRoomConfigQuery,
  useGetShowcaseRoomsQuery,
} from '@api/showcase-room'

import { hasToken } from '@utilities/token-helper'

interface Props {
  projectName: string
  room: string
  token: TokenInterface
}

const useGetShowcaseRoomsConfig = ({ projectName, room, token }: Props) => {
  const [currentRoomId, setCurrentRoomId] = useState<string>('')

  const isAuthenticated = useMemo(() => hasToken(token), [token])

  const {
    isLoaded: isShowcaseRoomsLoaded,
    isError: hasShowcaseRoomsError,
    showcaseRooms,
  } = useGetShowcaseRoomsQuery(
    { projectName },
    { selectFromResult: selectShowcaseRoomsFromResult, skip: !isAuthenticated }
  )

  const {
    isLoaded: isShowcaseRoomConfigLoaded,
    isError: hasShowcaseRoomConfigError,
    showcaseRoomConfig,
  } = useGetShowcaseRoomConfigQuery(
    {
      projectName,
      showcaseRoomId: currentRoomId,
    },
    {
      selectFromResult: selectShowcaseRoomConfigFromResult,
      skip: !currentRoomId || !isAuthenticated,
    }
  )

  useEffect(() => {
    if (!showcaseRooms.length) {
      return
    }

    const matchedRoom = showcaseRooms.find(
      ({ name }) => name.toLowerCase() === room.toLowerCase()
    )
    setCurrentRoomId(matchedRoom?.id || showcaseRooms[0].id)
  }, [showcaseRooms, room])

  return {
    isLoaded: isShowcaseRoomsLoaded && isShowcaseRoomConfigLoaded,
    isError: hasShowcaseRoomsError || hasShowcaseRoomConfigError,
    showcaseRoomConfig,
  }
}

export default useGetShowcaseRoomsConfig
