import React from 'react'
import { connect } from 'react-redux'

import { ProjectIdentity, RootStateTypeExtra } from '@store/types'

import Container from '@components/container/v2'
import DataHandler from '@components/data-handler'

import { selectTeamsInfoFromResult, useGetTeamQuery } from '@api/team'

import FirebaseControlQuery from '@firebaseUtil/firebase-control-query'
import { SessionMap } from '@firebaseUtil/types'

import CarouselControl from '@adUtilities/components/carousel-provider/carousel-control'
import { CarouselControlType } from '@adUtilities/constants/carousel'
import { BucketSource, FileType } from '@adUtilities/constants/common'

import SlideContent from './slide-content'
import TeamsSkeleton from './team-skeleton'

interface PagePropsInterface {
  session: SessionMap | undefined
  projectIdentity: ProjectIdentity
}

function Team({ session, projectIdentity }: PagePropsInterface) {
  const firebaseControlQuery = FirebaseControlQuery({
    ...projectIdentity,
    sessionKey: projectIdentity.sessionId,
  })

  const [activeGalleryItemIndex, setActiveGalleryItemIndex] = React.useState(0)
  const [toggleControls, setToggleControls] = React.useState(true)

  const teamPayload = useGetTeamQuery(
    { projectName: projectIdentity.projectId },
    { selectFromResult: selectTeamsInfoFromResult }
  )

  const slideData = React.useMemo(() => {
    const logoData = teamPayload?.teamsInfo?.filter(
      (team) => team.type === 'logo'
    )
    const backgroundData = teamPayload?.teamsInfo?.filter(
      (team) => team.type === 'background'
    )

    return logoData.map((data, index) => ({
      ...data,
      backgroundSrc: backgroundData[index]?.imageSource ?? '',
    }))
  }, [teamPayload?.teamsInfo])

  const handleItemClick = async (itemIndex: number) => {
    await firebaseControlQuery.update({
      [`team.galleryControl.activeItemID`]: slideData[itemIndex]?.id,
    })
  }

  React.useEffect(() => {
    if (session) {
      const {
        team: {
          galleryControl: { activeItemID: activeItemIDFirebase },
        },
      } = session
      const activeItemIndexFirebase = slideData?.findIndex(
        (item) => item.id === activeItemIDFirebase
      )
      setActiveGalleryItemIndex(
        activeItemIndexFirebase !== -1 ? activeItemIndexFirebase : 0
      )
    }
  }, [session, slideData])

  return (
    <Container>
      <DataHandler
        payloads={{
          ...teamPayload,
          data: teamPayload.teamsInfo,
        }}
        skeletonFrame={<TeamsSkeleton />}
      >
        <div className="relative h-full bg-neutralColour">
          <div className="relative z-10">
            <div
              className="absolute left-0 flex h-screen w-1/2 cursor-default flex-col justify-end px-14 pb-14 ipad-pro-land:w-2/5 ipad-pro-land:px-28"
              onClick={() => setToggleControls((state) => !state)}
              role="none"
            >
              <div>
                <CarouselControl
                  type={CarouselControlType.Label}
                  items={slideData.map((item) => ({
                    id: item.id,
                    src: item.imageSource,
                    label: item.label,
                    fileType: FileType.Image,
                    bucketType: BucketSource.Legacy,
                    noSpliceUrl: false,
                  }))}
                  currentSlideIndex={activeGalleryItemIndex}
                  toggleControls={toggleControls}
                  handleItemClick={handleItemClick}
                />
              </div>
            </div>
          </div>

          {slideData.map((data, index) => (
            <SlideContent
              key={data.id}
              isActiveSlide={index === activeGalleryItemIndex}
              slide={data}
              onSlideClick={() => setToggleControls((state) => !state)}
            />
          ))}
        </div>
      </DataHandler>
    </Container>
  )
}

export default connect(
  ({ projectIdentity, firestore: { session } }: RootStateTypeExtra) => ({
    session,
    projectIdentity,
  })
)(Team)
