import React from 'react'
import { connect } from 'react-redux'
import { CSSTransition, SwitchTransition } from 'react-transition-group'

import { LotGallery, ProjectIdentity, RootStateTypeExtra } from '@store/types'

import DataHandler from '@components/data-handler/v2'
import { Status } from '@components/data-handler/v2/data-handler'
import {
  PannellumDataInterface,
  PanoramaType,
} from '@components/showcase-pannellum/types'
import Skeleton from '@components/skeleton/v2'
import VideoPlayerControl from '@components/video-player'

import { DEFAULT_GALLERY_SETTINGS } from '@pages/properties-view/house-and-land/v2/constants'
import VideoPlaybackActions, {
  ActionType,
} from '@pages/properties-view/house-and-land/v2/video-playback-actions'

import {
  selectFromResult as selectFromExternalLinkResult,
  useGetExternalLinksByPropertyQuery,
} from '@api/external-links'
import {
  ActivePackageConfigurationType,
  FileInterface,
  GalleryInterface,
  LotInterface,
  MatrixDataInterface,
  PackageOptionsInterface,
  PackageSummaryInterface,
  selectPackagesFromResult,
  useGetPackagesByIdQuery,
} from '@api/houseAndLand'
import {
  selectFromResult as selectFromPanoramicResult,
  useGetPanoramicQuery,
} from '@api/panoramic'
import {
  UnitGalleryInterface,
  selectFromResult as selectFromLotGalleryResult,
  useGetUnitGalleryQuery as useGetLotGalleryQuery,
} from '@api/unit-gallery'

import { HouseAndLandPackageOptionType } from '@utilities/adgroup-utilities/types/houseAndLand'
import useTabVisibility from '@utilities/tab-visibility'
import VideoPlayerFirebaseService from '@utilities/video-player-firebase-service'

import FirebaseControlQuery from '@firebaseUtil/firebase-control-query'
import { SessionMap } from '@firebaseUtil/types'

import { ArrowSvg, LinkSvg } from '@svg/react'

import AssetHandler from '@adUtilities/asset-handler/asset-handler'
import { CarouselProvider } from '@adUtilities/components/carousel-provider'
import VideoPlayer from '@adUtilities/components/video-player'
import { VideoPlayerRef } from '@adUtilities/components/video-player/video-player'
import { CarouselControlType } from '@adUtilities/constants/carousel'
import {
  BucketSource,
  FileType,
  PlayerState,
} from '@adUtilities/constants/common'
import { GallerySettings } from '@adUtilities/types/carousel'

import Controls from './controls'
import DeselectedView from './deselected-view'
import LotViewSkeleton from './lot-view-skeleton'
import PackageInfoCard from './package-info-card'

interface LastActiveOption {
  type: string
  id: string
}

export interface LotViewProps {
  lot: LotInterface
  activePackage: PackageSummaryInterface | undefined
  projectIdentity: ProjectIdentity
  session: SessionMap | undefined
  activePrecinctId: string
  activeStageId: string
  activeLotId: string
  lastActiveOption: LastActiveOption
  activePackageConfiguration: ActivePackageConfigurationType | undefined
  fullScreenToggle: boolean
  setFullScreenToggle: (arg: boolean) => void
  handlePackageInfoModal: () => void
  activeMatrixData: MatrixDataInterface | undefined
  getVideoPlayerState: (arg: boolean) => void
}

const noImageObject = {
  id: 'no-image',
  src: '',
  label: 'No Image',
  type: FileType.Image,
  noSpliceUrl: false,
}

function LotView({
  lot,
  activePackage,
  projectIdentity,
  session,
  activePrecinctId,
  activeStageId,
  activeLotId,
  lastActiveOption,
  activePackageConfiguration,
  fullScreenToggle,
  setFullScreenToggle,
  handlePackageInfoModal,
  activeMatrixData,
  getVideoPlayerState,
}: LotViewProps) {
  const { projectName, projectUUID } = projectIdentity

  const firebaseControlQuery = FirebaseControlQuery({
    ...projectIdentity,
    sessionKey: projectIdentity.sessionId,
  })

  const [isConnected, setIsConnected] = React.useState(false)
  const [packageInfoToggle, setPackageInfoToggle] = React.useState(false)
  const [shouldShowSlideshowBtn, setShouldShowSlideShowBtn] =
    React.useState(false)
  const [deselected, setDeselectState] = React.useState(false)
  const [showExternalLinks, setShowExternalLinks] = React.useState(false)
  const [iframeLoaded, setIframeLoaded] = React.useState(false)
  const [activeLabel, setActiveLabel] = React.useState('')
  const [pinnedGalleryItemIndex, setPinnedGalleryItemIndex] = React.useState<
    number | null
  >(null)

  const {
    onlyShowPropertyGalleries: onlyShowLotGalleries,
    propertyGalleryControlStyle: lotGalleryControlStyle,
  } = projectIdentity || {}

  const gallerySettings: GallerySettings = React.useMemo(
    () => ({
      autoPlayIntervalInSeconds:
        projectIdentity?.gallerySettings?.intervalInSeconds ||
        DEFAULT_GALLERY_SETTINGS.autoPlayIntervalInSeconds,
      effectType:
        projectIdentity?.gallerySettings?.type ||
        DEFAULT_GALLERY_SETTINGS.effectType,
    }),
    [projectIdentity?.gallerySettings]
  )

  const packagePayload = useGetPackagesByIdQuery(
    { projectName, lotId: lot.id },
    {
      selectFromResult: selectPackagesFromResult,
    }
  )

  const packages = packagePayload?.packages

  const activePackageData: PackageOptionsInterface | undefined =
    React.useMemo(() => {
      if (packages?.length === 0 || !activePackage) {
        return undefined
      }

      return packages?.find(
        (item: PackageOptionsInterface) =>
          item.id === activePackage.id || item.name === activePackage.id
      )
    }, [packages, activePackage])

  const lotGalleryPayload = useGetLotGalleryQuery(
    { projectName, unitId: lot.name },
    {
      selectFromResult: selectFromLotGalleryResult,
      skip: packagePayload.status !== Status.FULFILLED,
    }
  )
  const lotGallery = lotGalleryPayload.unitGalleryData

  const panoramicPayload = useGetPanoramicQuery(
    { projectName: projectIdentity.projectName },
    { selectFromResult: selectFromPanoramicResult }
  )

  const panoramic = panoramicPayload.panoramicData

  const getPackageOptionDetailById = React.useCallback(
    (
      type: HouseAndLandPackageOptionType,
      id: string
    ): { images: FileInterface[]; price: number } => {
      if (!activePackageData) {
        return {
          images: [],
          price: 0,
        }
      }

      const {
        FloorplanOption,
        Facade,
        FacadeColor,
        InternalTheme,
        InternalColorScheme,
      } = HouseAndLandPackageOptionType

      if (type === FloorplanOption) {
        const optionData = activePackageData[type]?.find(
          (item) => item.id === id
        )
        return {
          images: optionData?.images || [],
          price: optionData?.price || 0,
        }
      }

      if (type === Facade) {
        const optionData = activePackageData[type]?.find(
          (item) => item.id === id
        )
        return {
          images: optionData?.images || [],
          price: optionData?.price || 0,
        }
      }

      if (type === FacadeColor) {
        let images: FileInterface[] = []
        let price = 0
        activePackageData[Facade]?.forEach((item) => {
          item.facadeColors.forEach((facadeColor) => {
            if (facadeColor.id === id) {
              images = images.concat(facadeColor.images)
              price = facadeColor.price || 0
            }
          })
        })

        return { images, price }
      }

      if (type === InternalTheme) {
        const optionData = activePackageData[type]?.find(
          (item) => item.id === id
        )
        return {
          images: optionData?.images || [],
          price: optionData?.price || 0,
        }
      }

      if (type === InternalColorScheme) {
        let images: FileInterface[] = []
        let price = 0
        activePackageData[InternalTheme]?.forEach((item) => {
          item.internalColorSchemes.forEach((facadeColor) => {
            if (facadeColor.id === id) {
              images = images.concat(facadeColor.images)
              price = facadeColor.price || 0
            }
          })
        })
        return { images, price }
      }

      return { images: [], price: 0 }
    },
    [activePackageData]
  )

  const transformDataForGallery = (
    argData: Array<
      (FileInterface | GalleryInterface) & { noSpliceUrl?: boolean }
    >
  ): LotGallery[] =>
    argData
      .sort((a, b) => a.order - b.order)
      .map((item) => ({
        id: item.id,
        src: item.url,
        label: item.title || item.name,
        type: 'type' in item ? (item.type as FileType) : FileType.Image,
        thumbnail: 'thumbnail' in item ? (item.thumbnail as string) : '',
        noSpliceUrl: Boolean(item.noSpliceUrl),
      }))

  const galleryData: LotGallery[] = React.useMemo(() => {
    setDeselectState(false)
    const floorImagePath = `${projectUUID}/floorplans/preview`
    const viewImagePath = `${projectUUID}/views`

    if (!activePackageData && !onlyShowLotGalleries) {
      const galleryHolder: LotGallery[] = []

      if (lot?.assets?.floorImage) {
        const currentFloorImage = lot?.assets?.floorImage || {}
        galleryHolder.push(
          ...transformDataForGallery([
            {
              id: currentFloorImage.id,
              name: currentFloorImage.name,
              title: currentFloorImage.title,
              url: `/${floorImagePath}/${currentFloorImage.url}`,
              type: currentFloorImage.type,
              order: currentFloorImage.order,
              noSpliceUrl: true,
            },
          ])
        )
      }
      if (lotGallery.length > 0) {
        galleryHolder.push(
          ...transformDataForGallery(
            [...lotGallery].map((item: UnitGalleryInterface) => ({
              id: item.id,
              name: item.label || '',
              title: item.label || '',
              url: item.src,
              type: item.type,
              thumbnail: item.thumbnail,
              order: 0,
            }))
          )
        )
      }

      if ((lot?.assets?.viewLinesImage?.length || 0) > 0) {
        const currentViewLinesImage = lot?.assets?.viewLinesImage || []
        galleryHolder.push(
          ...transformDataForGallery(
            [...currentViewLinesImage].map((item) => ({
              id: item.id,
              name: item.name,
              title: item.title,
              url: `/${viewImagePath}/${item.url}`,
              type: item.type,
              order: item.order,
              noSpliceUrl: true,
            }))
          )
        )
      }

      return galleryHolder.length > 0 ? galleryHolder : [noImageObject]
    }

    if (!activePackageData && onlyShowLotGalleries) {
      if (lotGallery.length > 0) {
        return transformDataForGallery(
          [...lotGallery].map((item: UnitGalleryInterface) => ({
            id: item.id,
            name: item.label || '',
            title: item.label || '',
            url: item.src,
            type: item.type,
            thumbnail: item.thumbnail,
            order: 0,
          }))
        )
      }

      if (lot?.assets?.floorImage) {
        const currentFloorImage = lot?.assets?.floorImage || {}
        return transformDataForGallery([
          {
            id: currentFloorImage.id,
            name: currentFloorImage.name,
            title: currentFloorImage.title,
            url: `${floorImagePath}/${currentFloorImage.url}`,
            type: currentFloorImage.type,
            order: currentFloorImage.order,
            noSpliceUrl: true,
          },
        ])
      }

      return [noImageObject]
    }

    if (
      Object.values(HouseAndLandPackageOptionType).includes(
        lastActiveOption.type as HouseAndLandPackageOptionType
      )
    ) {
      if (!lastActiveOption.id) {
        setDeselectState(true)
        setActiveLabel('')
        return []
      }

      const myImages: FileInterface[] = getPackageOptionDetailById(
        lastActiveOption.type as HouseAndLandPackageOptionType,
        lastActiveOption.id
      ).images

      if (myImages.length === 0) {
        return [noImageObject]
      }

      return transformDataForGallery([...myImages])
    }

    const packageGalleries = activePackageData?.galleries || []

    if (packageGalleries.length === 0) {
      return [noImageObject]
    }

    return transformDataForGallery([...packageGalleries])
  }, [
    activePackageData,
    lastActiveOption,
    lotGalleryPayload.status,
    onlyShowLotGalleries,
    lot?.id,
    projectUUID,
  ])

  const totalPrice = React.useMemo((): number => {
    const { price: lotPrice } = lot

    let totalPrice = lotPrice

    if (!activePackageData) {
      return totalPrice
    }

    if (activePackageData.housePrice) {
      totalPrice += activePackageData.housePrice
    }

    if (!activePackageConfiguration) {
      return totalPrice
    }

    const {
      FloorplanOption,
      Facade,
      FacadeColor,
      InternalTheme,
      InternalColorScheme,
    } = HouseAndLandPackageOptionType

    if (activePackageConfiguration[FloorplanOption]) {
      totalPrice +=
        getPackageOptionDetailById(
          FloorplanOption,
          activePackageConfiguration[FloorplanOption]
        ).price || 0
    }

    if (activePackageConfiguration[Facade]) {
      totalPrice +=
        getPackageOptionDetailById(Facade, activePackageConfiguration[Facade])
          .price || 0
    }

    if (activePackageConfiguration[FacadeColor]) {
      totalPrice +=
        getPackageOptionDetailById(
          FacadeColor,
          activePackageConfiguration[FacadeColor]
        ).price || 0
    }

    if (activePackageConfiguration[InternalTheme]) {
      totalPrice +=
        getPackageOptionDetailById(
          InternalTheme,
          activePackageConfiguration[InternalTheme]
        ).price || 0
    }

    if (activePackageConfiguration[InternalColorScheme]) {
      totalPrice +=
        getPackageOptionDetailById(
          InternalColorScheme,
          activePackageConfiguration[InternalColorScheme]
        ).price || 0
    }

    return totalPrice
  }, [lot, activePackageData, activePackageConfiguration])

  const hasPanoramicData: boolean = React.useMemo(() => {
    const foundData = panoramic.find(
      (item: PannellumDataInterface) =>
        item.type === PanoramaType.Lot &&
        item.panoramaGroup?.toString() === lot.name?.toString()
    )
    return foundData !== undefined
  }, [panoramic, lot])

  const externalLinksPayload = useGetExternalLinksByPropertyQuery(
    { projectName, propertyId: lot?.id || '' },
    {
      skip: !lot?.id,
      selectFromResult: selectFromExternalLinkResult,
    }
  )

  const videoPlayerRef = React.useRef<VideoPlayerRef>(null)

  const videoPlayerFirebaseService = VideoPlayerFirebaseService({
    galleryName: 'propertyGallery',
    projectIdentity,
  })

  const [currentSlideIndex, setCurrentSlideIndex] = React.useState(0)
  const [autoPlayState, setAutoPlayState] = React.useState(false)
  const [isBuffering, setBufferState] = React.useState(false)
  const [scrubbarTimerState, setScrubbarTimerState] = React.useState(false)
  const [playerControlState, setPlayerControlState] =
    React.useState<PlayerState>(PlayerState.Stop)
  const [playerState, setPlayerState] = React.useState<PlayerState>(
    PlayerState.Stop
  )
  const [isMuted, setMuteState] = React.useState(true)
  const [volume, setVolume] = React.useState<number>(0)
  const [videoLengthInSeconds, setVideoLengthInSeconds] = React.useState(0)
  const [currentVideoPositionInSeconds, setCurrentVideoPositionInSeconds] =
    React.useState(0)
  const [isSeeking, setSeekingState] = React.useState(false)

  const slides = React.useMemo(
    () =>
      (galleryData || []).map((item) => ({
        id: item.id,
        label: item.label,
        src:
          item.type === FileType.Image && item.src
            ? item.src
            : item.thumbnail || '',
        thumbnail: item.thumbnail,
        originalSrc: item.src,
        bucketType: BucketSource.Legacy,
        fileType: item.type as FileType,
        noSpliceUrl: item.noSpliceUrl,
      })),
    [galleryData]
  )
  const activeSlide = React.useMemo(
    () => slides[currentSlideIndex],
    [slides, currentSlideIndex]
  )

  const hasVideoItems = React.useMemo(
    () => slides.some((item) => item.fileType === FileType.Video),
    [slides]
  )

  const videoSource = React.useMemo(
    () =>
      (activeSlide?.fileType === FileType.Video &&
        AssetHandler({
          url: activeSlide.originalSrc,
          type: activeSlide.bucketType,
        })) ||
      '',
    [slides, activeSlide]
  )

  const setSlideShowToggle = (status: boolean) => {
    setAutoPlayState((prevVal) => !prevVal)
    if (!status) {
      setCurrentSlideIndex(0)
    }
    firebaseControlQuery.update({
      ...(!status && {
        [`propertyGallery.galleryControlV2.activeSlideIndex`]: 0,
      }),
      [`propertyGallery.galleryControlV2.autoPlayState`]: status,
    })
  }

  const handleIframLoad = () => {
    setIframeLoaded(true)
  }

  React.useEffect(() => {
    if (lot.name) {
      firebaseControlQuery.update({
        [`houseAndLand.activeLotId`]: lot.name,
      })
    }
  }, [lot])

  React.useEffect(() => {
    if (session) {
      const {
        connected,
        houseAndLand: { lotInfoPopup, externalLinkPopup },
      } = session
      setIsConnected(connected)
      setPackageInfoToggle(lotInfoPopup)
      setShowExternalLinks(externalLinkPopup)
    }
  }, [session?.connected, session?.houseAndLand, session?.propertyGallery])

  React.useEffect(() => {
    setShouldShowSlideShowBtn(galleryData.length > 1)
  }, [galleryData])

  const handleChangeCurrentSlideIndex = React.useCallback(
    (index: number) => {
      if (currentSlideIndex === index) {
        return
      }
      setCurrentSlideIndex(index)
      firebaseControlQuery.update({
        [`propertyGallery.galleryControlV2.activeSlideIndex`]: index,
        [`propertyGallery.playerControlV2.playerState`]: PlayerState.Stop,
        [`propertyGallery.playerControlV2.scrubbarTimerState`]: false,
        [`propertyGallery.playerControlV2.videoLengthInSeconds`]: 0,
        [`propertyGallery.playerControlV2.currentVideoPositionInSeconds`]: 0,
      })
    },
    [currentSlideIndex]
  )

  const handlePlayerState = React.useCallback(
    (status: boolean) => {
      getVideoPlayerState(status)
      setPlayerState(status ? PlayerState.Play : PlayerState.Stop)
    },
    [getVideoPlayerState]
  )

  const handlePlayClick = React.useCallback(async () => {
    setPlayerControlState(PlayerState.Play)
    setScrubbarTimerState(false)
    await videoPlayerFirebaseService.updatePlayState()
  }, [videoPlayerFirebaseService])

  const handlePauseClick = React.useCallback(async () => {
    setPlayerControlState(PlayerState.Pause)
    setScrubbarTimerState(false)
    await videoPlayerFirebaseService.updatePauseState()
  }, [videoPlayerFirebaseService])

  const handleStopClick = React.useCallback(async () => {
    setScrubbarTimerState(false)
    setPlayerControlState(PlayerState.Stop)
    setVideoLengthInSeconds(0)
    setCurrentVideoPositionInSeconds(0)
    await videoPlayerFirebaseService.updateStopState()
  }, [videoPlayerFirebaseService])

  const handleSeekTo = React.useCallback(
    async (argInSeconds: number) => {
      setScrubbarTimerState(false)
      setSeekingState(true)
      await videoPlayerFirebaseService.updateSeekState(argInSeconds)
    },
    [videoPlayerFirebaseService]
  )

  const handleMuteClick = React.useCallback(
    async (arg: boolean) => {
      setMuteState(arg)
      await videoPlayerFirebaseService.updateMuteState(arg)
    },
    [videoPlayerFirebaseService]
  )

  const handleVolumeDownClick = React.useCallback(async () => {
    if (volume > 0) {
      const newVolume = volume - 1

      setMuteState(false)
      setVolume(newVolume)
      await videoPlayerFirebaseService.updateVolumeState(newVolume)
    }
  }, [volume, videoPlayerFirebaseService])

  const handleVolumeUpClick = React.useCallback(async () => {
    if (volume < 10) {
      setMuteState(false)
      const newVolume = volume + 1
      setVolume(newVolume)
      await videoPlayerFirebaseService.updateVolumeState(newVolume)
    }
  }, [volume, videoPlayerFirebaseService])

  const handlePlaybackAction = React.useCallback(
    (actionType: ActionType) => {
      if (actionType === 'showcase') {
        setPlayerControlState(PlayerState.Play)
        setScrubbarTimerState(false)
        videoPlayerFirebaseService.updatePlayState()
      }
      if (actionType === 'remote') {
        videoPlayerRef.current?.updatePlayerState(PlayerState.Play)
      }
    },
    [videoPlayerFirebaseService]
  )

  const requestForVideoSync = React.useCallback(async () => {
    await videoPlayerFirebaseService.updateSyncState()
  }, [videoPlayerFirebaseService])

  const handleTogglePin = async () => {
    const newPinnedIndex =
      pinnedGalleryItemIndex === currentSlideIndex ? null : currentSlideIndex

    try {
      await firebaseControlQuery.update({
        [`propertyGallery.galleryControlV2.pinnedItemIndex`]: newPinnedIndex,
      })
    } catch {
      console.error('Failed to update pinned item index')
    }
  }

  const shouldDisablePlayOnShowcaseBtn = Boolean(
    pinnedGalleryItemIndex !== null &&
      pinnedGalleryItemIndex !== currentSlideIndex
  )

  useTabVisibility(
    React.useCallback(async () => {
      if (playerControlState === PlayerState.Play) {
        requestForVideoSync()
      }
    }, [playerControlState])
  )

  React.useEffect(() => {
    if (hasVideoItems && playerControlState === PlayerState.Play) {
      requestForVideoSync()
    }
  }, [hasVideoItems, playerControlState])

  React.useEffect(() => {
    if (!session) {
      return
    }
    const {
      connected,
      propertyGallery: {
        galleryControlV2: {
          activeSlideIndex: activeSlideIndexFirebase,
          autoPlayState: autoPlayStateFirebase,
          pinnedItemIndex: pinnedItemIndexFirebase,
        },
        playerControlV2: {
          playerState: playerStateFirebase,
          videoLengthInSeconds: videoLengthInSecondsFirebase,
          currentVideoPositionInSeconds: currentVideoPositionInSecondsFirebase,
          volume: volumeFirebase,
          scrubbarTimerState: scrubbarTimerStateFirebase,
          seekToValueInSeconds: seekToValueInSecondsFirebase,
          isBuffering: isBufferingFirebase,
          muted: mutedFirebase,
        },
      },
    } = session
    setIsConnected(connected)
    setCurrentSlideIndex(activeSlideIndexFirebase)
    setPinnedGalleryItemIndex(pinnedItemIndexFirebase)
    setAutoPlayState(autoPlayStateFirebase)
    setPlayerControlState(playerStateFirebase)
    setVideoLengthInSeconds(videoLengthInSecondsFirebase)
    setCurrentVideoPositionInSeconds(currentVideoPositionInSecondsFirebase)
    setVolume(volumeFirebase)
    setScrubbarTimerState(scrubbarTimerStateFirebase)
    setBufferState(isBufferingFirebase)
    setSeekingState(seekToValueInSecondsFirebase !== null)
    setMuteState(mutedFirebase)
  }, [session?.connected, session?.propertyGallery])

  React.useEffect(() => {
    if (!isConnected) {
      return
    }
    firebaseControlQuery.update({
      [`propertyGallery.galleryControlV2.activeSlideIndex`]: 0,
      [`propertyGallery.galleryControlV2.autoPlayState`]: false,
      [`propertyGallery.playerControlV2.playerState`]: PlayerState.Stop,
      [`propertyGallery.playerControlV2.scrubbarTimerState`]: false,
      [`propertyGallery.playerControlV2.videoLengthInSeconds`]: 0,
      [`propertyGallery.playerControlV2.currentVideoPositionInSeconds`]: 0,
    })
  }, [activePrecinctId, activeStageId, activeLotId, activePackage?.id])

  const dataHandlerPayload = React.useMemo(() => {
    if (packagePayload.status === Status.FULFILLED) {
      const packageCountOfThisLot = activeMatrixData
        ? activeMatrixData?.packages?.filter((item: PackageSummaryInterface) =>
            item.lots?.includes(lot.id)
          )?.length
        : 0

      let data
      if (packageCountOfThisLot > 0) {
        data = packages
      } else if (lotGalleryPayload?.unitGalleryData?.length > 0) {
        data = lotGalleryPayload.unitGalleryData
      } else {
        data = lot?.id
      }

      return {
        ...lotGalleryPayload,
        status:
          lotGalleryPayload.unitGalleryData?.length > 0
            ? (lotGallery.length && Status.FULFILLED) || Status.PENDING
            : lotGalleryPayload.status,
        data,
      }
    }

    return {
      ...packagePayload,
      data: packages,
    }
  }, [
    lotGalleryPayload,
    packagePayload,
    packages,
    activeMatrixData,
    lot?.id,
    lotGallery.length,
  ])

  const handleTogglePackageInfo = React.useCallback((status: boolean) => {
    setPackageInfoToggle(status)
    firebaseControlQuery.update({
      [`houseAndLand.lotInfoPopup`]: status,
    })
  }, [])

  React.useEffect(
    () => () => {
      firebaseControlQuery.update({
        [`propertyGallery.galleryControlV2.pinnedItemIndex`]: null,
      })
    },
    []
  )

  return (
    <DataHandler
      payload={{
        ...dataHandlerPayload,
      }}
      skeletonFrame={<LotViewSkeleton />}
    >
      <div className="relative flex h-full w-full bg-gray-700/50">
        <div className="absolute right-8 top-7 z-10">
          <Controls
            hasPanoramicData={hasPanoramicData}
            fullScreenToggle={fullScreenToggle}
            setFullScreenToggle={setFullScreenToggle}
            packageInfoToggle={packageInfoToggle}
            slideShowToggle={autoPlayState}
            shouldShowSlideshowBtn={shouldShowSlideshowBtn}
            setSlideShowToggle={(arg: boolean) => {
              setSlideShowToggle(arg)
              setFullScreenToggle(arg)
            }}
            setPackageInfoToggle={handleTogglePackageInfo}
            isVideoPlaying={
              playerControlState !== PlayerState.Stop ||
              playerState !== PlayerState.Stop
            }
            isPinned={pinnedGalleryItemIndex === currentSlideIndex}
            togglePin={handleTogglePin}
          />

          {!autoPlayState &&
            playerControlState === PlayerState.Stop &&
            playerState === PlayerState.Stop &&
            externalLinksPayload?.externalLinks?.length > 0 && (
              <div className="mt-4 flex justify-end">
                <button
                  type="button"
                  className="lot-view-control-icon cursor-pointer"
                  onClick={() => setShowExternalLinks(!showExternalLinks)}
                >
                  <LinkSvg className="h-5 w-5" />
                </button>
              </div>
            )}
        </div>
        <div
          className={`absolute bottom-0 z-10 h-auto w-full bg-shadow-horizontal transition duration-300 ease-in ${
            packageInfoToggle &&
            !autoPlayState &&
            playerControlState === PlayerState.Stop &&
            playerState === PlayerState.Stop
              ? 'translate-y-0'
              : 'translate-y-full'
          }`}
        >
          <div
            className={`flex h-full transform items-stretch justify-between duration-200 ease-in ${
              fullScreenToggle ? 'pb-2 pl-2' : 'pb-5 pl-5'
            } `}
          >
            <PackageInfoCard
              lot={lot}
              packageInfo={activePackage}
              totalPrice={totalPrice}
              handlePackageInfoModal={handlePackageInfoModal}
              connected={isConnected}
            />

            {isConnected && (
              <div className="self-end px-16 text-[22px] font-medium leading-5 text-white drop-shadow-40">
                {activeLabel}
              </div>
            )}
          </div>
        </div>
        <SwitchTransition>
          <CSSTransition
            timeout={200}
            classNames="transition-fade-out"
            key={deselected ? 'deselected' : galleryData?.[0]?.id}
          >
            {deselected ? (
              <DeselectedView type={lastActiveOption.type} />
            ) : (
              <div className="absolute inset-0">
                <CarouselProvider
                  slides={slides}
                  activeSlideIndex={currentSlideIndex}
                  showControl={
                    !autoPlayState &&
                    playerControlState === PlayerState.Stop &&
                    playerState === PlayerState.Stop
                  }
                  controlType={lotGalleryControlStyle as CarouselControlType}
                  mousewheel={false}
                  getCurrentSlideIndex={handleChangeCurrentSlideIndex}
                  autoPlay={autoPlayState}
                  controlPosition="right"
                  gallerySettings={gallerySettings}
                  allowSlideSwiping={
                    playerControlState === PlayerState.Stop &&
                    playerState === PlayerState.Stop
                  }
                />
              </div>
            )}
          </CSSTransition>
        </SwitchTransition>

        {hasVideoItems && playerControlState === PlayerState.Stop ? (
          <div
            className={`absolute inset-0 transition-all duration-300 ${videoSource ? 'visible' : 'hidden'}`}
          >
            <div className="flex h-full w-full items-center justify-center">
              <VideoPlaybackActions
                onPlay={handlePlaybackAction}
                disablePlayOnShowcaseBtn={shouldDisablePlayOnShowcaseBtn}
                autoPlayState={autoPlayState}
              />
            </div>
          </div>
        ) : null}

        {hasVideoItems ? (
          <div
            className={`absolute bottom-0 z-40 w-full px-2 pb-4 transition-all duration-300 ${
              playerControlState !== PlayerState.Stop
                ? 'translate-y-0 opacity-100'
                : 'pointer-events-none fixed translate-y-full opacity-0'
            }`}
          >
            <VideoPlayerControl
              scrubbarTimerState={scrubbarTimerState}
              isBuffering={isBuffering}
              isMuted={isMuted}
              volume={volume}
              isLooping={false}
              playerState={playerControlState}
              videoLengthInSeconds={videoLengthInSeconds}
              currentVideoPositionInSeconds={currentVideoPositionInSeconds}
              isSeeking={isSeeking}
              handleStopClick={handleStopClick}
              handlePauseClick={handlePauseClick}
              handlePlayClick={handlePlayClick}
              handleMuteClick={handleMuteClick}
              handleVolumeUpClick={handleVolumeUpClick}
              handleVolumeDownClick={handleVolumeDownClick}
              handleSeekTo={handleSeekTo}
              showLoopOption={false}
            />
          </div>
        ) : null}

        {hasVideoItems ? (
          <div
            className={`absolute inset-0 ${
              videoSource && playerState !== PlayerState.Stop
                ? 'visible'
                : 'invisible'
            }`}
          >
            <div className="flex h-full w-full items-center justify-center">
              <VideoPlayer
                key="lot-gallery-video-player"
                ref={videoPlayerRef}
                sources={[videoSource]}
                activeIndex={0}
                disablePlayButton={autoPlayState}
                showLoopOption={false}
                showControls
                getPlayerState={handlePlayerState}
                hidePlayButton
              />
            </div>
          </div>
        ) : null}
      </div>

      {showExternalLinks && (
        <div
          className={`fixed left-0 z-30 w-screen bg-secondaryColour ${
            fullScreenToggle
              ? 'top-0 h-screen'
              : 'top-9 h-page-container overflow-hidden rounded-t-3xl'
          }`}
        >
          <button
            type="button"
            className="absolute left-5 top-5 z-3 rounded bg-white p-1 drop-shadow-40"
            onClick={() => {
              setShowExternalLinks(false)
              setIframeLoaded(false)
            }}
          >
            <ArrowSvg className="h-8 w-8" strokeColor="#000" />
          </button>

          {!iframeLoaded && <Skeleton />}

          <iframe
            key={externalLinksPayload?.externalLinks?.[0]?.linkUrl}
            className={`relative z-2 h-full w-full overflow-hidden border-0 ${
              !iframeLoaded ? 'opacity-0' : ''
            }`}
            title={externalLinksPayload?.externalLinks?.[0]?.label}
            src={externalLinksPayload?.externalLinks?.[0]?.linkUrl}
            height="100%"
            width="100%"
            onLoad={handleIframLoad}
          />
        </div>
      )}
    </DataHandler>
  )
}

export default connect(
  ({ projectIdentity, firestore: { session } }: RootStateTypeExtra) => ({
    activePrecinctId: session?.houseAndLand?.activePrecinctId || '',
    activeStageId: session?.houseAndLand?.activeStageId || '',
    activeLotId: session?.houseAndLand?.activeLotId || '',
    projectIdentity,
    session,
  })
)(LotView)
