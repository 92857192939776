import React, { useEffect } from 'react'
import { connect } from 'react-redux'

import { SessionMap } from '@src/firebase-util/types'

import { ProjectIdentity, RootStateTypeExtra } from '@store/types'

import FirebaseControlQuery from '@firebaseUtil/firebase-control-query'

import useClearCache from '@hooks/useClearCache'

import { ArrowPathSvg } from '@svg/react'

interface ErrorHandlerFallbackProps {
  projectIdentity: ProjectIdentity
  session: SessionMap | undefined
}

function ErrorHandler({ projectIdentity, session }: ErrorHandlerFallbackProps) {
  const firebaseControlQuery = FirebaseControlQuery({
    ...projectIdentity,
    sessionKey: projectIdentity.sessionId,
  })

  const [isCacheCleared, handleClearCache] = useClearCache({
    projectIdentity,
    session,
  })

  const handleRefreshClick = () => {
    handleClearCache()
  }

  useEffect(() => {
    if (isCacheCleared) {
      firebaseControlQuery
        .update({
          [`activeRoute`]: 'vision',
        })
        .then(() => {
          window.location.reload()
        })
    }
  }, [isCacheCleared])

  return (
    <div className="h-screen">
      <div className="flex h-full">
        <div className="flex w-1/2 items-center justify-center bg-mainColour">
          <ArrowPathSvg className="h-32 w-32 text-white" />
        </div>
        <div className="flex w-1/2 items-center bg-white">
          <div className="mx-8">
            <h3 className="text-4xl font-bold">New updates available!</h3>
            <p className="mb-6 text-center text-2xl">
              It looks like this page hasn&apos;t loaded correctly, possibly due
              to recent updates. Please go back to the main page or use the menu
              to explore other sections. Alternatively, click below to refresh.
            </p>
            <button
              type="button"
              className="rounded-lg bg-tertiaryColour px-4 py-2 text-xl text-neutralColour"
              onClick={handleRefreshClick}
            >
              Refresh Now
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default connect(
  ({ projectIdentity, firestore: { session } }: RootStateTypeExtra) => ({
    projectIdentity,
    session,
  })
)(ErrorHandler)
