import React, { useEffect, useMemo, useState } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'

import {
  BucketSource,
  ExtendedUnitFilterOptions,
  FloorGalleryItem,
  ProjectIdentity,
  RootStateTypeExtra,
} from '@store/types'

import { PannellumDataInterface } from '@components/showcase-pannellum/types'

import FloorplateGallery from '@pages/properties-view/apartments/v2/floorplate-view/floorplate-gallery'

import { BuildingInterface } from '@api/building'
import {
  FloorPlateGalleryInterface,
  selectFromResult as selectFromFloorPlateGalleryResult,
  useGetFloorPlateGalleryQuery,
} from '@api/floor-plate-gallery'
import {
  MappingBlockCollection,
  MappingCollection,
} from '@api/interactive-plan'

import { getTabIndexByKey, getTabKeyByIndexBy } from '@utilities/floor-tab'

import FirebaseControlQuery from '@firebaseUtil/firebase-control-query'
import { SessionMap } from '@firebaseUtil/types'

import { FileType } from '@adUtilities/constants/common'

import FloorGalleryTab from './floor-gallery-tab'
import FloorplanCanvas from './floorplan-canvas'

interface FloorPlateProps {
  projectIdentity: ProjectIdentity
  session: SessionMap | undefined
  firebaseActiveBlock: string
  firebaseActiveLevel: string
  building: BuildingInterface
  floorplan: MappingCollection
  blocks: MappingBlockCollection
  unitFilter: ExtendedUnitFilterOptions
  panoramic: PannellumDataInterface[]
  fullScreenToggle: boolean
  getVideoPlayerState: (arg: boolean) => void
  setFullScreenToggle: (arg: boolean) => void
}

function FloorplateView({
  projectIdentity,
  session,
  firebaseActiveBlock,
  firebaseActiveLevel,
  building,
  floorplan,
  blocks,
  unitFilter,
  panoramic,
  fullScreenToggle,
  getVideoPlayerState,
  setFullScreenToggle,
}: FloorPlateProps) {
  const firebaseControlQuery = FirebaseControlQuery({
    ...projectIdentity,
    sessionKey: projectIdentity.sessionId,
  })

  const history = useHistory()

  const blockKeys: string[] = Object.keys(blocks) || []

  const [floorPlateGallery, setFloorPlateGallery] = useState<
    FloorPlateGalleryInterface[]
  >([])

  const [activeTab, setActiveTab] = useState<string>('floor')
  const [isVideoPlaying, setIsVideoPlaying] = useState<boolean>(false)
  const [hasPanoramicData, setPanoramicDataState] = useState<boolean>(false)

  const floorPlateGalleryPayload = useGetFloorPlateGalleryQuery(
    { projectName: projectIdentity.projectName },
    {
      selectFromResult: selectFromFloorPlateGalleryResult,
    }
  )

  const floorGalleryItems: FloorGalleryItem[] = useMemo(() => {
    if (!floorPlateGallery.length) {
      return []
    }

    let foundData

    if (firebaseActiveBlock && firebaseActiveLevel) {
      foundData = floorPlateGallery.find(
        ({ block, level }) =>
          block.toString() === firebaseActiveBlock &&
          level.toString() === firebaseActiveLevel
      )
    } else {
      foundData = floorPlateGallery.find(
        ({ level }) => level.toString() === firebaseActiveLevel
      )
    }

    if (foundData === null) {
      return []
    }

    const floorAssets = foundData?.assets || []

    if (floorAssets.length === 0) {
      return []
    }

    return floorAssets.map((item) => ({
      id: item.id,
      src: item.imageSrc || '',
      videoSource: item.videoSrc,
      thumbnail: item.imageSrc,
      fileType: item.type as FileType,
      bucketType: BucketSource.Legacy,
      noSpliceUrl: false,
    }))
  }, [firebaseActiveLevel, firebaseActiveBlock, floorPlateGallery])

  const hasInteractiveMap = (): boolean => {
    let mapName = firebaseActiveLevel

    if (blockKeys.length > 1) {
      mapName = firebaseActiveBlock
        ? `${firebaseActiveBlock}-${firebaseActiveLevel}`
        : firebaseActiveLevel
    }

    if (mapName in floorplan) {
      return floorplan[mapName]?.image !== ''
    }

    return false
  }

  const handleFloorGalleryTabClick = async (tabKey: string) => {
    await firebaseControlQuery.update({
      [`floorGallery.galleryControlV2.activeSlideIndex`]: 0,
      [`floorGallery.galleryControlV2.activeTabIndex`]:
        getTabIndexByKey(tabKey),
    })
  }

  const handleGoToPanoramic = async () => {
    await firebaseControlQuery.update({
      [`panoramic.scene`]: '',
    })
    await firebaseControlQuery.updateRoute('panoramic')
    history.push('panoramic')
  }

  useEffect(() => {
    const { floorPlateGalleryData } = floorPlateGalleryPayload

    if (!floorPlateGallery.length && floorPlateGalleryData.length) {
      setFloorPlateGallery(floorPlateGalleryData)
    }
  }, [floorPlateGalleryPayload])

  useEffect(() => {
    const matchingPanoramicData = panoramic.find(
      (item) =>
        item.type === 'level' &&
        item.panoramaGroup?.toString() === firebaseActiveLevel?.toString()
    )
    setPanoramicDataState(Boolean(matchingPanoramicData))
  }, [panoramic, firebaseActiveLevel])

  useEffect(() => {
    if (!session) {
      return
    }

    const {
      floorGallery: {
        galleryControlV2: { activeTabIndex },
      },
    } = session

    setActiveTab(getTabKeyByIndexBy(activeTabIndex))
  }, [session])

  if (!hasInteractiveMap())
    return (
      <div className="flex h-full w-full items-center justify-center bg-white text-4xl">
        No interactive floor plate found for this level.
      </div>
    )

  return (
    <>
      {activeTab === 'gallery' && floorGalleryItems.length > 0 && (
        <FloorplateGallery
          floorGalleryItems={floorGalleryItems}
          isVideoPlaying={isVideoPlaying}
          setIsVideoPlaying={setIsVideoPlaying}
          getVideoPlayerState={getVideoPlayerState}
        />
      )}

      {activeTab === 'floor' && (
        <FloorplanCanvas
          firebaseActiveBlock={firebaseActiveBlock}
          firebaseActiveLevel={firebaseActiveLevel}
          building={building}
          floorplan={floorplan}
          unitFilter={unitFilter}
          fullScreenToggle={fullScreenToggle}
          setFullScreenToggle={setFullScreenToggle}
        />
      )}

      {(floorGalleryItems.length > 0 || hasPanoramicData) &&
        !isVideoPlaying && (
          <div className="absolute bottom-5 right-5 z-3">
            <div className="flex items-center">
              {floorGalleryItems.length > 0 && (
                <FloorGalleryTab
                  activeTab={activeTab}
                  handleClick={handleFloorGalleryTabClick}
                />
              )}
              {hasPanoramicData && (
                <button
                  type="button"
                  onClick={handleGoToPanoramic}
                  className="text-md ml-2 rounded-md border border-mainColour bg-white px-8 py-2 font-medium tracking-widest text-mainColour"
                >
                  Panoramic
                </button>
              )}
            </div>
          </div>
        )}
    </>
  )
}

export default connect(
  ({ projectIdentity, firestore: { session } }: RootStateTypeExtra) => ({
    projectIdentity,
    session,
  })
)(FloorplateView)
