import React from 'react'
import { useDispatch } from 'react-redux'

import { setShortlists } from '@store/actionSlices/shortlist'
import {
  ProjectIdentity,
  ShortlistItemInterface,
  ShortlistPropertyType,
  UnitShortlistInterface,
} from '@store/types'

import { Payload } from '@components/data-handler/data-handler'

import {
  Level,
  selectFromResult as selectFromBuildingResult,
  useGetBuildingQuery,
} from '@api/building'
import {
  MatrixDataCollectionInterface,
  selectMatrixDataFromResult,
  selectPackagesFromResult,
  useGetPackagesByIdQuery,
  useLazyGetMatrixDataByPrecinctQuery,
} from '@api/houseAndLand'

import FirebaseControlQuery from '@firebaseUtil/firebase-control-query'
import { SessionMap } from '@firebaseUtil/types'

import { Unit } from '@adUtilities/types/apartment'

interface UseGetShortlistDataArgs {
  propertyType: ShortlistPropertyType
  session: SessionMap | undefined
  shortlists: ShortlistItemInterface[]
  sessionShortlists?: ShortlistItemInterface[]
  projectIdentity: ProjectIdentity
}

interface UseGetShortlistDataReturn {
  queryResult: Omit<Payload, 'data'>
  queryResultData: Payload['data']
  filteredShortlists: ShortlistItemInterface[]
}

const useGetShortlistData: (
  data: UseGetShortlistDataArgs
) => UseGetShortlistDataReturn = ({
  propertyType,
  session,
  shortlists,
  projectIdentity,
}: UseGetShortlistDataArgs) => {
  const AVAILABLE_STATUS = 'available'

  const dispatch = useDispatch()
  const firebaseControlQuery = FirebaseControlQuery({
    ...projectIdentity,
    sessionKey: projectIdentity.sessionId,
  })
  const [filteredShortlists, setFilteredShortlists] =
    React.useState<ShortlistItemInterface[]>(shortlists)

  React.useEffect(() => {
    setFilteredShortlists(shortlists)
  }, [shortlists])

  const buildingQueryResult = useGetBuildingQuery(
    { projectName: projectIdentity.projectId },
    {
      selectFromResult: selectFromBuildingResult,
      skip: propertyType !== ShortlistPropertyType.Unit,
    }
  )

  React.useEffect(() => {
    if (propertyType !== ShortlistPropertyType.Unit) {
      return
    }
    if (session && buildingQueryResult.status === 'fulfilled') {
      const {
        shortlist: { properties: firebaseProperties },
      } = session

      const flattenedBuildingArray: Unit[] =
        buildingQueryResult?.levels?.flatMap((item: Level) =>
          item.data.map((unit) => ({ ...unit, level: item.level }))
        )

      const selectedProperties: UnitShortlistInterface[] =
        flattenedBuildingArray
          .filter((unit) =>
            firebaseProperties?.find((item) => item.propertyId === unit.id)
          )
          .filter(
            (item) =>
              item.metas.status === projectIdentity?.statusLabels?.available ||
              item.metas.status === AVAILABLE_STATUS
          )
          .map((item) => ({
            propertyId: item.id,
            propertyName: item.name,
            references: {
              blockId: item.blockId,
              levelId: item.metas.level,
            },
            type: ShortlistPropertyType.Unit,
          }))

      firebaseControlQuery.update({
        [`shortlist.properties`]: selectedProperties,
      })
      setFilteredShortlists(selectedProperties)
      dispatch(setShortlists(selectedProperties))
    }
  }, [session, buildingQueryResult.isLoaded])

  const [
    batchMatrixDataByPrecinctQueryResponse,
    setBatchMatrixDataByPrecinctQueryResponse,
  ] = React.useState<
    Omit<
      ReturnType<typeof selectMatrixDataFromResult>,
      'matrixData' | 'errorStatus'
    > & {
      matrixData?: MatrixDataCollectionInterface
      errorStatus?: number
    }
  >({
    isError: false,
    isFetching: false,
    isLoaded: false,
    status: 'uninitialized',
    matrixData: {},
  })

  const { precinctIds, packageIds } = React.useMemo(
    () =>
      propertyType === ShortlistPropertyType.Lot
        ? (session?.shortlist?.properties || []).reduce<{
            precinctIds: string[]
            packageIds: string[]
          }>(
            (a, c) => {
              if (c.type === ShortlistPropertyType.Lot) {
                if (!a.precinctIds.includes(c.references.precinctId)) {
                  a.precinctIds.push(c.references.precinctId)
                }
                if (
                  c.configuration?.floorplan &&
                  !a.packageIds.includes(c.configuration.floorplan)
                ) {
                  a.packageIds.push(c.configuration.floorplan)
                }
              }
              return a
            },
            { precinctIds: [], packageIds: [] }
          )
        : { precinctIds: [], packageIds: [] },
    [session?.shortlist?.properties]
  )

  const [getMatrixDataByPrecinctQuery] = useLazyGetMatrixDataByPrecinctQuery({
    selectFromResult: selectMatrixDataFromResult,
  })
  const packagesByIdQueryResponse = useGetPackagesByIdQuery(
    {
      projectName: projectIdentity.projectId,
      packageIds: packageIds.join(','),
    },
    {
      selectFromResult: selectPackagesFromResult,
      skip: !(packageIds.length && propertyType === ShortlistPropertyType.Lot),
    }
  )

  const executeMatrixDataByPrecinctQuery = React.useCallback(async () => {
    if (
      !(propertyType === ShortlistPropertyType.Lot && precinctIds.length) ||
      batchMatrixDataByPrecinctQueryResponse.status === 'fulfilled'
    ) {
      return
    }
    try {
      setBatchMatrixDataByPrecinctQueryResponse({
        isFetching: true,
        isLoaded: false,
        isError: false,
        status: 'pending',
      })
      const responses = await Promise.all(
        precinctIds.map((item) =>
          getMatrixDataByPrecinctQuery({
            projectName: projectIdentity.projectId,
            precinctIdOrLabel: item,
            lots: true,
          })
        )
      )
      if (responses[0]?.isError) {
        throw responses[0]?.error
      }
      let newMatrixData: MatrixDataCollectionInterface = {}
      responses.forEach((response, index) => {
        const selectedResult = selectMatrixDataFromResult(response)
        newMatrixData = {
          ...newMatrixData,
          [precinctIds[index]]: selectedResult.matrixData,
        }
      })
      setBatchMatrixDataByPrecinctQueryResponse({
        isFetching: false,
        isLoaded: true,
        isError: false,
        status: 'fulfilled',
        matrixData: newMatrixData,
      })
    } catch (error) {
      setBatchMatrixDataByPrecinctQueryResponse({
        isFetching: false,
        isLoaded: true,
        isError: true,
        status: 'rejected',
        errorStatus: (error as { status: number })?.status,
      })
      console.log(error)
    }
  }, [precinctIds])

  React.useEffect(() => {
    if (
      session?.connected &&
      !session?.shortlist?.properties.length &&
      propertyType === ShortlistPropertyType.Lot
    ) {
      setBatchMatrixDataByPrecinctQueryResponse({
        isError: false,
        isFetching: false,
        isLoaded: true,
        status: 'uninitialized',
        matrixData: {},
      })
    }
  }, [session?.connected])

  React.useEffect(() => {
    if (propertyType !== ShortlistPropertyType.Lot) {
      return
    }
    if (
      session &&
      batchMatrixDataByPrecinctQueryResponse.status === 'fulfilled'
    ) {
      const {
        shortlist: { properties: firebaseProperties },
      } = session

      const selectedProperties =
        firebaseProperties?.filter((item) => {
          if (item.type !== ShortlistPropertyType.Lot) {
            return false
          }
          const matchedPrecinct =
            batchMatrixDataByPrecinctQueryResponse.matrixData?.[
              item.references.precinctId
            ]
          const matchedStage =
            matchedPrecinct?.stages?.[item.references.stageId]
          const matchedLot = matchedStage?.lots.find(
            (lot) => lot.id === item.propertyId
          )
          if (matchedLot) {
            return true
          }
          return false
        }) || []

      firebaseControlQuery.update({
        [`shortlist.properties`]: selectedProperties,
      })
      setFilteredShortlists(selectedProperties)
      dispatch(setShortlists(selectedProperties))
    }
  }, [session, batchMatrixDataByPrecinctQueryResponse.isLoaded])

  React.useEffect(() => {
    executeMatrixDataByPrecinctQuery()
  }, [executeMatrixDataByPrecinctQuery])

  const queryResult = React.useMemo<
    UseGetShortlistDataReturn['queryResult']
  >(() => {
    if (propertyType === ShortlistPropertyType.Unit) {
      return {
        ...buildingQueryResult,
        isLoaded:
          buildingQueryResult.status === 'uninitialized'
            ? false
            : buildingQueryResult.isLoaded,
      }
    }
    if (batchMatrixDataByPrecinctQueryResponse?.status !== 'fulfilled') {
      return batchMatrixDataByPrecinctQueryResponse
    }
    if (packagesByIdQueryResponse.status !== 'fulfilled') {
      return packagesByIdQueryResponse
    }
    return batchMatrixDataByPrecinctQueryResponse
  }, [
    buildingQueryResult,
    batchMatrixDataByPrecinctQueryResponse,
    packagesByIdQueryResponse,
    filteredShortlists.length,
  ])

  const queryResultData = React.useMemo(() => {
    if (propertyType === ShortlistPropertyType.Unit) {
      return buildingQueryResult.levels
    }
    return {
      packages: packagesByIdQueryResponse.packages,
      matrixData: batchMatrixDataByPrecinctQueryResponse.matrixData,
    }
  }, [
    buildingQueryResult.levels,
    batchMatrixDataByPrecinctQueryResponse.matrixData,
    packagesByIdQueryResponse.packages,
  ])

  return {
    queryResult,
    queryResultData,
    filteredShortlists,
  }
}

export default useGetShortlistData
