import React from 'react'
import { connect, useDispatch } from 'react-redux'
import { CSSTransition, TransitionGroup } from 'react-transition-group'

import { removeShortlist } from '@store/actionSlices/shortlist'
import type {
  GalleryItemInterface,
  GallerySettingsInterface,
  RootStateTypeExtra,
} from '@store/types'

import Container from '@components/container/v2'
import DataHandler from '@components/data-handler/v2'

import { selectFromResult, useGetVisionQuery } from '@api/vision'

import FirebaseControlQuery from '@firebaseUtil/firebase-control-query'
import { SessionMap } from '@firebaseUtil/types'

import { CarouselProvider } from '@adUtilities/components/carousel-provider'
import { BucketSource, FileType } from '@adUtilities/constants'
import { CarouselControlType } from '@adUtilities/constants/carousel'

import Settings from './settings'
import VisionSkeleton from './vision-skeleton'

enum BackgroundType {
  Color = 'color',
  Image = 'image',
  None = 'none',
}

interface VisionProps {
  session: SessionMap | undefined
  projectId: string
  sessionId: string
  backgroundType: BackgroundType
  backgroundImageSource?: string
  backgroundColor?: string
  gallerySettings?: GallerySettingsInterface
}

const PLACEHOLDER_IMAGE = process.env.REACT_APP_PLACEHOLDER_IMAGE_URL

function Vision({
  session,
  projectId,
  sessionId,
  backgroundType,
  backgroundImageSource,
  backgroundColor,
  gallerySettings,
}: VisionProps) {
  const firebaseControlQuery = FirebaseControlQuery({
    projectId,
    sessionKey: sessionId,
  })

  const dispatch = useDispatch()

  const [toggleControls, setToggleControls] = React.useState(true)
  const [activeSlideIndex, setActiveSlideIndex] = React.useState(0)

  const visionPayload = useGetVisionQuery(
    { projectName: projectId },
    { selectFromResult }
  )

  const handleCarouselProviderClick = () => setToggleControls((state) => !state)

  const slides = React.useMemo(() => {
    if (backgroundType === BackgroundType.Image && backgroundImageSource) {
      return [
        {
          id: 'vision-background-image',
          label: 'Vision Background Image',
          src: backgroundImageSource,
          thumbnail: backgroundImageSource,
          bucketType: BucketSource.New,
          fileType: FileType.Image,
          noSpliceUrl: false,
        },
      ]
    }

    if (visionPayload.gallery.length > 0) {
      return visionPayload.gallery.map((item: GalleryItemInterface) => ({
        id: item.id,
        label: item.imageName,
        src: item.imageSource,
        thumbnail: item.imageSource,
        bucketType: BucketSource.Legacy,
        fileType: FileType.Image,
        noSpliceUrl: false,
      }))
    }

    return [
      {
        id: 'no-vision-image',
        label: 'No Vision Image',
        src: PLACEHOLDER_IMAGE || '',
        thumbnail: PLACEHOLDER_IMAGE || '',
        bucketType: BucketSource.New,
        fileType: FileType.Image,
        noSpliceUrl: false,
      },
    ]
  }, [visionPayload.gallery, backgroundType, backgroundImageSource])

  const handleOnItemClick = React.useCallback(
    async (index: number) => {
      setActiveSlideIndex(index)
      await firebaseControlQuery.update({
        'vision.galleryControlV2.activeSlideIndex': index,
      })
    },
    [firebaseControlQuery]
  )

  React.useEffect(() => {
    if (session) {
      const {
        connected,
        vision: {
          galleryControlV2: { activeSlideIndex: activeSlideIndexFirebase },
        },
      } = session
      setActiveSlideIndex(activeSlideIndexFirebase)
      if (!connected) {
        dispatch(removeShortlist())
      }
    }
  }, [session])

  return (
    <Container>
      <DataHandler
        payload={{
          ...visionPayload,
          data: visionPayload.gallery,
        }}
        skeletonFrame={<VisionSkeleton />}
      >
        {backgroundType === BackgroundType.Color ? (
          <div
            className="h-full w-full bg-red-500"
            style={{ backgroundColor: backgroundColor || '#FFFFFF' }}
          ></div>
        ) : (
          <TransitionGroup className="h-full">
            <CSSTransition classNames="transition-fade" timeout={500}>
              <CarouselProvider
                gallerySettings={{
                  effectType: gallerySettings?.type || 'fadeIn',
                  autoPlayIntervalInSeconds:
                    gallerySettings?.intervalInSeconds || 7,
                }}
                slides={slides}
                activeSlideIndex={activeSlideIndex}
                showControl
                slideImageClass="object-cover w-full"
                onContainerClick={handleCarouselProviderClick}
                showBlurredBackground={false}
                controlType={CarouselControlType.Thumbnail}
                getCurrentSlideIndex={(index: number) =>
                  handleOnItemClick(index)
                }
                controlPosition="right"
                mousewheel={false}
              ></CarouselProvider>
            </CSSTransition>
          </TransitionGroup>
        )}
      </DataHandler>
      <div
        className={`absolute bottom-5 left-6 z-1 transition-opacity ${toggleControls ? 'opacity-100' : 'opacity-0'}`}
      >
        <Settings />
      </div>
    </Container>
  )
}

const mapStateToProps = ({
  firestore: { session },
  projectIdentity: {
    projectId,
    sessionId,
    theme: {
      remoteVisionBackground: { type, url, color },
    },
    gallerySettings,
  },
}: RootStateTypeExtra) => ({
  session,
  projectId,
  sessionId,
  backgroundType: type as BackgroundType,
  backgroundImageSource: url,
  backgroundColor: color,
  gallerySettings,
})

export default connect(mapStateToProps)(Vision)
