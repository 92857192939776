import React from 'react'
import { connect } from 'react-redux'

import { ProjectIdentity, RootStateTypeExtra } from '@store/types'

import FirebaseControlQuery from '@firebaseUtil/firebase-control-query'

import { ChevronSvg } from '@svg/react'

interface ComponentProps {
  projectIdentity: ProjectIdentity
  level: string
  active: boolean
  levelStagePrefix: string
}

function LevelHead({
  projectIdentity,
  level,
  active,
  levelStagePrefix,
}: ComponentProps) {
  const firebaseControlQuery = FirebaseControlQuery({
    ...projectIdentity,
    sessionKey: projectIdentity.sessionId,
  })

  const handleLevelClick = async () => {
    await firebaseControlQuery.update({
      [`building.activeLevel`]: level,
      [`building.activeUnit`]: '',
      [`floorGallery.galleryControlV2.activeSlideIndex`]: 0,
      [`floorGallery.galleryControlV2.activeTabIndex`]: 0,
    })
  }

  return (
    <button
      id={`label-${level}`}
      className="sticky top-0 z-10 flex w-full items-center justify-between bg-[#EEEEEE] py-3"
      type="button"
      onClick={handleLevelClick}
    >
      <span
        className={`${
          active ? 'active-level border-b-2 border-zinc-800' : ''
        } text-md font-bold tracking-widest`}
      >
        {levelStagePrefix || 'Level'} {level}
      </span>
      <ChevronSvg className="h-6 w-6" rotate="right" />
    </button>
  )
}

export default connect(({ projectIdentity }: RootStateTypeExtra) => ({
  projectIdentity,
}))(LevelHead)
