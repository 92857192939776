import React from 'react'
import { connect } from 'react-redux'
import { CSSTransition, TransitionGroup } from 'react-transition-group'

import {
  GalleryInterface,
  GalleryItemInterface,
  ProjectIdentity,
  type RootStateTypeExtra,
} from '@store/types'

import Container from '@components/container/v2'
import DataHandler from '@components/data-handler/v2'

import {
  selectFromResult as selectFromBrochureResult,
  useGetBrochureQuery,
} from '@api/brochure'

import FirebaseControlQuery from '@firebaseUtil/firebase-control-query'
import { SessionMap } from '@firebaseUtil/types'

import {
  CarouselProvider,
  Tab,
} from '@adUtilities/components/carousel-provider'
import { CarouselControlType } from '@adUtilities/constants/carousel'
import { BucketSource, FileType } from '@adUtilities/constants/common'

import BrochureSkeleton from './brochure-skeleton'

export interface BrochureProps {
  session: SessionMap | undefined
  projectIdentity: ProjectIdentity
}

function Brochure({ session, projectIdentity }: BrochureProps) {
  const firebaseControlQuery = FirebaseControlQuery({
    ...projectIdentity,
    sessionKey: projectIdentity.sessionId,
  })

  const [toggleControls, setToggleControls] = React.useState(true)
  const [activeTabIndex, setActiveTabIndex] = React.useState(0)
  const [activeSlideIndex, setActiveSlideIndex] = React.useState(0)

  const brochurePayload = useGetBrochureQuery(
    { projectName: projectIdentity.projectId },
    { selectFromResult: selectFromBrochureResult }
  )

  const slides = React.useMemo(
    () =>
      brochurePayload.brochureData.length > 0
        ? brochurePayload.brochureData[activeTabIndex].items.map(
            (item: GalleryItemInterface) => ({
              id: item.id,
              label: item.imageName,
              src: item.imageSource,
              thumbnail: item.imageSource,
              bucketType: BucketSource.Legacy,
              fileType: FileType.Image,
              noSpliceUrl: false,
            })
          )
        : [],
    [brochurePayload.brochureData, activeTabIndex]
  )

  const handleCarouselProviderClick = () => setToggleControls((state) => !state)

  const handleOnTabChange = React.useCallback(
    async (index: number) => {
      setActiveTabIndex(index)
      setActiveSlideIndex(0)
      await firebaseControlQuery.update({
        'brochure.galleryControlV2.activeTabIndex': index,
        'brochure.galleryControlV2.activeSlideIndex': 0,
      })
    },
    [firebaseControlQuery]
  )

  const handleOnItemClick = React.useCallback(
    async (index: number) => {
      setActiveSlideIndex(index)
      await firebaseControlQuery.update({
        'brochure.galleryControlV2.activeSlideIndex': index,
      })
    },
    [firebaseControlQuery]
  )

  React.useEffect(() => {
    if (!session) {
      return
    }

    const {
      brochure: {
        galleryControlV2: {
          activeTabIndex: activeTabIndexFirebase,
          activeSlideIndex: activeSlideIndexFirebase,
        },
      },
    } = session

    setActiveTabIndex(activeTabIndexFirebase)
    setActiveSlideIndex(activeSlideIndexFirebase)
  }, [session?.brochure.galleryControlV2])

  return (
    <Container>
      <DataHandler
        payload={{ ...brochurePayload, data: brochurePayload.brochureData }}
        skeletonFrame={<BrochureSkeleton />}
      >
        <div
          className={`absolute top-8 z-2 transition-opacity ${toggleControls ? 'opacity-100' : 'pointer-events-none opacity-0'}`}
        >
          <Tab
            items={brochurePayload.brochureData.map(
              (item: GalleryInterface) => ({ text: item.label || '' })
            )}
            handleOnItemClick={handleOnTabChange}
            activeItemIndex={activeTabIndex}
            toggleControls={!toggleControls}
          />
        </div>

        <TransitionGroup className="h-full">
          <CSSTransition
            key={activeTabIndex}
            classNames="transition-fade"
            timeout={500}
          >
            <CarouselProvider
              gallerySettings={{
                effectType: projectIdentity.gallerySettings.type,
                autoPlayIntervalInSeconds:
                  projectIdentity.gallerySettings.intervalInSeconds,
              }}
              slides={slides}
              activeSlideIndex={activeSlideIndex}
              onContainerClick={handleCarouselProviderClick}
              showControl
              controlType={CarouselControlType.Thumbnail}
              getCurrentSlideIndex={(index: number) => handleOnItemClick(index)}
              mousewheel={false}
            ></CarouselProvider>
          </CSSTransition>
        </TransitionGroup>
      </DataHandler>
    </Container>
  )
}

const mapStateToProps = ({
  firestore: { session },
  projectIdentity,
}: RootStateTypeExtra) => ({
  session,
  projectIdentity,
})

export default connect(mapStateToProps)(Brochure)
