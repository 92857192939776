import React from 'react'
import { CSSTransition, SwitchTransition } from 'react-transition-group'

import {
  AreaSvg,
  BathSvg,
  BedSvg,
  CarSvg,
  FrontageSvg,
  PowderSvg,
  StudySvg,
} from '@components/adgroup-svg/react'

import { ConfigurationInterface, LotInterface } from '@api/houseAndLand'

import { formatAreaNumber } from '@adUtilities/helper'

type LotConfigurationProps = Partial<ConfigurationInterface> &
  Pick<LotInterface, 'area' | 'frontage'>

function LotConfiguration({
  bed,
  study,
  bath,
  powderRoom,
  car,
  area,
  frontage,
}: LotConfigurationProps) {
  const configAnimationKey = React.useMemo(
    () =>
      Number(bed) > 0 ||
      Number(study) > 0 ||
      Number(bath) > 0 ||
      Number(powderRoom) > 0 ||
      Number(car) > 0,
    [bed, study, bath, powderRoom, car]
  )

  const areaAndFrontageAnimationKey = React.useMemo(
    () => Number(area) > 0 || Number(frontage) > 0,
    [area, frontage]
  )

  return (
    <div className="flex flex-col text-zinc-500">
      <SwitchTransition>
        <CSSTransition
          classNames="transition-lot-room-configuration-expand-v2"
          timeout={300}
          key={`${configAnimationKey}`}
        >
          <div className="mb-2 flex gap-3">
            {Number(bed) > 0 && (
              <div className="inline-flex items-center gap-1" title="Bed">
                <BedSvg className="h-[17px] w-[17px]" />
                <span>{bed}</span>
              </div>
            )}
            {Number(study) > 0 && (
              <div className="inline-flex items-center gap-1" title="Study">
                <StudySvg className="h-[17px] w-[17px]" />
                <span>{study}</span>
              </div>
            )}
            {Number(bath) > 0 && (
              <div className="inline-flex items-center gap-1" title="Bath">
                <BathSvg className="h-[17px] w-[17px]" />
                <span>{bath}</span>
              </div>
            )}
            {Number(powderRoom) > 0 && (
              <div className="inline-flex items-center gap-1" title="Powder">
                <PowderSvg className="h-[17px] w-[17px]" />
                <span>{powderRoom}</span>
              </div>
            )}
            {Number(car) > 0 && (
              <div className="inline-flex items-center gap-1" title="Car">
                <CarSvg className="h-[17px] w-[17px]" />
                <span>{car}</span>
              </div>
            )}
          </div>
        </CSSTransition>
      </SwitchTransition>
      <SwitchTransition>
        <CSSTransition
          classNames="transition-lot-room-configuration-expand-v2"
          timeout={300}
          key={`${areaAndFrontageAnimationKey}`}
        >
          <div className="flex gap-3">
            {Number(area) > 0 && (
              <div className="inline-flex items-center gap-1" title="Area">
                <AreaSvg className="h-[17px] w-[17px]" />
                <span>{formatAreaNumber(area)} sqm</span>
              </div>
            )}
            {Number(frontage) > 0 && (
              <div className="inline-flex items-center gap-1" title="Frontage">
                <FrontageSvg className="h-[17px] w-[17px]" />
                <span>{formatAreaNumber(frontage)} m</span>
              </div>
            )}
          </div>
        </CSSTransition>
      </SwitchTransition>
    </div>
  )
}

export default LotConfiguration
