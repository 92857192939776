import React, { BaseSyntheticEvent } from 'react'

import { GalleryItemInterface } from '@store/types'

import { PlaySvg } from '@svg/react'

import AssetHandler from '@adUtilities/asset-handler/asset-handler'

export interface ComponentPropsInterface {
  carouselData: {
    index: number
    item: GalleryItemInterface
  }
  onItemClick: (index: number) => void
  showPlay: boolean
  onPlayClick: (index: number) => void
  cursorClassName?: string
  imgClass?: string
  hasBlurredEffect?: boolean
}

function CarouselItem({
  carouselData,
  onItemClick,
  showPlay,
  onPlayClick,
  cursorClassName = 'cursor-pointer',
  imgClass = 'object-cover',
  hasBlurredEffect,
}: ComponentPropsInterface) {
  const PLACEHOLDER_IMAGE = process.env.REACT_APP_PLACEHOLDER_IMAGE_URL

  const isVideo = (item: GalleryItemInterface) =>
    item?.videoSource && item?.videoSource !== ''

  const onImageError = (e: BaseSyntheticEvent) => {
    e.target.src = PLACEHOLDER_IMAGE
  }

  const isTypeColor = carouselData.item?.config?.isTypeColor
  const blurred = carouselData.item?.config?.blurred

  return (
    <div
      className={`item relative h-full w-full ${
        hasBlurredEffect ? 'flex items-center overflow-hidden rounded-lg' : ''
      }`}
    >
      {hasBlurredEffect && (
        <img
          onClick={() => onItemClick(carouselData.index)}
          className={`image-blur absolute inset-0 z-1 h-full w-full object-cover ${cursorClassName}`}
          role="none"
          loading="lazy"
          src={
            AssetHandler({
              url: carouselData.item?.imageSource,
              type: carouselData.item?.assetType || 'legacy',
              staticUrl: carouselData.item?.config?.staticUrl,
              noSpliceUrl: carouselData.item?.config?.noSpliceUrl,
            }) ?? PLACEHOLDER_IMAGE
          }
          alt={carouselData.item?.title}
          onError={onImageError}
        />
      )}

      {isTypeColor ? (
        <div
          className={`z-2 h-full w-full ${cursorClassName} ${imgClass}`}
          style={{ backgroundColor: carouselData.item?.imageSource }}
          onClick={() => onItemClick(carouselData.index)}
          role="none"
        />
      ) : (
        <img
          onClick={() => onItemClick(carouselData.index)}
          className={`z-2 h-full w-full ${cursorClassName} ${
            blurred ? 'image-blur' : ''
          } ${imgClass}`}
          role="none"
          loading="lazy"
          src={
            AssetHandler({
              url: carouselData.item?.imageSource,
              type: carouselData.item?.assetType || 'legacy',
              staticUrl: carouselData.item?.config?.staticUrl,
              noSpliceUrl: carouselData.item?.config?.noSpliceUrl,
            }) ?? PLACEHOLDER_IMAGE
          }
          alt={carouselData.item?.title}
          onError={onImageError}
        />
      )}

      {isVideo(carouselData.item) && showPlay && (
        <button
          type="button"
          className={`absolute left-1/2 top-1/2 z-10 -translate-x-1/2 -translate-y-1/2 rounded-full bg-secondaryColour p-3 drop-shadow-md ${cursorClassName}`}
          onClick={() => {
            onPlayClick(carouselData.index)
          }}
        >
          <PlaySvg className="h-3 w-3 text-mainColour" />
        </button>
      )}
    </div>
  )
}

export default CarouselItem
