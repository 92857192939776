import React from 'react'
import { connect } from 'react-redux'

import {
  InteractivePlanType,
  LotFilterInterface,
  ProjectIdentity,
  RootStateTypeExtra,
} from '@store/types'

import Container from '@components/container/v2'
import DataHandler from '@components/data-handler'
import { Status } from '@components/data-handler/data-handler'
import useGetFilterData from '@components/filter/use-get-filter-data'
import FILTER_INITIAL_STATE from '@components/filter/v2/filterStateHouseAndLand'
import Filter from '@components/filter/v2/house-and-land-filter'
import ImageHandler from '@components/image-handler'
import { CanvasInteractive } from '@components/showcase-canvas'
import { CanvasRefInterface } from '@components/showcase-canvas/canvas-interactive'
import { Polygon } from '@components/showcase-canvas/types'

import {
  MatrixDataCollectionInterface,
  PrecinctListItemInterface,
  StageCollectionInterface,
  selectMatrixDataFromResult,
  useGetMatrixDataByPrecinctQuery,
} from '@api/houseAndLand'
import {
  MapContent,
  MappingBlockCollection,
  selectFromResult as selectFromResultInteractive,
  useGetInteractivePlanQuery,
} from '@api/interactive-plan'

import styleUtil from '@utilities/style-util'

import FirebaseControlQuery from '@firebaseUtil/firebase-control-query'
import { SessionMap } from '@firebaseUtil/types'

import { ArrowSvg, FilterSvg, MapPinSvg } from '@svg/react'

import filterPackageSummary from '@adUtilities/filter-package-summary'

import PrecinctSkeleton from './precinct-skeleton'

export interface PrecinctProps {
  session: SessionMap | undefined
  projectIdentity: ProjectIdentity
  precinctList: PrecinctListItemInterface[]
}

const findFilterStages = (
  matrixData: MatrixDataCollectionInterface,
  lotFilter: LotFilterInterface,
  activePrecinct: string,
  availableStatusLabel: string
): StageCollectionInterface => {
  const activeMatrixData = matrixData[activePrecinct]
  if (!activeMatrixData || Object.keys(activeMatrixData)?.length === 0) {
    return {}
  }
  if (!lotFilter?.apply) {
    return activeMatrixData.stages
  }
  const filteredMatrixData = filterPackageSummary(
    activeMatrixData,
    {
      ...lotFilter,
      storey: lotFilter.storey.map((item) => Number(item)),
    },
    availableStatusLabel
  )
  if (!filteredMatrixData) {
    return {}
  }
  return filteredMatrixData.stages || {}
}

const ARROW_TYPE = 'arrow'
const ARROW_SMALL_TYPE = 'arrow-small'

function Precinct({ session, projectIdentity, precinctList }: PrecinctProps) {
  const themeData = styleUtil()

  const firebaseControlQuery = FirebaseControlQuery({
    ...projectIdentity,
    sessionKey: projectIdentity.sessionId,
  })

  const [storeActivePrecinct, setActivePrecinct] = React.useState<string>('')
  const { statusLabels } = projectIdentity
  const [storePrecincts, setPrecincts] = React.useState<MappingBlockCollection>(
    {}
  )

  const [hasSessionInitialized, setHasSessionInitialized] =
    React.useState(false)

  const matrixPayload = useGetMatrixDataByPrecinctQuery(
    {
      projectName: projectIdentity.projectName,
      precinctIdOrLabel: storeActivePrecinct,
    },
    {
      selectFromResult: selectMatrixDataFromResult,
      skip: !storeActivePrecinct,
    }
  )

  const lotFilter = React.useMemo(
    () => session?.houseAndLand?.lotFilter || FILTER_INITIAL_STATE,
    [session?.houseAndLand?.lotFilter]
  )

  const levelMarkerColour = projectIdentity.markerColourSettings
  const matrixData = {
    [storeActivePrecinct]: matrixPayload.matrixData,
  }

  const filterStages = findFilterStages(
    matrixData,
    lotFilter,
    storeActivePrecinct,
    statusLabels.available
  )

  const precinctKeys = React.useMemo(
    () => Object.keys(storePrecincts),
    [storePrecincts]
  )

  const canvasContainerRef = React.useRef<HTMLDivElement>(null)
  const canvasRef = React.useRef<CanvasRefInterface>()
  const [isFilterOpen, toggleFilter] = React.useState(false)
  const [rendering, setRendering] = React.useState(false)
  const [renderCanvas, setRenderCanvas] = React.useState(false)
  const [isCanvasImageLoaded, setImageLoaded] = React.useState(false)

  const prevActivePrecinctRef = React.useRef(storeActivePrecinct)
  const [hasLoaded, setHasLoaded] = React.useState(false)
  const [filterActivePrecinct, setFilterActivePrecinct] = React.useState('')

  useGetFilterData({
    projectName: projectIdentity.projectId,
    precinctId: filterActivePrecinct,
  })

  const interactivePayload = useGetInteractivePlanQuery(
    {
      projectName: projectIdentity.projectName,
      type: InteractivePlanType.Precinct,
      slug: storeActivePrecinct,
    },
    {
      selectFromResult: selectFromResultInteractive,
      skip: Object.keys(matrixPayload.matrixData?.stages || {}).length === 0,
    }
  )
  React.useEffect(() => {
    const { maps } = interactivePayload
    if (maps.precinct && Object.keys(maps.precinct)?.length > 0) {
      setPrecincts(maps.precinct)
    }
  }, [interactivePayload])

  const getAvailableLotCount = (poly: Polygon) => {
    const splicedGroupId = poly.groupId.split('-')
    const foundStage = filterStages[splicedGroupId[1] || poly.groupId]
    if (foundStage) {
      return foundStage.lots?.filter(
        (res) => res.status === statusLabels?.available
      ).length
    }
    return 0
  }

  const getStageStatus = React.useCallback(
    (poly: Polygon) => {
      const splicedGroupId = poly.groupId.split('-')

      if (!lotFilter?.apply) {
        return false
      }
      const foundStage = filterStages[splicedGroupId[1] || poly.groupId]

      if (!foundStage) {
        return false
      }

      return foundStage.lots?.length > 0
    },
    [lotFilter, filterStages]
  )

  const getStageLotCount = React.useCallback(
    (poly: Polygon) => {
      const splicedGroupId = poly.groupId.split('-')
      const foundStage = filterStages[splicedGroupId[1] || poly.groupId]

      if (!foundStage) return 0

      return foundStage.lots?.length
    },
    [lotFilter, filterStages]
  )

  const findRelevantStage = React.useCallback(
    (stageName: string) =>
      matrixData?.[storeActivePrecinct]?.stages?.[stageName]?.lots,
    [matrixData, storeActivePrecinct]
  )

  const checkGroupId = React.useCallback((groupId: string) => {
    const splitGroupString = groupId.split('-')
    if (splitGroupString.length > 1) {
      const [activePrecinct, activeLevel] = splitGroupString
      return {
        activePrecinct,
        activeLevel,
      }
    }
    return {
      activeLevel: groupId,
      activePrecinct: '',
    }
  }, [])

  const interactiveClick = React.useCallback(
    async (poly: Polygon) => {
      await firebaseControlQuery.update({
        [`houseAndLand.activeStageId`]: poly.groupId,
      })
    },
    [checkGroupId, precinctKeys]
  )

  const interactiveClickOnComplete = React.useCallback(async () => {
    await firebaseControlQuery.update({
      [`houseAndLand.sidePanelFolded`]: true,
    })
    await firebaseControlQuery.updateRoute('stages')
  }, [])

  const getLabel = React.useCallback((poly: Polygon) => {
    const POLY_TYPE = poly.type || ''
    if (POLY_TYPE === ARROW_TYPE || POLY_TYPE === ARROW_SMALL_TYPE) {
      return poly.label || poly.groupId
    }
    return `${poly.label} ${poly.groupId}`
  }, [])

  const setInteractiveAction = React.useCallback(
    (areaViewMap: MapContent) => ({
      ...areaViewMap,
      polygons: areaViewMap.polygons
        .filter((poly) =>
          findRelevantStage(poly.groupId?.split('-')?.[1] || poly.groupId)
        )
        .map((poly) => ({
          ...poly,
          label: getLabel(poly),
          isHidden: getStageLotCount(poly) === 0,
          subLabel: poly?.subLabel
            ? `${getStageLotCount(poly)} ${poly.subLabel}`
            : '',
          postFix: `- Available Lots (${getAvailableLotCount(poly)})`,
          onClick: () => interactiveClick(poly),
          onComplete: () => interactiveClickOnComplete(),
          markerColour: levelMarkerColour,
        })),
    }),
    [
      findRelevantStage,
      getStageStatus,
      getLabel,
      getStageLotCount,
      getAvailableLotCount,
      interactiveClick,
      interactiveClickOnComplete,
      levelMarkerColour,
      statusLabels,
    ]
  )

  const precinct = React.useMemo(() => {
    if (!(storeActivePrecinct === '' || storeActivePrecinct)) {
      return []
    }
    if (!storePrecincts[storeActivePrecinct]) {
      return (
        Object.values(storePrecincts)?.[0]?.map((res) =>
          setInteractiveAction(res)
        ) || []
      )
    }
    return (
      storePrecincts[storeActivePrecinct]?.map((res) =>
        setInteractiveAction(res)
      ) || []
    )
  }, [storeActivePrecinct, storePrecincts, setInteractiveAction, matrixData])

  const goBackToArea = async () => {
    await firebaseControlQuery.updateRoute('area-view-house-and-land')
    await firebaseControlQuery.update({
      [`houseAndLand.activePrecinctId`]: '',
    })
  }

  React.useEffect(() => {
    if (!rendering) {
      canvasRef?.current?.setPolyActive()
    }
  }, [precinct, rendering])

  React.useEffect(() => {
    if (interactivePayload.status === Status.PENDING) {
      setImageLoaded(false)
    }

    if (interactivePayload.status === Status.FULFILLED) {
      setHasLoaded(true)
    }
  }, [interactivePayload.status])

  React.useEffect(() => {
    if (prevActivePrecinctRef.current === storeActivePrecinct) {
      return
    }
    prevActivePrecinctRef.current = storeActivePrecinct
    setTimeout(() => {
      canvasRef?.current?.setCanvas()
    }, 100)
  }, [storeActivePrecinct])

  React.useEffect(() => {
    if (session) {
      const {
        houseAndLand: { activePrecinctId: firebaseActivePrecinct },
      } = session

      setActivePrecinct(firebaseActivePrecinct)

      if (!hasSessionInitialized) {
        setFilterActivePrecinct(firebaseActivePrecinct)
        setHasSessionInitialized(true)
      }
    }
  }, [session?.houseAndLand?.activePrecinctId])

  React.useEffect(() => {
    setRenderCanvas(true)
  }, [])

  const dataHandlerPayload = React.useMemo(() => {
    if (interactivePayload.status === Status.UNINITIALIZED) {
      return matrixPayload
    }

    return interactivePayload
  }, [interactivePayload, matrixPayload])

  return (
    <Container>
      <>
        <DataHandler
          payloads={{
            ...dataHandlerPayload,
            status: hasLoaded ? Status.FULFILLED : dataHandlerPayload.status,
            data: precinctKeys,
          }}
          skeletonFrame={<PrecinctSkeleton />}
        >
          <div className="absolute left-5 top-5 z-20">
            <div className="flex items-center gap-4">
              <button
                onClick={goBackToArea}
                type="button"
                className="rounded bg-white p-1 drop-shadow-40"
              >
                <ArrowSvg className="h-8 w-8" strokeColor="#000" />
              </button>
              <button
                data-testid="toggle-filter"
                onClick={() => toggleFilter(!isFilterOpen)}
                type="button"
                className="rounded bg-white p-2.5 drop-shadow-40"
              >
                <FilterSvg className="h-5 w-5" />
              </button>
              {precinctList.length > 1 && (
                <button
                  data-testid="toggle-filter"
                  onClick={() => toggleFilter(!isFilterOpen)}
                  type="button"
                  className="flex items-center gap-1.5 rounded bg-white p-2 drop-shadow-40"
                >
                  <MapPinSvg />
                  <span>{storeActivePrecinct}</span>
                </button>
              )}
            </div>
          </div>

          <ImageHandler
            key={precinct[0]?.image}
            url={precinct[0]?.image}
            type="new"
            className="background-cover image-blur absolute inset-0 z-2"
            noSpliceUrl
            showFallbackImage={false}
            bgProps={{
              gradiant: 0.5,
            }}
          />

          <div className="absolute inset-0 z-3">
            <div
              ref={canvasContainerRef}
              className={`relative m-auto flex h-full w-full items-center justify-center ${
                isCanvasImageLoaded ? 'opacity-100' : 'opacity-0'
              }`}
            >
              {canvasContainerRef && renderCanvas && precinct[0] && (
                <CanvasInteractive
                  id="level-canvas"
                  ref={canvasRef}
                  canvasData={precinct[0]}
                  parentRef={canvasContainerRef}
                  hasLabel={false}
                  labelPrefix="Stage:"
                  isRendering={setRendering}
                  theme={{
                    brandColour: themeData.mainColour || '',
                    font: themeData.font || '',
                  }}
                  adjustCanvasSizeWithContainer
                  setImageLoaded={setImageLoaded}
                />
              )}
            </div>
          </div>
        </DataHandler>

        {session && (
          <Filter
            isOpen={isFilterOpen}
            toggle={toggleFilter}
            activePrecinct={filterActivePrecinct}
            setActivePrecinct={setFilterActivePrecinct}
            firebaseActiveStage={session.houseAndLand.activeStageId}
            firebaseLotFilter={session.houseAndLand.lotFilter}
            hideStages
          />
        )}
      </>
    </Container>
  )
}

export default connect(
  ({
    firestore: { session },
    houseAndLand: { precinctList },
    projectIdentity,
  }: RootStateTypeExtra) => ({
    session,
    projectIdentity,
    precinctList,
  })
)(Precinct)
