import { fabric } from 'fabric'
import type { IEvent } from 'fabric/fabric-impl'
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react'

import getWindowDimensions from '@utilities/window-dimensions'

import AssetHandler from '@adUtilities/asset-handler/asset-handler'

import { SVGArrowURL, SVGChevronURL } from './constants'
import MarkerUtil from './marker'
import Oblong from './oblong'
import PolygonUtil from './polygon'
import RectUtil from './rect'
import { CustomGroup, Polygon } from './types'
import { objectZoomInFade, setObjectActive } from './utils/animation'
import EventsUtil from './utils/events'
import FabricOperations from './utils/fabric-operations'
import { getScaledAttribute } from './utils/utils'

export interface CanvasData {
  image: string
  polygons: Array<Polygon>
}

export interface CanvasRefInterface {
  artificialTrigger(arg: string): void
  setPolyActive(): void
  setCanvas(): void
}

export interface Theme {
  font: string
  brandColour: string
}

export interface CanvasProps {
  id: string
  canvasData: CanvasData
  parentRef?: React.RefObject<HTMLDivElement>
  staticWidth?: number
  staticHeight?: number
  hasLabel?: boolean
  labelPrefix?: string
  isRendering?: (arg: boolean) => void
  ratio?: string
  theme?: Theme
  adjustCanvasSizeWithContainer?: boolean
  setImageLoaded?: (arg: boolean) => void
}

declare global {
  interface Window {
    canvasData: CanvasData
    chevronSvg: fabric.Object
    arrowSvg: fabric.Object
    hasLabel?: boolean
  }
}

const CanvasInteractive = forwardRef<
  CanvasRefInterface | undefined,
  CanvasProps
>(
  (
    {
      id,
      canvasData,
      parentRef,
      staticWidth = 0,
      staticHeight = 0,
      hasLabel,
      labelPrefix,
      isRendering,
      ratio = 'max',
      theme,
      adjustCanvasSizeWithContainer,
      setImageLoaded,
    },
    ref
  ) => {
    window.canvasData = canvasData

    const windowDimensions = getWindowDimensions()
    const markerUtil = MarkerUtil()
    const polygonUtil = PolygonUtil()
    const rectUtil = RectUtil()
    const oblUtil = Oblong()
    const eventsUtil = EventsUtil({ labelPrefix: labelPrefix || '' })

    const fabricRef = React.useRef<fabric.Canvas | null>(null)
    const canvasRef = React.useRef<HTMLCanvasElement | null>(null)
    const [bgImage, setBgImage] = useState<null | fabric.Image>(null)

    const [hasSvgArrow, setHasSvgArrow] = useState(false)
    const [hasSvgArrowSmall, setHasSvgArrowSmall] = useState(false)

    const [canWindowRender, setCanWindowRender] = useState(false)

    const [rendering, setRendering] = useState(false)

    const [timeoutIdScreenChange, setTimeoutIdScreenChange] =
      useState<NodeJS.Timeout>()

    const eventUtilMouseMove = React.useCallback(
      (e: IEvent<Event>) => eventsUtil.mouseMove(e, fabricRef?.current),
      []
    )
    const eventUtilMouseOver = React.useCallback(
      (e: IEvent<Event>) => eventsUtil.mouseOver(e, fabricRef?.current),
      []
    )
    const eventUtilMouseOut = React.useCallback(
      (e: IEvent<Event>) => eventsUtil.mouseOut(e, fabricRef?.current),
      []
    )
    const eventUtilMouseUp = React.useCallback(
      (e: IEvent<Event>) => eventsUtil.mouseUp(e, fabricRef?.current),
      []
    )

    const clearEvents = () => {
      if (fabricRef?.current) {
        fabricRef?.current?.off('mouse:move', eventUtilMouseMove)
        fabricRef?.current?.off('mouse:over', eventUtilMouseOver)
        fabricRef?.current?.off('mouse:out', eventUtilMouseOut)
        fabricRef?.current?.off('mouse:up', eventUtilMouseUp)
      }
    }

    const setEvents = () => {
      clearEvents()
      if (fabricRef?.current) {
        fabricRef?.current?.on('mouse:move', eventUtilMouseMove)
        fabricRef?.current?.on('mouse:over', eventUtilMouseOver)
        fabricRef?.current?.on('mouse:out', eventUtilMouseOut)
        fabricRef?.current?.on('mouse:up', eventUtilMouseUp)
      }
    }

    const cleanObjects = () => {
      if (!canvasData?.polygons) {
        return
      }
      if (fabricRef.current) {
        fabricRef.current
          .getObjects()
          .forEach((obj) => fabricRef?.current?.remove(obj))
      }
    }

    const buildMainObject = ({
      rect,
      marker,
      poly,
      obl,
      angle,
      index,
      onClick,
      onComplete,
      disabled,
      label,
      groupId,
      isInvisible,
    }: {
      rect: fabric.Rect
      obl: fabric.Group
      marker: fabric.Group
      poly: fabric.Group
      index: number
      groupId: string
      label?: string
      angle?: number
      noArrow?: boolean
      onClick?: () => void
      onComplete?: () => void
      disabled?: boolean
      isInvisible?: boolean
    }) => {
      const mainObj = new fabric.Group([poly, marker, rect, obl], {
        objectCaching: false,
        hasBorders: false,
        hasControls: false,
        lockMovementX: true,
        lockMovementY: true,
        angle: angle || 0,
        opacity: isInvisible ? 0 : 1,
      }) as CustomGroup

      mainObj.arrayIndex = index

      if (!disabled) {
        mainObj.onGroupSelect = onClick
        mainObj.onGroupSelectComplete = onComplete
      }

      mainObj.id = groupId
      mainObj.label = label || ''

      return mainObj
    }

    const setObjectsInCanvas = (image: fabric.Image) => {
      canvasData.polygons.forEach((polygon, index) => {
        if (fabricRef.current) {
          const { points, label, size, groupId, noArrow } = polygon

          const bgSize = {
            width: image?.getScaledWidth() || 0,
            height: image?.getScaledHeight() || 0,
          }

          const polyAttrib = polygonUtil.getPolyAttributes(points)

          const mainObj = buildMainObject({
            ...polygon,
            marker: markerUtil.createMarker({
              ...polygon,
              bgSize,
              widthRatio:
                getScaledAttribute(size.width, bgSize.width) /
                (polyAttrib.width || 0),
              heightRatio:
                getScaledAttribute(size.height, bgSize.height) /
                (polyAttrib.height || 0),
              label: label || groupId,
            }),
            rect: rectUtil.createRect({ ...polygon, bgSize }),
            poly: polygonUtil.createPolyGroup({
              ...polygon,
              bgSize,
            }),
            obl: oblUtil.createOblong({
              ...polygon,
              bgSize,
              font: theme?.font || '',
              brandColour: theme?.brandColour || '',
              noArrow: noArrow || false,
            }),
            index,
          })

          fabricRef.current.add(mainObj)
        }
      })
      setRendering(false)
    }

    const artificialTrigger = (groupId: string) => {
      if (fabricRef.current) {
        objectZoomInFade({ groupId, canvas: fabricRef.current })
      }
    }

    const setPolyActive = () => {
      if (fabricRef.current) {
        setObjectActive({
          polygons: canvasData.polygons,
          fabricRef: fabricRef.current,
        })
      }
    }

    function percentage(partialValue: number, totalValue: number) {
      return Math.round((100 * partialValue) / totalValue)
    }

    const setCanvasSize = () => {
      if (fabricRef.current) {
        const bgImageObj = fabricRef.current.backgroundImage as fabric.Image

        bgImageObj.scaleToHeight(
          parentRef?.current?.clientHeight || staticHeight
        )

        const bgImageWidth = Math.round(bgImageObj?.getScaledWidth())
        const parentWidth = Math.round(parentRef?.current?.clientWidth || 0)

        if (percentage(bgImageWidth, parentWidth) > 100) {
          bgImageObj.scaleToWidth(
            parentRef?.current?.clientWidth || staticWidth
          )
        }

        fabricRef.current?.setWidth(
          bgImageObj?.getScaledWidth() ||
            parentRef?.current?.clientWidth ||
            staticWidth
        )
        fabricRef.current?.setHeight(
          bgImageObj?.getScaledHeight() ||
            parentRef?.current?.clientHeight ||
            staticHeight
        )

        fabricRef.current?.renderAll()
      }
    }

    const drawCanvas = () => {
      if (fabricRef.current) {
        setRendering(true)

        fabric.Image.fromURL(
          AssetHandler({
            url: canvasData.image,
            type: 'new',
            noSpliceUrl: true,
          }),
          (oImg) => {
            const setImage = FabricOperations(fabricRef?.current).setImageState(
              oImg,
              ratio
            )
            setImage.animate('opacity', 1, {
              duration: 300,
              onChange: () => {
                fabricRef.current?.setBackgroundImage(
                  setImage,
                  fabricRef.current?.renderAll.bind(fabricRef.current)
                )
                cleanObjects()
              },
              onComplete: () => {
                setCanvasSize()
                if (canvasData?.polygons) {
                  setBgImage(setImage)
                }
              },
            })
          }
        )
      }
    }

    const setCanvas = () => {
      if (fabricRef.current) {
        if (bgImage) {
          bgImage.animate('opacity', 0, {
            duration: 300,
            onChange: () => {
              cleanObjects()
              fabricRef.current?.setBackgroundImage(
                bgImage,
                fabricRef.current?.renderAll.bind(fabricRef.current)
              )
            },
            onComplete: () => {
              drawCanvas()
              setImageLoaded?.(true)
            },
          })
          return
        }
        cleanObjects()
        drawCanvas()
        setCanWindowRender(true)
      }
    }

    const initCanvas = () => {
      const canvasWrapper = parentRef?.current?.parentElement
      fabric.Object.prototype.objectCaching = false
      fabricRef.current = new fabric.Canvas(canvasRef?.current, {
        width: canvasWrapper?.clientWidth || staticWidth,
        height: canvasWrapper?.clientHeight || staticHeight,
        selection: false,
        preserveObjectStacking: true,
        perPixelTargetFind: true,
        hoverCursor: 'pointer',
        enableRetinaScaling: true,
      })
      setTimeout(() => {
        setCanvas()
      }, 100)
    }

    const initializedCanvasSizeWithContainer = React.useRef(false)
    React.useEffect(() => {
      if (
        !adjustCanvasSizeWithContainer ||
        initializedCanvasSizeWithContainer.current ||
        !bgImage
      ) {
        return
      }
      setCanvas()
      initializedCanvasSizeWithContainer.current = true
    }, [bgImage, adjustCanvasSizeWithContainer])

    useImperativeHandle(ref, () => ({
      artificialTrigger,
      setPolyActive,
      setCanvas,
    }))

    useEffect(() => {
      if (isRendering) {
        isRendering(rendering)
      }
    }, [rendering])

    useEffect(() => {
      if (!canWindowRender) {
        return
      }
      if (timeoutIdScreenChange) {
        clearTimeout(timeoutIdScreenChange)
      }
      const timeoutId = setTimeout(() => {
        setCanvas()
      }, 100)
      setTimeoutIdScreenChange(timeoutId)
    }, [windowDimensions])

    const removeCanvasChildren = () => {
      const children = canvasRef?.current?.parentElement?.children
      if (children) {
        Array.from(children).forEach((res) => {
          res?.remove()
        })
      }
    }

    React.useLayoutEffect(() => {
      initCanvas()
      return () => {
        clearEvents()
        fabricRef.current?.setWidth(0)
        fabricRef.current?.setHeight(0)
        fabricRef?.current?.clear()
        fabricRef?.current?.renderAll()
        fabricRef?.current?.remove()
        removeCanvasChildren()
      }
    }, [])

    React.useLayoutEffect(() => {
      window.hasLabel = hasLabel
    }, [hasLabel])

    React.useEffect(() => {
      if (window.chevronSvg && window.arrowSvg) {
        setHasSvgArrowSmall(true)
        setHasSvgArrow(true)
        return
      }
      fabric.loadSVGFromURL(SVGChevronURL, (objects) => {
        window.chevronSvg = fabric.util.groupSVGElements(objects, {
          originX: 'center',
          originY: 'center',
          hasBorders: false,
          hasControls: false,
          objectCaching: false,
        })
        setHasSvgArrowSmall(true)
      })

      fabric.loadSVGFromURL(SVGArrowURL, (objects) => {
        window.arrowSvg = fabric.util.groupSVGElements(objects, {
          originX: 'center',
          originY: 'center',
          hasBorders: false,
          hasControls: false,
          objectCaching: false,
        })
        setHasSvgArrow(true)
      })
    }, [])

    React.useEffect(() => {
      if (bgImage && hasSvgArrowSmall && hasSvgArrow) {
        setObjectsInCanvas(bgImage)
        setEvents()
        setPolyActive()
        fabricRef.current?.renderAll()
      }
    }, [hasSvgArrowSmall, hasSvgArrow, bgImage])

    return <canvas ref={canvasRef} key={id} />
  }
)

export default CanvasInteractive
