import React, { MouseEventHandler } from 'react'
import { connect } from 'react-redux'

import { ProjectIdentity, RootStateTypeExtra } from '@store/types'

import FirebaseControlQuery from '@firebaseUtil/firebase-control-query'

import useParseMapLayerActionRoute from '@adUtilities/map-action-route-hnl-util'

import Layer from './layer'

interface LayerHnlProps {
  projectIdentity: ProjectIdentity
  label: string
  actionRoute: string
}

function LayerHnl({ projectIdentity, label, actionRoute }: LayerHnlProps) {
  const firebaseControlQuery = FirebaseControlQuery({
    ...projectIdentity,
    sessionKey: projectIdentity.sessionId,
  })

  const [parseMapActionRoute] = useParseMapLayerActionRoute()

  const { path, params, isValid } = React.useMemo(
    () => parseMapActionRoute(actionRoute),
    [actionRoute, parseMapActionRoute]
  )

  const handleClick = React.useCallback<MouseEventHandler<HTMLAnchorElement>>(
    async (e) => {
      e.preventDefault()
      firebaseControlQuery.update({
        [`houseAndLand.activePrecinctId`]: params.precinct || '',
        [`houseAndLand.activeStageId`]: params.stage || '',
        [`houseAndLand.activeLotId`]: params.lot || '',
        [`activeRoute`]: path,
      })
    },
    [path, params]
  )

  return (
    <Layer label={label} path={path} isValid={isValid} onClick={handleClick} />
  )
}

export default connect(({ projectIdentity }: RootStateTypeExtra) => ({
  projectIdentity,
}))(LayerHnl)
