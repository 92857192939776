import React from 'react'
import { connect, useDispatch } from 'react-redux'

import { setProjectIdentity } from '@store/actionSlices/projectIdentity'
import { setWhiteLabel } from '@store/actionSlices/whiteLabel'
import { ProjectIdentity, RootStateTypeExtra } from '@store/types'

import {
  selectFromResult as selectFromConfigResult,
  useGetConfigQuery,
} from '@api/config'
import { ShowcaseRoomConfig } from '@api/showcase-room'
import {
  selectFromWhiteLabelResult,
  useGetWhiteLabelsQuery,
} from '@api/white-label'

import AssetHandler from '@adUtilities/asset-handler/asset-handler'

type BackgroundStyle = {
  backgroundImage?: string
  backgroundColor?: string
}

export interface ContainerProps {
  className?: string
  children: React.ReactNode
  projectIdentity: ProjectIdentity
  showcaseRoomConfig: ShowcaseRoomConfig
  hasBottomNavigation?: boolean
  rootElementId?: string
}

function Container({
  className = '',
  children,
  projectIdentity,
  hasBottomNavigation,
  rootElementId,
  showcaseRoomConfig,
}: ContainerProps) {
  const dispatch = useDispatch()
  const containerRef = React.useRef<HTMLDivElement>(null)
  const [hasBackgroundImage, setHasBackgroundImage] = React.useState(false)

  const {
    configData,
    isLoaded: isConfigLoaded,
    isError: configHasError,
  } = useGetConfigQuery(
    { projectName: projectIdentity.projectId },
    {
      selectFromResult: selectFromConfigResult,
      skip: !projectIdentity.projectId,
    }
  )

  const {
    whiteLabelData,
    isLoaded: isWhiteLabelLoaded,
    isError: whiteLabelHasError,
  } = useGetWhiteLabelsQuery(
    { projectName: projectIdentity.projectId },
    {
      selectFromResult: selectFromWhiteLabelResult,
      skip: !projectIdentity.projectId,
    }
  )

  const getBackground = (): BackgroundStyle => {
    const {
      theme: { remoteBackground },
    } = projectIdentity
    const { type, url, color } = remoteBackground ?? {}

    if (type === 'image' && url) {
      return {
        backgroundImage: `url(${AssetHandler({
          url,
          type: 'new',
        })})`,
      }
    }

    return { backgroundColor: color ?? '#0F172A' }
  }

  React.useEffect(() => {
    const rootElem = document.getElementById(rootElementId || 'root')
    const backgroundStyle = getBackground()
    if (rootElem) {
      if (backgroundStyle.backgroundImage) {
        rootElem.style.backgroundImage = backgroundStyle.backgroundImage
        rootElem.style.backgroundSize = 'cover'
        setHasBackgroundImage(true)
      }
      if (backgroundStyle.backgroundColor) {
        rootElem.style.backgroundColor = backgroundStyle.backgroundColor
      }
      if (
        containerRef.current &&
        (backgroundStyle.backgroundImage || backgroundStyle.backgroundColor)
      ) {
        containerRef.current.style.backgroundColor = ''
        containerRef.current.style.backgroundImage = ''
      }
    }
  }, [projectIdentity])

  React.useEffect(() => {
    if (isConfigLoaded && !configHasError) {
      dispatch(
        setProjectIdentity({
          ...projectIdentity,
          ...configData,
          ...showcaseRoomConfig,
        })
      )
    }
  }, [isConfigLoaded, showcaseRoomConfig])

  React.useEffect(() => {
    if (isWhiteLabelLoaded && !whiteLabelHasError) {
      dispatch(setWhiteLabel(whiteLabelData))
    }
  }, [isWhiteLabelLoaded])

  return (
    <div
      className={`background-cover relative ml-auto h-screen ${
        hasBottomNavigation ? 'w-screen' : 'w-[70%]'
      } ${className} ${
        hasBackgroundImage &&
        'overflow-hidden bg-black/[0.35] backdrop-blur-[75px]'
      }`}
      style={{ ...getBackground() }}
      ref={containerRef}
    >
      {children}
    </div>
  )
}

export default connect(
  ({
    projectIdentity,
    showcaseRoom: showcaseRoomConfig,
  }: RootStateTypeExtra) => ({
    projectIdentity,
    showcaseRoomConfig,
  })
)(Container)
