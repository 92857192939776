import React from 'react'

import { PlayCircleSvg } from '@svg/react'

export type ActionType = 'showcase' | 'remote'

interface VideoPlaybackActionsProps {
  onPlay: (actionType: ActionType) => void
  disablePlayOnShowcaseBtn?: boolean
  autoPlayState?: boolean
}

function VideoPlaybackActions({
  onPlay,
  disablePlayOnShowcaseBtn = false,
  autoPlayState = false,
}: VideoPlaybackActionsProps) {
  const handlePlay = React.useCallback(
    (actionType: ActionType) => () => {
      onPlay(actionType)
    },
    [onPlay]
  )

  return (
    <div className="flex flex-row gap-5">
      <button
        type="button"
        className="text-md z-1 flex max-h-14 items-center rounded-md border border-mainColour bg-white px-2 py-2 font-medium tracking-widest text-mainColour disabled:cursor-not-allowed disabled:border-gray-400 disabled:bg-gray-300 disabled:text-gray-500"
        disabled={disablePlayOnShowcaseBtn || autoPlayState}
        onClick={handlePlay('showcase')}
      >
        <PlayCircleSvg size="m" className="mr-2 h-6 w-6" />
        <span>Play on Showcase</span>
      </button>
      <button
        type="button"
        className="text-md z-1 flex max-h-14 items-center rounded-md border border-mainColour bg-white px-2 py-2 font-medium tracking-widest text-mainColour disabled:cursor-not-allowed disabled:border-gray-400 disabled:bg-gray-300 disabled:text-gray-500"
        disabled={autoPlayState}
        onClick={handlePlay('remote')}
      >
        <PlayCircleSvg size="m" className="mr-2 h-6 w-6" />
        <span>Play on Remote</span>
      </button>
    </div>
  )
}

export default VideoPlaybackActions
