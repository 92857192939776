import React from 'react'
import { connect } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'

import { ShortlistState } from '@store/actionSlices/shortlist'
import { NavigationMenuKey, RouteKey } from '@store/constants'
import {
  MenuItemInterface,
  ProjectIdentity,
  RootStateTypeExtra,
} from '@store/types'

import { capitalizeFirstLetter } from '@utilities/helper'
import useHiddenNavs from '@utilities/use-hidden-navs'

import FirebaseControlQuery from '@firebaseUtil/firebase-control-query'

import { ProjectType } from '@adUtilities/constants/common'

import BottomNavigation from './bottom-navigation'
import LeftNavigation from './left-navigation'
import MENU_ITEMS from './menu-items'

interface ComponentPropsInterface {
  type: 'bottom-navigation' | 'left-navigation'
  projectIdentity: ProjectIdentity
  children?: React.ReactNode
  shortlist: ShortlistState
}

function Navigation({
  type,
  projectIdentity,
  children,
  shortlist,
}: ComponentPropsInterface) {
  const location = useLocation()
  const history = useHistory()
  const hiddenNavs = useHiddenNavs()

  const firebaseControlQuery = FirebaseControlQuery({
    ...projectIdentity,
    sessionKey: projectIdentity.sessionId,
  })

  const handleMenuClick = async (item: MenuItemInterface) => {
    const { route, routeViaFirebase } = item
    if (!route) {
      return
    }
    history.push({ pathname: `/${route}`, state: { from: location.pathname } })
    if (routeViaFirebase) {
      await firebaseControlQuery.updateRoute(route)
    }
  }

  const updateResidencesRoute = (
    residencesMenu: MenuItemInterface,
    snaploaderState: boolean,
    envisionVRState: boolean,
    projectType: ProjectType
  ): MenuItemInterface => {
    if (projectIdentity.projectName === 'the-windermere') {
      return { ...residencesMenu, route: RouteKey.ADTHREED }
    }
    if (snaploaderState)
      return { ...residencesMenu, route: RouteKey.SNAPLOADER_VIEW }
    if (envisionVRState)
      return { ...residencesMenu, route: RouteKey.THREE_D_BUILDING }
    if (projectType === ProjectType.HouseAndLand)
      return { ...residencesMenu, route: RouteKey.AREA_VIEW_HOUSE_AND_LAND }
    return residencesMenu
  }

  const navItems = React.useMemo(() => {
    const {
      navigationSettings,
      disconnect,
      showClearCacheOption,
      isSnaploaderActive,
      isEnvisionVRActive,
      type: projectType,
    } = projectIdentity

    return MENU_ITEMS.map((item: MenuItemInterface) => {
      const foundItem = navigationSettings.find(
        (navigationSetting: MenuItemInterface) =>
          navigationSetting.key === item.key
      )

      const newItem = foundItem
        ? {
            ...item,
            label: capitalizeFirstLetter(foundItem.label),
            active: foundItem.active,
            order: foundItem.order,
          }
        : item

      if (hiddenNavs.includes(item.key)) {
        newItem.active = false
      }

      if (newItem.key === NavigationMenuKey.TOOLS) {
        return { ...newItem, visibility: disconnect || showClearCacheOption }
      }

      let myItem = { ...newItem }

      if (newItem.key === NavigationMenuKey.RESIDENCES) {
        myItem = updateResidencesRoute(
          myItem,
          isSnaploaderActive,
          isEnvisionVRActive,
          projectType
        )
      }

      return myItem
    }).sort((a, b) => {
      if (!Number.isNaN(a.order ?? NaN) && !Number.isNaN(b.order ?? NaN)) {
        return Number(a.order) - Number(b.order)
      }
      return MENU_ITEMS.indexOf(a) - MENU_ITEMS.indexOf(b)
    })
  }, [projectIdentity, hiddenNavs])

  const activeRoute = React.useMemo(() => {
    const paths = location.pathname.split('/')
    return paths[1] || ''
  }, [location])

  return type === 'left-navigation' ? (
    <div className="absolute left-0 top-0 z-10 h-screen w-[30%] rounded-br-lg rounded-tr-lg bg-neutralColour shadow-nav">
      <LeftNavigation
        menuItems={navItems}
        handleClick={handleMenuClick}
        activeRoute={activeRoute}
        shortlistCount={shortlist?.shortlists?.length || 0}
      />
    </div>
  ) : (
    <div className="absolute h-full w-full">
      <BottomNavigation menuItems={navItems} handleClick={handleMenuClick}>
        {children}
      </BottomNavigation>
    </div>
  )
}

export default connect(
  ({ projectIdentity, shortlist }: RootStateTypeExtra) => ({
    shortlist,
    projectIdentity,
  })
)(Navigation)
