import { UseQueryStateResult } from '@reduxjs/toolkit/dist/query/react/buildHooks.d'
import { createApi } from '@reduxjs/toolkit/query/react'

import baseQueryInterceptor from '@api/base-query-interceptor'
import { ApiResponse } from '@api/types/api-response.type'

export interface ShortlistFormData {
  projectId?: string
  title?: string
  name?: string
  mobile?: string
  email: string
  agent?: string
  agentMobile?: string
  agentEmail?: string
  documents?: Array<ShortlistFormDocuments>
  items?: Array<ShortlistFormItems>
}

export interface ShortlistFormDocuments {
  title: string
  url: string
}

export interface ShortlistFormItems {
  name: string
  configuration: string
  price: string
  area?: string
  image: string
}

export interface ShortlistDocument {
  config?: string
  contentType: string
  driver: string
  fileName: string
  id: string
  location: string
  meta: ShortlistDocumentMeta
  name: string
  position: number
  subType?: string
  title?: string
  updated: string
}

export interface ShortlistDocumentMeta {
  logo: boolean
  map: boolean
  cover: boolean
}

export interface ShortlistAgent {
  id: string
  firstName: string
  lastName: string
  email: string
  type: 'agent' | 'partner-agent'
  showcaseEnabled: boolean
}

export interface AgentFormData {
  firstName: string
  lastName: string
  email: string
  phone: string
}

interface RegisterBuyerParams {
  firstName: string
  lastName: string
  phoneNumber: string
  email: string
}

interface RegisterBuyerResponse {
  _id: string
  email: string
}

export interface SaveShortlistParams {
  project: string
  documentIds?: string[]
  agentId?: string
  shortlistedProperties: {
    propertyId: string
    archived: boolean
    configuration?: PackageConfiguration
  }[]
  user: string
}

export interface SaveShortlistResponse {
  id: string
  propertyId: string
  propertyName: string
}

export interface PackageConfiguration {
  packageId?: string
  floorPlanId?: string
  facadeId?: string
  facadeColorId?: string
  internalThemeId?: string
  internalColorSchemeId?: string
}

export const shortlistedApi = createApi({
  reducerPath: 'shortlistedApi',
  baseQuery: baseQueryInterceptor,
  tagTypes: ['AgentsList'],
  endpoints: (builder) => ({
    postShortlisted: builder.mutation<
      ApiResponse<Array<ShortlistDocument>>,
      ShortlistFormData
    >({
      query: (params: ShortlistFormData) => ({
        url: `/v1/${params.projectId}/shortlist`,
        method: 'post',
        body: {
          ...params,
        },
      }),
    }),
    getShortlistDocuments: builder.query<
      ApiResponse<Array<ShortlistDocument>>,
      { projectId: string }
    >({
      query: (params: { projectId: string }) => ({
        url: `/v1/${params.projectId}/shortlist/documents`,
        method: 'get',
      }),
    }),
    getAgentsList: builder.query<
      ApiResponse<Array<ShortlistAgent>>,
      {
        projectId: string
        type?: ShortlistAgent['type']
        showcaseEnabled?: ShortlistAgent['showcaseEnabled']
      }
    >({
      query: (params: {
        projectId: string
        type?: ShortlistAgent['type']
        showcaseEnabled?: ShortlistAgent['showcaseEnabled']
      }) => ({
        url: `/v2/${params.projectId}/agents/list`,
        method: 'get',
        params: {
          type: params.type,
          showcaseEnabled: params.showcaseEnabled,
        },
      }),
      providesTags: ['AgentsList'],
    }),
    getAgentByEmailOrId: builder.query<
      ApiResponse<ShortlistAgent>,
      { projectId: string; emailOrId?: string; id?: string }
    >({
      query: (params: { projectId: string; emailOrId: string }) => ({
        url: `/v2/${params.projectId}/agents/${params.emailOrId}`,
        method: 'get',
      }),
    }),
    createShortlistAgent: builder.mutation<
      ApiResponse<ShortlistAgent>,
      AgentFormData & { projectId: string }
    >({
      query: (params: AgentFormData & { projectId: string }) => ({
        url: `/v2/${params.projectId}/agents`,
        method: 'post',
        body: {
          ...params,
          type: 'partner-agent',
        },
      }),
      invalidatesTags: ['AgentsList'],
    }),
    registerBuyer: builder.mutation<
      ApiResponse<RegisterBuyerResponse>,
      RegisterBuyerParams
    >({
      query: (params: RegisterBuyerParams) => ({
        url: `/v1/user`,
        method: 'post',
        body: {
          firstName: params.firstName,
          lastName: params.lastName,
          phone_number: params.phoneNumber,
          email: params.email,
        },
      }),
    }),
    saveShortlist: builder.mutation<
      ApiResponse<SaveShortlistResponse>,
      SaveShortlistParams
    >({
      query: (params: SaveShortlistParams) => ({
        url: `/v3/shortlists`,
        method: 'post',
        body: {
          project: params.project,
          shortlistedProperties: params.shortlistedProperties,
          documents: params.documentIds || [],
          agentId: params.agentId || null,
          user: params.user,
        },
      }),
    }),
  }),
})

export const selectFromResult = ({
  data,
  isLoading,
  isError,
  status,
}: UseQueryStateResult<any, any>) => ({
  data: data?.data || [],
  isLoaded: !isLoading,
  isError,
  status,
})

export const selectFromShortlistAgentsResult = ({
  data,
  isLoading,
  isError,
  status,
}: UseQueryStateResult<any, any>): {
  data: Array<ShortlistAgent>
  isLoaded: boolean
  isError: boolean
  status: string
} => ({
  data: data?.data || [],
  isLoaded: !isLoading,
  isError,
  status,
})

export const selectFromShortlistDocumentsResult = ({
  data,
  isLoading,
  isError,
  status,
  error,
}: UseQueryStateResult<any, any>): {
  data: Array<ShortlistDocument>
  isLoaded: boolean
  isError: boolean
  status: string
  errorStatus: number
} => ({
  data: data?.data || [],
  isLoaded: !isLoading,
  isError,
  status,
  errorStatus: error?.status,
})

export const {
  usePostShortlistedMutation,
  useGetShortlistDocumentsQuery,
  useGetAgentsListQuery,
  useLazyGetAgentByEmailOrIdQuery,
  useCreateShortlistAgentMutation,
  useRegisterBuyerMutation,
  useSaveShortlistMutation,
} = shortlistedApi
