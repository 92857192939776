import { UseQueryStateResult } from '@reduxjs/toolkit/dist/query/react/buildHooks.d'
import { createApi } from '@reduxjs/toolkit/query/react'

import {
  EnvisionVRConfigurationInterface,
  GallerySettingsInterface,
  HouseAndLandConfigurationInterface,
  IdlePageInterface,
  LogoInterface,
  PriceRange,
  PropertyConfigurationInterface,
  PropertyGalleryTabInterface,
  RemoteInteractiveBuildingInterface,
  StatusColors,
  StatusLabels,
  ThemeConfigInterface,
  VendorsInterface,
} from '@store/types'

import baseQueryInterceptor from '@api/base-query-interceptor'
import { ApiResponse } from '@api/types/api-response.type'

import { ProjectType } from '@adUtilities/constants/common'

export interface ThreeDViewer {
  url: string
  active: boolean
}

export type InSpace = ThreeDViewer

export interface SnaploaderInterface {
  active: boolean
  sceneSnapId: string
  sceneModelId: string
}

export type SimplifiedThemeConfig = Omit<
  ThemeConfigInterface,
  | 'remoteBackground'
  | 'remoteVisionBackground'
  | 'remoteVisionLogo'
  | 'remoteTertiaryColour'
>

export interface IdleTimeControl {
  active: boolean
  duration: number
}

export interface MarkerColourInterface {
  available: string
  sold: string
  area: string
  levels: string
}

export interface ProjectConfigInterface {
  hideFilter: boolean
  hideInfoPanelAspect: boolean
  hidePropertyInfoName: boolean
  hideSoldPrice: boolean
  idlePage: IdlePageInterface
  logo: LogoInterface
  longhand: boolean
  onlyShowPropertyGalleries: boolean
  propertyGalleryControlStyle: string
  propertyGalleryTab: PropertyGalleryTabInterface
  propertyPrefix: string
  showPrice: boolean
  showcaseActive: boolean
  sidePanelTitle: string
  markerColour: MarkerColourInterface
  isSnaploaderActive: boolean
  isEnvisionVRActive: boolean
  hasThreeDViewer: boolean
  prices: PriceRange
  aspects: string[]
  unitTypes: string[]
  propertyConfig: PropertyConfigurationInterface
  houseAndLandConfig: HouseAndLandConfigurationInterface
  gallerySettings: GallerySettingsInterface
  disableSoldProperty: boolean
  type: ProjectType
  statusLabels: StatusLabels
  statusColors: StatusColors
  lightUpBuildingModelOnFilter: boolean
  remoteInteractiveBuilding: RemoteInteractiveBuildingInterface
}

export interface GeneralConfigData {
  hideFilter: boolean
  hideInfoPanelAspect: boolean
  hidePropertyInfoName: boolean
  hideSoldPrice: boolean
  idlePage: IdlePageInterface
  logo: LogoInterface
  longhand: boolean
  onlyShowPropertyGalleries: boolean
  propertyGalleryControlStyle: string
  propertyGalleryTab: PropertyGalleryTabInterface
  propertyPrefix: string
  showPrice: boolean
  showcaseActive: boolean
  sidePanelTitle: string
}

export interface ConfigPayload {
  canonicalName: string
  id: string
  status: string
  title: string
  type: ProjectType
  uuid: string
  config: {
    disableSoldProperty: boolean
    lightUpBuildingModelOnFilter: boolean
    filter: {
      prices: PriceRange
      aspects: Array<string>
      unitTypes: Array<string>
      propertyConfigurationSettings: PropertyConfigurationInterface
      houseAndLandConfig: HouseAndLandConfigurationInterface
    }
    gallerySettings: GallerySettingsInterface
    generalConfig: GeneralConfigData
    markerColourSettings: MarkerColourInterface
    statusColors: StatusColors
    statusLabels: StatusLabels
    vendors: VendorsInterface
    remoteInteractiveBuilding: RemoteInteractiveBuildingInterface
  }
}

type ConfigType = {
  projectName: string
}

export const configApi = createApi({
  reducerPath: 'configApi',
  baseQuery: baseQueryInterceptor,
  endpoints: (builder) => ({
    getConfig: builder.query<ApiResponse<ConfigPayload>, ConfigType>({
      query: (params: ConfigType) => ({
        url: `/v3/${params.projectName}/configs`,
        method: 'get',
      }),
    }),
  }),
})

const snaploaderState = (snaploaderData: SnaploaderInterface): boolean => {
  const active = snaploaderData?.active || false
  const sceneSnapId = snaploaderData?.sceneSnapId || ''
  const sceneModelId = snaploaderData?.sceneModelId || ''

  return active && sceneSnapId !== '' && sceneModelId !== ''
}

const envisionVRState = (
  envisionVRData: EnvisionVRConfigurationInterface
): boolean => {
  const active = envisionVRData?.active || false
  const url = envisionVRData?.url || ''

  return active && url !== ''
}

const threeDViewerState = (threeDViewer: ThreeDViewer | undefined): boolean => {
  if (!threeDViewer) {
    return false
  }

  const active = threeDViewer?.active || false
  const url = threeDViewer?.url || ''

  return active && url !== ''
}

export const selectFromResult = ({
  data,
  isLoading,
  status,
  error,
  isError,
}: UseQueryStateResult<any, any>): {
  configData: ProjectConfigInterface
  isLoaded: boolean
  hasError: boolean
  status: string
  isError: boolean
} => ({
  configData: {
    hideFilter: data?.data?.config?.generalConfig?.hideFilter || false,
    hideInfoPanelAspect:
      data?.data?.config?.generalConfig?.hideInfoPanelAspect || false,
    hidePropertyInfoName:
      data?.data?.config?.generalConfig?.hidePropertyInfoName || false,
    hideSoldPrice: data?.data?.config?.generalConfig?.hideSoldPrice || false,
    idlePage: data?.data?.config?.generalConfig?.idlePage || {},
    logo: data?.data?.config?.generalConfig?.logo || {},
    longhand: data?.data?.config?.generalConfig?.longhand || false,
    onlyShowPropertyGalleries:
      data?.data?.config?.generalConfig?.onlyShowPropertyGalleries || false,
    propertyGalleryControlStyle:
      data?.data?.config?.generalConfig?.propertyGalleryControlStyle || '',
    propertyGalleryTab:
      data?.data?.config?.generalConfig?.propertyGalleryTab || {},
    propertyPrefix: data?.data?.config?.generalConfig?.propertyPrefix || '',
    showPrice: data?.data?.config?.generalConfig?.showPrice || false,
    showcaseActive: data?.data?.config?.project?.showcaseActive || false,
    sidePanelTitle: data?.data?.config?.generalConfig?.sidePanelTitle || '',
    markerColour: data?.data?.config?.markerColorSettings || {},
    isSnaploaderActive: snaploaderState(
      data?.data?.config?.vendors?.snaploader || {}
    ),
    isEnvisionVRActive: envisionVRState(
      data?.data?.config?.vendors?.envisionVr || {}
    ),
    hasThreeDViewer: threeDViewerState(
      data?.data?.config?.vendors?.envisionVr || {}
    ),
    prices: data?.data?.config?.filter?.prices || {},
    aspects: data?.data?.config?.filter?.aspects || [],
    unitTypes: data?.data?.config?.filter?.unitTypes || [],
    propertyConfig:
      data?.data?.config?.filter?.propertyConfigurationSettings || {},
    houseAndLandConfig: data?.data?.config?.filter?.houseAndLandConfig || {},
    gallerySettings: data?.data?.config?.gallerySettings || {},
    disableSoldProperty: data?.data?.config?.disableSoldProperty || false,
    type: data?.data?.type || ProjectType.Apartment,
    statusLabels: data?.data?.config?.statusLabels || {},
    statusColors: data?.data?.config?.statusColors || {},
    lightUpBuildingModelOnFilter:
      data?.data?.config?.lightUpBuildingModelOnFilter || false,
    remoteInteractiveBuilding:
      data?.data?.config?.remoteInteractiveBuilding || {},
  },
  isLoaded: !isLoading,
  hasError: error !== undefined,
  status,
  isError,
})

export const { useGetConfigQuery } = configApi
