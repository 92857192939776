import React, { BaseSyntheticEvent } from 'react'
import { connect } from 'react-redux'

import {
  GalleryItemInterface,
  ProjectIdentity,
  RootStateTypeExtra,
} from '@store/types'

import Container from '@components/container'
import DataHandler from '@components/data-handler'
import GallerySkeleton from '@components/gallery-skeleton'

import {
  selectFromResult as selectFromTeamResult,
  useGetTeamQuery,
} from '@api/team'

import FirebaseControlQuery from '@firebaseUtil/firebase-control-query'
import { SessionMap } from '@firebaseUtil/types'

import AssetHandler from '@adUtilities/asset-handler/asset-handler'

interface PagePropsInterface {
  session: SessionMap | undefined
  projectIdentity: ProjectIdentity
}

function Team({ session, projectIdentity }: PagePropsInterface) {
  const PLACEHOLDER_IMAGE = process.env.REACT_APP_PLACEHOLDER_IMAGE_URL

  const firebaseControlQuery = FirebaseControlQuery({
    ...projectIdentity,
    sessionKey: projectIdentity.sessionId,
  })

  const [activeGalleryItemIndex, setActiveGalleryItemIndex] = React.useState(0)

  const teamPayload = useGetTeamQuery(
    { projectName: projectIdentity.projectId },
    { selectFromResult: selectFromTeamResult }
  )

  const handleItemClick = async (itemId: string) => {
    await firebaseControlQuery.update({
      [`team.galleryControl.activeItemID`]: itemId,
    })
  }

  const onImageError = (e: BaseSyntheticEvent) => {
    e.target.src = PLACEHOLDER_IMAGE
  }

  React.useEffect(() => {
    if (session) {
      const {
        team: {
          galleryControl: { activeItemID: activeItemIDFirebase },
        },
      } = session
      const activeItemIndexFirebase = teamPayload?.teamData?.findIndex(
        (item) => item.id === activeItemIDFirebase
      )
      setActiveGalleryItemIndex(
        activeItemIndexFirebase !== -1 ? activeItemIndexFirebase : 0
      )
    }
  }, [session])

  return (
    <Container>
      <DataHandler
        payloads={{ ...teamPayload, data: teamPayload.teamData }}
        skeletonFrame={<GallerySkeleton />}
      >
        <div className="h-full w-full px-4">
          <div className="flex h-top-bar w-full items-center justify-center text-neutralColour">
            <div className="text-title font-medium">Team</div>
          </div>
          <div className="no-scrollbar h-page-body w-full overflow-auto">
            <div className="grid grid-cols-3 gap-4">
              {teamPayload.teamData.map(
                (item: GalleryItemInterface, index: number) => (
                  <div
                    className={`h-full w-full rounded-lg bg-gradient shadow-standard ${
                      activeGalleryItemIndex === index ? '' : 'cursor-pointer'
                    }`}
                    onClick={() => handleItemClick(item.id)}
                    role="none"
                    key={item.id}
                  >
                    <div className="flex h-48 w-full items-center justify-center rounded-lg p-2">
                      <img
                        className="h-auto max-w-full rounded-lg object-cover"
                        loading="lazy"
                        src={
                          AssetHandler({
                            url: item.imageSource,
                            type: item.assetType || 'legacy',
                          }) ?? PLACEHOLDER_IMAGE
                        }
                        alt={item.title}
                        onError={onImageError}
                      />
                    </div>
                    <div
                      title={item.title || 'Untitled'}
                      className={`truncate rounded-b-lg p-4 text-default ${
                        activeGalleryItemIndex === index
                          ? 'bg-secondaryColour text-mainColour'
                          : 'bg-transparent text-neutralColour'
                      }`}
                    >
                      {item.title || 'Untitled'}
                    </div>
                  </div>
                )
              )}
            </div>
          </div>
        </div>
      </DataHandler>
    </Container>
  )
}

export default connect(
  ({ projectIdentity, firestore: { session } }: RootStateTypeExtra) => ({
    session,
    projectIdentity,
  })
)(Team)
