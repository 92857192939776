import React from 'react'

import { DataNotFound, ErrorHandler } from './errors'

export interface Payload {
  isLoaded: boolean
  isError: boolean
  status: string
  data: any
  errorStatus?: number
}

export interface DataHandlerProps {
  children: React.ReactNode
  skeletonFrame?: React.ReactNode
  className?: string
  payload: Payload
  message?: string
  showDefaultNotFound?: boolean
}

// eslint-disable-next-line no-shadow
export enum Status {
  UNINITIALIZED = 'uninitialized',
  PENDING = 'pending',
  FULFILLED = 'fulfilled',
  REJECTED = 'rejected',
}

function DataHandler({
  children,
  skeletonFrame,
  className,
  payload,
  message = 'This page needs to be setup',
  showDefaultNotFound = true,
}: DataHandlerProps) {
  const [isLoaded, setIsLoaded] = React.useState(false)
  const [hasData, sethasData] = React.useState(true)

  const checkData = <T,>(data: T) => {
    if (!data) {
      sethasData(false)
      return false
    }
    if (Array.isArray(data)) {
      sethasData(data.length > 0)
      return data.length > 0
    }
    if (typeof data === 'object') {
      return Object.keys(data).length > 0
    }
    sethasData(true)
    return true
  }

  const handleInitialLoad = () => {
    const { status, data } = payload
    if (status === Status.FULFILLED || status === Status.REJECTED) {
      setIsLoaded(true)
      checkData(data)
    }
  }

  const handleRouteSwitch = () => {
    const { status, data } = payload
    if (status === Status.UNINITIALIZED && checkData(data)) {
      setIsLoaded(true)
    }
  }

  React.useEffect(() => {
    handleInitialLoad()
    handleRouteSwitch()
  }, [payload])

  if (!isLoaded && !payload.isError) {
    if (skeletonFrame) {
      return <>{skeletonFrame}</>
    }

    return (
      <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
        <div className="h-16 w-16 animate-spin rounded-full border-4 border-solid border-mainColour border-t-transparent" />
      </div>
    )
  }

  if (isLoaded && !hasData) {
    if (payload.isError) {
      if (payload.errorStatus === 404) {
        return <DataNotFound message={message} />
      }
      return <ErrorHandler />
    }
    return showDefaultNotFound ? (
      <DataNotFound message={message} />
    ) : (
      <>{children}</>
    )
  }

  return <div className={`h-full w-full ${className ?? ''}`}>{children}</div>
}

export default DataHandler
