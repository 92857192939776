import React, { useEffect, useMemo, useRef, useState } from 'react'
import { connect } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'

import {
  ExtendedUnitFilterOptions,
  ProjectIdentity,
  RootStateTypeExtra,
} from '@store/types'

import Container from '@components/container/v2'
import { Status } from '@components/data-handler/data-handler'
import DataHandler from '@components/data-handler/v2'
import BuildingFilter from '@components/filter/v2/building-filter'
import FILTER_INITIAL_STATE from '@components/filter/v2/filterState'
import { PannellumDataInterface } from '@components/showcase-pannellum/types'

import FloorplateView from '@pages/properties-view/apartments/v2/floorplate-view'
import BuildingSkeleton from '@pages/properties-view/apartments/v2/skeleton/building-skeleton'
import UnitView from '@pages/properties-view/apartments/v2/unit-view'
import UnitsSidePanel from '@pages/properties-view/apartments/v2/units-side-panel'

import {
  BuildingInterface,
  selectFromResult as selectFromBuildingResult,
  useGetBuildingQuery,
} from '@api/building'
import {
  InteractivePlanData,
  selectFromResult as selectFromInteractivePlanResult,
  useGetInteractivePlanQuery,
} from '@api/interactive-plan'
import {
  selectFromResult as selectFromPanoramicResult,
  useGetPanoramicQuery,
} from '@api/panoramic'

import { SessionMap } from '@firebaseUtil/types'

import { getQueryStringParams } from '@adUtilities/helper'
import { Unit } from '@adUtilities/types'

export interface BuildingProps {
  session: SessionMap | undefined
  projectIdentity: ProjectIdentity
  onFullScreenToggle?: React.Dispatch<React.SetStateAction<boolean | undefined>>
}

function Building({
  session,
  projectIdentity,
  onFullScreenToggle,
}: BuildingProps) {
  const history = useHistory()
  const location = useLocation()

  const urlParams = useRef(getQueryStringParams(location.search))

  const [unit, setUnit] = useState<Unit>()
  const [interactivePlan, setInteractivePlan] = useState<InteractivePlanData>({
    areaView: {
      image: '',
      polygons: [],
    },
    blocks: {},
    floorplan: {},
    precinct: {},
    stages: {},
  })
  const [building, setBuilding] = useState<BuildingInterface>({
    levels: [],
    blockOrders: [],
  })
  const [panoramic, setPanoramic] = useState<PannellumDataInterface[]>([])

  const [activeBlock, setActiveBlock] = React.useState<string>('')
  const [activeLevel, setActiveLevel] = React.useState<string>('')
  const [activeUnit, setActiveUnit] = React.useState<string>('')

  const [unitFilter, setUnitFilter] =
    React.useState<ExtendedUnitFilterOptions>(FILTER_INITIAL_STATE)

  const [fullScreenToggle, setFullScreenToggle] = useState<boolean>(false)
  const [unitSidePanelState, setUnitSidePanelState] = useState<boolean>(true)
  const [isFilterOpen, toggleFilter] = useState<boolean>(false)

  const interactivePlanPayload = useGetInteractivePlanQuery(
    { projectName: projectIdentity.projectName },
    { selectFromResult: selectFromInteractivePlanResult }
  )

  const buildingPayload = useGetBuildingQuery(
    { projectName: projectIdentity.projectName },
    { selectFromResult: selectFromBuildingResult }
  )

  const panoramicPayload = useGetPanoramicQuery(
    { projectName: projectIdentity.projectName },
    { selectFromResult: selectFromPanoramicResult }
  )

  const apiStatus = useMemo(() => {
    const { FULFILLED, REJECTED, PENDING } = Status
    const { status: interactivePayloadStatus } = interactivePlanPayload
    const { status: buildingPayloadStatus } = buildingPayload

    if (
      interactivePayloadStatus === FULFILLED &&
      buildingPayloadStatus === FULFILLED
    ) {
      return FULFILLED
    }

    if (
      interactivePayloadStatus === REJECTED ||
      buildingPayloadStatus === REJECTED
    ) {
      return REJECTED
    }

    return PENDING
  }, [interactivePlanPayload.status, buildingPayload.status])

  const floorplanLength = useMemo(
    () => Object.keys(interactivePlan.floorplan).length,
    [interactivePlan.floorplan]
  )

  const handleFullScreenToggle = (arg: boolean) => {
    setFullScreenToggle(arg)
    setUnitSidePanelState(!arg)
    onFullScreenToggle?.(arg)
  }

  const findUnit = () => {
    building.levels.forEach((level) => {
      const foundUnit = level.data.find(
        (unt) => unt.name.replace(/\s/g, '') === activeUnit.replace(/\s/g, '')
      )
      if (foundUnit) {
        setUnit(foundUnit)
      }
    })
  }

  const resetUnit = () => {
    setUnit(undefined)
  }

  useEffect(() => {
    if (building.levels.length && activeUnit) {
      findUnit()
    }
    if (!activeUnit) {
      resetUnit()
    }
  }, [building, activeUnit])

  useEffect(() => {
    const { maps } = interactivePlanPayload
    if (!floorplanLength && maps.areaView) {
      setInteractivePlan(maps)
    }
  }, [interactivePlanPayload, interactivePlan.floorplan])

  useEffect(() => {
    const { levels, blockOrders } = buildingPayload

    if (!building.levels.length && levels.length) {
      setBuilding((prev) => ({
        ...prev,
        levels,
        blockOrders,
      }))
    }
  }, [buildingPayload, building])

  useEffect(() => {
    const { panoramicData, isLoaded } = panoramicPayload
    if (panoramic.length === 0 && isLoaded && panoramicData.length > 0) {
      setPanoramic(panoramicData)
    }
  }, [panoramicPayload])

  useEffect(() => () => onFullScreenToggle?.(undefined), [onFullScreenToggle])

  useEffect(() => {
    if (
      !(
        urlParams.current?.block ||
        urlParams.current?.level ||
        urlParams.current?.unit
      )
    ) {
      return
    }
    history.replace(location.pathname)
  }, [location.pathname])

  useEffect(() => {
    if (!session) {
      return
    }

    const {
      building: { activeBlock, activeLevel, activeUnit, unitFilter },
    } = session

    setActiveBlock(activeBlock)
    setActiveLevel(activeLevel)
    setActiveUnit(activeUnit)

    setUnitFilter(unitFilter)
  }, [session])

  return (
    <Container className="flex" fullScreenMode={fullScreenToggle}>
      <DataHandler
        payload={{
          ...buildingPayload,
          data: building.levels,
          status: apiStatus,
        }}
        skeletonFrame={<BuildingSkeleton />}
        className="flex"
      >
        <div
          className={`h-full ${
            !unitSidePanelState ? '!w-0 opacity-0' : 'opacity-100'
          } w-[297px] flex-grow-0 transition-all duration-300 ease-in-out`}
        >
          <UnitsSidePanel
            building={building}
            blocks={interactivePlan.blocks}
            unitFilter={unitFilter}
            filterPopupToggle={isFilterOpen}
            setFilterPopupToggle={(arg: boolean) => {
              toggleFilter(arg)
            }}
          />

          <BuildingFilter
            isOpen={isFilterOpen}
            building={building}
            blocks={interactivePlan.blocks}
            firebaseUnitFilter={unitFilter}
            firebaseActiveBlock={activeBlock}
            firebaseActiveLevel={activeLevel}
            toggle={toggleFilter}
          />
        </div>

        <div
          className={`z-10 h-full flex-1 ${
            activeUnit ? 'transition-all duration-300 ease-in-out' : ''
          }`}
        >
          {activeUnit && unit ? (
            <UnitView
              key={unit.id}
              unit={unit}
              panoramic={panoramic}
              fullScreenToggle={fullScreenToggle}
              setFullScreenToggle={handleFullScreenToggle}
              getVideoPlayerState={handleFullScreenToggle}
            />
          ) : (
            <DataHandler
              payload={{
                ...interactivePlanPayload,
                data: floorplanLength,
              }}
              skeletonFrame={<BuildingSkeleton />}
              className="relative"
            >
              <FloorplateView
                firebaseActiveBlock={activeBlock}
                firebaseActiveLevel={activeLevel}
                building={building}
                floorplan={interactivePlan.floorplan}
                blocks={interactivePlan.blocks}
                unitFilter={unitFilter}
                panoramic={panoramic}
                fullScreenToggle={fullScreenToggle}
                setFullScreenToggle={handleFullScreenToggle}
                getVideoPlayerState={handleFullScreenToggle}
              />
            </DataHandler>
          )}
        </div>
      </DataHandler>
    </Container>
  )
}

export default connect(
  ({ projectIdentity, firestore: { session } }: RootStateTypeExtra) => ({
    session,
    projectIdentity,
  })
)(Building)
