import React, { MouseEventHandler } from 'react'
import { connect } from 'react-redux'

import { ProjectIdentity, RootStateTypeExtra } from '@store/types'

import FirebaseControlQuery from '@firebaseUtil/firebase-control-query'

import useParseMapLayerActionRoute from '@adUtilities/map-action-route-util'

import Layer from './layer'

interface LayerApartmentProps {
  projectIdentity: ProjectIdentity
  label: string
  actionRoute: string
}

function LayerApartment({
  projectIdentity,
  label,
  actionRoute,
}: LayerApartmentProps) {
  const firebaseControlQuery = FirebaseControlQuery({
    ...projectIdentity,
    sessionKey: projectIdentity.sessionId,
  })

  const [parseMapActionRoute] = useParseMapLayerActionRoute()
  const { path, params, isValid } = React.useMemo(
    () => parseMapActionRoute(actionRoute),
    [actionRoute, parseMapActionRoute]
  )

  const handleClick = React.useCallback<MouseEventHandler<HTMLAnchorElement>>(
    async (e) => {
      e.preventDefault()
      firebaseControlQuery.update({
        [`building.activeBlock`]: params.block || '',
        [`building.activeLevel`]: params.level || '',
        [`building.activeUnit`]: params.unit || '',
        [`activeRoute`]: path,
      })
    },
    [path, params]
  )

  return (
    <Layer label={label} path={path} isValid={isValid} onClick={handleClick} />
  )
}

export default connect(({ projectIdentity }: RootStateTypeExtra) => ({
  projectIdentity,
}))(LayerApartment)
