import React from 'react'
import { CSSTransition, TransitionGroup } from 'react-transition-group'

import { GalleryInterface } from '@store/types'

import {
  CarouselItem,
  WithCarouselControl,
} from '@components/carousel-handler/elements'

interface CarouselHandlerProps {
  carousel: GalleryInterface
  roundedControl?: boolean
  hideControls?: boolean
  carouselItemCursorClassName?: string
  hasBlurredEffect?: boolean
  imgClass?: string
  activeIndex: number
  onItemClick: (index: number) => void
  onChangeActiveItemIndex: (index: number) => void
  onPlayClick: (index: number) => void
  showPlay: boolean
}

function CarouselHandler({
  carousel,
  roundedControl,
  hideControls,
  carouselItemCursorClassName,
  imgClass,
  hasBlurredEffect,
  activeIndex,
  onItemClick,
  onChangeActiveItemIndex,
  onPlayClick,
  showPlay,
}: CarouselHandlerProps) {
  const activeItem = React.useMemo(
    () => carousel.items[activeIndex],
    [carousel, activeIndex]
  )

  const handleChangeActiveItemIndex = React.useCallback(
    (index: number) => {
      onChangeActiveItemIndex(index)
    },
    [onChangeActiveItemIndex]
  )

  return (
    <div className="relative h-full w-full">
      <WithCarouselControl
        activeItemIndex={activeIndex}
        onChangeActiveItemIndex={handleChangeActiveItemIndex}
        itemsCount={carousel.items.length}
        rounded={roundedControl}
        hideControls={hideControls}
      >
        <TransitionGroup component={null}>
          <CSSTransition
            key={`${activeIndex}-${activeItem?.id}`}
            classNames="transition-fade"
            timeout={300}
          >
            <div
              className="absolute inset-0 h-full w-full"
              key={`${activeIndex}-${activeItem?.id}`}
            >
              <CarouselItem
                carouselData={{
                  index: activeIndex,
                  item: activeItem,
                }}
                onItemClick={onItemClick}
                showPlay={showPlay}
                onPlayClick={onPlayClick}
                cursorClassName={carouselItemCursorClassName}
                imgClass={imgClass}
                hasBlurredEffect={hasBlurredEffect}
              />
            </div>
          </CSSTransition>
        </TransitionGroup>
      </WithCarouselControl>
    </div>
  )
}

export default CarouselHandler
