import { InteractiveMapData } from '@api/interactive-map'

const DEFAULT_MAP_STACK_VALUE: InteractiveMapData = {
  src: '',
  layers: [],
  storyMode: false,
}

export { DEFAULT_MAP_STACK_VALUE }

export default DEFAULT_MAP_STACK_VALUE
