import { ExtendedUnitFilterOptions } from '@store/types'

const FILTER_INITIAL_STATE: ExtendedUnitFilterOptions = {
  range: {
    min: 'Any',
    max: 'Any',
  },
  aspect: [],
  unitType: 'Any',
  configurations: {
    bed: {
      min: 'Any',
      max: 'Any',
    },
    study: {
      min: 'Any',
      max: 'Any',
    },
    bath: {
      min: 'Any',
      max: 'Any',
    },
    powderRoom: {
      min: 'Any',
      max: 'Any',
    },
    car: {
      min: 'Any',
      max: 'Any',
    },
  },
  showAvailable: false,
  levelsShowLabels: false,
  unitsShowLabels: false,
  anyLevel: true,
  apply: false,
}

export default FILTER_INITIAL_STATE
