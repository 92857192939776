import React from 'react'
import { connect } from 'react-redux'

import { ProjectIdentity, RootStateTypeExtra } from '@store/types'

import FirebaseControlQuery from '@firebaseUtil/firebase-control-query'

import { ArrowSvg } from '@svg/react'

interface ComponentProps {
  projectIdentity: ProjectIdentity
  activeLotId: string
  activeStageId: string
}

function BackButton({
  projectIdentity,
  activeLotId,
  activeStageId,
}: ComponentProps) {
  const firebaseControlQuery = FirebaseControlQuery({
    ...projectIdentity,
    sessionKey: projectIdentity.sessionId,
  })

  const PRECINCT_VIEW = 'precinct'

  const handleBackNav = () => {
    if (activeLotId) {
      firebaseControlQuery.update({
        [`houseAndLand.activeLotId`]: '',
      })
      return
    }
    if (activeStageId) {
      firebaseControlQuery.update({
        [`houseAndLand.activeStageId`]: '',
      })
      firebaseControlQuery.updateRoute(PRECINCT_VIEW)
    }
  }

  return (
    <button
      className="rounded-full border-2 border-zinc-400 p-1"
      type="button"
      onClick={() => handleBackNav()}
    >
      <ArrowSvg className="h-5 w-5 stroke-zinc-400" rotate="left" />
    </button>
  )
}

export default connect(({ projectIdentity }: RootStateTypeExtra) => ({
  projectIdentity,
}))(BackButton)
