import 'firebase/compat/firestore'
import React from 'react'
import { createRoot } from 'react-dom/client'
import { ReactNotifications } from 'react-notifications-component'
import { Provider } from 'react-redux'
import { BrowserRouter as Router } from 'react-router-dom'

import App from '@src/app'

import Store from '@store/store'

import LoadingIndicator from '@components/loading-indicator'

import './index.css'
import reportWebVitals from './reportWebVitals'

const root = createRoot(document.getElementById('root') as HTMLElement)

root.render(
  <Provider store={Store.store}>
    <Router>
      <ReactNotifications />
      <App />
      <LoadingIndicator />
    </Router>
  </Provider>
)
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
