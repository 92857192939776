import React, { useEffect, useMemo, useRef, useState } from 'react'
import { connect } from 'react-redux'

import { RootStateType } from '@store/store'
import {
  DropDownOption,
  ExtendedUnitFilterOptions,
  ProjectIdentity,
} from '@store/types'

import DropDown from '@components/dropdown/v2'
import FILTER_INITIAL_STATE from '@components/filter/v2/filterState'
import ToggleSwitch from '@components/toggle-switch'

import { BuildingInterface, Level } from '@api/building'
import { MappingBlockCollection } from '@api/interactive-plan'
import { FilterWhiteLabel } from '@api/white-label'

import FirebaseControlQuery from '@firebaseUtil/firebase-control-query'

import { CloseSvg } from '@svg/react'

import {
  Unit,
  UnitFilterOptionsConfigurations,
} from '@adUtilities/types/apartment'
import { MinMaxInterface } from '@adUtilities/types/common'

import RangeOptions from './range-options'

export interface ConfigPopupProps {
  projectIdentity: ProjectIdentity
  filterWhiteLabel: FilterWhiteLabel
  isOpen: boolean
  building: BuildingInterface
  blocks: MappingBlockCollection
  firebaseUnitFilter: ExtendedUnitFilterOptions
  firebaseActiveBlock: string
  firebaseActiveLevel?: string
  hideLevelOption?: boolean
  toggle: (arg: boolean) => void
  onChangeBlock?: (arg: string) => void
}

function BuildingFilter({
  projectIdentity,
  filterWhiteLabel,
  isOpen,
  building,
  blocks,
  firebaseUnitFilter,
  firebaseActiveBlock,
  firebaseActiveLevel,
  hideLevelOption,
  toggle,
  onChangeBlock,
}: ConfigPopupProps) {
  const firebaseControlQuery = FirebaseControlQuery({
    ...projectIdentity,
    sessionKey: projectIdentity.sessionId,
  })

  const {
    prices,
    showPrice,
    propertyConfig,
    aspects,
    unitTypes,
    statusLabels,
  } = projectIdentity || {}

  const filterRef = useRef<HTMLDivElement>(null)

  const [showAvailable, setShowAvailable] = useState<boolean>(
    firebaseUnitFilter.showAvailable
  )
  const [checkAnyLevel, setAnyLevel] = useState<boolean>(
    firebaseUnitFilter.anyLevel
  )
  const [configurations, setConfigurations] =
    React.useState<UnitFilterOptionsConfigurations>(
      firebaseUnitFilter.configurations
    )
  const [range, setRange] = useState<MinMaxInterface>(firebaseUnitFilter.range)
  const [aspect, setAspect] = useState<string[]>(firebaseUnitFilter.aspect)
  const [filterUnitType, setFilterUnitType] = useState<string>(
    firebaseUnitFilter.unitType
  )

  const [activeBlock, setActiveBlock] = useState<string>(firebaseActiveBlock)
  const [activeLevel, setActiveLevel] = useState<string>(
    firebaseActiveLevel || ''
  )

  const buildingOptions = useMemo(() => {
    if (building.blockOrders.length > 0) {
      return building.blockOrders.map((item) => ({
        label: item,
        value: item,
      }))
    }

    return Object.keys(blocks)?.map((item) => ({
      label: item,
      value: item,
    }))
  }, [blocks, building.blockOrders])

  const unitTypeOptions = useMemo(
    () =>
      unitTypes?.map((item) => ({
        label: item,
        value: item,
      })),
    [unitTypes]
  )

  const getLevelOptions = (block?: string) => {
    const currentBlock = block || activeBlock
    const levelList: Level[] = [...building.levels]

    if (showAvailable) {
      levelList.filter((level) =>
        level.data.filter(
          (unt: Unit) =>
            unt.metas.status === statusLabels?.available ||
            unt.metas.status === 'available'
        )
      )
    }

    const levels: DropDownOption[] = []

    levelList.forEach((res) => {
      if (activeBlock && buildingOptions.length > 1) {
        if (res.data.find((level) => level.blockId === currentBlock)) {
          levels.push({
            label: res.level,
            value: res.level,
          })
        }
      } else {
        levels.push({
          label: res.level,
          value: res.level,
        })
      }
    })

    return levels
  }

  const handleRangeChange = async (rangeValue: MinMaxInterface) => {
    await firebaseControlQuery.update({
      [`building.unitFilter.range`]: rangeValue,
    })
  }

  const handleAspectOnchange = async (value: string) => {
    if (aspect.includes(value)) {
      await firebaseControlQuery.update({
        [`building.unitFilter.aspect`]: aspect.filter((item) => item !== value),
      })
    } else {
      await firebaseControlQuery.update({
        [`building.unitFilter.aspect`]: [...aspect, value],
      })
    }
  }

  const handleUnitTypeOnchange = async (value: string) => {
    await firebaseControlQuery.update({
      [`building.unitFilter.unitType`]: value,
    })
  }

  const handleConfigurationOnchange = async (
    configs: UnitFilterOptionsConfigurations
  ) => {
    await firebaseControlQuery.update({
      [`building.unitFilter.configurations`]: configs,
    })
  }

  const handleShowAvailable = async (checked: boolean) => {
    await firebaseControlQuery.update({
      [`building.unitFilter.showAvailable`]: checked,
    })
  }

  const handleLevelOnChange = async (level: string) => {
    await firebaseControlQuery.update({
      [`building.activeLevel`]: level,
      [`building.activeUnit`]: '',
    })
  }

  const handleAnyLevel = async (checked: boolean) => {
    await firebaseControlQuery.update({
      [`building.unitFilter.anyLevel`]: checked,
    })
  }

  const applyFilter = async () => {
    toggle(false)

    const filterPayload = {
      'building.unitFilter.apply': true,
      ...(buildingOptions.length > 1 && {
        'building.activeBlock': activeBlock,
      }),
      ...(!hideLevelOption && { 'building.activeLevel': activeLevel }),
      'building.activeUnit': '',
    }

    await firebaseControlQuery.update(filterPayload)

    if (buildingOptions.length > 1) {
      onChangeBlock?.(activeBlock)
    } else {
      handleLevelOnChange(activeLevel)
    }
  }

  const resetFilter = async () => {
    await firebaseControlQuery.update({
      [`building.unitFilter`]: FILTER_INITIAL_STATE,
    })
  }

  const handleOutsideClick = (event: MouseEvent) => {
    if (
      isOpen &&
      filterRef.current &&
      !filterRef.current.contains(event.target as Node)
    ) {
      toggle(false)
    }
  }

  useEffect(() => {
    setRange(firebaseUnitFilter.range)
    setAspect(firebaseUnitFilter.aspect)
    setAnyLevel(firebaseUnitFilter.anyLevel)
    setFilterUnitType(firebaseUnitFilter.unitType)
    setShowAvailable(firebaseUnitFilter.showAvailable)
    setConfigurations(firebaseUnitFilter.configurations)
  }, [firebaseUnitFilter])

  useEffect(() => {
    if (isOpen && firebaseUnitFilter.apply) {
      firebaseControlQuery.update({
        [`building.unitFilter.apply`]: false,
      })
    }
  }, [firebaseUnitFilter, activeLevel, activeBlock])

  useEffect(() => {
    setActiveBlock(firebaseActiveBlock || Object.keys(blocks ?? {})?.[0])
  }, [firebaseActiveBlock, blocks])

  useEffect(() => {
    setActiveLevel(firebaseActiveLevel || '')
  }, [firebaseActiveLevel])

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      document.addEventListener('click', handleOutsideClick, false)
    }, 0)
    return () => {
      clearTimeout(timeoutId)
      document.removeEventListener('click', handleOutsideClick)
    }
  }, [isOpen])

  return (
    <div
      ref={filterRef}
      className={`fixed top-[36px] z-30 h-page-container w-[297px] rounded-tl-2xl bg-white px-4 pb-10 text-black shadow-2xl ${
        isOpen ? '' : '-translate-x-full'
      } no-scrollbar overflow-y-scroll transition-transform duration-500`}
    >
      <div className="mt-6 flex items-center justify-between">
        <div>
          <button
            className="flex items-center"
            type="button"
            onClick={() => toggle(!isOpen)}
          >
            <CloseSvg
              className="h-3.5 w-3.5 cursor-pointer"
              strokeColor="black"
            />
          </button>
        </div>
        <div>
          <h4 className="text-xl font-semibold">Filter</h4>
        </div>
        <div>
          <button
            type="button"
            onClick={resetFilter}
            className="text-base font-medium text-mainColour underline"
          >
            Reset
          </button>
        </div>
      </div>
      <div className="mt-6 flex flex-col gap-[1.20rem] text-base font-medium">
        {buildingOptions.length > 1 && (
          <div className="flex flex-col gap-1">
            <span>{filterWhiteLabel?.blockDropdownLabel || 'Building'}</span>
            <DropDown
              onSelect={(value) => {
                const [firstLevel] = getLevelOptions(value)
                setActiveBlock(value)
                setActiveLevel(firstLevel.value)
              }}
              items={buildingOptions}
              value={activeBlock}
              className="py-2"
              buttonRadius="rounded-[7px]"
              buttonRadiusClasses="rounded-tl-[7px] rounded-tr-[7px]"
              optionRadiusClasses="rounded-bl-[7px] rounded-br-[7px]"
            />
          </div>
        )}

        {getLevelOptions().length && !hideLevelOption ? (
          <div className="flex flex-col gap-1">
            <span className={` ${checkAnyLevel ? 'text-gray-300' : ''}`}>
              {filterWhiteLabel?.levelDropdownLabel || 'Level'}
            </span>
            <DropDown
              onSelect={(value) => {
                setActiveLevel(value)
              }}
              items={getLevelOptions()}
              disabled={checkAnyLevel}
              value={activeLevel}
              className="py-2"
              buttonRadius="rounded-[7px]"
              buttonRadiusClasses="rounded-tl-[7px] rounded-tr-[7px]"
              optionRadiusClasses="rounded-bl-[7px] rounded-br-[7px]"
            />

            <div className="mt-2 inline-flex items-center">
              <ToggleSwitch
                key="anyLevel"
                id="anyLevel"
                onChangeCallback={(e) => {
                  handleAnyLevel(e.target.checked)
                }}
                initialState={checkAnyLevel}
                onColor="bg-mainColour"
                offColor="bg-[#BDC3C7]"
              />
              <div className="ml-3 text-base font-medium">Any Level</div>
            </div>
          </div>
        ) : (
          ''
        )}

        {showPrice && (
          <RangeOptions
            field="price"
            options={prices}
            range={range}
            handleChange={handleRangeChange}
          />
        )}

        {Object.entries(propertyConfig)?.map(
          ([field, value]) =>
            value?.length > 0 && (
              <RangeOptions
                field={field}
                key={field}
                options={value as unknown as Array<string | number>}
                range={
                  configurations[field as keyof UnitFilterOptionsConfigurations]
                }
                handleChange={(configValue: MinMaxInterface) => {
                  handleConfigurationOnchange({
                    ...configurations,
                    [field as keyof UnitFilterOptionsConfigurations]:
                      configValue,
                  })
                }}
              />
            )
        )}

        {unitTypeOptions.length > 0 && (
          <div className="flex flex-col gap-1">
            <span>Unit Types</span>
            <DropDown
              onSelect={handleUnitTypeOnchange}
              items={[{ value: 'Any', label: 'Any' }, ...unitTypeOptions]}
              value={filterUnitType}
              className="py-2"
              buttonRadius="rounded-[7px]"
              buttonRadiusClasses="rounded-tl-[7px] rounded-tr-[7px]"
              optionRadiusClasses="rounded-bl-[7px] rounded-br-[7px]"
            />
          </div>
        )}

        {aspects && aspects.length > 0 && (
          <div className="flex flex-col gap-1">
            <span>Aspect</span>
            <div className="grid-cols-auto grid gap-2 md:grid-cols-[repeat(auto-fit,minmax(80px,1fr))]">
              {aspects?.map((aspectValue: string) => (
                <button
                  type="button"
                  key={aspectValue}
                  onClick={() => {
                    handleAspectOnchange(aspectValue.toLowerCase())
                  }}
                  className={`truncate rounded px-2 py-2 text-center text-base font-medium ${
                    aspect.includes(aspectValue.toLowerCase())
                      ? 'bg-mainColour text-white'
                      : 'bg-[#E5E5E5] hover:bg-[#BDC3C7]'
                  }`}
                >
                  {aspectValue}
                </button>
              ))}
            </div>
          </div>
        )}

        <div className="mt-8 inline-flex items-center whitespace-nowrap">
          <ToggleSwitch
            key="availableResidence"
            id="availableResidence"
            onChangeCallback={(e) => {
              handleShowAvailable(e.target.checked)
            }}
            initialState={showAvailable}
            onColor="bg-mainColour"
            offColor="bg-[#BDC3C7]"
          />
          <div className="ml-3 text-base font-medium">
            {filterWhiteLabel?.toggleText || 'Only show available residences'}
          </div>
        </div>
        <div className="flex flex-col gap-1">
          <button
            type="button"
            onClick={applyFilter}
            disabled={firebaseUnitFilter.apply}
            className={`rounded-lg px-4 py-4 text-xl font-semibold text-white duration-300 ${
              firebaseUnitFilter.apply ? 'bg-gray-300' : 'bg-mainColour'
            }`}
          >
            Apply Filters
          </button>
        </div>
      </div>
    </div>
  )
}

export default connect(({ projectIdentity, whiteLabel }: RootStateType) => ({
  projectIdentity,
  filterWhiteLabel: whiteLabel?.showcase?.filter,
}))(BuildingFilter)
