import React from 'react'
import { connect } from 'react-redux'

import { ProjectIdentity, RootStateTypeExtra, SessionMap } from '@store/types'

import { InteractiveMapData as Stack } from '@api/interactive-map'

import FirebaseControlQuery from '@firebaseUtil/firebase-control-query'

import { ArrowSvg, ChevronDoubleSvg, EyeSvg } from '@svg/react'

import LayerList from './layer-list'

interface LayerCategoryPanelProps {
  projectIdentity: ProjectIdentity
  firebaseActiveCategory?: SessionMap['interactiveMapActiveCategory']
  categories: Array<string>
  interactiveMap: Stack
}

function LayerCategoryPanel({
  projectIdentity,
  firebaseActiveCategory,
  categories,
  interactiveMap,
}: LayerCategoryPanelProps) {
  const firebaseControlQuery = FirebaseControlQuery({
    ...projectIdentity,
    sessionKey: projectIdentity.sessionId,
  })

  const activeCategory = firebaseActiveCategory?.label

  const [showLayerCategoryPanel, toggleLayerCategoryPanel] =
    React.useState(true)

  const getActiveItem = (category: string) =>
    interactiveMap.layers.reduce(
      (count, layer) =>
        count + (layer.category === category && layer.visible ? 1 : 0),
      0
    )

  const handleShowCategoryDetails = (category: string) => {
    const matchedLayer = interactiveMap.layers.find(
      (layer) =>
        layer.category === category &&
        layer.categoryDefault &&
        layer.baseImageOverride
    )
    if (matchedLayer) {
      firebaseControlQuery.update({
        [`interactiveMapActiveCategory`]: {
          label: category,
          baseImageOverride: matchedLayer.baseImageOverride,
        },
      })
      return
    }
    firebaseControlQuery.update({
      [`interactiveMapActiveCategory`]: {
        label: category,
        baseImageOverride: '',
      },
    })
  }

  const handleToggleCategoryDetails = (category?: string) => {
    if (!category) {
      firebaseControlQuery.update({
        [`interactiveMapActiveCategory`]: {
          label: '',
          baseImageOverride: '',
        },
      })
      return
    }
    handleShowCategoryDetails(category)
  }

  return showLayerCategoryPanel ? (
    <div className="absolute bottom-16 left-8 z-20 w-[18rem]">
      {activeCategory ? (
        <LayerList
          hideCategoryDetails={handleToggleCategoryDetails}
          activeCategory={activeCategory}
          interactiveMap={interactiveMap}
        >
          <button
            className="absolute -left-4 top-[41%] z-10 rounded-full bg-white p-2"
            type="button"
            onClick={() => {
              toggleLayerCategoryPanel(!showLayerCategoryPanel)
            }}
          >
            <ChevronDoubleSvg rotate="top" stroke={2} className="h-4 w-4" />
          </button>
        </LayerList>
      ) : (
        <div className="relative rounded-xl bg-white/60">
          <button
            className={`absolute -left-4 ${
              categories.length === 1 ? 'top-[20%]' : 'top-[41%]'
            } z-10 rounded-full bg-white p-2`}
            type="button"
            onClick={() => {
              toggleLayerCategoryPanel(!showLayerCategoryPanel)
            }}
          >
            <ChevronDoubleSvg rotate="top" stroke={2} className="h-4 w-4" />
          </button>
          {categories.map((category) => (
            <div
              role="button"
              tabIndex={0}
              key={category}
              onClick={() => {
                handleToggleCategoryDetails(category)
              }}
              onKeyDown={() => {}}
              className="flex items-center justify-between border-b border-gray-300 px-6 py-3 last:border-transparent"
            >
              <div className="flex items-center gap-2">
                <span className="text-sm font-semibold">{category}</span>
                {getActiveItem(category) ? (
                  <button
                    type="button"
                    className="flex items-center gap-2 rounded-xl bg-mainColour px-2 text-white"
                  >
                    <EyeSvg className="h-4 w-4" />
                    <span className="text-sm">{getActiveItem(category)}</span>
                  </button>
                ) : (
                  ''
                )}
              </div>
              <button type="button">
                <ArrowSvg
                  className="h-6 w-6"
                  strokeColor="#000"
                  rotate="right"
                />
              </button>
            </div>
          ))}
        </div>
      )}
    </div>
  ) : (
    <button
      className="absolute left-4 top-2/4 z-20 rounded-full bg-white p-2"
      type="button"
      onClick={() => {
        toggleLayerCategoryPanel(!showLayerCategoryPanel)
      }}
    >
      <ChevronDoubleSvg stroke={2} rotate="down" className="h-4 w-4" />
    </button>
  )
}

export default connect(
  ({ projectIdentity, firestore: { session } }: RootStateTypeExtra) => ({
    projectIdentity,
    firebaseActiveCategory: session?.interactiveMapActiveCategory,
  })
)(LayerCategoryPanel)
