import React from 'react'

import { CloseSvg } from '@components/adgroup-svg/react'

interface ComponentPropsInterface {
  closeAction: () => void
}

function PackageInfoModal({ closeAction }: ComponentPropsInterface) {
  return (
    <div className="flex h-full w-full items-center bg-gray-700 bg-opacity-[0.02]">
      <div className="m-auto w-2/4 rounded-2xl bg-white p-6">
        <button onClick={closeAction} type="button" className="mb-6">
          <CloseSvg className="h-3 w-3" strokeColor="black" />
        </button>
        <div className="mb-4 text-2xl font-bold">Inclusions List</div>
        <div className="text-lg">
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry. Lorem Ipsum has been the industry&#39;s standard dummy text
          ever since the 1500s, when an unknown printer took a galley of type
          and scrambled it to make a type specimen book. It has survived not
          only five centuries, but also the leap into electronic typesetting,
          remaining essentially unchanged. It was popularised in the 1960s with
          the release of Letraset sheets containing Lorem Ipsum passages, and
          more recently with desktop publishing software like Aldus PageMaker
          including versions of Lorem Ipsum.
        </div>
      </div>
    </div>
  )
}

export default PackageInfoModal
