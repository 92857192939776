import React from 'react'
import { connect } from 'react-redux'

import { ProjectIdentity, RootStateTypeExtra } from '@store/types'

import FirebaseControlQuery from '@firebaseUtil/firebase-control-query'

import { ChevronSvg } from '@svg/react'

interface ComponentProps {
  projectIdentity: ProjectIdentity
  stage: { id: string; label: string }
  active: boolean
  levelStagePrefix: string
  activeLotId: string
}

function StageHead({
  projectIdentity,
  stage,
  active,
  levelStagePrefix,
  activeLotId,
}: ComponentProps) {
  const firebaseControlQuery = FirebaseControlQuery({
    ...projectIdentity,
    sessionKey: projectIdentity.sessionId,
  })

  const handleClick = React.useCallback(() => {
    if (!active) {
      firebaseControlQuery.update({
        [`houseAndLand.activeStageId`]: stage.label,
      })
    }
    if (activeLotId) {
      firebaseControlQuery.update({
        [`houseAndLand.activeLotId`]: '',
      })
    }
  }, [stage, active, activeLotId])

  return (
    <button
      id={`id-${stage.id}`}
      className="sticky top-0 z-10 flex w-full items-center justify-between bg-[#EEEEEE] py-4"
      type="button"
      onClick={handleClick}
    >
      <span
        className={`${
          active ? 'active-stage underline decoration-2 underline-offset-4' : ''
        } text-lg font-bold tracking-widest`}
      >
        {levelStagePrefix || 'Stage'} {stage.label}
      </span>
      <ChevronSvg className="h-6 w-6" rotate="right" stroke={2} />
    </button>
  )
}

export default connect(
  ({ projectIdentity, firestore: { session } }: RootStateTypeExtra) => ({
    projectIdentity,
    activeLotId: session?.houseAndLand?.activeLotId || '',
  })
)(StageHead)
