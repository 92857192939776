import { fabric } from 'fabric'

import { CustomObjectTypes, MarkerType, TransparentFill } from '../constants'
import LabelUtil from '../label'
import { CustomGroup, Polygon } from '../types'

export interface CanvasData {
  image: string
  polygons: Array<Polygon>
}

declare global {
  interface Window {
    canvasData: CanvasData
    hasLabel?: boolean
  }
}

const Events = ({ labelPrefix }: { labelPrefix: string }) => {
  const labelUtil = LabelUtil()

  const removeAllLabelGroups = (canvas: fabric.Canvas | null) => {
    if (canvas?.getObjects()) {
      canvas.getObjects().forEach((obj) => {
        if (obj.type === 'labelGroup') {
          canvas?.remove(obj)
          canvas?.renderAll()
        }
      })
    }
  }

  const getPolygonLabel = (poly: Polygon) => {
    if (poly?.label) {
      return poly.label
    }
    return `${labelPrefix || ''} ${poly?.groupId || 'No Id'} ${
      poly?.postFix || ''
    }`
  }

  const setMouseOverFill = ({ type, color }: Polygon) =>
    type === MarkerType.arrow || type === MarkerType.arrowSmall
      ? TransparentFill
      : color

  const disableIfArrows = (markerType: string) =>
    MarkerType.arrow === markerType || MarkerType.arrowSmall === markerType

  const mouseMove = (e: fabric.IEvent, canvas: fabric.Canvas | null) => {
    if (!canvas) {
      return
    }
    if (window.hasLabel) {
      if (e.target?.isType('group')) {
        const activeGroup = e.target as CustomGroup
        const selectedIndex = activeGroup?.arrayIndex
        const contextPointer = canvas?.getPointer(e.e)
        if (
          disableIfArrows(window.canvasData.polygons[selectedIndex]?.type || '')
        ) {
          return
        }
        removeAllLabelGroups(canvas)
        const label = getPolygonLabel(window.canvasData.polygons[selectedIndex])
        const labelGroup = labelUtil.createLabel({
          label,
          groupOverride: {
            left: contextPointer?.x,
            top: (contextPointer?.y || 0) - 50,
          } as fabric.Group,
        })
        canvas?.add(labelGroup)
        canvas?.renderAll()
      }
    }
  }

  const mouseOver = (e: fabric.IEvent, canvas: fabric.Canvas | null) => {
    if (!canvas) {
      return
    }
    if (e.target?.isType('group')) {
      const activeGroup = e.target as CustomGroup
      const selectedIndex = activeGroup?.arrayIndex
      const noPolyHover =
        !window.canvasData.polygons[selectedIndex]?.noPolyHover
      activeGroup?.forEachObject((obj) => {
        if (obj.name === 'PolyGroup') {
          const group = obj as fabric.Group
          group.forEachObject((innerObj) => {
            if (innerObj?.isType('polygon') && noPolyHover) {
              innerObj?.animate(
                {
                  fill: setMouseOverFill(
                    window.canvasData.polygons[selectedIndex]
                  ),
                  opacity: 0.7,
                },
                {
                  onChange: canvas?.renderAll.bind(canvas),
                  duration: 300,
                  easing: fabric.util.ease.easeInSine,
                }
              )
            }
          })
        } else if (obj.isType('group')) {
          const group = obj as fabric.Group
          let maxRadius = 0
          if (
            disableIfArrows(
              window.canvasData.polygons[selectedIndex]?.type || ''
            )
          ) {
            return
          }
          group.forEachObject((innerObj) => {
            if (innerObj?.name === CustomObjectTypes.outerCircle) {
              const foundCircle = innerObj as fabric.Circle
              maxRadius = (foundCircle?.radius || 0) / 2
            }
          })

          group.forEachObject((innerObj) => {
            if (innerObj?.name === CustomObjectTypes.innerCircle) {
              const circle = innerObj as fabric.Circle
              circle?.animate(
                { radius: maxRadius || 11 },
                {
                  onChange: canvas?.renderAll.bind(canvas),
                  duration: 300,
                  easing: fabric.util.ease.easeInSine,
                }
              )
            }
          })
        }
      })
    }
  }

  const mouseOut = (e: fabric.IEvent, canvas: fabric.Canvas | null) => {
    if (!canvas) {
      return
    }
    if (e.target?.isType('group')) {
      const activeGroup = e.target as CustomGroup
      const selectedIndex = activeGroup?.arrayIndex
      const noPolyHover =
        !window.canvasData.polygons[selectedIndex]?.noPolyHover
      if (window.hasLabel) {
        removeAllLabelGroups(canvas)
      }
      if (
        disableIfArrows(window.canvasData.polygons[selectedIndex]?.type || '')
      ) {
        return
      }
      activeGroup?.forEachObject((obj) => {
        if (obj.name === 'PolyGroup') {
          const group = obj as fabric.Group
          group.forEachObject((innerObj) => {
            if (innerObj?.isType('polygon') && noPolyHover) {
              innerObj?.animate(
                {
                  fill: window.canvasData.polygons[selectedIndex]
                    ?.activeByDefault
                    ? window.canvasData.polygons[selectedIndex]?.color
                    : TransparentFill,
                  opacity: window.canvasData.polygons[selectedIndex]
                    ? 0.7
                    : 0.1,
                },
                {
                  onChange: canvas?.renderAll.bind(canvas),
                  duration: 300,
                  easing: fabric.util.ease.easeInSine,
                }
              )
            }
          })
        } else if (obj.isType('group')) {
          const group = obj as fabric.Group
          group.forEachObject((innerObj) => {
            if (innerObj?.isType('circle')) {
              const circle = innerObj as fabric.Circle
              if (circle.radius !== 18) {
                circle?.animate(
                  { radius: 5 },
                  {
                    onChange: canvas?.renderAll.bind(canvas),
                    duration: 300,
                    easing: fabric.util.ease.easeInSine,
                  }
                )
              }
            }
          })
        }
      })
    }
  }

  const mouseUp = (e: fabric.IEvent, canvas: fabric.Canvas | null) => {
    if (!canvas) {
      return
    }
    if (e.target?.isType('group')) {
      const activeGroup = e.target as CustomGroup
      if (activeGroup?.onGroupSelect) {
        const mousePoint = e.pointer as fabric.Point

        const canvasRect = new fabric.Rect({
          width: canvas?.width,
          height: canvas?.height,
          fill: '#000',
          opacity: 0,
          objectCaching: true,
        })

        canvas?.add(canvasRect)

        canvasRect?.animate('opacity', 1, {
          duration: 1000,
          easing: fabric.util.ease.easeInCubic,
        })

        if (activeGroup.onGroupSelectComplete) {
          activeGroup.onGroupSelect()
        }

        fabric.util.animate({
          startValue: canvas?.getZoom(),
          endValue: 5,
          duration: 1000,
          easing: fabric.util.ease.easeInCubic,
          onChange: (zoomvalue) => {
            canvas?.zoomToPoint(mousePoint, zoomvalue)
            canvas?.renderAll.bind(canvas)
          },
          onComplete: () => {
            if (
              activeGroup?.onGroupSelect &&
              !activeGroup.onGroupSelectComplete
            ) {
              activeGroup.onGroupSelect()
            }
            if (
              activeGroup?.onGroupSelect &&
              activeGroup.onGroupSelectComplete
            ) {
              activeGroup.onGroupSelectComplete()
            }
          },
        })
      }
    }
  }

  return {
    mouseUp,
    mouseOver,
    mouseOut,
    mouseMove,
  }
}

export default Events
