import React from 'react'
import { connect } from 'react-redux'

import { ShortlistState } from '@store/actionSlices/shortlist'
import {
  LotShortlistInterface,
  PackageConfigurationInterface,
  ProjectIdentity,
  RootStateTypeExtra,
  ShortlistPropertyType,
} from '@store/types'

import Modal from '@components/modals/v2'

import LotConfiguration from '@pages/properties-view/house-and-land/v2/lots-side-panel/lot-card/lot-configuration'

import { LotInterface, PackageInterface } from '@api/houseAndLand'

import { formatPrice } from '@utilities/helper'
import useParseLotPackageConfiguration from '@utilities/lot-package-util'
import ShortlistHandler from '@utilities/shortlist-handler'

import { StarSvg } from '@svg/react'

import AssetHandler from '@adUtilities/asset-handler/asset-handler'

const PLACEHOLDER_IMAGE = process.env.REACT_APP_PLACEHOLDER_IMAGE_URL

interface LotThumbnailProps {
  lot: LotInterface
  package?: PackageInterface
  configuration?: PackageConfigurationInterface
  isFirstItem: boolean
  shortlist: ShortlistState
  projectIdentity: ProjectIdentity
}

function LotThumbnail({
  lot,
  package: lotPackage,
  configuration,
  isFirstItem,
  shortlist,
  projectIdentity,
}: LotThumbnailProps) {
  const [showConfirmModal, toggleConfirmModal] = React.useState(false)
  const [removeShortlistProperty, setRemoveShortlistProperty] = React.useState<
    boolean | 'all'
  >(false)

  const [parsePackageConfiguration] = useParseLotPackageConfiguration()
  const { photo, price, configurationTitle } = React.useMemo(
    () =>
      parsePackageConfiguration({
        lot,
        package: lotPackage,
        configuration,
        projectId: projectIdentity.projectUUID,
      }),
    [
      parsePackageConfiguration,
      lot,
      lotPackage,
      configuration,
      projectIdentity.projectUUID,
    ]
  )

  const property = React.useMemo<LotShortlistInterface | undefined>(
    () => ({
      propertyId: lot.id,
      propertyName: lot.name,
      references: {
        precinctId: '',
        stageId: '',
      },
      configuration,
      type: ShortlistPropertyType.Lot,
    }),
    [lot]
  )

  const [, handleShortlistClick] =
    (property &&
      ShortlistHandler({
        property,
        shortlist,
        setShortlistErrorMessage: () => {},
        projectIdentity,
        toggleConfirmModal,
        removeShortlistProperty,
        setRemoveShortlistProperty,
        available: lot.status || '',
      })) ||
    []

  const handleRemove = React.useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.stopPropagation()
      handleShortlistClick?.()
    },
    [handleShortlistClick]
  )

  return (
    <>
      <Modal
        headerLabel="Remove from shortlist"
        toggle={toggleConfirmModal}
        cancelAction={() => {}}
        okAction={() => {
          setRemoveShortlistProperty(true)
        }}
        isVisible={!!showConfirmModal}
        okLabel="Remove"
        cancelLabel="Cancel"
      >
        <div className="px-4">
          <p className="leading-[120%] text-fontBlackColour opacity-60">
            Are you sure you want to remove this from your shortlist?
          </p>
          <p className="mt-5 font-[600] italic leading-[120%] text-fontBlackColour opacity-60">
            {`Lot ${lot.name}`}
            {configurationTitle ? <>: {configurationTitle}</> : null}
          </p>
        </div>
      </Modal>

      <div className="relative w-[350px] overflow-hidden border-b-2 border-gray-300 last:rounded-b-md last:border-0">
        {isFirstItem && (
          <div className="absolute left-4.5 top-4.5 flex items-center rounded-[100px] bg-white px-2 py-1.5 shadow-md">
            <div className="mr-1.5 inline-block h-3 w-3 rounded-full bg-green-500" />
            <span className="font-bold leading-none">Lot {lot.name}</span>
          </div>
        )}
        {isFirstItem && (
          <img
            className="h-[145px] w-full rounded-t-md object-cover"
            alt="thumbnail"
            src={AssetHandler({
              url: String(photo.url || PLACEHOLDER_IMAGE),
              type: photo?.config?.type || 'new',
              staticUrl: photo?.config?.staticUrl ?? true,
              noSpliceUrl: photo?.config?.noSpliceUrl ?? false,
            })}
          />
        )}
        <div className="flex flex-col gap-1.5 bg-white px-4.5 py-5">
          {projectIdentity?.showPrice && (
            <div className="flex items-center justify-between">
              <p className="text-[17px] font-semibold leading-none tracking-[0.17px] text-gray-900">
                {formatPrice(price)}
              </p>

              <button
                type="button"
                title="Shortlist lot"
                onClick={handleRemove}
              >
                <StarSvg className="h-4 w-4 stroke-amber-500 text-amber-500" />
              </button>
            </div>
          )}
          <div className="flex items-center justify-between gap-4">
            <LotConfiguration
              {...lotPackage?.configuration}
              area={lot.area}
              frontage={lot.frontage}
            />
            {!projectIdentity?.showPrice && (
              <button
                type="button"
                title="Shortlist unit"
                onClick={handleRemove}
                className="self-start pt-0.5"
              >
                <StarSvg className="h-4 w-4 stroke-amber-500 text-amber-500" />
              </button>
            )}
          </div>{' '}
          {configurationTitle ? (
            <div className="mt-2 break-words border-t py-3">
              <p className="italic">{configurationTitle}</p>
            </div>
          ) : null}
        </div>
      </div>
    </>
  )
}

export default connect(
  ({ projectIdentity, shortlist }: RootStateTypeExtra) => ({
    projectIdentity,
    shortlist,
  })
)(LotThumbnail)
