import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'

import { ArrowSvg } from '@src/components/adgroup-svg/react'
import Throttle from '@src/utilities/throttle'

import { ProjectIdentity, RootStateTypeExtra } from '@store/types'

import Container from '@components/container/v2'
import DataHandler from '@components/data-handler'
import PannellumView from '@components/showcase-pannellum'
import {
  PannellumDataInterface,
  PannellumViewRefInterface,
  PanoramaImageType,
} from '@components/showcase-pannellum/types'

import {
  selectFromResult as selectFromPanoramicResult,
  useGetPanoramicQuery,
} from '@api/panoramic'

import { getQueryStringParams } from '@utilities/helper'

import FirebaseControlQuery from '@firebaseUtil/firebase-control-query'
import { SessionMap } from '@firebaseUtil/types'

import { ProjectType } from '@adUtilities/constants/common'

import PanoramicSkeleton from './panoramic-skeleton'

interface PanoramicProps {
  session: SessionMap | undefined
  projectIdentity: ProjectIdentity
}

function Panoramic({ session, projectIdentity }: PanoramicProps) {
  const firebaseControlQuery = FirebaseControlQuery({
    ...projectIdentity,
    sessionKey: projectIdentity.sessionId,
  })

  const history = useHistory()
  const location = useLocation()
  const { prevUrl } = getQueryStringParams(location.search)

  const pannellumViewRef = React.useRef<PannellumViewRefInterface>()

  const [currentZoom, setCurrentZoom] = useState(0)
  const [currentYaw, setCurrentYaw] = useState(0)
  const [currentPitch, setCurrentPitch] = useState(0)

  const [panoramic, setPanoramic] = useState<PannellumDataInterface[]>([])

  const [rotationSpeed, setRotationSpeed] = useState(0)

  const [activePanoramicParent, setActivePanoramicParent] = useState('')
  const [scene, setScene] = useState('')
  const [activeSceneId, setActiveSceneId] = useState('')
  const [activePanoramaType, setActivePanoramaType] =
    useState<PanoramaImageType>(PanoramaImageType.Sphere)

  const [viewerInit, setViewerInit] = useState(false)
  const [eventsInit, setEventsInit] = useState(false)

  const zoom = Throttle(currentZoom, 500)
  const pitch = Throttle(currentPitch, 500)
  const yaw = Throttle(currentYaw, 500)

  const panoramicPayload = useGetPanoramicQuery(
    { projectName: projectIdentity.projectId },
    { selectFromResult: selectFromPanoramicResult }
  )

  const handleSceneChange = (e: string) => {
    setRotationSpeed(0)
    setCurrentYaw(pannellumViewRef?.current?.pannellum.getYaw() || 0)
    setCurrentPitch(pannellumViewRef?.current?.pannellum.getPitch() || 0)
    setCurrentZoom(pannellumViewRef?.current?.pannellum.getHfov() || 0)
    setScene(e)
  }

  const handleSwipe = () => {
    setRotationSpeed(0)
    setCurrentYaw(pannellumViewRef?.current?.pannellum.getYaw() || 0)
    setCurrentPitch(pannellumViewRef?.current?.pannellum.getPitch() || 0)
  }

  const handleZoomChange = () => {
    setRotationSpeed(0)
    setCurrentZoom(pannellumViewRef?.current?.pannellum.getHfov() || 0)
  }

  const returnPrevPage = () => {
    if (prevUrl) {
      history.push(prevUrl)
      firebaseControlQuery.updateRoute(
        prevUrl === 'lot-view' ? 'stages' : prevUrl
      )
      return
    }

    if (projectIdentity.type === ProjectType.HouseAndLand) {
      history.push('stages')
      firebaseControlQuery.updateRoute('stages')
      return
    }

    firebaseControlQuery.updateRoute('building')
  }

  useEffect(() => {
    if (panoramic.length > 0) {
      const activeItem = panoramic.find((res) => res.nameMap === scene)
      setActiveSceneId(activeItem?.nameMap || panoramic[0]?.nameMap)
      setActivePanoramaType(
        activeItem?.panoramaType ||
          panoramic[0]?.panoramaType ||
          PanoramaImageType.Sphere
      )
    }
  }, [scene, panoramic])

  useEffect(() => {
    if (panoramicPayload.panoramicData.length > 0 && panoramic.length === 0) {
      setPanoramic(
        panoramicPayload.panoramicData
          ?.filter((item) => item.panoramaGroup === activePanoramicParent)
          .sort((a, b) => Number(b.default) - Number(a.default))
      )
    }
  }, [panoramicPayload, activePanoramicParent])

  useEffect(() => {
    if (pannellumViewRef?.current && viewerInit) {
      pannellumViewRef?.current.pannellum.on(
        'mousedown',
        () => handleZoomChange
      )
      pannellumViewRef?.current.pannellum.on('scenechange', handleSceneChange)
      pannellumViewRef?.current.pannellum.on('zoomchange', handleZoomChange)
      setEventsInit(true)
    }
    return () => {
      if (pannellumViewRef?.current && viewerInit) {
        if (eventsInit) {
          pannellumViewRef?.current.pannellum.off(
            'scenechange',
            handleSceneChange
          )
          pannellumViewRef?.current.pannellum.off(
            'zoomchange',
            handleZoomChange
          )
        }
      }
    }
  }, [viewerInit, eventsInit, activeSceneId])

  useEffect(() => {
    if (pannellumViewRef?.current?.pannellum) {
      firebaseControlQuery.update({
        [`panoramic.rotationSpeed`]: rotationSpeed,
      })
    }
  }, [rotationSpeed])

  useEffect(() => {
    if (pannellumViewRef?.current?.pannellum) {
      firebaseControlQuery.update({
        [`panoramic.pitch`]: pitch,
        [`panoramic.yaw`]: yaw,
        [`panoramic.zoom`]: zoom,
        [`panoramic.scene`]: activeSceneId,
      })
    }
  }, [pitch, yaw, zoom, activeSceneId])

  useEffect(() => {
    if (session) {
      const {
        building: {
          activeUnit: firebaseActiveUnit,
          activeLevel: firebaseActiveLevel,
        },
        houseAndLand: {
          activeStageId: firebaseActiveStageId,
          activeLotId: firebaseActiveLotId,
        },
        panoramic: { scene: firebaseScene },
      } = session

      if (scene) {
        return
      }

      setScene(firebaseScene)

      if (projectIdentity?.type === ProjectType.HouseAndLand) {
        setActivePanoramicParent(firebaseActiveLotId || firebaseActiveStageId)
        return
      }

      setActivePanoramicParent(firebaseActiveUnit || firebaseActiveLevel)
    }
  }, [
    session?.building?.activeUnit,
    session?.building?.activeLevel,
    session?.panoramic?.scene,
    scene,
    projectIdentity?.type,
  ])

  return (
    <Container className="h-page-container w-screen">
      <DataHandler
        payloads={{
          ...panoramicPayload,
          data: panoramicPayload.panoramicData,
        }}
        skeletonFrame={<PanoramicSkeleton />}
      >
        <div className="absolute left-5 top-5 z-10">
          <button
            type="button"
            onClick={returnPrevPage}
            className="fixed rounded bg-white p-1 drop-shadow-40"
          >
            <ArrowSvg className="h-8 w-8" strokeColor="#000" />
          </button>
        </div>

        <PannellumView
          key="panoramic-view"
          className="h-full w-full"
          useControlImg
          ref={pannellumViewRef}
          payload={panoramic}
          activeScene={activeSceneId}
          panoramaType={activePanoramaType}
          viewerInit={setViewerInit}
          swipeHandler={handleSwipe}
        />
      </DataHandler>
    </Container>
  )
}

export default connect(
  ({ projectIdentity, firestore: { session } }: RootStateTypeExtra) => ({
    session,
    projectIdentity,
  })
)(Panoramic)
