enum NavigationMenuKey {
  RESIDENCES = 'residences',
  INTERACTIVE_MAP = 'interactive-map',
  VIDEOS = 'videos',
  GALLERY = 'gallery',
  BROCHURE = 'brochure',
  TEAM = 'team',
  EXTERNAL_ROUTES = 'external-routes',
  SHORTLIST = 'shortlist',
  SESSION_SETTINGS = 'session-settings',
  TOOLS = 'tools',
  ADTHREED = '3d',
}

enum RouteKey {
  VISION = 'vision',
  AREA_VIEW = 'area-view',
  LEVEL_VIEW = 'level-view',
  BUILDING = 'building',
  AREA_VIEW_HOUSE_AND_LAND = 'area-view-house-and-land',
  PRECINCT = 'precinct',
  STAGES = 'stages',
  LOT_VIEW = 'lot-view',
  INTERACTIVE_MAP = 'interactive-map',
  VIDEO_GALLERY = 'video-gallery',
  GALLERY = 'gallery',
  TEAMS = 'teams',
  BROCHURE = 'brochure',
  EXTERNAL_VIEWS = 'external-views',
  SHORTLIST = 'shortlist',
  PANORAMIC = 'panoramic',
  PANORAMIC_VIEW = 'panoramic-view',
  SNAPLOADER_VIEW = 'snaploader-view',
  THREE_D_BUILDING = '3d-building',
  LOCATION = 'location',
  DASHBOARD = 'dashboard',
  ADTHREED = '3d',
}

enum SortOrder {
  ASC = 'asc',
  DESC = 'desc',
}

const DEFAULT_SHOWCASE_ROOM = 'default'

export { NavigationMenuKey, RouteKey, SortOrder, DEFAULT_SHOWCASE_ROOM }
