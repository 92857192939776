import React from 'react'

import { ArrowSvg, EllipsisHorizontalSvg, FilterSvg } from '@svg/react'

function StageHeadSkeleton() {
  return (
    <div className="sticky top-0 z-10 flex w-full items-center justify-between bg-[#EEEEEE] py-4">
      <div className="h-3 w-20 rounded bg-zinc-400/20"></div>
      <div className="h-3 w-6 rounded bg-zinc-400/20"></div>
    </div>
  )
}

function LotCardSkeleton() {
  return (
    <div className="shimmer-container relative flex flex-col space-y-3 rounded-none p-0 shadow-none">
      <div className="overflow-hidden rounded-md bg-white">
        <div className="flex flex-col space-y-4 px-6 py-4">
          <div className="flex justify-between">
            <div className="h-3 w-24 rounded bg-zinc-400/20"></div>
            <div className="h-3 w-6 rounded bg-zinc-400/20"></div>
          </div>
          <div className="flex justify-between">
            <div className="h-5 w-6 rounded bg-zinc-400/20"></div>
            <div className="h-5 w-6 rounded bg-zinc-400/20"></div>
            <div className="h-5 w-6 rounded bg-zinc-400/20"></div>
            <div className="h-5 w-6 rounded bg-zinc-400/20"></div>
          </div>
          <div className="flex items-center space-x-4">
            <div className="h-3 w-24 rounded bg-zinc-400/20"></div>
            <div className="h-3 w-20 rounded bg-zinc-400/20"></div>
          </div>
        </div>
      </div>
    </div>
  )
}

function LotSidePanelSkeleton() {
  return (
    <div className="h-full w-full bg-[#EEEEEE]">
      <div className="flex justify-between bg-white p-4">
        <div className="flex items-center space-x-4">
          <div className="rounded-full border-2 border-zinc-400 p-1">
            <ArrowSvg className="h-5 w-5 stroke-zinc-400" rotate="left" />
          </div>
          <div className="relative">
            <div className="rounded-full border-2 border-zinc-400 p-1">
              <EllipsisHorizontalSvg className="h-5 w-5 text-zinc-400" />
            </div>
          </div>
        </div>
        <div className="relative flex rounded bg-mainColour px-3 py-1 text-xs text-white">
          <div className="inline-flex items-center">
            <span className="text-xs font-bold tracking-widest">
              Add Filter
            </span>
            <FilterSvg className="ml-1 h-4 w-4" />
          </div>
        </div>
      </div>
      <div className="house-and-land-scrollbar-v2 h-unit-sidepanel-container overflow-y-scroll px-4 pb-0.5">
        <div className="flex flex-col space-y-2">
          <div>
            <StageHeadSkeleton />
            <div className="flex flex-col space-y-4">
              <LotCardSkeleton />
              <LotCardSkeleton />
              <LotCardSkeleton />
            </div>
          </div>
          <div>
            <StageHeadSkeleton />
            <div className="flex flex-col space-y-4">
              <LotCardSkeleton />
              <LotCardSkeleton />
              <LotCardSkeleton />
            </div>
          </div>
          <div>
            <StageHeadSkeleton />
            <div className="flex flex-col space-y-4">
              <LotCardSkeleton />
              <LotCardSkeleton />
              <LotCardSkeleton />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LotSidePanelSkeleton
