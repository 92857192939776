import React from 'react'
import { connect } from 'react-redux'

import { initialFilter } from '@store/actionSlices/lotFilter'
import { ShortlistState } from '@store/actionSlices/shortlist'
import {
  LotFilterInterface,
  LotShortlistInterface,
  ProjectIdentity,
  RootStateTypeExtra,
} from '@store/types'

import { PackageSummaryInterface, StageInterface } from '@api/houseAndLand'

import countFilterChanges from '@utilities/lot-filter-util'

import FirebaseControlQuery from '@firebaseUtil/firebase-control-query'

import ActionsButton from './actions-button'
import AddFilterButton from './add-filter-button'
import BackButton from './back-button'
import LotCard from './lot-card'
import { LotCardProps } from './lot-card/lot-card'
import NoLotCard from './no-lot-card'
import StageHead from './stage-head'

interface LotsSidePanelProps {
  projectIdentity: ProjectIdentity
  stages: Array<StageInterface>
  packages: Array<PackageSummaryInterface>
  filterPopupToggle: boolean
  setFilterPopupToggle: (arg: boolean) => void
  activePackageId: string
  activeStageId: string
  activeLotId: string
  lotFilter: LotFilterInterface
  shortlists: ShortlistState['shortlists']
  levelStagePrefix: string
  onLotConfigurationValueChange?: LotCardProps['onConfigurationValueChange']
  onLotConfigurationOptionSelect?: LotCardProps['onConfigurationOptionSelect']
  onLotConfigurationPropertyExpand?: LotCardProps['onConfigurationPropertyExpand']
}

const PADDING_FROM_CONTAINER_TOP = 124

function LotsSidePanel({
  projectIdentity,
  stages,
  packages,
  filterPopupToggle,
  setFilterPopupToggle,
  activeStageId,
  activePackageId,
  activeLotId,
  lotFilter,
  shortlists,
  levelStagePrefix,
  onLotConfigurationValueChange,
  onLotConfigurationOptionSelect,
  onLotConfigurationPropertyExpand,
}: LotsSidePanelProps) {
  const initialActivePackageIdRef = React.useRef<string | null>(activePackageId)
  const lotSidePanelContainerRef = React.useRef<HTMLDivElement>(null)
  const handleInitialActivePackageIdRef = React.useRef(true)

  const firebaseControlQuery = FirebaseControlQuery({
    ...projectIdentity,
    sessionKey: projectIdentity.sessionId,
  })

  const packagesOfLot = React.useCallback(
    (lotId: string) =>
      packages?.filter((item: PackageSummaryInterface) =>
        item.lots.includes(lotId)
      ),
    [packages]
  )

  const filteredCount = React.useMemo(() => {
    if (lotFilter.apply) {
      return countFilterChanges(initialFilter, lotFilter)
    }
    return 0
  }, [lotFilter.apply])

  const { defaultPackageId, validInitialActivePackageId } =
    React.useMemo(() => {
      const matchedStage = stages.find((stage) => stage.label === activeStageId)
      const matchedLot = matchedStage?.lots?.find(
        (lot) => lot.name === activeLotId
      )
      const lotPackages = matchedLot?.id ? packagesOfLot(matchedLot.id) : []
      return {
        defaultPackageId: lotPackages?.[0]?.id,
        validInitialActivePackageId: lotPackages?.find(
          (item) => item.id === initialActivePackageIdRef.current
        )?.id,
      }
    }, [activeLotId, packagesOfLot])

  const { isShortlisted, shortlistedConfiguration } = React.useMemo(() => {
    const matchedShortlist = shortlists.find(
      (item) => item.propertyName === activeLotId
    ) as LotShortlistInterface | undefined
    return {
      isShortlisted: !!matchedShortlist,
      shortlistedConfiguration: matchedShortlist?.configuration,
    }
  }, [shortlists, activeLotId])

  React.useEffect(() => {
    if (!activeLotId) {
      firebaseControlQuery.update({
        [`houseAndLand.activePackageId`]: '',
      })
      return
    }
    if (
      initialActivePackageIdRef.current &&
      handleInitialActivePackageIdRef.current &&
      validInitialActivePackageId
    ) {
      firebaseControlQuery.update({
        [`houseAndLand.activePackageId`]: validInitialActivePackageId,
      })
      handleInitialActivePackageIdRef.current = false
      return
    }
    const value = defaultPackageId || ''
    firebaseControlQuery.update({
      [`houseAndLand.activePackageId`]: value,
    })
  }, [activeLotId, defaultPackageId, validInitialActivePackageId])

  React.useEffect(() => {
    if (!activeLotId || !isShortlisted) {
      return
    }
    const value = shortlistedConfiguration?.floorplan || ''
    firebaseControlQuery.update({
      [`houseAndLand.activePackageId`]: value,
    })
  }, [isShortlisted, activeLotId])

  React.useEffect(() => {
    const timer = setTimeout(() => {
      const activeStageElement = document.querySelector(
        '.active-stage'
      ) as HTMLDivElement

      if (activeStageElement && lotSidePanelContainerRef?.current) {
        const offsetTop =
          activeStageElement.offsetTop -
          lotSidePanelContainerRef.current.offsetTop

        lotSidePanelContainerRef.current.scrollTo({
          top: offsetTop,
          behavior: 'smooth',
        })
      }
    }, 500)
    return () => clearTimeout(timer)
  }, [activeStageId, lotSidePanelContainerRef.current])

  React.useEffect(() => {
    const timer = setTimeout(() => {
      const activeLotElement = document.getElementById(activeLotId)
      if (activeLotElement && lotSidePanelContainerRef?.current) {
        lotSidePanelContainerRef.current.scrollTo({
          top: activeLotElement.offsetTop - PADDING_FROM_CONTAINER_TOP,
          behavior: 'smooth',
        })
      }
    }, 500)
    return () => clearTimeout(timer)
  }, [activeLotId, lotSidePanelContainerRef.current])

  return (
    <div className="h-full w-full bg-[#EEEEEE]">
      <div className="flex justify-between bg-white p-4">
        <div className="flex items-center space-x-4">
          <BackButton activeLotId={activeLotId} activeStageId={activeStageId} />
          <ActionsButton />
        </div>
        <AddFilterButton
          filterCount={filteredCount}
          popupState={filterPopupToggle}
          togglePopup={setFilterPopupToggle}
        />
      </div>
      <div
        ref={lotSidePanelContainerRef}
        className="house-and-land-scrollbar-v2 h-unit-sidepanel-container overflow-y-scroll px-4 pb-0.5"
      >
        {stages.length > 0 ? (
          stages.map((stage) => (
            <div
              key={stage.id}
              className={`${
                stage.label === activeStageId ? 'active-stage' : ''
              }`}
            >
              <StageHead
                stage={stage}
                active={stage.label === activeStageId}
                levelStagePrefix={levelStagePrefix}
              />
              <div className="flex flex-col space-y-3">
                {stage.lots.length > 0 ? (
                  stage.lots.map((lot) => (
                    <LotCard
                      key={lot.id}
                      lot={lot}
                      stageInfo={stage}
                      active={activeLotId === lot.name}
                      packages={packagesOfLot(lot.id)}
                      onConfigurationValueChange={
                        activeLotId === lot.name
                          ? onLotConfigurationValueChange
                          : undefined
                      }
                      onConfigurationOptionSelect={
                        activeLotId === lot.name
                          ? onLotConfigurationOptionSelect
                          : undefined
                      }
                      onConfigurationPropertyExpand={
                        activeLotId === lot.name
                          ? onLotConfigurationPropertyExpand
                          : undefined
                      }
                    />
                  ))
                ) : (
                  <NoLotCard />
                )}
              </div>
            </div>
          ))
        ) : (
          <NoLotCard />
        )}
      </div>
    </div>
  )
}

export default connect(
  ({
    projectIdentity,
    firestore: { session },
    lotFilter,
    shortlist: { shortlists },
    whiteLabel,
  }: RootStateTypeExtra) => ({
    projectIdentity,
    lotFilter,
    activeStageId: session?.houseAndLand?.activeStageId || '',
    activeLotId: session?.houseAndLand?.activeLotId || '',
    activePackageId: session?.houseAndLand?.activePackageId || '',
    shortlists,
    levelStagePrefix:
      whiteLabel?.showcase?.propertySidePanel?.levelStagePrefix || '',
  })
)(LotsSidePanel)
