import React, { useEffect, useMemo, useRef, useState } from 'react'
import { connect } from 'react-redux'

import { FilterOptionsConfigurations } from '@src/utilities/adgroup-utilities/types'

import { RootStateType } from '@store/store'
import {
  LotFilterConfiguration,
  LotFilterInterface,
  ProjectIdentity,
} from '@store/types'

import DropDown from '@components/dropdown/v2'
import FILTER_INITIAL_STATE from '@components/filter/v2/filterStateHouseAndLand'
import ToggleSwitch from '@components/toggle-switch/v2'

import {
  PrecinctListItemInterface,
  SummaryCollectionInterface,
} from '@api/houseAndLand'
import { FilterWhiteLabel } from '@api/white-label'

import FirebaseControlQuery from '@firebaseUtil/firebase-control-query'

import { CloseSvg } from '@svg/react'

import { MinMaxInterface } from '@adUtilities/types/common'

import RangeOptions from './range-options'

export interface FilterProps {
  projectIdentity: ProjectIdentity
  filterWhiteLabel: FilterWhiteLabel
  isOpen: boolean
  activePrecinct: string
  setActivePrecinct: (arg: string) => void
  firebaseActiveStage: string
  firebaseLotFilter: LotFilterInterface
  precinctList: Array<PrecinctListItemInterface>
  packageSummary: SummaryCollectionInterface | null
  toggle: (arg: boolean) => void
  hideStages?: boolean
  isStageFetching?: boolean
}

interface FilterPayload {
  [key: string]: string | boolean
}

function HouseAndLandFilter({
  projectIdentity,
  filterWhiteLabel,
  isOpen,
  activePrecinct,
  setActivePrecinct,
  firebaseActiveStage,
  firebaseLotFilter,
  precinctList,
  packageSummary,
  toggle,
  hideStages,
  isStageFetching,
}: FilterProps) {
  const firebaseControlQuery = FirebaseControlQuery({
    ...projectIdentity,
    sessionKey: projectIdentity.sessionId,
  })
  const { prices, showPrice, propertyConfig, houseAndLandConfig, aspects } =
    projectIdentity

  const {
    lotFrontage: lotFrontageOptions,
    lotArea: lotAreaOptions,
    storey: storeyOptions,
    builder: builderOptions,
  } = houseAndLandConfig || {}

  const activePackageSummary = packageSummary?.[activePrecinct]
  const [activeStage, setActiveStage] = useState(firebaseActiveStage)
  const [checkAnyStage, setAnyStage] = useState(firebaseLotFilter.anyStage)
  const [isStageChange, setStageChange] = useState(false)

  const [lotConfigurations, setLotConfigurations] =
    useState<LotFilterConfiguration>(firebaseLotFilter.lotConfiguration)
  const [configurations, setConfigurations] =
    useState<FilterOptionsConfigurations>(firebaseLotFilter.configurations)
  const [storey, setStorey] = useState<Array<number>>(firebaseLotFilter.storey)
  const [builder, setBuilder] = useState<Array<string>>(
    firebaseLotFilter.builder
  )
  const [showAvailable, setShowAvailable] = useState(
    firebaseLotFilter.showOnlyAvailable
  )

  const filterRef = useRef<HTMLDivElement>(null)

  const precinctOptions = useMemo(
    () =>
      precinctList.map(({ label }) => ({
        label,
        value: label,
      })),
    [precinctList]
  )
  const stageOptions = useMemo(
    () =>
      Object.keys(activePackageSummary?.stages ?? {})?.map((label) => ({
        label: `Stage ${label}`,
        value: label,
      })),
    [activePrecinct, activePackageSummary]
  )

  const applyFilter = async () => {
    const filterPayload: FilterPayload = {
      [`houseAndLand.lotFilter.apply`]: true,
    }
    if (precinctOptions.length > 1) {
      filterPayload['houseAndLand.activePrecinctId'] = activePrecinct
    }
    if (!hideStages && stageOptions.length > 1) {
      filterPayload['houseAndLand.activeStageId'] = activeStage
    }
    firebaseControlQuery.update(filterPayload)
    toggle(false)
  }

  const handleAnyStage = (checked: boolean) =>
    firebaseControlQuery.update({
      [`houseAndLand.lotFilter.anyStage`]: checked,
    })
  const handleLotConfigurationChange = (configs: LotFilterConfiguration) =>
    firebaseControlQuery.update({
      [`houseAndLand.lotFilter.lotConfiguration`]: configs,
    })
  const handleConfigurationChange = (configs: FilterOptionsConfigurations) =>
    firebaseControlQuery.update({
      [`houseAndLand.lotFilter.configurations`]: configs,
    })
  const handleStoreyChange = (value: number) => {
    if (storey.includes(value)) {
      firebaseControlQuery.update({
        [`houseAndLand.lotFilter.storey`]: storey.filter(
          (item) => item !== value
        ),
      })
    } else {
      firebaseControlQuery.update({
        [`houseAndLand.lotFilter.storey`]: [...storey, value],
      })
    }
  }
  const handleAspectChange = (value: string) => {
    const lotAspect: string[] = lotConfigurations?.aspects
    if (lotAspect) {
      let filterAspect: string[] = []
      if (lotAspect.includes(value)) {
        filterAspect = lotAspect.filter((item) => item !== value)
      } else {
        filterAspect = [...lotAspect, value]
      }
      firebaseControlQuery.update({
        [`houseAndLand.lotFilter.lotConfiguration.aspects`]: filterAspect,
      })
    }
  }
  const handleBuilderChange = (value: string) => {
    if (builder.includes(value)) {
      firebaseControlQuery.update({
        [`houseAndLand.lotFilter.builder`]: builder.filter(
          (item) => item !== value
        ),
      })
    } else {
      firebaseControlQuery.update({
        [`houseAndLand.lotFilter.builder`]: [...builder, value],
      })
    }
  }

  const handleShowAvailable = (checked: boolean) =>
    firebaseControlQuery.update({
      [`houseAndLand.lotFilter.showOnlyAvailable`]: checked,
    })

  const resetFilter = async () => {
    await firebaseControlQuery.update({
      [`houseAndLand.lotFilter`]: FILTER_INITIAL_STATE,
    })
  }

  const handleOutsideClick = (event: MouseEvent) => {
    if (
      isOpen &&
      filterRef.current &&
      !filterRef.current.contains(event.target as Node)
    ) {
      toggle(false)
    }
  }

  useEffect(() => {
    if (isStageChange) {
      const firstStage =
        Object.keys(activePackageSummary?.stages ?? {})?.[0] || ''
      setActiveStage(firstStage)
    }
  }, [activePackageSummary, activePrecinct])

  useEffect(() => {
    setAnyStage(firebaseLotFilter.anyStage)
    setLotConfigurations(firebaseLotFilter.lotConfiguration)
    setConfigurations(firebaseLotFilter.configurations)
    setStorey(firebaseLotFilter.storey)
    setBuilder(firebaseLotFilter.builder)
    setShowAvailable(firebaseLotFilter.showOnlyAvailable)
  }, [firebaseLotFilter])

  useEffect(() => {
    if (isOpen && firebaseLotFilter?.apply) {
      firebaseControlQuery.update({
        [`houseAndLand.lotFilter.apply`]: false,
      })
    }
  }, [firebaseLotFilter, activeStage, activePrecinct])

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      document.addEventListener('click', handleOutsideClick, false)
    }, 0)
    return () => {
      clearTimeout(timeoutId)
      document.removeEventListener('click', handleOutsideClick)
    }
  }, [isOpen])

  return (
    <div
      ref={filterRef}
      className={`fixed top-[36px] z-30 h-page-container w-[297px] rounded-tl-2xl bg-white px-4 pb-10 text-black shadow-2xl ${
        isOpen ? '' : '-translate-x-full'
      } transition-transform duration-500`}
    >
      <div className="flex h-screen flex-col">
        <div className="mb-2 mt-6 flex items-center justify-between">
          <div>
            <button
              className="flex items-center"
              type="button"
              onClick={() => toggle(!isOpen)}
            >
              <CloseSvg
                className="h-3.5 w-3.5 cursor-pointer"
                strokeColor="black"
              />
            </button>
          </div>
          <div>
            <h4 className="text-xl font-semibold">Filter</h4>
          </div>
          <div>
            <button
              type="button"
              onClick={resetFilter}
              className="text-base font-medium text-mainColour underline"
            >
              Reset
            </button>
          </div>
        </div>
        <div className="no-scrollbar h-[84%] overflow-y-auto">
          <div className="mt-6 flex flex-col gap-[1.20rem] text-base font-medium">
            {precinctOptions.length > 1 && (
              <div className="flex flex-col gap-1">
                <span>
                  {filterWhiteLabel?.precinctDropdownLabel || 'Precinct'}
                </span>
                <DropDown
                  onSelect={(value) => {
                    setActivePrecinct(value)
                    setStageChange(true)
                  }}
                  items={precinctOptions}
                  value={activePrecinct}
                  className="py-2"
                  buttonRadius="rounded-[7px]"
                  buttonRadiusClasses="rounded-tl-[7px] rounded-tr-[7px]"
                  optionRadiusClasses="rounded-bl-[7px] rounded-br-[7px]"
                />
              </div>
            )}

            {!hideStages && isStageFetching && (
              <div className="flex flex-col gap-1">
                <div className="h-4 w-16 rounded-lg bg-slate-400/20">
                  <div className="shimmer-container relative !p-2 opacity-50"></div>
                </div>
                <div className="h-10 w-full rounded-lg bg-slate-400/20">
                  <div className="shimmer-container relative opacity-50"></div>
                </div>
                <div className="mt-2 h-4 w-32 rounded-lg bg-slate-400/20">
                  <div className="shimmer-container relative !p-2 opacity-50"></div>
                </div>
              </div>
            )}

            {stageOptions?.length > 1 && !hideStages && !isStageFetching && (
              <div className="flex flex-col gap-1">
                <span className={` ${checkAnyStage ? 'text-gray-300' : ''}`}>
                  {filterWhiteLabel?.stageDropdownLabel || 'Stage'}
                </span>
                <DropDown
                  onSelect={(value) => {
                    setActiveStage(value)
                  }}
                  items={stageOptions}
                  disabled={checkAnyStage}
                  value={activeStage}
                  className="py-2"
                  buttonRadius="rounded-[7px]"
                  buttonRadiusClasses="rounded-tl-[7px] rounded-tr-[7px]"
                  optionRadiusClasses="rounded-bl-[7px] rounded-br-[7px]"
                />

                <div className="mt-2 inline-flex items-center">
                  <ToggleSwitch
                    key="anyStage"
                    onChangeCallback={(e) => {
                      handleAnyStage(e.target.checked)
                    }}
                    initialState={checkAnyStage}
                    onColor="bg-mainColour"
                    offColor="bg-[#BDC3C7]"
                  />
                  <div className="ml-3 text-base font-medium">Any Stage</div>
                </div>
              </div>
            )}

            {showPrice && (
              <RangeOptions
                field="price"
                options={prices}
                range={lotConfigurations.price}
                handleChange={(configValue: MinMaxInterface) => {
                  handleLotConfigurationChange({
                    ...lotConfigurations,
                    price: configValue,
                  })
                }}
              />
            )}

            {Object.entries(propertyConfig)?.map(
              ([field, value]) =>
                value?.length > 0 && (
                  <RangeOptions
                    field={field}
                    key={field}
                    options={value as unknown as Array<string | number>}
                    range={
                      configurations?.[
                        field as keyof FilterOptionsConfigurations
                      ] || {
                        min: 'Any',
                        max: 'Any',
                      }
                    }
                    handleChange={(configValue: MinMaxInterface) => {
                      handleConfigurationChange({
                        ...configurations,
                        [field as keyof FilterOptionsConfigurations]:
                          configValue,
                      })
                    }}
                  />
                )
            )}

            {(lotFrontageOptions?.length || 0) > 0 && (
              <RangeOptions
                field="lotFrontage"
                options={lotFrontageOptions || []}
                range={lotConfigurations.frontage}
                handleChange={(configValue: MinMaxInterface) => {
                  handleLotConfigurationChange({
                    ...lotConfigurations,
                    frontage: configValue,
                  })
                }}
              />
            )}

            {(lotAreaOptions?.length || 0) > 0 && (
              <RangeOptions
                field="lotArea"
                options={lotAreaOptions || []}
                range={lotConfigurations.area}
                handleChange={(configValue: MinMaxInterface) => {
                  handleLotConfigurationChange({
                    ...lotConfigurations,
                    area: configValue,
                  })
                }}
              />
            )}

            {(storeyOptions?.length || 0) > 0 && (
              <div className="flex flex-col gap-1">
                <span>Storey</span>
                <div className="grid grid-cols-1 gap-2">
                  {storeyOptions?.map(({ label, value }) => (
                    <button
                      type="button"
                      key={label}
                      onClick={() => {
                        handleStoreyChange(value)
                      }}
                      className={`rounded py-2 text-center text-base font-medium ${
                        storey.includes(value)
                          ? 'bg-mainColour text-white'
                          : 'bg-[#E5E5E5] hover:bg-[#BDC3C7]'
                      }`}
                    >
                      {label}
                    </button>
                  ))}
                </div>
              </div>
            )}

            {aspects?.length > 0 && (
              <div className="flex flex-col gap-1">
                <span>Aspect</span>
                <div className="grid-cols-auto grid gap-2 md:grid-cols-[repeat(auto-fit,minmax(80px,1fr))]">
                  {aspects?.map((aspectValue: string) => (
                    <button
                      type="button"
                      key={aspectValue}
                      onClick={() => {
                        handleAspectChange(aspectValue.toLowerCase())
                      }}
                      className={`truncate rounded px-2 py-2 text-center text-base font-medium ${
                        lotConfigurations?.aspects?.includes(
                          aspectValue.toLowerCase()
                        )
                          ? 'bg-mainColour text-white'
                          : 'bg-[#E5E5E5] hover:bg-[#BDC3C7]'
                      }`}
                    >
                      {aspectValue}
                    </button>
                  ))}
                </div>
              </div>
            )}

            {(builderOptions?.length || 0) > 0 && (
              <div className="flex flex-col gap-1">
                <span>Builder</span>
                <div className="grid grid-cols-1 gap-2">
                  {builderOptions?.map((builderValue: string) => (
                    <button
                      type="button"
                      key={builderValue}
                      onClick={() => {
                        handleBuilderChange(builderValue.toLowerCase())
                      }}
                      className={`rounded py-2 text-center text-base font-medium ${
                        builder.includes(builderValue.toLowerCase())
                          ? 'bg-mainColour text-white'
                          : 'bg-[#E5E5E5] hover:bg-[#BDC3C7]'
                      }`}
                    >
                      {builderValue}
                    </button>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="mb-12 flex flex-col gap-[1.20rem]">
          <div className="mt-2 inline-flex items-center whitespace-nowrap">
            <ToggleSwitch
              key="checkbox"
              onChangeCallback={(e) => {
                handleShowAvailable(e.target.checked)
              }}
              initialState={showAvailable}
              onColor="bg-mainColour"
              offColor="bg-[#BDC3C7]"
            />
            <div className="ml-3 text-base font-medium">
              {filterWhiteLabel?.toggleText || 'Show only available lots'}
            </div>
          </div>
          <div className="flex flex-col gap-1">
            <button
              type="button"
              onClick={applyFilter}
              disabled={firebaseLotFilter.apply}
              className={`rounded-lg px-4 py-4 text-xl font-semibold text-white duration-300 ${
                firebaseLotFilter.apply ? 'bg-gray-300' : 'bg-mainColour'
              }`}
            >
              Apply Filters
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default connect(
  ({
    projectIdentity,
    whiteLabel,
    houseAndLand: { precinctList, packageSummary },
  }: RootStateType) => ({
    projectIdentity,
    filterWhiteLabel: whiteLabel?.showcase?.filter,
    precinctList,
    packageSummary,
  })
)(HouseAndLandFilter)
