import React from 'react'

import { NavigationMenuKey, RouteKey } from '@store/constants'
import { MenuItemInterface } from '@store/types'

import { SessionSettingsModalAction } from '@components/session-settings'
import { ToolsModalAction } from '@components/tools'

const MENU_ITEMS: Array<MenuItemInterface> = [
  {
    key: NavigationMenuKey.RESIDENCES,
    label: 'Residences',
    route: RouteKey.AREA_VIEW,
    relatedRoutes: [
      RouteKey.AREA_VIEW,
      RouteKey.LEVEL_VIEW,
      RouteKey.BUILDING,
      RouteKey.AREA_VIEW_HOUSE_AND_LAND,
      RouteKey.PRECINCT,
      RouteKey.STAGES,
      RouteKey.LOT_VIEW,
      RouteKey.SNAPLOADER_VIEW,
      RouteKey.THREE_D_BUILDING,
      RouteKey.PANORAMIC_VIEW,
      RouteKey.PANORAMIC,
      RouteKey.ADTHREED,
    ],
    active: true,
    type: 'main-menu',
    routeViaFirebase: true,
    order: 1,
  },
  {
    key: NavigationMenuKey.INTERACTIVE_MAP,
    label: 'Map',
    route: RouteKey.INTERACTIVE_MAP,
    relatedRoutes: [RouteKey.INTERACTIVE_MAP],
    active: true,
    type: 'main-menu',
    routeViaFirebase: true,
    order: 2,
  },
  {
    key: NavigationMenuKey.VIDEOS,
    label: 'Videos',
    route: RouteKey.VIDEO_GALLERY,
    relatedRoutes: [RouteKey.VIDEO_GALLERY],
    active: true,
    type: 'main-menu',
    routeViaFirebase: true,
    order: 3,
  },
  {
    key: NavigationMenuKey.GALLERY,
    label: 'Gallery',
    route: RouteKey.GALLERY,
    relatedRoutes: [RouteKey.GALLERY],
    active: true,
    type: 'main-menu',
    routeViaFirebase: true,
    order: 4,
  },
  {
    key: NavigationMenuKey.BROCHURE,
    label: 'Brochure',
    route: RouteKey.BROCHURE,
    relatedRoutes: [RouteKey.BROCHURE],
    active: true,
    type: 'main-menu',
    routeViaFirebase: true,
    order: 5,
  },
  {
    key: NavigationMenuKey.TEAM,
    label: 'Team',
    route: RouteKey.TEAMS,
    relatedRoutes: [RouteKey.TEAMS],
    active: true,
    type: 'main-menu',
    routeViaFirebase: true,
    order: 6,
  },
  {
    key: NavigationMenuKey.EXTERNAL_ROUTES,
    label: 'External links',
    route: RouteKey.EXTERNAL_VIEWS,
    relatedRoutes: [RouteKey.EXTERNAL_VIEWS],
    active: true,
    type: 'main-menu',
    routeViaFirebase: true,
    order: 7,
  },
  {
    key: NavigationMenuKey.SHORTLIST,
    label: 'Shortlist',
    route: RouteKey.SHORTLIST,
    relatedRoutes: [RouteKey.SHORTLIST],
    active: true,
    type: 'main-menu',
    routeViaFirebase: true,
    order: 8,
  },
  {
    key: NavigationMenuKey.SESSION_SETTINGS,
    label: 'Session',
    active: true,
    type: 'footer-menu',
    order: 9,
    render: ({ menu, key, style, tabIndex, className }) => (
      <SessionSettingsModalAction
        key={key}
        className={className}
        style={style}
        tabIndex={tabIndex}
      >
        {menu.label}
      </SessionSettingsModalAction>
    ),
  },
  {
    key: NavigationMenuKey.TOOLS,
    label: 'Tools',
    active: true,
    type: 'footer-menu',
    order: 10,
    render: ({ menu, key, style, tabIndex, className }) => (
      <ToolsModalAction
        key={key}
        className={className}
        style={style}
        tabIndex={tabIndex}
      >
        {menu.label}
      </ToolsModalAction>
    ),
  },
]

export default MENU_ITEMS
