import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'

import {
  BucketSource,
  LotGallery,
  PlayerState,
  ProjectIdentity,
  RootStateTypeExtra,
} from '@store/types'

import DataHandler from '@components/data-handler/v2'
import { PannellumDataInterface } from '@components/showcase-pannellum/types'
import Skeleton from '@components/skeleton/v2'
import VideoPlayerControl from '@components/video-player'

import {
  selectFromResult as selectFromExternalLinkResult,
  useGetExternalLinksByPropertyQuery,
} from '@api/external-links'
import {
  selectFromResult as selectFromUnitGalleryResult,
  useGetUnitGalleryQuery,
} from '@api/unit-gallery'

import useTabVisibility from '@utilities/tab-visibility'
import VideoPlayerFirebaseService from '@utilities/video-player-firebase-service'

import FirebaseControlQuery from '@firebaseUtil/firebase-control-query'
import { SessionMap } from '@firebaseUtil/types'

import { ArrowSvg, PlayCircleSvg } from '@svg/react'

import AssetHandler from '@adUtilities/asset-handler/asset-handler'
import { CarouselProvider } from '@adUtilities/components/carousel-provider'
import VideoPlayer from '@adUtilities/components/video-player'
import { VideoPlayerRef } from '@adUtilities/components/video-player/video-player'
import { CarouselControlType } from '@adUtilities/constants/carousel'
import { FileType } from '@adUtilities/constants/common'
import { Unit, ViewLineImage } from '@adUtilities/types/apartment'

import TopControlBar from './top-control-bar'
import UnitInfoCard from './unit-info-card'

export interface UnitViewProps {
  projectIdentity: ProjectIdentity
  session: SessionMap | undefined
  unit: Unit
  panoramic: PannellumDataInterface[]
  fullScreenToggle: boolean
  setFullScreenToggle: (arg: boolean) => void
  getVideoPlayerState: (arg: boolean) => void
}

const NO_IMAGE_OBJECT: LotGallery = {
  id: 'no-image',
  src: '',
  label: 'No Image',
  type: FileType.Image,
  noSpliceUrl: false,
}

const GALLERY_NAME = 'propertyGallery'

function UnitView({
  projectIdentity,
  session,
  unit,
  panoramic,
  fullScreenToggle,
  setFullScreenToggle,
  getVideoPlayerState,
}: UnitViewProps) {
  const firebaseControlQuery = FirebaseControlQuery({
    ...projectIdentity,
    sessionKey: projectIdentity.sessionId,
  })

  const history = useHistory()

  const {
    projectUUID,
    projectName,
    propertyGalleryControlStyle,
    onlyShowPropertyGalleries,
    gallerySettings,
  } = projectIdentity

  const { type: effectType, intervalInSeconds: autoPlayIntervalInSeconds } =
    gallerySettings || {}

  const videoPlayerRef = useRef<VideoPlayerRef>(null)

  const [gallery, setGallery] = useState<LotGallery[]>([])

  const [unitInfoToggle, setUnitInfoToggle] = useState(false)
  const [hasPanoramicData, setPanoramicDataState] = useState(false)
  const [showExternalLinks, setShowExternalLinks] = useState(false)
  const [shouldShowSlideshowBtn, setShouldShowSlideShowBtn] = useState(false)
  const [isVideoPlaying, setIsVideoPlaying] = useState(false)

  const [autoPlayState, setAutoPlayState] = useState(false)

  const [pinnedGalleryItemIndex, setPinnedGalleryItemIndex] = useState<
    number | null
  >(null)
  const [activeSlideIndex, setActiveSlideIndex] = useState(0)

  const [playerState, setPlayerState] = useState<PlayerState>(PlayerState.Stop)
  const [videoLengthInSeconds, setVideoLengthInSeconds] = useState(0)
  const [currentVideoPositionInSeconds, setCurrentVideoPositionInSeconds] =
    useState(0)
  const [volume, setVolume] = useState<number>(0)
  const [scrubbarTimerState, setScrubbarTimerState] = useState(false)
  const [isBuffering, setBufferState] = useState(false)
  const [isSeeking, setSeekingState] = useState(false)
  const [isMuted, setMuteState] = useState(true)

  const unitGalleryPayload = useGetUnitGalleryQuery(
    { projectName, unitId: unit.id },
    { selectFromResult: selectFromUnitGalleryResult }
  )

  const externalLinksPayload = useGetExternalLinksByPropertyQuery(
    { projectName, propertyId: unit?.name || '' },
    {
      skip: !unit?.name,
      selectFromResult: selectFromExternalLinkResult,
    }
  )

  const videoPlayerFirebaseService = VideoPlayerFirebaseService({
    galleryName: GALLERY_NAME,
    projectIdentity,
  })

  const unitGalleryItems = useMemo(
    () =>
      (gallery || []).map((item) => ({
        id: item.id,
        label: item.label,
        src:
          item.type === FileType.Image && item.src
            ? item.src
            : item.thumbnail || '',
        thumbnail: item.thumbnail,
        originalSrc: item.src,
        bucketType: BucketSource.Legacy,
        fileType: item.type as FileType,
        noSpliceUrl: item.noSpliceUrl,
      })),
    [gallery]
  )

  const activeSlide = useMemo(
    () => unitGalleryItems[activeSlideIndex],
    [unitGalleryItems, activeSlideIndex]
  )

  const hasVideoItems = useMemo(
    () => unitGalleryItems.some((item) => item.fileType === FileType.Video),
    [unitGalleryItems]
  )

  const videoSource = useMemo(
    () =>
      (activeSlide?.fileType === FileType.Video &&
        AssetHandler({
          url: activeSlide.originalSrc,
          type: activeSlide.bucketType,
        })) ||
      '',
    [unitGalleryItems, activeSlide]
  )

  const buildUnitGallery = (unitGallery: LotGallery[]) => {
    const galleryData: LotGallery[] = []

    const {
      metas: { floorImage, floorLabel, floorPdf, viewLineImages },
    } = unit

    if (
      !onlyShowPropertyGalleries &&
      viewLineImages &&
      viewLineImages.length > 0
    ) {
      viewLineImages.forEach((item: ViewLineImage) => {
        galleryData.push({
          ...item,
          id: item.id,
          src: `/${projectUUID}/views/${item.src}`,
          type: FileType.Image,
          noSpliceUrl: true,
        })
      })
    }

    if (!onlyShowPropertyGalleries && floorImage) {
      galleryData.unshift({
        id: floorPdf,
        type: FileType.Image,
        src: `/${projectUUID}/floorplans/preview/${floorImage}`,
        label: floorLabel || floorImage,
        noSpliceUrl: true,
      })
    }

    unitGallery.forEach((galleryItem: LotGallery) => {
      const gradiant = galleryItem.type === 'video' ? 0.5 : 0
      galleryData.push({ ...galleryItem, gradiant, noSpliceUrl: false })
    })

    if (!galleryData.length) {
      galleryData.push(NO_IMAGE_OBJECT)
    }

    setGallery(galleryData)
  }

  const handleCarouselItemClick = useCallback(async (index: number) => {
    setActiveSlideIndex(index)
    await firebaseControlQuery.update({
      [`${GALLERY_NAME}.galleryControlV2.activeSlideIndex`]: index,
    })
  }, [])

  const handleUnitInfoToggle = async (state: boolean) => {
    await firebaseControlQuery.update({
      [`building.unitInfoPopup`]: state,
    })
  }

  const handleGoToPanoramic = async () => {
    await firebaseControlQuery.update({
      [`panoramic.scene`]: '',
    })
    await firebaseControlQuery.updateRoute('panoramic')
    history.push('panoramic')
  }

  const handleSlideShowToggle = async (state: boolean) => {
    setFullScreenToggle(state)
    if (!state) {
      await firebaseControlQuery.update({
        [`${GALLERY_NAME}.galleryControlV2.activeSlideIndex`]: 0,
      })
    }
    await firebaseControlQuery.update({
      [`${GALLERY_NAME}.galleryControlV2.autoPlayState`]: state,
    })
  }

  const handleVideoPlayerClick = async (state: boolean) => {
    setIsVideoPlaying(state)
    getVideoPlayerState(state)
  }

  const handlePlayOnShowcase = async () => {
    setPlayerState(PlayerState.Play)
    setScrubbarTimerState(false)
    setIsVideoPlaying(true)
    await videoPlayerFirebaseService.updatePlayState()
  }

  const handlePlayOnRemote = () => {
    videoPlayerRef.current?.updatePlayerState(PlayerState.Play)
  }

  const handleTogglePin = async () => {
    const newPinnedIndex =
      pinnedGalleryItemIndex === activeSlideIndex ? null : activeSlideIndex

    try {
      await firebaseControlQuery.update({
        [`${GALLERY_NAME}.galleryControlV2.pinnedItemIndex`]: newPinnedIndex,
      })
    } catch {
      console.error('Failed to update pinned item index')
    }
  }

  const handlePlayClick = useCallback(async () => {
    setPlayerState(PlayerState.Play)
    setScrubbarTimerState(false)
    await videoPlayerFirebaseService.updatePlayState()
  }, [videoPlayerFirebaseService])

  const handlePauseClick = useCallback(async () => {
    setPlayerState(PlayerState.Pause)
    setScrubbarTimerState(false)
    await videoPlayerFirebaseService.updatePauseState()
  }, [videoPlayerFirebaseService])

  const handleStopClick = useCallback(async () => {
    setScrubbarTimerState(false)
    setPlayerState(PlayerState.Stop)
    setVideoLengthInSeconds(0)
    setCurrentVideoPositionInSeconds(0)
    setIsVideoPlaying(false)
    await videoPlayerFirebaseService.updateStopState()
  }, [videoPlayerFirebaseService])

  const handleVolumeDownClick = useCallback(async () => {
    if (volume > 0) {
      const newVolume = volume - 1

      setMuteState(false)
      setVolume(newVolume)
      await videoPlayerFirebaseService.updateVolumeState(newVolume)
    }
  }, [volume, videoPlayerFirebaseService])

  const handleVolumeUpClick = useCallback(async () => {
    if (volume < 10) {
      setMuteState(false)
      const newVolume = volume + 1
      setVolume(newVolume)
      await videoPlayerFirebaseService.updateVolumeState(newVolume)
    }
  }, [volume, videoPlayerFirebaseService])

  const handleMuteClick = useCallback(
    async (arg: boolean) => {
      setMuteState(arg)
      await videoPlayerFirebaseService.updateMuteState(arg)
    },
    [videoPlayerFirebaseService]
  )

  const handleSeekTo = useCallback(
    async (argInSeconds: number) => {
      setScrubbarTimerState(false)
      setSeekingState(true)
      await videoPlayerFirebaseService.updateSeekState(argInSeconds)
    },
    [videoPlayerFirebaseService]
  )

  const requestForVideoSync = useCallback(async () => {
    await videoPlayerFirebaseService.updateSyncState()
  }, [videoPlayerFirebaseService])

  const shouldDisablePlayOnShowcaseBtn =
    Boolean(
      pinnedGalleryItemIndex !== null &&
        pinnedGalleryItemIndex !== activeSlideIndex
    ) || autoPlayState

  useTabVisibility(
    useCallback(async () => {
      if (playerState === PlayerState.Play) {
        await requestForVideoSync()
      }
    }, [playerState])
  )

  useEffect(() => {
    const { unitGalleryData, isLoaded, isError } = unitGalleryPayload

    if (isLoaded && !isError && unitGalleryData.length) {
      buildUnitGallery(unitGalleryData)
    }
  }, [unitGalleryPayload])

  useEffect(() => {
    if (hasVideoItems && playerState === PlayerState.Play) {
      requestForVideoSync()
    }
  }, [hasVideoItems, playerState])

  useEffect(() => {
    const matchingPanoramicData = panoramic.find(
      (item: PannellumDataInterface) =>
        item.type === 'unit' &&
        item.panoramaGroup?.toString() === unit.name?.toString()
    )
    setPanoramicDataState(matchingPanoramicData !== undefined)
  }, [panoramic, unit])

  useEffect(() => {
    setShouldShowSlideShowBtn(gallery.length > 1)
  }, [gallery])

  useEffect(() => {
    if (!session) {
      return
    }

    const {
      building: { unitInfoPopup, externalLinkPopup },
      propertyGallery: {
        galleryControlV2: { activeSlideIndex, autoPlayState, pinnedItemIndex },
        playerControlV2: {
          playerState,
          videoLengthInSeconds,
          currentVideoPositionInSeconds,
          volume,
          scrubbarTimerState,
          seekToValueInSeconds,
          isBuffering,
          muted,
        },
      },
    } = session

    setUnitInfoToggle(unitInfoPopup)
    setShowExternalLinks(externalLinkPopup)

    setActiveSlideIndex(activeSlideIndex)
    setPinnedGalleryItemIndex(pinnedItemIndex)
    setAutoPlayState(autoPlayState)

    setPlayerState(playerState)
    setIsVideoPlaying(playerState === PlayerState.Play)

    setVideoLengthInSeconds(videoLengthInSeconds)
    setCurrentVideoPositionInSeconds(currentVideoPositionInSeconds)
    setVolume(volume)
    setScrubbarTimerState(scrubbarTimerState)
    setSeekingState(seekToValueInSeconds !== null)
    setBufferState(isBuffering)
    setMuteState(muted)
  }, [session?.propertyGallery])

  useEffect(
    () => () => {
      setIsVideoPlaying(false)
      videoPlayerFirebaseService.resetPlayerState()
      firebaseControlQuery.update({
        [`building.unitInfoPopup`]: false,
        [`${GALLERY_NAME}.galleryControlV2.autoPlayState`]: false,
        [`${GALLERY_NAME}.galleryControlV2.pinnedItemIndex`]: null,
      })
    },
    []
  )

  return (
    <DataHandler
      payload={{
        ...unitGalleryPayload,
        data: gallery,
      }}
      skeletonFrame={<Skeleton containerClass="!w-full absolute" />}
    >
      <div className="relative flex h-full w-full">
        {!isVideoPlaying && playerState === PlayerState.Stop && (
          <div className="absolute top-0 z-5 w-full items-start p-4">
            <TopControlBar
              hasPanoramicData={hasPanoramicData}
              unitInfoToggle={unitInfoToggle}
              slideShowToggle={autoPlayState}
              shouldShowSlideshowBtn={shouldShowSlideshowBtn}
              showExternalLinks={showExternalLinks}
              externalLinkToggle={
                externalLinksPayload?.externalLinks?.length > 0
              }
              isPinned={pinnedGalleryItemIndex === activeSlideIndex}
              fullScreenToggle={fullScreenToggle}
              setFullScreenToggle={setFullScreenToggle}
              setSlideShowToggle={handleSlideShowToggle}
              setShowExternalLinks={setShowExternalLinks}
              setUnitInfoToggle={handleUnitInfoToggle}
              setShowPanoramic={handleGoToPanoramic}
              togglePin={handleTogglePin}
            />
          </div>
        )}

        <div
          className={`h-92 pointer-events-none absolute bottom-0 z-20 w-full bg-shadow-horizontal opacity-75 transition duration-300 ease-in ${
            unitInfoToggle && !autoPlayState && playerState === PlayerState.Stop
              ? 'translate-y-0'
              : 'translate-y-full'
          }`}
        >
          <div
            className={`flex h-full transform items-stretch duration-200 ease-in ${
              fullScreenToggle ? 'pb-2' : 'pb-12'
            } pl-5`}
          >
            <div className="self-end">
              <UnitInfoCard unit={unit} />
            </div>
          </div>
        </div>

        <div className="absolute inset-0">
          <CarouselProvider
            allowSlideSwiping={
              !isVideoPlaying && playerState === PlayerState.Stop
            }
            controlPosition="right"
            autoPlay={autoPlayState}
            gallerySettings={{ effectType, autoPlayIntervalInSeconds }}
            showControl={!autoPlayState && playerState === PlayerState.Stop}
            mousewheel={false}
            slides={unitGalleryItems}
            activeSlideIndex={activeSlideIndex}
            controlType={propertyGalleryControlStyle as CarouselControlType}
            getCurrentSlideIndex={handleCarouselItemClick}
          />
        </div>

        {hasVideoItems && (
          <div
            className={`absolute inset-0 ${
              videoSource ? 'visible' : 'invisible'
            }`}
          >
            <div className="flex h-full w-full items-center justify-center">
              {!isVideoPlaying && playerState === PlayerState.Stop && (
                <div className="flex flex-row gap-5">
                  <button
                    type="button"
                    className="video-player-play-btn"
                    onClick={handlePlayOnShowcase}
                    disabled={shouldDisablePlayOnShowcaseBtn}
                  >
                    <PlayCircleSvg size="m" className="mr-2 h-6 w-6" />
                    <span>Play on Showcase</span>
                  </button>
                  <button
                    type="button"
                    className="video-player-play-btn"
                    onClick={handlePlayOnRemote}
                    disabled={autoPlayState}
                  >
                    <PlayCircleSvg size="m" className="mr-2 h-6 w-6" />
                    <span>Play on Remote</span>
                  </button>
                </div>
              )}

              <VideoPlayer
                ref={videoPlayerRef}
                showControls
                hidePlayButton
                sources={[videoSource]}
                activeIndex={0}
                getPlayerState={handleVideoPlayerClick}
                showLoopOption={false}
              />
            </div>
          </div>
        )}

        {hasVideoItems && (
          <div
            className={`absolute bottom-0 z-40 w-full px-2 pb-4 transition-all duration-1000 ${
              playerState !== PlayerState.Stop
                ? 'translate-y-0 opacity-100'
                : 'pointer-events-none fixed translate-y-full opacity-0'
            }`}
          >
            <VideoPlayerControl
              isLooping={false}
              showLoopOption={false}
              volume={volume}
              isMuted={isMuted}
              scrubbarTimerState={scrubbarTimerState}
              isBuffering={isBuffering}
              playerState={playerState}
              videoLengthInSeconds={videoLengthInSeconds}
              currentVideoPositionInSeconds={currentVideoPositionInSeconds}
              isSeeking={isSeeking}
              handlePlayClick={handlePlayClick}
              handlePauseClick={handlePauseClick}
              handleStopClick={handleStopClick}
              handleVolumeUpClick={handleVolumeUpClick}
              handleVolumeDownClick={handleVolumeDownClick}
              handleMuteClick={handleMuteClick}
              handleSeekTo={handleSeekTo}
            />
          </div>
        )}
      </div>

      {showExternalLinks && (
        <div
          className={`fixed left-0 z-30 w-screen bg-secondaryColour ${
            fullScreenToggle
              ? 'top-0 h-screen'
              : 'top-9 h-page-container overflow-hidden rounded-t-3xl'
          }`}
        >
          <button
            type="button"
            className="absolute left-5 top-5 z-3 rounded bg-white p-1 drop-shadow-40"
            onClick={() => setShowExternalLinks(false)}
          >
            <ArrowSvg className="h-8 w-8" strokeColor="#000" />
          </button>

          <div className="absolute left-1/2 top-1/2 z-1 -translate-x-1/2 -translate-y-1/2">
            <div className="h-16 w-16 animate-spin rounded-full border-4 border-solid border-mainColour border-t-transparent" />
          </div>

          <iframe
            key={externalLinksPayload?.externalLinks?.[0]?.linkUrl}
            className="relative z-2 h-full w-full overflow-hidden border-0"
            title={externalLinksPayload?.externalLinks?.[0]?.label}
            src={externalLinksPayload?.externalLinks?.[0]?.linkUrl}
            height="100%"
            width="100%"
          />
        </div>
      )}
    </DataHandler>
  )
}

export default connect(
  ({ projectIdentity, firestore: { session } }: RootStateTypeExtra) => ({
    projectIdentity,
    session,
  })
)(UnitView)
