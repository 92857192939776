import React from 'react'
import { FallbackProps } from 'react-error-boundary'
import { connect } from 'react-redux'

import { ProjectIdentity, RootStateTypeExtra } from '@store/types'

import Container from '@components/container'

import FirebaseControlQuery from '@firebaseUtil/firebase-control-query'
import { SessionMap } from '@firebaseUtil/types'

import useClearCache from '@hooks/useClearCache'

import { ArrowPathSvg } from '@svg/react'

import { setBackground } from '@adUtilities/asset-handler/asset-util'

const FALLBACK_IMAGE_URL = process.env.REACT_APP_PLACEHOLDER_IMAGE_URL || ''

export type ErrorBoundaryFallbackProps = Partial<FallbackProps> & {
  projectIdentity: ProjectIdentity
  session: SessionMap | undefined
}

function ErrorBoundaryFallback({
  projectIdentity,
  session,
  resetErrorBoundary,
}: ErrorBoundaryFallbackProps) {
  const firebaseControlQuery = FirebaseControlQuery({
    ...projectIdentity,
    sessionKey: projectIdentity.sessionId,
  })

  const [isCacheCleared, handleClearCache] = useClearCache({
    projectIdentity,
    session,
  })

  const onClearCache = () => {
    handleClearCache()
  }

  React.useEffect(() => {
    if (isCacheCleared) {
      resetErrorBoundary?.()
      firebaseControlQuery
        .update({
          [`activeRoute`]: 'vision',
        })
        .then(() => {
          window.location.reload()
        })
    }
  }, [isCacheCleared])

  return (
    <Container>
      <div className="h-screen">
        <div
          className="relative flex h-full flex-col items-center justify-center"
          style={{
            backgroundImage:
              setBackground(FALLBACK_IMAGE_URL)?.backgroundImage ?? '',
          }}
        >
          <ArrowPathSvg className="h-32 w-32 text-neutral-grey" />
          <h3 className="mb-8 mt-4 text-center text-4xl font-bold text-neutral-grey">
            New updates available!
          </h3>
          <p className="mb-6 text-center text-2xl">
            It looks like this page hasn&apos;t loaded correctly, possibly due
            to recent updates. Please go back to the main page or use the menu
            to explore other sections. Alternatively, click below to refresh.
          </p>
          <button
            type="button"
            className="rounded-lg bg-tertiaryColour px-4 py-2 text-xl text-neutralColour"
            onClick={onClearCache}
          >
            Refresh Now
          </button>
        </div>
      </div>
    </Container>
  )
}

export default connect(
  ({ projectIdentity, firestore: { session } }: RootStateTypeExtra) => ({
    projectIdentity,
    session,
  })
)(ErrorBoundaryFallback)
