import React from 'react'
import { connect } from 'react-redux'

import {
  InteractivePlanType,
  ProjectIdentity,
  RootStateTypeExtra,
} from '@store/types'

import DataHandler from '@components/data-handler/v2'
import { Status } from '@components/data-handler/v2/data-handler'
import {
  PannellumDataInterface,
  PanoramaType,
} from '@components/showcase-pannellum/types'
import VideoPlayerControl from '@components/video-player'

import { DEFAULT_GALLERY_SETTINGS } from '@pages/properties-view/house-and-land/v2/constants'
import VideoPlaybackActions, {
  ActionType,
} from '@pages/properties-view/house-and-land/v2/video-playback-actions'

import {
  selectFromResult as selectFromFloorPlateGalleryResult,
  useGetFloorPlateGalleryQuery,
} from '@api/floor-plate-gallery'
import {
  LotInterface,
  selectPrecinctListFromResult,
  useGetPrecinctListQuery,
} from '@api/houseAndLand'
import {
  selectFromResult as selectStageFromResult,
  useGetInteractivePlanQuery,
} from '@api/interactive-plan'
import {
  selectFromResult as selectFromPanoramicResult,
  useGetPanoramicQuery,
} from '@api/panoramic'

import { getTabIndexByKey, getTabKeyByIndexBy } from '@utilities/floor-tab'
import useTabVisibility from '@utilities/tab-visibility'
import VideoPlayerFirebaseService from '@utilities/video-player-firebase-service'

import FirebaseControlQuery from '@firebaseUtil/firebase-control-query'
import { SessionMap } from '@firebaseUtil/types'

import { CarouselProvider } from '@adUtilities/components/carousel-provider'
import VideoPlayer from '@adUtilities/components/video-player'
import { VideoPlayerRef } from '@adUtilities/components/video-player/video-player'
import { CarouselControlType } from '@adUtilities/constants/carousel'
import {
  BucketSource,
  FileType,
  PlayerState,
} from '@adUtilities/constants/common'
import { GallerySettings } from '@adUtilities/types/carousel'

import StageCanvas from './stage-canvas'
import StageGalleryTab from './stage-gallery-tab'
import StageSkeleton from './stage-skeleton'

interface ComponentProps {
  originalLots: Array<LotInterface>
  filteredLots: Array<LotInterface>
  getVideoPlayerState: (arg: boolean) => void
  projectIdentity: ProjectIdentity
  session: SessionMap | undefined
  activePrecinctId: string
  activeStageId: string
  activeLotId: string
  fullScreenToggle: boolean
  setFullScreenToggle: (arg: boolean) => void
}

function StageView({
  originalLots,
  filteredLots,
  getVideoPlayerState,
  projectIdentity,
  session,
  activePrecinctId,
  activeStageId,
  activeLotId,
  fullScreenToggle,
  setFullScreenToggle,
}: ComponentProps) {
  const videoPlayerRef = React.useRef<VideoPlayerRef>(null)
  const connectedRef = React.useRef(false)

  const firebaseControlQuery = FirebaseControlQuery({
    ...projectIdentity,
    sessionKey: projectIdentity.sessionId,
  })

  const videoPlayerFirebaseService = VideoPlayerFirebaseService({
    galleryName: 'floorGallery',
    projectIdentity,
  })

  const [isConnected, setIsConnected] = React.useState(false)

  const [isBuffering, setBufferState] = React.useState(false)
  const [scrubbarTimerState, setScrubbarTimerState] = React.useState(false)
  const [playerControlState, setPlayerControlState] =
    React.useState<PlayerState>(PlayerState.Stop)
  const [playerState, setPlayerState] = React.useState<PlayerState>(
    PlayerState.Stop
  )
  const [isMuted, setMuteState] = React.useState(true)
  const [volume, setVolume] = React.useState<number>(0)
  const [videoLengthInSeconds, setVideoLengthInSeconds] = React.useState(0)
  const [currentVideoPositionInSeconds, setCurrentVideoPositionInSeconds] =
    React.useState(0)
  const [isSeeking, setSeekingState] = React.useState(false)

  const gallerySettings: GallerySettings = React.useMemo(
    () => ({
      autoPlayIntervalInSeconds:
        projectIdentity?.gallerySettings?.intervalInSeconds ||
        DEFAULT_GALLERY_SETTINGS.autoPlayIntervalInSeconds,
      effectType:
        projectIdentity?.gallerySettings?.type ||
        DEFAULT_GALLERY_SETTINGS.effectType,
    }),
    [projectIdentity?.gallerySettings]
  )

  const precinctsPayload = useGetPrecinctListQuery(
    { projectName: projectIdentity.projectName },
    {
      selectFromResult: selectPrecinctListFromResult,
    }
  )

  const precinctList = precinctsPayload?.precinctList

  const stagePlanMapKey = React.useMemo(
    () =>
      precinctList?.length > 1
        ? `${activePrecinctId}-${activeStageId}`
        : activeStageId,
    [precinctList, activePrecinctId, activeStageId]
  )

  const stagePayload = useGetInteractivePlanQuery(
    {
      projectName: projectIdentity.projectName,
      type: InteractivePlanType.Stages,
      slug: stagePlanMapKey,
    },
    { selectFromResult: selectStageFromResult }
  )

  const stagePlan = stagePayload?.maps.stages

  const floorPlateGalleryPayload = useGetFloorPlateGalleryQuery(
    { projectName: projectIdentity.projectName },
    { selectFromResult: selectFromFloorPlateGalleryResult }
  )

  const floorPlateGallery = floorPlateGalleryPayload.floorPlateGalleryData

  const panoramicPayload = useGetPanoramicQuery(
    { projectName: projectIdentity.projectName },
    { selectFromResult: selectFromPanoramicResult }
  )

  const panoramic = panoramicPayload.panoramicData

  const [activeTab, setActiveTab] = React.useState('floor')
  const [currentSlideIndex, setCurrentSlideIndex] = React.useState(0)

  const handleNavigateToPanoramic = React.useCallback(() => {
    firebaseControlQuery.updateRoute('panoramic')
  }, [])

  const hasPanoramicData: boolean = React.useMemo(() => {
    const foundItem = panoramic.find(
      (item: PannellumDataInterface) =>
        item.type === PanoramaType.Stage &&
        (item.panoramaGroup || '')?.toString() === activeStageId?.toString()
    )
    return foundItem !== undefined
  }, [panoramic, activeStageId])

  const slides = React.useMemo(() => {
    if (floorPlateGallery.length < 1) {
      return []
    }
    let foundData = null

    if (activePrecinctId && activeStageId) {
      foundData = floorPlateGallery.find(
        (value) =>
          (value.precinct || '').toString() === activePrecinctId &&
          (value.stage || '').toString() === activeStageId
      )
    } else {
      foundData = floorPlateGallery.find(
        (value) => (value.stage || '').toString() === activeStageId
      )
    }

    if (foundData === null) {
      return []
    }

    const floorAssets = foundData?.assets || []

    if (floorAssets.length === 0) {
      return []
    }

    return floorAssets.map((item) => ({
      id: item.id,
      src: item.imageSrc || '',
      videoSource: item.videoSrc,
      thumbnail: item.imageSrc,
      fileType: item.type as FileType,
      bucketType: BucketSource.Legacy,
      noSpliceUrl: false,
    }))
  }, [activeStageId, activePrecinctId, floorPlateGallery])

  const hasVideoItems: boolean = React.useMemo(
    () => slides.filter((item) => item.fileType === FileType.Video).length > 0,
    [slides]
  )

  const videoSource: string = React.useMemo(() => {
    const item = slides.find(
      (slide, index) =>
        slide.fileType === FileType.Video && index === currentSlideIndex
    )

    return item ? item.videoSource || '' : ''
  }, [slides, currentSlideIndex])

  const handleFloorGalleryTabClick = async (tabKey: string) => {
    setActiveTab(tabKey)
    await firebaseControlQuery.update({
      [`floorGallery.galleryControlV2.activeSlideIndex`]: 0,
      [`floorGallery.galleryControlV2.activeTabIndex`]:
        getTabIndexByKey(tabKey),
    })
  }

  const handleChangeCurrentSlideIndex = React.useCallback(
    (index: number) => {
      if (currentSlideIndex === index) {
        return
      }
      setCurrentSlideIndex(index)
      firebaseControlQuery.update({
        [`floorGallery.galleryControlV2.activeSlideIndex`]: index,
        [`floorGallery.playerControlV2.playerState`]: PlayerState.Stop,
        [`floorGallery.playerControlV2.scrubbarTimerState`]: false,
        [`floorGallery.playerControlV2.videoLengthInSeconds`]: 0,
        [`floorGallery.playerControlV2.currentVideoPositionInSeconds`]: 0,
      })
    },
    [currentSlideIndex]
  )

  const handlePlayerState = React.useCallback(
    (status: boolean) => {
      getVideoPlayerState(status)
      setPlayerState(status ? PlayerState.Play : PlayerState.Stop)
    },
    [getVideoPlayerState]
  )

  const handlePlayClick = React.useCallback(async () => {
    setPlayerControlState(PlayerState.Play)
    setScrubbarTimerState(false)
    await videoPlayerFirebaseService.updatePlayState()
  }, [videoPlayerFirebaseService])

  const handlePauseClick = React.useCallback(async () => {
    setPlayerControlState(PlayerState.Pause)
    setScrubbarTimerState(false)
    await videoPlayerFirebaseService.updatePauseState()
  }, [videoPlayerFirebaseService])

  const handleStopClick = React.useCallback(async () => {
    setScrubbarTimerState(false)
    setPlayerControlState(PlayerState.Stop)
    setVideoLengthInSeconds(0)
    setCurrentVideoPositionInSeconds(0)
    await videoPlayerFirebaseService.updateStopState()
  }, [videoPlayerFirebaseService])

  const handleSeekTo = React.useCallback(
    async (argInSeconds: number) => {
      setScrubbarTimerState(false)
      setSeekingState(true)
      await videoPlayerFirebaseService.updateSeekState(argInSeconds)
    },
    [videoPlayerFirebaseService]
  )

  const handleMuteClick = React.useCallback(
    async (arg: boolean) => {
      setMuteState(arg)
      await videoPlayerFirebaseService.updateMuteState(arg)
    },
    [videoPlayerFirebaseService]
  )

  const handleVolumeDownClick = React.useCallback(async () => {
    if (volume > 0) {
      const newVolume = volume - 1

      setMuteState(false)
      setVolume(newVolume)
      await videoPlayerFirebaseService.updateVolumeState(newVolume)
    }
  }, [volume, videoPlayerFirebaseService])

  const handleVolumeUpClick = React.useCallback(async () => {
    if (volume < 10) {
      setMuteState(false)
      const newVolume = volume + 1
      setVolume(newVolume)
      await videoPlayerFirebaseService.updateVolumeState(newVolume)
    }
  }, [volume, videoPlayerFirebaseService])

  const handlePlaybackAction = React.useCallback(
    (actionType: ActionType) => {
      if (actionType === 'showcase') {
        setPlayerControlState(PlayerState.Play)
        setScrubbarTimerState(false)
        videoPlayerFirebaseService.updatePlayState()
      }
      if (actionType === 'remote') {
        videoPlayerRef.current?.updatePlayerState(PlayerState.Play)
      }
    },
    [videoPlayerFirebaseService]
  )

  const requestForVideoSync = React.useCallback(async () => {
    await videoPlayerFirebaseService.updateSyncState()
  }, [videoPlayerFirebaseService])

  useTabVisibility(
    React.useCallback(async () => {
      if (playerControlState === PlayerState.Play) {
        requestForVideoSync()
      }
    }, [playerControlState])
  )

  React.useEffect(() => {
    if (hasVideoItems && playerControlState === PlayerState.Play) {
      requestForVideoSync()
    }
  }, [hasVideoItems, playerControlState])

  React.useEffect(() => {
    if (!session) {
      return
    }
    const {
      connected,
      floorGallery: {
        galleryControlV2: {
          activeSlideIndex: activeSlideIndexFirebase,
          activeTabIndex: activeTabIndexFirebase,
        },
        playerControlV2: {
          playerState: playerStateFirebase,
          videoLengthInSeconds: videoLengthInSecondsFirebase,
          currentVideoPositionInSeconds: currentVideoPositionInSecondsFirebase,
          volume: volumeFirebase,
          scrubbarTimerState: scrubbarTimerStateFirebase,
          seekToValueInSeconds: seekToValueInSecondsFirebase,
          isBuffering: isBufferingFirebase,
          muted: mutedFirebase,
        },
      },
    } = session
    connectedRef.current = connected
    setIsConnected(connected)
    setCurrentSlideIndex(activeSlideIndexFirebase)
    setActiveTab(getTabKeyByIndexBy(activeTabIndexFirebase || 0))

    setPlayerControlState(playerStateFirebase)
    setVideoLengthInSeconds(videoLengthInSecondsFirebase)
    setCurrentVideoPositionInSeconds(currentVideoPositionInSecondsFirebase)
    setVolume(volumeFirebase)
    setScrubbarTimerState(scrubbarTimerStateFirebase)
    setBufferState(isBufferingFirebase)
    setSeekingState(seekToValueInSecondsFirebase !== null)
    setMuteState(mutedFirebase)
  }, [session?.connected, session?.floorGallery])

  React.useEffect(() => {
    if (!isConnected) {
      return
    }
    firebaseControlQuery.update({
      [`floorGallery.galleryControlV2.activeSlideIndex`]: 0,
      [`floorGallery.galleryControlV2.activeTabIndex`]:
        getTabIndexByKey('floor'),
      [`floorGallery.playerControlV2.playerState`]: PlayerState.Stop,
      [`floorGallery.playerControlV2.scrubbarTimerState`]: false,
      [`floorGallery.playerControlV2.videoLengthInSeconds`]: 0,
      [`floorGallery.playerControlV2.currentVideoPositionInSeconds`]: 0,
    })
  }, [activePrecinctId, activeStageId, activeLotId])

  const dataHandlerStatus = React.useMemo<string>(() => {
    if (precinctsPayload.status !== Status.FULFILLED) {
      return precinctsPayload.status
    }
    if (floorPlateGalleryPayload.status !== Status.FULFILLED) {
      return floorPlateGalleryPayload.status
    }
    return stagePayload.status
  }, [
    precinctsPayload.status,
    stagePayload.status,
    floorPlateGalleryPayload.status,
  ])

  return (
    <DataHandler
      payload={{
        ...stagePayload,
        status: dataHandlerStatus,
        data: Object.entries(stagePlan?.[stagePlanMapKey] || {}),
      }}
      skeletonFrame={<StageSkeleton />}
    >
      <>
        {activeTab === 'gallery' && slides.length > 0 ? (
          <div className="absolute inset-0">
            <CarouselProvider
              slides={slides}
              activeSlideIndex={currentSlideIndex}
              showControl={
                playerControlState === PlayerState.Stop &&
                playerState === PlayerState.Stop
              }
              controlType={CarouselControlType.Thumbnail}
              getCurrentSlideIndex={handleChangeCurrentSlideIndex}
              mousewheel
              gallerySettings={gallerySettings}
              allowSlideSwiping={
                playerControlState === PlayerState.Stop &&
                playerState === PlayerState.Stop
              }
            />

            {hasVideoItems && playerControlState === PlayerState.Stop ? (
              <div
                className={`absolute inset-0 transition-all duration-300 ${videoSource ? 'visible' : 'hidden'}`}
              >
                <div className="flex h-full w-full items-center justify-center">
                  <VideoPlaybackActions onPlay={handlePlaybackAction} />
                </div>
              </div>
            ) : null}

            {hasVideoItems ? (
              <div
                className={`absolute bottom-0 z-40 w-full px-2 pb-4 transition-all duration-300 ${
                  playerControlState !== PlayerState.Stop
                    ? 'translate-y-0 opacity-100'
                    : 'pointer-events-none fixed translate-y-full opacity-0'
                }`}
              >
                <VideoPlayerControl
                  scrubbarTimerState={scrubbarTimerState}
                  isBuffering={isBuffering}
                  isMuted={isMuted}
                  volume={volume}
                  isLooping={false}
                  playerState={playerControlState}
                  videoLengthInSeconds={videoLengthInSeconds}
                  currentVideoPositionInSeconds={currentVideoPositionInSeconds}
                  isSeeking={isSeeking}
                  handleStopClick={handleStopClick}
                  handlePauseClick={handlePauseClick}
                  handlePlayClick={handlePlayClick}
                  handleMuteClick={handleMuteClick}
                  handleVolumeUpClick={handleVolumeUpClick}
                  handleVolumeDownClick={handleVolumeDownClick}
                  handleSeekTo={handleSeekTo}
                  showLoopOption={false}
                />
              </div>
            ) : null}

            {hasVideoItems ? (
              <div
                className={`absolute inset-0 ${
                  videoSource && playerState !== PlayerState.Stop
                    ? 'visible'
                    : 'invisible'
                }`}
              >
                <div className="flex h-full w-full items-center justify-center">
                  <VideoPlayer
                    ref={videoPlayerRef}
                    sources={[videoSource]}
                    activeIndex={0}
                    getPlayerState={handlePlayerState}
                    showControls
                    showLoopOption={false}
                    hidePlayButton
                  />
                </div>
              </div>
            ) : null}
          </div>
        ) : (
          <StageCanvas
            stagePlan={stagePlan?.[stagePlanMapKey]}
            originalLots={originalLots}
            filteredLots={filteredLots}
            session={session}
            isFullWidth={false}
            fullScreenToggle={fullScreenToggle}
            setFullScreenToggle={setFullScreenToggle}
          />
        )}

        {(slides.length > 0 || hasPanoramicData) &&
          playerControlState !== PlayerState.Play &&
          playerState !== PlayerState.Play && (
            <div className="absolute bottom-5 right-5 z-3">
              <div className="flex items-center">
                {slides.length > 0 && (
                  <StageGalleryTab
                    activeTab={activeTab}
                    handleClick={handleFloorGalleryTabClick}
                  />
                )}
                {hasPanoramicData && (
                  <button
                    className="text-md ml-2 rounded-md border border-mainColour bg-white px-8 py-2 font-medium tracking-widest text-mainColour"
                    type="button"
                    onClick={handleNavigateToPanoramic}
                  >
                    Panoramic
                  </button>
                )}
              </div>
            </div>
          )}
      </>
    </DataHandler>
  )
}

const mapStateToProps = ({
  projectIdentity,
  firestore: { session },
}: RootStateTypeExtra) => ({
  projectIdentity,
  session,
  activePrecinctId: session?.houseAndLand?.activePrecinctId || '',
  activeStageId: session?.houseAndLand?.activeStageId || '',
  activeLotId: session?.houseAndLand?.activeLotId || '',
})

export default connect(mapStateToProps)(StageView)
