import { UseQueryStateResult } from '@reduxjs/toolkit/dist/query/react/buildHooks.d'
import { createApi } from '@reduxjs/toolkit/query/react'

import baseQueryInterceptor from '@api/base-query-interceptor'
import { ApiResponse } from '@api/types/api-response.type'

import { Unit } from '@adUtilities/types/apartment'

export interface BuildingInterface {
  levels: Level[]
  blockOrders: string[]
}

export interface Level {
  level: string
  data: Array<Unit>
  height: number
}

type BuildingType = {
  projectName: string
}

export const buildingApi = createApi({
  reducerPath: 'buildingApi',
  baseQuery: baseQueryInterceptor,
  endpoints: (builder) => ({
    getBuilding: builder.query<ApiResponse<Array<Level>>, BuildingType>({
      query: (params: BuildingType) => ({
        url: `/v1/${params.projectName}/building`,
        method: 'get',
      }),
    }),
  }),
})

export const selectFromResult = ({
  data,
  isLoading,
  isError,
  status,
  isSuccess,
  error,
}: UseQueryStateResult<any, any>): {
  levels: Level[]
  blockOrders: string[]
  isLoaded: boolean
  isError: boolean
  status: string
  isSuccess: boolean
  errorStatus: number
} => ({
  levels: data?.data?.lists || [],
  blockOrders: data?.data?.blockOrders || [],
  isLoaded: !isLoading,
  isError,
  isSuccess,
  errorStatus: error?.status,
  status,
})

export const { useGetBuildingQuery } = buildingApi
