import React from 'react'

import { MenuItemInterface } from '@store/types'

import { ExternalViewsData } from '@api/external-views'

import MENU_ITEMS from '../menu-items'

interface ComponentPropsInterface {
  externalViews: Array<ExternalViewsData>
  externalMenuItemsToggle: boolean
  setExternalMenuItemsToggle: (arg: boolean) => void
  activeExternalViewItemIndex: number | null
  handleExternalViewMenuClick: (
    menuItem: MenuItemInterface,
    menuItemIndex: number
  ) => void
}

function ExternalMenuItems({
  externalViews,
  externalMenuItemsToggle,
  setExternalMenuItemsToggle,
  activeExternalViewItemIndex,
  handleExternalViewMenuClick,
}: ComponentPropsInterface) {
  const handleClick = (index: number) => {
    const externalMenu = MENU_ITEMS.find(
      (item) => item.key === 'external-routes'
    )

    if (!externalMenu) {
      return
    }

    setExternalMenuItemsToggle(false)
    handleExternalViewMenuClick(externalMenu, index)
  }

  return (
    <div
      onMouseEnter={() => setExternalMenuItemsToggle(true)}
      onMouseLeave={() => setExternalMenuItemsToggle(false)}
      className={`no-scrollbar absolute top-9 z-1 max-h-[400px] overflow-hidden overflow-y-scroll rounded-md transition-all duration-500 ease-in-out ${
        externalMenuItemsToggle ? 'visible opacity-100' : 'invisible opacity-0'
      }`}
    >
      <ul className="mt-2 space-y-2 whitespace-nowrap rounded-md bg-neutralColour py-3">
        {externalViews.map((externalView: ExternalViewsData, index: number) => (
          <li key={`${externalView.title}-${index.toString()}`}>
            <button
              type="button"
              className={`w-full px-6 py-3 text-left text-xs font-semibold uppercase tracking-widest hover:bg-secondaryColour ${
                activeExternalViewItemIndex === index
                  ? 'bg-secondaryColour'
                  : ''
              }`}
              onClick={() => handleClick(index)}
            >
              {externalView.title}
            </button>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default ExternalMenuItems
