import React from 'react'
import { connect } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'

import { ProjectIdentity, RootStateTypeExtra } from '@store/types'

import Container from '@components/container/v2'
import DataHandler from '@components/data-handler/v2'

import {
  selectFromResult as selectFromExternalViewsResult,
  useGetExternalViewsQuery,
} from '@api/external-views'

import ExternalViewsSkeleton from './externel-views-skeleton'

interface PagePropsInterface {
  projectIdentity: ProjectIdentity
}

const PAGE_ROUTE = '/external-views'

function ExternalViews({ projectIdentity }: PagePropsInterface) {
  const history = useHistory()
  const location = useLocation()

  const activeViewIndex = React.useMemo(
    () => location?.state?.externalView || 0,
    [location?.state?.externalView]
  )

  const externalViewsPayload = useGetExternalViewsQuery(
    { projectName: projectIdentity.projectId },
    { selectFromResult: selectFromExternalViewsResult }
  )

  React.useEffect(
    () => () => {
      const currentRoute = history.location.pathname || ''
      if (currentRoute !== PAGE_ROUTE) {
        history.push({
          state: { externalView: undefined },
        })
      }
    },
    []
  )

  return (
    <Container>
      <DataHandler
        payload={{
          ...externalViewsPayload,
          data: externalViewsPayload.externalViewsData,
        }}
        skeletonFrame={<ExternalViewsSkeleton />}
      >
        <iframe
          key={activeViewIndex}
          className="z-0 h-full w-full overflow-hidden border-0"
          title={externalViewsPayload.externalViewsData[activeViewIndex]?.title}
          src={externalViewsPayload.externalViewsData[activeViewIndex]?.url}
          height="100%"
          width="100%"
        />
      </DataHandler>
    </Container>
  )
}

const mapStateToProps = ({ projectIdentity }: RootStateTypeExtra) => ({
  projectIdentity,
})

export default connect(mapStateToProps)(ExternalViews)
