import { UseQueryStateResult } from '@reduxjs/toolkit/dist/query/react/buildHooks.d'
import { createApi } from '@reduxjs/toolkit/query/react'

import { NavigationMenuKey, SortOrder } from '@store/constants'
import { BackgroundInterface, ThemeConfigInterface } from '@store/types'

import baseQueryInterceptor from '@api/base-query-interceptor'
import { ApiResponse } from '@api/types/api-response.type'

interface Project {
  id: string
  title: string
  canonicalName: string
}

interface ShowcaseRoomItem {
  id: string
  isDefault: boolean
  name: string
  project: Project
}

interface NavigationItem {
  key: NavigationMenuKey
  label: string
  active: boolean
  order?: number
  sortable: boolean
}

type Templates = Record<string, { template: string }>

export interface ShowcaseThemeResponse {
  font: string
  mainColor: string
  neutralColor: string
  secondaryColor: string
  tertiaryColor: string
  remoteTertiaryColor: string
  remoteBackground: BackgroundInterface
  remoteVisionBackground: BackgroundInterface
  remoteVisionLogo: string
}

export interface ShowcaseRoomConfigApiData {
  theme: ShowcaseThemeResponse
  navigationSettings: NavigationItem[]
  templates: Templates
}

export interface ShowcaseRoomConfig {
  theme: ThemeConfigInterface
  navigationSettings: NavigationItem[]
  templates: Templates
}

export interface ShowcaseRoomData {
  id: string
  isDefault: boolean
  name: string
  project: Project
  showcaseConfig: ShowcaseRoomConfigApiData
}

export interface ShowcaseRoomList {
  data: ShowcaseRoomItem[]
  total: number
  per_page: number
  current_page: number
  last_page: number
}

type ShowcaseRoomsListParams = {
  projectName: string
  per_page?: number
  sort_order?: SortOrder.ASC | SortOrder.DESC
  search?: string
  pagination?: boolean
}

type ShowcaseRoomConfigParams = {
  projectName: string
  showcaseRoomId: string
}

export const showcaseRoomApi = createApi({
  reducerPath: 'showcaseRoomApi',
  baseQuery: baseQueryInterceptor,
  endpoints: (builder) => ({
    getShowcaseRooms: builder.query<
      ApiResponse<ShowcaseRoomList>,
      ShowcaseRoomsListParams
    >({
      query: ({ projectName, ...params }: ShowcaseRoomsListParams) => ({
        url: `/v3/${projectName}/showcase-rooms`,
        method: 'get',
        params,
      }),
    }),
    getShowcaseRoomConfig: builder.query<
      ApiResponse<ShowcaseRoomData>,
      ShowcaseRoomConfigParams
    >({
      query: ({ projectName, showcaseRoomId }: ShowcaseRoomConfigParams) => ({
        url: `/v3/${projectName}/showcase-rooms/${showcaseRoomId}`,
        method: 'get',
      }),
    }),
  }),
})

const prepareShowcaseRoomConfig = (
  data: ShowcaseRoomConfigApiData
): ShowcaseRoomConfig => ({
  theme: {
    font: data?.theme?.font || 'TT Commons',
    mainColour: data?.theme?.mainColor || '#7C9169',
    secondaryColour: data?.theme?.secondaryColor || '#FFF7E9',
    tertiaryColour: data?.theme?.tertiaryColor || '#6B714E',
    neutralColour: data?.theme?.neutralColor || '#DDDAC6',
    remoteTertiaryColour: data?.theme?.remoteTertiaryColor || '#000000',
    remoteVisionLogo: data?.theme?.remoteVisionLogo || '',
    remoteBackground: {
      type: data?.theme?.remoteBackground?.type || 'none',
      color: data?.theme?.remoteBackground?.color || '#272727',
      url: data?.theme?.remoteBackground?.url || '',
    },
    remoteVisionBackground: {
      type: data?.theme?.remoteVisionBackground?.type || 'none',
      color: data?.theme?.remoteVisionBackground?.color || '#272727',
      url: data?.theme?.remoteVisionBackground?.url || '',
    },
  },
  navigationSettings: data?.navigationSettings || [],
  templates: data?.templates || {},
})

export const selectShowcaseRoomsFromResult = ({
  data,
  isLoading,
  isError,
  status,
}: UseQueryStateResult<any, any>): {
  showcaseRooms: ShowcaseRoomItem[]
  isLoaded: boolean
  isError: boolean
  status: string
} => ({
  showcaseRooms: data?.data?.data || [],
  isLoaded: !isLoading,
  isError,
  status,
})

export const selectShowcaseRoomConfigFromResult = ({
  data,
  isLoading,
  isError,
  status,
}: UseQueryStateResult<any, any>): {
  showcaseRoomConfig: ShowcaseRoomConfig | null
  isLoaded: boolean
  isError: boolean
  status: string
} => ({
  showcaseRoomConfig: data?.data?.showcaseConfig
    ? prepareShowcaseRoomConfig(data?.data?.showcaseConfig)
    : null,
  isLoaded: !isLoading,
  isError,
  status,
})

export const { useGetShowcaseRoomsQuery, useGetShowcaseRoomConfigQuery } =
  showcaseRoomApi
