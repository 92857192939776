import React from 'react'

import {
  CloseSvg,
  EyeSlashSvg,
  EyeSvg,
  FullScreenSvg,
  LinkSvg,
  PanoramaSvg,
  PinSvg,
  PlaySvg,
  StopSvg,
  UnpinSvg,
} from '@svg/react'

interface ComponentProps {
  hasPanoramicData: boolean
  fullScreenToggle: boolean
  unitInfoToggle: boolean
  slideShowToggle: boolean
  externalLinkToggle: boolean
  showExternalLinks: boolean
  shouldShowSlideshowBtn: boolean
  isPinned: boolean
  setFullScreenToggle: (arg: boolean) => void
  setUnitInfoToggle: (arg: boolean) => void
  setSlideShowToggle: (arg: boolean) => void
  setShowExternalLinks: (arg: boolean) => void
  setShowPanoramic: () => void
  togglePin: () => void
}

function TopControlBar({
  hasPanoramicData,
  fullScreenToggle,
  setFullScreenToggle,
  unitInfoToggle,
  setUnitInfoToggle,
  slideShowToggle,
  setSlideShowToggle,
  externalLinkToggle,
  showExternalLinks,
  setShowExternalLinks,
  shouldShowSlideshowBtn,
  setShowPanoramic,
  isPinned,
  togglePin,
}: ComponentProps) {
  return (
    <div className="flex items-center justify-between text-xs font-semibold tracking-widest text-white">
      <button
        type="button"
        onClick={() => setFullScreenToggle(!fullScreenToggle)}
        className="rounded bg-[#747474] p-2"
      >
        {fullScreenToggle ? (
          <CloseSvg className="h-6 w-6 text-white" />
        ) : (
          <FullScreenSvg className="h-6 w-6 text-white" />
        )}
      </button>

      <div className="flex gap-4">
        {externalLinkToggle && !slideShowToggle && (
          <button
            type="button"
            onClick={() => setShowExternalLinks(!showExternalLinks)}
            className="inline-flex items-center rounded bg-[#747474] px-3 py-1 uppercase"
          >
            <LinkSvg className="mr-1 h-4 w-4" />
            {showExternalLinks ? 'Hide External Link' : 'Show External Link'}
          </button>
        )}

        {!slideShowToggle && (
          <button
            type="button"
            onClick={() => setUnitInfoToggle(!unitInfoToggle)}
            className="inline-flex items-center rounded bg-[#747474] px-3 py-1 uppercase"
          >
            {unitInfoToggle ? (
              <>
                <EyeSlashSvg className="mr-1 h-4 w-4" /> Hide Info
              </>
            ) : (
              <>
                <EyeSvg className="mr-1 h-4 w-4" /> Show Info
              </>
            )}
          </button>
        )}

        {hasPanoramicData && !slideShowToggle && (
          <button
            type="button"
            onClick={setShowPanoramic}
            className="inline-flex items-center rounded bg-[#747474] px-3 py-1 uppercase"
          >
            <PanoramaSvg className="mr-1 h-5 w-5" /> Panoramic View
          </button>
        )}

        {shouldShowSlideshowBtn && (
          <button
            type="button"
            className="inline-flex items-center rounded bg-[#747474] px-3 py-1 uppercase"
            onClick={() => setSlideShowToggle(!slideShowToggle)}
          >
            {!slideShowToggle ? (
              <>
                <PlaySvg className="mr-1 h-3 w-3" /> Start Slideshow
              </>
            ) : (
              <>
                <StopSvg className="mr-1 h-5 w-5" /> Stop Slideshow
              </>
            )}
          </button>
        )}

        {!slideShowToggle && (
          <button
            type="button"
            className="inline-flex items-center rounded bg-[#747474] px-3 py-1 uppercase"
            onClick={togglePin}
          >
            {isPinned ? (
              <>
                <UnpinSvg className="mr-1 h-4 w-4 rotate-90" />
                <span>Unpin</span>
              </>
            ) : (
              <>
                <PinSvg className="mr-1 h-4 w-4 rotate-90" />
                <span>Pin</span>
              </>
            )}
          </button>
        )}
      </div>
    </div>
  )
}

export default TopControlBar
