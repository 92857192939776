import React from 'react'

import { SvgProps } from './types'

function UnpinSvg({ width, height, className, stroke }: SvgProps) {
  return (
    <svg
      width={width || '40'}
      height={height || '40'}
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      viewBox="0 0 24 24"
      strokeWidth={stroke || 0.1}
      stroke="currentColor"
      className={className}
      strokeMiterlimit={10}
    >
      <g id="Layer_1" />
      <g id="Layer_2">
        <g>
          <path d="M6.9,12.7l0.9,1.4c0.2,0.3,0.5,0.4,0.8,0.4c0.2,0,0.4-0.1,0.6-0.2c0.5-0.3,0.6-0.9,0.3-1.4l-0.9-1.3l3.1-3.1l1.3,0.9    c0.5,0.3,1.1,0.2,1.4-0.3c0.3-0.5,0.2-1.1-0.3-1.4l-1.4-1c0.6-1.8,0.3-3.8-1.2-5.2c-0.4-0.4-1-0.4-1.4,0l-8.5,8.5    c-0.4,0.4-0.4,1,0,1.4c1,1,2.3,1.5,3.5,1.5C5.8,13,6.4,12.9,6.9,12.7z M10.8,3.9c0.5,1.1,0.3,2.5-0.6,3.4l-2.8,2.8    c-0.9,0.9-2.3,1.1-3.4,0.6L10.8,3.9z" />
          <path d="M20.7,5.3c-0.4-0.4-1-0.4-1.4,0l-14,14c-0.4,0.4-0.4,1,0,1.4C5.5,20.9,5.7,21,6,21s0.5-0.1,0.7-0.3l3.3-3.3l0.8,1.2    c0.5,0.8,1.3,1.2,2.2,1.3c0.1,0,0.2,0,0.3,0c0.8,0,1.6-0.3,2.1-0.9l1.1-1.1l2.1,2.1c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.3    c0.4-0.4,0.4-1,0-1.4l-2.1-2.1l1.1-1.1c0.6-0.6,1-1.5,0.9-2.4c-0.1-0.9-0.6-1.7-1.3-2.2L17.4,10l3.3-3.3    C21.1,6.3,21.1,5.7,20.7,5.3z M17.5,12.5c0.3,0.2,0.4,0.4,0.4,0.7c0,0.3-0.1,0.6-0.3,0.8L14,17.6c-0.2,0.2-0.5,0.3-0.8,0.3    c-0.3,0-0.6-0.2-0.7-0.4l-1-1.5l4.5-4.5L17.5,12.5z" />
        </g>
      </g>
    </svg>
  )
}

export default UnpinSvg
