import React, { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'

import {
  ExtendedUnitFilterOptions,
  ProjectIdentity,
  RootStateTypeExtra,
} from '@store/types'

import FILTER_INITIAL_STATE from '@components/filter/v2/filterState'

import { BuildingInterface, Level } from '@api/building'
import { MappingBlockCollection } from '@api/interactive-plan'

import countFilterChanges from '@utilities/unit-filter-util'

import { SessionMap } from '@firebaseUtil/types'

import filterUnit from '@adUtilities/filter-unit'
import { Unit } from '@adUtilities/types/apartment'

import AddFilterButton from './add-filter-button'
import BackButton from './back-button'
import LevelHead from './level-head'
import NoUnitCard from './no-unit-card'
import UnitCard from './unit-card'

interface UnitsSidePanelProps {
  projectIdentity: ProjectIdentity
  session: SessionMap | undefined
  building: BuildingInterface
  blocks: MappingBlockCollection
  unitFilter: ExtendedUnitFilterOptions
  filterPopupToggle: boolean
  levelStagePrefix: string
  setFilterPopupToggle: (arg: boolean) => void
}

function UnitsSidePanel({
  session,
  projectIdentity,
  building,
  blocks,
  unitFilter,
  filterPopupToggle,
  setFilterPopupToggle,
  levelStagePrefix,
}: UnitsSidePanelProps) {
  const unitSidePanelContainerRef = useRef<HTMLDivElement>(null)

  const blockKeys: string[] =
    building?.blockOrders.length >= 1
      ? building?.blockOrders
      : Object.keys(blocks) || []

  const [firebaseActiveBlock, setFirebaseActiveBlock] = useState<string>('')
  const [firebaseActiveLevel, setFirebaseActiveLevel] = useState<string>('')
  const [firebaseActiveUnit, setFirebaseActiveUnit] = useState<string>('')
  const [filteredCount, setFilteredCount] = useState<number>(0)

  const getFilteredData = (): Level[] => {
    const levelList: Level[] = [...building.levels]

    const { apply: filterApplied, anyLevel } = unitFilter || {}

    let filterLevelList = levelList
      .map((unit) => ({
        ...unit,
        data:
          blockKeys.length > 1
            ? unit.data.filter((item) => item.blockId === firebaseActiveBlock)
            : unit.data,
      }))
      .filter((unit) => unit.data.length)

    if (!filterApplied) return filterLevelList

    if (!anyLevel) {
      filterLevelList = filterLevelList.filter(
        (item: Level) => item.level === firebaseActiveLevel
      )
    }

    const filteredLevels: Level[] = []

    filterLevelList.forEach((item: Level) => {
      let units = item.data

      if (blockKeys.length > 1 && firebaseActiveBlock) {
        units = units.filter(
          (unit: Unit) => unit.blockId === firebaseActiveBlock
        )
      }

      units = unitFilter?.apply
        ? units.filter((unit: Unit) =>
            filterUnit(
              unit,
              unitFilter,
              projectIdentity?.showPrice,
              projectIdentity?.statusLabels?.available
            )
          )
        : units

      if (units.length > 0) {
        filteredLevels.push({ ...item, data: units })
      }
    })

    return filteredLevels
  }

  useEffect(() => {
    if (unitFilter?.apply) {
      setFilteredCount(countFilterChanges(FILTER_INITIAL_STATE, unitFilter))
    } else {
      setFilteredCount(0)
    }
  }, [unitFilter])

  useEffect(() => {
    const timer = setTimeout(() => {
      const activeLevelElement = document.querySelector(
        '.active-level'
      ) as HTMLDivElement

      if (
        activeLevelElement &&
        unitSidePanelContainerRef?.current &&
        firebaseActiveLevel
      ) {
        const offsetTop =
          activeLevelElement.offsetTop -
          unitSidePanelContainerRef.current.offsetTop

        unitSidePanelContainerRef.current.scrollTo({
          top: offsetTop,
          behavior: 'smooth',
        })
      }
    }, 500)
    return () => clearTimeout(timer)
  }, [firebaseActiveLevel, unitFilter?.apply])

  useEffect(() => {
    if (!session) {
      return
    }

    const {
      building: { activeBlock, activeLevel, activeUnit },
    } = session

    setFirebaseActiveBlock(activeBlock)
    setFirebaseActiveLevel(activeLevel)
    setFirebaseActiveUnit(activeUnit)
  }, [session])

  return (
    <div className="h-full w-full bg-[#EEEEEE]">
      <div className="flex justify-between bg-white p-4">
        <BackButton />
        <AddFilterButton
          unitCount={filteredCount}
          popupState={filterPopupToggle}
          togglePopup={setFilterPopupToggle}
        />
      </div>
      <div
        ref={unitSidePanelContainerRef}
        className="custom-scrollbar h-unit-sidepanel-container overflow-y-scroll px-4"
      >
        {getFilteredData().length > 0 ? (
          getFilteredData().map((level: Level) => (
            <div
              key={level.level}
              className={`${
                level.level === firebaseActiveLevel ? 'active-level' : ''
              }`}
            >
              <LevelHead
                level={level.level}
                active={level.level === firebaseActiveLevel}
                levelStagePrefix={levelStagePrefix}
              />
              {level.data.length > 0 ? (
                level.data.map((unit: Unit) => (
                  <UnitCard
                    key={unit.id}
                    unit={unit}
                    active={firebaseActiveUnit === unit.name}
                  />
                ))
              ) : (
                <NoUnitCard />
              )}
            </div>
          ))
        ) : (
          <NoUnitCard />
        )}
      </div>
    </div>
  )
}

export default connect(
  ({
    firestore: { session },
    whiteLabel,
    projectIdentity,
  }: RootStateTypeExtra) => ({
    session,
    levelStagePrefix:
      whiteLabel?.showcase?.propertySidePanel?.levelStagePrefix || '',
    projectIdentity,
  })
)(UnitsSidePanel)
