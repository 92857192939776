import React from 'react'
import QRCode from 'react-qr-code'
import { connect } from 'react-redux'
import { CSSTransition, SwitchTransition } from 'react-transition-group'

import { ShortlistState } from '@store/actionSlices/shortlist'
import {
  LotShortlistInterface,
  ProjectIdentity,
  RootStateTypeExtra,
  ShortlistPropertyType,
  UnitShortlistInterface,
} from '@store/types'

import Container from '@components/container/v2'
import DataHandler from '@components/data-handler'
import DropDown from '@components/dropdown/v2'

import {
  Agent,
  NewAgentPayload,
  selectFromAgentsResult,
  useCreatePartnerAgentMutation,
  useGetAllAgentsQuery,
  useLazyGetAgentByRefQuery,
} from '@api/agent'
import {
  ShortlistDocument,
  selectFromShortlistDocumentsResult,
  useGetShortlistDocumentsQuery,
} from '@api/shortlisted'
import { WhiteLabelShowcase } from '@api/white-label'

import { ProjectType } from '@utilities/adgroup-utilities/constants/common'
import { notifyError } from '@utilities/adgroup-utilities/notifier'

import FirebaseControlQuery from '@firebaseUtil/firebase-control-query'
import { SessionMap } from '@firebaseUtil/types'

import {
  CheckmarkCircleSvg,
  CloseSvg,
  FileSvg,
  LoadingSvg,
  PlusCircleSvg,
  PlusSvg,
} from '@svg/react'

import useGetShortlistData from '../useGetShortlistData'
import AddAgentForm from './add-agent-form'
import EmptyState from './empty-state'
import LotsList from './lots-list'
import ManualBuyerRegistration from './manual-buyer-registration'
import ShortlistSkeleton from './shortlist-skeleton'
import UnitsList from './units-list'

export interface ShortlistedProps {
  shortlist: ShortlistState
  projectIdentity: ProjectIdentity
  projectName: string
  session: SessionMap | undefined
  showcaseWhiteLabel: WhiteLabelShowcase
}

const BUYERS_PORTAL_URL = process.env.REACT_APP_BUYERS_PORTAL_MICROSERVICE_URL
const MAXIMUM_DOCUMENT_LENGTH_TO_SEND =
  Number(process.env.REACT_APP_MAXIMUM_DOCUMENT_LENGTH_TO_SEND) || 6

function Shortlist({
  shortlist,
  projectIdentity,
  projectName,
  session,
  showcaseWhiteLabel,
}: ShortlistedProps) {
  const firebaseControlQuery = FirebaseControlQuery({
    ...projectIdentity,
    sessionKey: projectIdentity.sessionId,
  })

  const shortlistWhiteLabel = showcaseWhiteLabel?.shortlist || {}

  const propertyType = React.useMemo<ShortlistPropertyType>(
    () =>
      projectIdentity.type === ProjectType.HouseAndLand
        ? ShortlistPropertyType.Lot
        : ShortlistPropertyType.Unit,
    []
  )

  const qrCode = React.useMemo(
    () =>
      `${BUYERS_PORTAL_URL}?shortlist-key=${session?.shortlist?.shortlistKey}`,
    [session?.shortlist?.shortlistKey]
  )
  const isFirebaseLoading = React.useMemo(
    () => !session?.shortlist?.shortlistKey,
    [session?.shortlist?.shortlistKey]
  )

  const [showManualBuyerRegistration, setShowManualBuyerRegistration] =
    React.useState(false)
  const [registeredBuyer, setRegisteredBuyer] = React.useState<{
    email: string
    isLinked: boolean
  }>()

  const [documentsList, setDocumentsList] = React.useState<ShortlistDocument[]>(
    []
  )
  const [selectedDocuments, setSelectedDocuments] = React.useState<string[]>([])

  const [agentsList, setAgentsList] = React.useState<Agent[]>([])
  const [selectedAgent, setSelectedAgent] = React.useState<Agent | undefined>()

  const { filteredShortlists, queryResult, queryResultData } =
    useGetShortlistData({
      propertyType,
      session,
      sessionShortlists: session?.shortlist?.properties,
      shortlists: shortlist.shortlists,
      projectIdentity,
    })

  const [showAddAgentForm, setShowAddAgentForm] = React.useState(false)
  const [addAgentErrors, setAddAgentErrors] = React.useState<string[]>()
  const [createShortlistAgent, { isLoading: isCreatingAgent }] =
    useCreatePartnerAgentMutation()
  const [
    getShortlistAgentByEmailOrId,
    { isFetching: isFetchingShortlistAgentByEmailOrId },
  ] = useLazyGetAgentByRefQuery()

  const handleSelectDocument = (document: ShortlistDocument) => {
    if (selectedDocuments.length >= MAXIMUM_DOCUMENT_LENGTH_TO_SEND) {
      alert(
        `You can attach a maximum of ${MAXIMUM_DOCUMENT_LENGTH_TO_SEND} items.`
      )
      return
    }

    firebaseControlQuery.update({
      [`shortlist.selectedDocumentIds`]: [...selectedDocuments, document.id],
    })
  }

  const handleUnselectDocument = (documentId: string) => {
    firebaseControlQuery.update({
      'shortlist.selectedDocumentIds': selectedDocuments.filter(
        (item) => item !== documentId
      ),
    })
  }

  const shortlistDocumentsResponse = useGetShortlistDocumentsQuery(
    { projectId: projectIdentity.projectId },
    { selectFromResult: selectFromShortlistDocumentsResult }
  )

  const shortlistAgentsResponse = useGetAllAgentsQuery(
    { projectName, showcaseEnabled: true, type: 'agent' },
    { selectFromResult: selectFromAgentsResult }
  )

  const clearSelectedAgent = () => {
    setSelectedAgent(undefined)
    firebaseControlQuery.update({
      [`shortlist.selectedAgentId`]: '',
    })
  }

  const handleAgentChange = async (
    agentId: string,
    fetchWhenUnavailable?: boolean
  ) => {
    if (agentId === 'new') {
      setShowAddAgentForm(true)
      return
    }
    setShowAddAgentForm(false)
    const matchedAgent = shortlistAgentsResponse?.data?.find(
      (item) => item.id === agentId
    )

    if (fetchWhenUnavailable && agentId && !matchedAgent) {
      try {
        const resp = await getShortlistAgentByEmailOrId({
          projectId: projectName,
          ref: agentId,
        })
        if (resp.data?.data) {
          setSelectedAgent(resp.data.data)
          firebaseControlQuery.update({
            [`shortlist.selectedAgentId`]: resp.data.data.id,
          })
          return
        }
      } catch (error) {
        console.log(error)
      }
    }

    if (matchedAgent) {
      setSelectedAgent(matchedAgent)
      firebaseControlQuery.update({
        [`shortlist.selectedAgentId`]: matchedAgent.id,
      })
    } else {
      clearSelectedAgent()
    }
  }

  const handleResetAddAgentForm = () => {
    setAddAgentErrors(undefined)
    setShowAddAgentForm(false)
  }

  const handleAddAgent = async (
    values: Record<keyof NewAgentPayload, string>
  ) => {
    try {
      const existingAgentResponse = await getShortlistAgentByEmailOrId({
        ref: values.email,
        projectId: projectName,
      }).unwrap()
      if (existingAgentResponse?.data) {
        handleResetAddAgentForm()
        setSelectedAgent(existingAgentResponse.data)
        firebaseControlQuery.update({
          [`shortlist.selectedAgentId`]: existingAgentResponse.data.id,
        })
        return
      }
    } catch (error) {
      console.log(error)
    }
    try {
      const newAgentResponse = await createShortlistAgent({
        ...values,
        projectName,
      }).unwrap()
      if (!newAgentResponse.data) {
        return
      }
      handleResetAddAgentForm()
      setSelectedAgent(newAgentResponse.data)
      firebaseControlQuery.update({
        [`shortlist.selectedAgentId`]: newAgentResponse.data.id,
      })
    } catch (e) {
      const error = e as { data: { errors: string[]; message: string } }
      if (error?.data?.message) {
        notifyError(error?.data?.message)
      }
      if (error?.data?.errors?.length) {
        setAddAgentErrors(error.data.errors)
      }
    }
  }

  React.useEffect(() => {
    const { data: shortlistDocuments, isLoaded } = shortlistDocumentsResponse
    if (
      shortlistDocuments.length > 0 &&
      isLoaded &&
      documentsList.length === 0
    ) {
      setDocumentsList(shortlistDocuments)
    }
  }, [shortlistDocumentsResponse])

  React.useEffect(() => {
    const { data: shortlistAgents, isLoaded } = shortlistAgentsResponse
    if (shortlistAgents.length > 0 && isLoaded && agentsList.length === 0) {
      setAgentsList(shortlistAgents)
    }
  }, [shortlistAgentsResponse])

  React.useEffect(() => {
    if (session) {
      const { shortlist: firebaseShortlist } = session

      if (documentsList && agentsList) {
        setSelectedDocuments(
          documentsList
            .filter((item) =>
              firebaseShortlist.selectedDocumentIds.includes(item.id)
            )
            .map((document) => document.id)
        )
      }
    }
  }, [session, documentsList, agentsList])

  React.useEffect(() => {
    handleAgentChange(session?.shortlist?.selectedAgentId || '', true)
  }, [session?.shortlist?.selectedAgentId])

  const agentOptions = React.useMemo(
    () => [
      {
        label: (
          <div className="group inline-flex w-full justify-between">
            <p className="group-hover:underline">Add a new agent</p>
            <p>+</p>
          </div>
        ),
        value: 'new',
        postFix: <PlusSvg className="h-5 w-5" />,
      },
      { label: 'None', value: '' },
      ...agentsList.map((item) => ({
        label: `${item.firstName} ${item.lastName}`,
        value: item.id,
      })),
    ],
    [agentsList]
  )

  return (
    <Container className="flex bg-white transition-size-spacing duration-300 ease-in-out">
      <DataHandler
        payloads={{
          ...queryResult,
          data: queryResultData,
        }}
        skeletonFrame={<ShortlistSkeleton />}
      >
        {filteredShortlists.length === 0 ? (
          <EmptyState />
        ) : (
          <div className="flex h-full w-full p-3">
            <div className="flex shrink-0 flex-col rounded-2xl bg-neutral-grey-2 p-11 pb-4">
              {propertyType === ShortlistPropertyType.Lot ? (
                <LotsList
                  shortlists={filteredShortlists as LotShortlistInterface[]}
                  shortlistsData={queryResultData}
                />
              ) : (
                <UnitsList
                  shortlistsData={queryResultData}
                  shortlists={filteredShortlists as UnitShortlistInterface[]}
                />
              )}
            </div>

            <div className="no-scrollbar short-screen:justify-normal flex w-full flex-col items-center justify-center overflow-y-auto p-3">
              {isFirebaseLoading ? (
                <div className="mb-10 h-[250px] w-[250px] rounded-lg bg-zinc-400/20">
                  <div className="shimmer-container relative h-full rounded-none opacity-50 shadow-none"></div>
                </div>
              ) : (
                <CSSTransition
                  classNames="transition-zoom-out-qr"
                  in={
                    showAddAgentForm ||
                    (showManualBuyerRegistration && !registeredBuyer)
                  }
                  timeout={300}
                >
                  {(showManualBuyerRegistration && !registeredBuyer) ||
                  showAddAgentForm ? (
                    <button
                      className="mb-9 w-[250px] rounded-md border border-[#DDD] px-5 py-4"
                      type="button"
                      onClick={handleResetAddAgentForm}
                      disabled={
                        isFetchingShortlistAgentByEmailOrId || isCreatingAgent
                      }
                    >
                      <QRCode
                        value={qrCode}
                        style={{
                          height: 'auto',
                          maxWidth: '100%',
                          width: '100%',
                        }}
                      />
                    </button>
                  ) : (
                    <div className="mb-12 w-[250px] rounded-md border border-[#DDD] px-5 py-4">
                      <QRCode
                        value={qrCode}
                        style={{
                          height: 'auto',
                          maxWidth: '100%',
                          width: '100%',
                        }}
                      />
                    </div>
                  )}
                </CSSTransition>
              )}

              {registeredBuyer ? (
                <div className="flex flex-col items-center gap-[48px]">
                  <div className="flex flex-col items-center gap-1">
                    <p className="text-[35px] font-medium leading-[42px]">
                      {registeredBuyer.isLinked
                        ? 'Your shortlist has been sent.'
                        : 'Your shortlist has been sent to'}
                    </p>
                    {registeredBuyer.isLinked ? null : (
                      <p className="text-[35px] font-bold leading-[42px]">
                        {registeredBuyer.email}
                      </p>
                    )}
                  </div>
                  <div className="flex items-center gap-1">
                    <p className="text-[17px] font-medium">
                      Scan the QR code or
                    </p>
                    <button
                      type="button"
                      onClick={() => setRegisteredBuyer(undefined)}
                    >
                      <p className="text-[17px] font-medium underline">
                        register another buyer manually
                      </p>
                    </button>
                  </div>
                </div>
              ) : (
                <>
                  <p className="mb-9 text-center text-[35px] font-bold leading-[42px]">
                    {shortlistWhiteLabel?.qrCodeScanLabel ||
                      'Scan the QR code to save your shortlist'}
                  </p>
                  <div className="mb-6 w-[400px]">
                    <p className="mb-2.5 text-sm font-semibold tracking-wider">
                      {shortlistWhiteLabel?.includeLabel || 'INCLUDE'}
                    </p>
                    <div className="custom-scrollbar relative flex max-h-[116px] flex-col gap-3 overflow-y-auto rounded-[5px] border border-[#DDD] bg-neutral-grey px-5 py-3">
                      {shortlistDocumentsResponse.isLoaded ? null : (
                        <div className="flex justify-center">
                          <LoadingSvg className="h-6 w-6 fill-black text-[#DDD]" />
                        </div>
                      )}
                      {shortlistDocumentsResponse.isLoaded &&
                      documentsList.length > 0
                        ? documentsList?.map((item) => (
                            <div
                              key={item.id}
                              className="flex items-center gap-3"
                            >
                              <FileSvg
                                size="m"
                                className="h-4 w-4 text-gray-400"
                              />
                              <p className="flex-1 overflow-hidden text-ellipsis whitespace-nowrap text-base font-medium">
                                {item.title || item.fileName}
                              </p>
                              {selectedDocuments.findIndex(
                                (selected) => selected === item.id
                              ) > -1 ? (
                                <button
                                  type="button"
                                  onClick={() =>
                                    handleUnselectDocument(item.id)
                                  }
                                >
                                  <CheckmarkCircleSvg
                                    size="s"
                                    className="h-6 w-6 text-mainColour"
                                  />
                                </button>
                              ) : (
                                <button
                                  type="button"
                                  onClick={() => handleSelectDocument(item)}
                                >
                                  <PlusCircleSvg
                                    size="s"
                                    className="h-6 w-6 text-[#777777]"
                                    stroke={1.5}
                                  />
                                </button>
                              )}
                            </div>
                          ))
                        : null}
                      {shortlistDocumentsResponse.isLoaded &&
                      documentsList.length <= 0 ? (
                        <span className="text-base font-medium">None</span>
                      ) : null}
                    </div>
                  </div>
                </>
              )}
              <SwitchTransition>
                <CSSTransition
                  timeout={300}
                  classNames="transition-fade-out"
                  key={`${showAddAgentForm}${registeredBuyer}`}
                >
                  <div>
                    {!registeredBuyer && showAddAgentForm ? (
                      <div className="w-[400px]">
                        <AddAgentForm
                          onCancel={handleResetAddAgentForm}
                          onConfirm={handleAddAgent}
                          isProcessing={
                            isFetchingShortlistAgentByEmailOrId ||
                            isCreatingAgent
                          }
                          errors={addAgentErrors}
                        />
                      </div>
                    ) : null}
                    {!registeredBuyer && !showAddAgentForm ? (
                      <div className="w-[400px]">
                        <p className="mb-2.5 text-sm font-semibold tracking-wider">
                          {shortlistWhiteLabel?.agentLabel || 'AGENT'}
                        </p>
                        {!shortlistAgentsResponse.isLoaded ? (
                          <div className="flex justify-center rounded-[5px] border border-[#DDD] bg-neutral-grey px-5 py-3">
                            <LoadingSvg className="h-6 w-6 fill-black text-[#DDD]" />
                          </div>
                        ) : null}
                        {shortlistAgentsResponse.isLoaded && selectedAgent ? (
                          <div className="flex items-center justify-between rounded-[5px] border-[#DDD] bg-neutral-grey px-5 py-3">
                            <p className="font-medium">
                              {selectedAgent.firstName} {selectedAgent.lastName}
                            </p>
                            <button
                              className="flex items-center"
                              type="button"
                              onClick={clearSelectedAgent}
                            >
                              <CloseSvg
                                className="h-3.5 w-3.5 cursor-pointer"
                                strokeColor="black"
                              />
                            </button>
                          </div>
                        ) : null}
                        {shortlistAgentsResponse.isLoaded && !selectedAgent ? (
                          <DropDown
                            items={agentOptions}
                            value={
                              showAddAgentForm ? 'new' : (selectedAgent ?? '')
                            }
                            onSelect={(value) => handleAgentChange(value)}
                            className="!border-[#DDD] bg-neutral-grey !px-5 !py-3 text-base"
                            optionClassName="!border-[#DDD] !bg-neutral-grey !px-5 !py-4"
                            placeholder="None"
                            buttonRadius="rounded-[5px]"
                            buttonRadiusClasses="rounded-tl-[5px] rounded-tr-[5px]"
                            optionRadiusClasses="rounded-bl-[5px] rounded-br-[5px]"
                            useValueAsKey
                          />
                        ) : null}
                      </div>
                    ) : null}
                  </div>
                </CSSTransition>
              </SwitchTransition>
              <SwitchTransition>
                <CSSTransition
                  timeout={300}
                  classNames="transition-fade-out"
                  key={`${showManualBuyerRegistration}`}
                >
                  <div>
                    {registeredBuyer ? null : (
                      <div className="mt-6">
                        {showManualBuyerRegistration ? (
                          <ManualBuyerRegistration
                            className="mt-2 w-[400px]"
                            projectName={projectName}
                            shortlistData={shortlist}
                            onShowQRCode={() =>
                              setShowManualBuyerRegistration(false)
                            }
                            onRegistrationComplete={setRegisteredBuyer}
                            agentId={session?.shortlist.selectedAgentId}
                            documentIds={session?.shortlist.selectedDocumentIds}
                          />
                        ) : (
                          <div className="flex items-center gap-1">
                            <p className="font-medium">
                              Can&apos;t scan the QR code?
                            </p>
                            <button
                              type="button"
                              onClick={() =>
                                setShowManualBuyerRegistration(true)
                              }
                            >
                              <p className="font-medium underline">
                                Register buyer manually
                              </p>
                            </button>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </CSSTransition>
              </SwitchTransition>
            </div>
          </div>
        )}
      </DataHandler>
    </Container>
  )
}

export default connect(
  ({
    shortlist,
    projectIdentity,
    projectIdentity: { projectName },
    firestore: { session },
    whiteLabel: { showcase },
  }: RootStateTypeExtra) => ({
    shortlist,
    projectIdentity,
    projectName,
    session,
    showcaseWhiteLabel: showcase,
  })
)(Shortlist)
