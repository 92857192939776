import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { connect } from 'react-redux'

import {
  FloorGalleryItem,
  PlayerState,
  ProjectIdentity,
  RootStateTypeExtra,
} from '@store/types'

import VideoPlayerRemote from '@components/video-player'

import useTabVisibility from '@utilities/tab-visibility'
import VideoPlayerFirebaseService from '@utilities/video-player-firebase-service'

import FirebaseControlQuery from '@firebaseUtil/firebase-control-query'
import { SessionMap } from '@firebaseUtil/types'

import { PlayCircleSvg } from '@svg/react'

import { CarouselProvider } from '@adUtilities/components/carousel-provider'
import VideoPlayerShowcase from '@adUtilities/components/video-player'
import { VideoPlayerRef } from '@adUtilities/components/video-player/video-player'
import { FileType } from '@adUtilities/constants'
import { CarouselControlType } from '@adUtilities/constants/carousel'

interface Props {
  projectIdentity: ProjectIdentity
  session: SessionMap | undefined
  floorGalleryItems: FloorGalleryItem[]
  isVideoPlaying: boolean
  setIsVideoPlaying: (state: boolean) => void
  getVideoPlayerState: (arg: boolean) => void
}

function FloorplateGallery({
  projectIdentity,
  session,
  floorGalleryItems,
  isVideoPlaying,
  setIsVideoPlaying,
  getVideoPlayerState,
}: Props) {
  const firebaseControlQuery = FirebaseControlQuery({
    ...projectIdentity,
    sessionKey: projectIdentity.sessionId,
  })

  const videoPlayerFirebaseService = VideoPlayerFirebaseService({
    galleryName: 'floorGallery',
    projectIdentity,
  })

  const videoPlayerRef = useRef<VideoPlayerRef>(null)

  const [currentSlideIndex, setCurrentSlideIndex] = useState<number>(0)

  const [playerState, setPlayerState] = useState<PlayerState>(PlayerState.Stop)
  const [videoLengthInSeconds, setVideoLengthInSeconds] = useState(0)
  const [currentVideoPositionInSeconds, setCurrentVideoPositionInSeconds] =
    useState(0)
  const [volume, setVolume] = useState<number>(0)
  const [scrubbarTimerState, setScrubbarTimerState] = useState(false)
  const [isBuffering, setBufferState] = useState(false)
  const [isSeeking, setSeekingState] = useState(false)
  const [isMuted, setMuteState] = useState(true)

  const {
    gallerySettings: {
      type: effectType,
      intervalInSeconds: autoPlayIntervalInSeconds,
    },
  } = projectIdentity

  const hasVideoItems: boolean = useMemo(
    () =>
      floorGalleryItems.filter((item) => item.fileType === FileType.Video)
        .length > 0,
    [floorGalleryItems]
  )

  const handleCarouselItemClick = useCallback(async (index: number) => {
    setCurrentSlideIndex(index)
    await firebaseControlQuery.update({
      [`floorGallery.galleryControlV2.activeSlideIndex`]: index,
    })
  }, [])

  const handleVideoPlayerClick = async (state: boolean) => {
    setIsVideoPlaying(state)
    getVideoPlayerState(state)
  }

  const handlePlayOnShowcase = async () => {
    setPlayerState(PlayerState.Play)
    setScrubbarTimerState(false)
    setIsVideoPlaying(true)
    await videoPlayerFirebaseService.updatePlayState()
  }

  const handlePlayOnRemote = () => {
    videoPlayerRef.current?.updatePlayerState(PlayerState.Play)
  }

  const handlePlayClick = useCallback(async () => {
    setPlayerState(PlayerState.Play)
    setScrubbarTimerState(false)
    await videoPlayerFirebaseService.updatePlayState()
  }, [videoPlayerFirebaseService])

  const handlePauseClick = useCallback(async () => {
    setPlayerState(PlayerState.Pause)
    setScrubbarTimerState(false)
    await videoPlayerFirebaseService.updatePauseState()
  }, [videoPlayerFirebaseService])

  const handleStopClick = useCallback(async () => {
    setScrubbarTimerState(false)
    setPlayerState(PlayerState.Stop)
    setVideoLengthInSeconds(0)
    setCurrentVideoPositionInSeconds(0)
    setIsVideoPlaying(false)
    await videoPlayerFirebaseService.updateStopState()
  }, [videoPlayerFirebaseService])

  const handleVolumeDownClick = useCallback(async () => {
    if (volume > 0) {
      const newVolume = volume - 1

      setMuteState(false)
      setVolume(newVolume)
      await videoPlayerFirebaseService.updateVolumeState(newVolume)
    }
  }, [volume, videoPlayerFirebaseService])

  const handleVolumeUpClick = useCallback(async () => {
    if (volume < 10) {
      setMuteState(false)
      const newVolume = volume + 1
      setVolume(newVolume)
      await videoPlayerFirebaseService.updateVolumeState(newVolume)
    }
  }, [volume, videoPlayerFirebaseService])

  const handleMuteClick = useCallback(
    async (arg: boolean) => {
      setMuteState(arg)
      await videoPlayerFirebaseService.updateMuteState(arg)
    },
    [videoPlayerFirebaseService]
  )

  const handleSeekTo = useCallback(
    async (argInSeconds: number) => {
      setScrubbarTimerState(false)
      setSeekingState(true)
      await videoPlayerFirebaseService.updateSeekState(argInSeconds)
    },
    [videoPlayerFirebaseService]
  )

  const requestForVideoSync = useCallback(async () => {
    await videoPlayerFirebaseService.updateSyncState()
  }, [videoPlayerFirebaseService])

  const videoSource: string = useMemo(() => {
    const item = floorGalleryItems.find(
      ({ fileType }, index) =>
        fileType === FileType.Video && index === currentSlideIndex
    )

    return item ? item.videoSource || '' : ''
  }, [floorGalleryItems, currentSlideIndex])

  useEffect(() => {
    if (!session) {
      return
    }

    const {
      floorGallery: {
        galleryControlV2: { activeSlideIndex },
        playerControlV2: {
          playerState,
          videoLengthInSeconds,
          currentVideoPositionInSeconds,
          volume,
          scrubbarTimerState,
          seekToValueInSeconds,
          isBuffering,
          muted,
        },
      },
    } = session

    setCurrentSlideIndex(activeSlideIndex)

    setPlayerState(playerState)
    setIsVideoPlaying(playerState === PlayerState.Play)

    setVideoLengthInSeconds(videoLengthInSeconds)
    setCurrentVideoPositionInSeconds(currentVideoPositionInSeconds)
    setVolume(volume)
    setScrubbarTimerState(scrubbarTimerState)
    setSeekingState(seekToValueInSeconds !== null)
    setBufferState(isBuffering)
    setMuteState(muted)
  }, [session])

  useEffect(() => {
    if (hasVideoItems && playerState === PlayerState.Play) {
      requestForVideoSync()
    }
  }, [hasVideoItems, playerState])

  useTabVisibility(
    useCallback(async () => {
      if (playerState === PlayerState.Play) {
        requestForVideoSync()
      }
    }, [playerState])
  )

  useEffect(
    () => () => {
      setIsVideoPlaying(false)
      videoPlayerFirebaseService.resetPlayerState()
    },
    []
  )

  return (
    <div className="absolute inset-0">
      <CarouselProvider
        allowSlideSwiping={!isVideoPlaying && playerState === PlayerState.Stop}
        gallerySettings={{
          effectType,
          autoPlayIntervalInSeconds,
        }}
        showControl={playerState === PlayerState.Stop}
        mousewheel={false}
        slides={floorGalleryItems}
        activeSlideIndex={currentSlideIndex}
        controlType={CarouselControlType.Thumbnail}
        getCurrentSlideIndex={handleCarouselItemClick}
      />

      {hasVideoItems && (
        <div
          className={`absolute inset-0 ${
            videoSource ? 'visible' : 'invisible'
          }`}
        >
          <div className="flex h-full w-full items-center justify-center">
            {!isVideoPlaying && playerState === PlayerState.Stop && (
              <div className="flex flex-row gap-5">
                <button
                  type="button"
                  className="text-md z-1 flex max-h-14 items-center rounded-md border border-mainColour bg-white px-2 py-2 font-medium tracking-widest text-mainColour"
                  onClick={handlePlayOnShowcase}
                >
                  <PlayCircleSvg size="m" className="mr-2 h-6 w-6" />
                  <span>Play on Showcase</span>
                </button>
                <button
                  type="button"
                  className="text-md z-1 flex max-h-14 items-center rounded-md border border-mainColour bg-white px-2 py-2 font-medium tracking-widest text-mainColour"
                  onClick={handlePlayOnRemote}
                >
                  <PlayCircleSvg size="m" className="mr-2 h-6 w-6" />
                  <span>Play on Remote</span>
                </button>
              </div>
            )}

            <VideoPlayerShowcase
              ref={videoPlayerRef}
              showControls
              sources={[videoSource]}
              activeIndex={0}
              getPlayerState={handleVideoPlayerClick}
              showLoopOption={false}
              hidePlayButton
            />
          </div>
        </div>
      )}

      {hasVideoItems && (
        <div
          className={`absolute bottom-0 z-40 w-full px-2 pb-4 transition-all duration-1000 ${
            playerState !== PlayerState.Stop
              ? 'translate-y-0 opacity-100'
              : 'pointer-events-none fixed translate-y-full opacity-0'
          }`}
        >
          <VideoPlayerRemote
            isLooping={false}
            showLoopOption={false}
            volume={volume}
            isMuted={isMuted}
            scrubbarTimerState={scrubbarTimerState}
            isBuffering={isBuffering}
            playerState={playerState}
            videoLengthInSeconds={videoLengthInSeconds}
            currentVideoPositionInSeconds={currentVideoPositionInSeconds}
            isSeeking={isSeeking}
            handlePlayClick={handlePlayClick}
            handlePauseClick={handlePauseClick}
            handleStopClick={handleStopClick}
            handleVolumeUpClick={handleVolumeUpClick}
            handleVolumeDownClick={handleVolumeDownClick}
            handleMuteClick={handleMuteClick}
            handleSeekTo={handleSeekTo}
          />
        </div>
      )}
    </div>
  )
}

export default connect(
  ({ projectIdentity, firestore: { session } }: RootStateTypeExtra) => ({
    projectIdentity,
    session,
  })
)(FloorplateGallery)
