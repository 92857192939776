import React, { useEffect, useMemo, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { setProjectIdentity } from '@store/actionSlices/projectIdentity'
import { setShowcaseRoomConfig } from '@store/actionSlices/showcase-room'
import { TokenInterface } from '@store/actionSlices/token'
import { DEFAULT_SHOWCASE_ROOM, RouteKey } from '@store/constants'
import { ProjectIdentity, RootStateTypeExtra } from '@store/types'

import {
  selectFromResult as selectFromConfigResult,
  useGetConfigQuery,
} from '@api/config'
import { ShowcaseRoomConfig } from '@api/showcase-room'

import { getQueryStringParams } from '@utilities/helper'

import useGetShowcaseRoomsConfig from '@hooks/useGetShowcaseRoomConfig'

interface Props {
  projectIdentity: ProjectIdentity
  showcaseRoom: string
  token: TokenInterface
  showcaseRoomConfig: ShowcaseRoomConfig
  v1: React.ReactNode
  v2: React.ReactNode
}

function TemplateHandler({
  projectIdentity,
  showcaseRoom,
  token,
  v1,
  v2,
  showcaseRoomConfig,
}: Props) {
  const location = useLocation()
  const dispatch = useDispatch()

  const [isLoading, setIsLoading] = useState(true)
  const [hasError, setHasError] = useState(false)

  const {
    isLoaded,
    isError,
    showcaseRoomConfig: showcaseRoomConfigApiData,
  } = useGetShowcaseRoomsConfig({
    projectName: projectIdentity.projectName,
    room: showcaseRoom || DEFAULT_SHOWCASE_ROOM,
    token,
  })

  const {
    configData,
    isLoaded: isConfigLoaded,
    isError: configHasError,
  } = useGetConfigQuery(
    { projectName: projectIdentity.projectId },
    {
      selectFromResult: selectFromConfigResult,
      skip: !projectIdentity.projectId,
    }
  )

  const templateFromQuery = useMemo(
    () => getQueryStringParams(location.search)?.template,
    [location.search]
  )

  const routeKey = location.pathname.split('/').pop()
  const isValidRouteKey = Object.values(RouteKey).includes(routeKey)

  useEffect(() => {
    if (isLoaded && showcaseRoomConfigApiData) {
      setIsLoading(false)
      dispatch(setShowcaseRoomConfig(showcaseRoomConfigApiData))
    }
    if (isLoaded && isError) {
      setIsLoading(false)
      setHasError(true)
    }
  }, [isLoaded, isError, showcaseRoomConfigApiData, dispatch])

  useEffect(() => {
    if (isConfigLoaded && !configHasError) {
      dispatch(
        setProjectIdentity({
          ...projectIdentity,
          ...configData,
          ...showcaseRoomConfig,
        })
      )
    }
  }, [isConfigLoaded, showcaseRoomConfig])

  if (isLoading) {
    return null
  }

  if (!isLoading && hasError) {
    return <>{v1}</>
  }

  const fallbackTemplate = 'default'
  const showcaseTemplate = isValidRouteKey
    ? showcaseRoomConfigApiData?.templates?.[routeKey]?.template ||
      fallbackTemplate
    : fallbackTemplate

  const selectedTemplate = templateFromQuery || showcaseTemplate

  switch (selectedTemplate) {
    case 'v2':
      return <>{v2}</>
    default:
      return <>{v1}</>
  }
}

export default connect(
  ({
    projectIdentity,
    token,
    appConfig: { room: showcaseRoom },
    showcaseRoom: showcaseRoomConfig,
  }: RootStateTypeExtra) => ({
    showcaseRoom,
    projectIdentity,
    token,
    showcaseRoomConfig,
  })
)(TemplateHandler)
