import React, { useCallback } from 'react'

import { DropDownOption, PriceRange } from '@store/types'

import DropDown from '@components/dropdown/v2'

import { formatPrice } from '@utilities/helper'

import { MinMaxInterface } from '@adUtilities/types/common'

export interface ConfigPopupProps {
  field: string
  options: Array<string | number> | PriceRange
  range: MinMaxInterface
  handleChange: (range: MinMaxInterface) => void
}
function RangeOptions({
  field,
  options,
  range: { min, max },
  handleChange,
}: ConfigPopupProps) {
  const LABEL = {
    bed: 'Bedrooms',
    bath: 'Bathrooms',
    study: 'Study',
    car: 'Car spaces',
    powderRoom: 'Powder Room',
    lotFrontage: 'Lot Frontage',
    lotArea: 'Lot Area',
    price: 'Price',
  }
  const prepearDropdownItems = (
    data: Array<string | number>,
    price?: boolean
  ): Array<DropDownOption> => {
    const dropDownOptions: Array<DropDownOption> = [
      { label: 'Any', value: 'Any' },
    ]
    data?.forEach((value: string | number) =>
      dropDownOptions.push({
        label: price ? formatPrice(value) : value.toString(),
        value: value.toString(),
      })
    )
    return dropDownOptions
  }
  const disabledMaxOption = useCallback(
    (value: string) =>
      !(value === 'Any' || min === 'Any' || Number(value) >= Number(min)),
    [min]
  )
  const disabledMinOption = useCallback(
    (value: string) =>
      !(value === 'Any' || max === 'Any' || Number(value) <= Number(max)),
    [max]
  )

  return (
    <div className="flex flex-col gap-1" key={field}>
      <span>{LABEL[field as keyof typeof LABEL]}</span>
      <div className="flex gap-2">
        <span className="w-full">
          <DropDown
            onSelect={(value) => {
              handleChange({
                min: value,
                max,
              })
            }}
            items={prepearDropdownItems(
              Array.isArray(options) ? options : options.min,
              field === 'price'
            )}
            value={min}
            label="Min"
            disabledOption={disabledMinOption}
            buttonRadius="rounded-[7px]"
            buttonRadiusClasses="rounded-tl-[7px] rounded-tr-[7px]"
            optionRadiusClasses="rounded-bl-[7px] rounded-br-[7px]"
          />
        </span>
        <span className="w-full">
          <DropDown
            onSelect={(value) => {
              handleChange({
                min,
                max: value,
              })
            }}
            items={prepearDropdownItems(
              Array.isArray(options) ? options : options.max,
              field === 'price'
            )}
            value={max}
            label="Max"
            disabledOption={disabledMaxOption}
            buttonRadius="rounded-[7px]"
            buttonRadiusClasses="rounded-tl-[7px] rounded-tr-[7px]"
            optionRadiusClasses="rounded-bl-[7px] rounded-br-[7px]"
          />
        </span>
      </div>
    </div>
  )
}

export default RangeOptions
