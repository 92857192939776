import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import {
  BackgroundType,
  ProjectIdentity,
  RootStateTypeExtra,
} from '@store/types'

import { ProjectType } from '@adUtilities/constants/common'

const defaultConfig: ProjectIdentity = {
  disconnect: true,
  searchQuery: false,
  showClearCacheOption: true,
  projectId: '',
  projectName: '',
  projectLabel: '',
  projectUUID: '',
  sessionId: '',
  assetPort: '',
  assetHost: '',
  sessionList: [],
  masterKey: '',
  disconnectedSessions: [],

  disableSoldProperty: false,
  lightUpBuildingModelOnFilter: false,
  prices: {
    max: [],
    min: [],
  },
  aspects: [],
  unitTypes: [],
  propertyConfig: {},
  houseAndLandConfig: {},
  gallerySettings: {
    intervalInSeconds: 7,
    type: 'slide',
  },

  hideFilter: false,
  hideInfoPanelAspect: false,
  hidePropertyInfoName: false,
  hideSoldPrice: false,
  idlePage: {
    active: false,
    page: '',
  },
  logo: {
    visible: false,
    url: '',
  },
  longhand: false,
  onlyShowPropertyGalleries: false,
  propertyGalleryControlStyle: 'thumbnail',
  propertyGalleryTab: {
    active: false,
    duration: 0,
  },
  propertyPrefix: '',
  showPrice: false,
  showcaseActive: false,
  sidePanelTitle: '',
  markerColourSettings: {
    available: '#FFFFFF',
    sold: '#D14417',
    area: '#FFFFFF',
    levels: '#FFFFFF',
  },
  statusLabels: {},
  statusColors: {},
  isSnaploaderActive: false,
  isEnvisionVRActive: false,
  hasThreeDViewer: false,
  theme: {
    font: '',
    mainColour: '',
    secondaryColour: '',
    neutralColour: '',
    tertiaryColour: '',
    remoteBackground: {
      type: BackgroundType.Color,
      color: '#0F172A',
      url: '',
    },
    remoteTertiaryColour: '',
    remoteVisionBackground: {
      type: BackgroundType.None,
      color: '',
      url: '',
    },
    remoteVisionLogo: '',
  },
  navigationSettings: [],
  remoteInteractiveBuilding: {
    areaView: {
      useMatrix: false,
    },
  },
  type: ProjectType.Apartment,
}

let initialState = defaultConfig as ProjectIdentity

const projectIdentity = localStorage.getItem('projectIdentity')

if (projectIdentity) {
  initialState = JSON.parse(projectIdentity)
}

const projectIdentitySlice = createSlice({
  name: 'projectIdentity',
  initialState,
  reducers: {
    setProjectIdentity: (
      state: ProjectIdentity,
      action: PayloadAction<ProjectIdentity>
    ) => {
      localStorage.setItem('projectIdentity', JSON.stringify(action.payload))
      return {
        ...state,
        ...action.payload,
      }
    },
    removeProjectIdentity: () => {
      localStorage.removeItem('projectIdentity')
      return defaultConfig
    },
  },
})

export const { setProjectIdentity, removeProjectIdentity } =
  projectIdentitySlice.actions

export const selectProject = (state: RootStateTypeExtra) =>
  state.projectIdentity

export default projectIdentitySlice.reducer
