import React from 'react'
import { connect } from 'react-redux'

import { ProjectIdentity, RootStateTypeExtra } from '@store/types'

import FirebaseControlQuery from '@firebaseUtil/firebase-control-query'

import {
  CloseSvg,
  EyeSlashSvg,
  EyeSvg,
  FullScreenSvg,
  PanoramaSvg,
  PinSvg,
  PlaySvg,
  StopSvg,
  UnpinSvg,
} from '@svg/react'

interface ComponentProps {
  projectIdentity: ProjectIdentity
  hasPanoramicData: boolean
  fullScreenToggle: boolean
  setFullScreenToggle: (arg: boolean) => void
  packageInfoToggle: boolean
  setPackageInfoToggle: (arg: boolean) => void
  slideShowToggle: boolean
  setSlideShowToggle: (arg: boolean) => void
  isVideoPlaying: boolean
  shouldShowSlideshowBtn: boolean
  isPinned: boolean
  togglePin: () => void
}

function Controls({
  projectIdentity,
  hasPanoramicData,
  fullScreenToggle,
  setFullScreenToggle,
  packageInfoToggle,
  setPackageInfoToggle,
  slideShowToggle,
  setSlideShowToggle,
  isVideoPlaying,
  shouldShowSlideshowBtn,
  isPinned,
  togglePin,
}: ComponentProps) {
  const firebaseControlQuery = FirebaseControlQuery({
    ...projectIdentity,
    sessionKey: projectIdentity.sessionId,
  })

  const handleNavigateToPanoramic = React.useCallback(() => {
    firebaseControlQuery.updateRoute('panoramic')
  }, [])

  return (
    <div
      className={`flex flex-col items-end space-y-4 ${
        isVideoPlaying && 'hidden'
      }`}
    >
      {!slideShowToggle && (
        <>
          <button
            type="button"
            onClick={() => setFullScreenToggle(!fullScreenToggle)}
            className="flex items-center space-x-2"
          >
            <span
              className={`lot-view-control-text ${
                !packageInfoToggle ? 'opacity-0' : 'opacity-100'
              }`}
            >
              {fullScreenToggle && 'Exit'} Fullscreen
            </span>
            <span className="lot-view-control-icon">
              {fullScreenToggle ? (
                <CloseSvg className="h-5 w-5" />
              ) : (
                <FullScreenSvg className="h-5 w-5" />
              )}
            </span>
          </button>
          <button
            type="button"
            onClick={() => setPackageInfoToggle(!packageInfoToggle)}
            className="flex items-center space-x-2"
          >
            <span
              className={`lot-view-control-text ${
                !packageInfoToggle ? 'opacity-0' : 'opacity-100'
              }`}
            >
              {packageInfoToggle ? 'Hide' : 'Show'} Info
            </span>
            <span className="lot-view-control-icon">
              {packageInfoToggle ? (
                <EyeSlashSvg className="h-5 w-5" />
              ) : (
                <EyeSvg className="h-5 w-5" />
              )}
            </span>
          </button>
          {hasPanoramicData && (
            <button
              className="flex items-center space-x-2"
              type="button"
              onClick={handleNavigateToPanoramic}
            >
              <span
                className={`lot-view-control-text ${
                  !packageInfoToggle ? 'opacity-0' : 'opacity-100'
                }`}
              >
                Panoramic View
              </span>
              <span className="lot-view-control-icon">
                <PanoramaSvg className="h-5 w-5" />
              </span>
            </button>
          )}
        </>
      )}
      {shouldShowSlideshowBtn && (
        <button
          type="button"
          onClick={() => setSlideShowToggle(!slideShowToggle)}
          className="flex items-center space-x-2"
        >
          <span
            className={`lot-view-control-text ${
              !packageInfoToggle ? 'opacity-0' : 'opacity-100'
            }`}
          >
            {!slideShowToggle ? 'Start' : 'Stop'} Slideshow
          </span>
          <span className="lot-view-control-icon">
            {!slideShowToggle ? (
              <PlaySvg className="h-3 w-3" />
            ) : (
              <StopSvg className="h-4 w-4" />
            )}
          </span>
        </button>
      )}
      {!slideShowToggle && (
        <button
          type="button"
          className="flex items-center space-x-2"
          onClick={togglePin}
        >
          <span
            className={`lot-view-control-text ${
              !packageInfoToggle ? 'opacity-0' : 'opacity-100'
            }`}
          >
            {isPinned ? 'Unpin' : 'Pin'}
          </span>
          <span className="lot-view-control-icon">
            {isPinned ? (
              <UnpinSvg className="h-5 w-5 rotate-90" />
            ) : (
              <PinSvg className="h-5 w-5 rotate-90" />
            )}
          </span>
        </button>
      )}
    </div>
  )
}

export default connect(({ projectIdentity }: RootStateTypeExtra) => ({
  projectIdentity,
}))(Controls)
