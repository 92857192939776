import { UseQueryStateResult } from '@reduxjs/toolkit/dist/query/react/buildHooks.d'
import { createApi } from '@reduxjs/toolkit/query/react'

import baseQueryInterceptor from '@api/base-query-interceptor'
import { ApiResponse } from '@api/types/api-response.type'

export interface AreaViewWhiteLabel {
  pageTitle: string
}

export interface AreaViewHouseAndLandWhiteLabel {
  pageTitle: string
}

export interface FilterWhiteLabel {
  blockDropdownLabel: string
  configuration: string
  levelDropdownLabel: string
  precinctDropdownLabel: string
  stageDropdownLabel: string
  toggleText: string
}

export interface ShortlistWhiteLabel {
  agentLabel: string
  agentFormTitle: string
  includeLabel: string
  pageTitle: string
  qrCodeScanLabel: string
}

export interface LabelStageView {
  levelStagePrefix: string
}

export interface PropertySidePanelLabels {
  levelStagePrefix: string
  packageListTitle: string
}

export interface WhiteLabelShowcase {
  filter: FilterWhiteLabel
  propertySidePanel: PropertySidePanelLabels
  shortlist: Omit<ShortlistWhiteLabel, 'agentFormTitle' | 'pageTitle'>
}

export interface WhiteLabelRemote {
  areaView: AreaViewWhiteLabel
  areaViewHouseAndLand: AreaViewHouseAndLandWhiteLabel
  filter: FilterWhiteLabel
  labelStageView: LabelStageView
  lotView: Pick<PropertySidePanelLabels, 'packageListTitle'>
  shortlist: ShortlistWhiteLabel
}

export interface WhiteLabel {
  showcase: WhiteLabelShowcase
  remote: WhiteLabelRemote
}

export enum WhiteLabelType {
  Showcase = 'showcase',
  Remote = 'remote',
}

type RequestType = {
  projectName: string
  type?: WhiteLabelType
}

const VERSION = 'v3'

export const whiteLabelApi = createApi({
  reducerPath: 'whiteLabelApi',
  baseQuery: baseQueryInterceptor,
  endpoints: (builder) => ({
    getWhiteLabels: builder.query<ApiResponse<WhiteLabel>, RequestType>({
      query: ({ projectName, type }: RequestType) => ({
        url: `/${VERSION}/${projectName}/white-labels`,
        method: 'get',
        params: {
          app: type,
        },
      }),
    }),
  }),
})

export const selectFromWhiteLabelResult = ({
  data,
  isLoading,
  isError,
  status,
  error,
}: UseQueryStateResult<any, any>): {
  whiteLabelData: WhiteLabel
  isLoaded: boolean
  isError: boolean
  status: string
  errorStatus: number
} => ({
  whiteLabelData: data?.data || {},
  isLoaded: !isLoading,
  isError,
  status,
  errorStatus: error?.status,
})

export const { useGetWhiteLabelsQuery } = whiteLabelApi
