import React from 'react'
import { connect } from 'react-redux'

import {
  GalleryControlInterface,
  GalleryItemInterface,
  HouseAndLandPackageOptionType,
  LotGallery as LotGalleryInterface,
  PlayerControlInterface,
  PlayerState,
  ProjectIdentity,
  RootStateTypeExtra,
} from '@store/types'

import CarouselHandler from '@components/carousel-handler'
import CarouselOverlay from '@components/carousel-overlay'
import VideoPlayer from '@components/video-player'

import {
  FileInterface,
  GalleryInterface,
  LotInterface,
  PackageInterface,
} from '@api/houseAndLand'

import useTabVisibility from '@utilities/tab-visibility'
import VideoPlayerFirebaseService from '@utilities/video-player-firebase-service'

import FirebaseControlQuery from '@firebaseUtil/firebase-control-query'
import { SessionMap } from '@firebaseUtil/types'

import { FileType } from '@adUtilities/constants/common'

import DeselectedView from './deselected-view'

export interface LotGalleryControlSessionInterface {
  galleryControl: GalleryControlInterface
  playerControl: PlayerControlInterface
}

interface LotGalleryProps {
  session: SessionMap | undefined
  lotGalleryData?: Array<LotGalleryInterface>
  lastActiveOptionType?: string
  lastActiveOptionId?: string
  setShouldShowSlideShowBtn: (arg: boolean) => void
  activePackageData?: PackageInterface
  activeLotData?: LotInterface
  packageImages: FileInterface[]
  projectIdentity: ProjectIdentity
}

const NO_IMAGE_OBJECT = {
  id: 'no-image',
  src: '',
  label: 'No Image',
  type: FileType.Image,
  noSpliceUrl: false,
}

function LotGallery({
  session,
  lotGalleryData,
  lastActiveOptionType,
  lastActiveOptionId,
  setShouldShowSlideShowBtn,
  activePackageData,
  activeLotData,
  packageImages,
  projectIdentity,
}: LotGalleryProps) {
  const firebaseControlQuery = FirebaseControlQuery({
    ...projectIdentity,
    sessionKey: projectIdentity.sessionId,
  })

  const videoPlayerFirebaseService = VideoPlayerFirebaseService({
    galleryName: 'propertyGallery',
    projectIdentity,
  })

  const [activeSlideIndex, setActiveSlideIndex] = React.useState(0)
  const [isBuffering, setBufferState] = React.useState(false)
  const [scrubbarTimerState, setScrubbarTimerState] = React.useState(false)
  const [playerState, setPlayerState] = React.useState<PlayerState>(
    PlayerState.Stop
  )
  const [isMuted, setMuteState] = React.useState(true)
  const [volume, setVolume] = React.useState<number>(0)
  const [videoLengthInSeconds, setVideoLengthInSeconds] = React.useState(0)
  const [currentVideoPositionInSeconds, setCurrentVideoPositionInSeconds] =
    React.useState(0)
  const [isSeeking, setSeekingState] = React.useState(false)

  const [showEnlargedView, setShowEnlargedView] = React.useState(false)

  const transformDataForGallery = (
    argData: Array<
      (FileInterface | GalleryInterface) & {
        noSpliceUrl?: boolean
        staticUrl?: boolean
      }
    >
  ): LotGalleryInterface[] =>
    argData
      .sort((a, b) => a.order - b.order)
      .map((item) => ({
        id: item.id,
        src: item.url,
        label: item.title || item.name,
        type: 'type' in item ? (item.type as FileType) : FileType.Image,
        thumbnail: 'thumbnail' in item ? (item.thumbnail as string) : '',
        noSpliceUrl: Boolean(item.noSpliceUrl),
        staticUrl: typeof item.staticUrl === 'boolean' ? item.staticUrl : true,
      }))

  const lotGallery: LotGalleryInterface[] = React.useMemo(() => {
    const floorImagePath = `${projectIdentity.projectUUID}/floorplans/preview`
    const viewImagePath = `${projectIdentity.projectUUID}/views`

    if (!activePackageData && !projectIdentity?.onlyShowPropertyGalleries) {
      const galleryHolder: LotGalleryInterface[] = []

      if (activeLotData?.assets?.floorImage) {
        const currentFloorImage = activeLotData?.assets?.floorImage || {}
        galleryHolder.push(
          ...transformDataForGallery([
            {
              id: currentFloorImage.id,
              name: currentFloorImage.name,
              title: currentFloorImage.title,
              url: `${floorImagePath}/${currentFloorImage.url}`,
              type: currentFloorImage.type,
              order: currentFloorImage.order,
              noSpliceUrl: true,
              staticUrl: false,
            },
          ])
        )
      }

      const myLotGalleries: Array<LotGalleryInterface> = lotGalleryData || []
      if (myLotGalleries.length > 0) {
        galleryHolder.push(
          ...transformDataForGallery(
            [...myLotGalleries].map((item: LotGalleryInterface) => ({
              id: item.id,
              name: item.label || '',
              title: item.label || '',
              url: item.src,
              type: item.type,
              thumbnail: item.thumbnail,
              order: 0,
              staticUrl: false,
            }))
          )
        )
      }

      if ((activeLotData?.assets?.viewLinesImage?.length || 0) > 0) {
        const currentViewLinesImage =
          activeLotData?.assets?.viewLinesImage || []
        galleryHolder.push(
          ...transformDataForGallery(
            [...currentViewLinesImage].map((item) => ({
              id: item.id,
              name: item.name,
              title: item.title,
              url: `${viewImagePath}/${item.url}`,
              type: item.type,
              order: item.order,
              noSpliceUrl: true,
              staticUrl: false,
            }))
          )
        )
      }

      return galleryHolder.length > 0 ? galleryHolder : [NO_IMAGE_OBJECT]
    }

    if (!activePackageData && projectIdentity?.onlyShowPropertyGalleries) {
      const myLotGalleries: Array<LotGalleryInterface> = lotGalleryData || []
      if (myLotGalleries.length > 0) {
        return transformDataForGallery(
          [...myLotGalleries].map((item: LotGalleryInterface) => ({
            id: item.id,
            name: item.label || '',
            title: item.label || '',
            url: item.src,
            type: item.type,
            thumbnail: item.thumbnail,
            order: 0,
            staticUrl: false,
          }))
        )
      }

      if (activeLotData?.assets?.floorImage) {
        const currentFloorImage = activeLotData?.assets?.floorImage || {}
        return transformDataForGallery([
          {
            id: currentFloorImage.id,
            name: currentFloorImage.name,
            title: currentFloorImage.title,
            url: `${floorImagePath}/${currentFloorImage.url}`,
            type: currentFloorImage.type,
            order: currentFloorImage.order,
            noSpliceUrl: true,
            staticUrl: false,
          },
        ])
      }

      return [NO_IMAGE_OBJECT]
    }

    if (
      Object.values(HouseAndLandPackageOptionType).includes(
        lastActiveOptionType as HouseAndLandPackageOptionType
      )
    ) {
      if (!lastActiveOptionId) {
        return []
      }

      if (packageImages.length === 0) {
        return [NO_IMAGE_OBJECT]
      }

      return transformDataForGallery([...packageImages])
    }

    const packageGalleries = activePackageData?.galleries || []

    if (packageGalleries.length === 0) {
      return [NO_IMAGE_OBJECT]
    }

    return transformDataForGallery([...packageGalleries])
  }, [
    activePackageData,
    activeLotData,
    lastActiveOptionId,
    lastActiveOptionType,
    packageImages,
    projectIdentity.projectUUID,
    projectIdentity?.onlyShowPropertyGalleries,
  ])

  React.useEffect(() => {
    firebaseControlQuery.update({
      [`propertyGallery.galleryControlV2.activeSlideIndex`]: 0,
    })
  }, [lotGallery?.[0]?.id])

  React.useEffect(() => {
    setShouldShowSlideShowBtn(lotGallery.length > 1)
  }, [lotGallery.length])

  const galleryData: GalleryItemInterface[] = React.useMemo(() => {
    const galleryItems: Array<GalleryItemInterface> = []

    if (lotGallery && lotGallery.length > 0) {
      lotGallery.forEach((galleryItem) => {
        const imageSource =
          galleryItem.type === 'video'
            ? galleryItem.thumbnail || ''
            : galleryItem.src
        const videoSource = galleryItem.type === 'video' ? galleryItem.src : ''
        galleryItems.push({
          id: galleryItem.id,
          imageSource,
          videoSource,
          config: {
            staticUrl: galleryItem.staticUrl,
            noSpliceUrl: galleryItem.noSpliceUrl,
          },
        })
      })
    }

    return galleryItems
  }, [lotGallery])

  const hasVideo = React.useMemo(
    () => galleryData?.some((item) => !!item.videoSource),
    [galleryData]
  )

  const activeItem = React.useMemo(
    () => galleryData?.[activeSlideIndex],
    [galleryData, activeSlideIndex]
  )

  const handlePlayClick = React.useCallback(async () => {
    setPlayerState(PlayerState.Play)
    setScrubbarTimerState(false)
    await videoPlayerFirebaseService.updatePlayState()
  }, [])

  const handlePauseClick = React.useCallback(async () => {
    setPlayerState(PlayerState.Pause)
    setScrubbarTimerState(false)
    await videoPlayerFirebaseService.updatePauseState()
  }, [])

  const handleStopClick = React.useCallback(async () => {
    setScrubbarTimerState(false)
    setPlayerState(PlayerState.Stop)
    setVideoLengthInSeconds(0)
    setCurrentVideoPositionInSeconds(0)
    await videoPlayerFirebaseService.updateStopState()
  }, [])

  const handleSeekTo = React.useCallback(async (argInSeconds: number) => {
    setScrubbarTimerState(false)
    setSeekingState(true)
    await videoPlayerFirebaseService.updateSeekState(argInSeconds)
  }, [])

  const handleMuteClick = React.useCallback(async (arg: boolean) => {
    setMuteState(arg)
    await videoPlayerFirebaseService.updateMuteState(arg)
  }, [])

  const handleVolumeDownClick = React.useCallback(async () => {
    if (volume > 0) {
      const newVolume = volume - 1
      setMuteState(false)
      setVolume(newVolume)
      await videoPlayerFirebaseService.updateVolumeState(newVolume)
    }
  }, [volume])

  const handleVolumeUpClick = React.useCallback(async () => {
    if (volume < 10) {
      setMuteState(false)
      const newVolume = volume + 1
      setVolume(newVolume)
      await videoPlayerFirebaseService.updateVolumeState(newVolume)
    }
  }, [volume])

  const updateSyncState = React.useCallback(async () => {
    await videoPlayerFirebaseService.updateSyncState()
  }, [videoPlayerFirebaseService])

  useTabVisibility(
    React.useCallback(async () => {
      if (playerState === PlayerState.Play) {
        updateSyncState()
      }
    }, [playerState])
  )

  const handleChangeActiveItemIndex = React.useCallback(
    async (index: number) => {
      setActiveSlideIndex(index)
      setPlayerState(PlayerState.Stop)
      setScrubbarTimerState(false)
      await firebaseControlQuery.update({
        [`propertyGallery.galleryControlV2.autoPlayState`]: false,
        [`propertyGallery.galleryControlV2.activeSlideIndex`]: index,
        [`propertyGallery.playerControlV2.playerState`]: PlayerState.Stop,
        [`propertyGallery.playerControlV2.scrubbarTimerState`]: false,
        [`propertyGallery.playerControlV2.videoLengthInSeconds`]: 0,
        [`propertyGallery.playerControlV2.currentVideoPositionInSeconds`]: 0,
      })
    },
    [activeSlideIndex]
  )

  const handlePlayButtonClick = React.useCallback(async (index: number) => {
    setActiveSlideIndex(index)
    setPlayerState(PlayerState.Play)
    setScrubbarTimerState(false)
    await firebaseControlQuery.update({
      [`propertyGallery.galleryControlV2.autoPlayState`]: false,
      [`propertyGallery.galleryControlV2.activeSlideIndex`]: index,
      [`propertyGallery.playerControlV2.playerState`]: PlayerState.Play,
      [`propertyGallery.playerControlV2.scrubbarTimerState`]: false,
    })
  }, [])

  React.useEffect(() => {
    if (!session) {
      return
    }
    const {
      propertyGallery: {
        galleryControlV2: { activeSlideIndex: activeSlideIndexFirebase },
        playerControlV2: {
          playerState: playerStateFirebase,
          videoLengthInSeconds: videoLengthInSecondsFirebase,
          currentVideoPositionInSeconds: currentVideoPositionInSecondsFirebase,
          volume: volumeFirebase,
          scrubbarTimerState: scrubbarTimerStateFirebase,
          seekToValueInSeconds: seekToValueInSecondsFirebase,
          isBuffering: isBufferingFirebase,
          muted: muteStateFirebase,
        },
      },
    } = session
    setActiveSlideIndex(activeSlideIndexFirebase)
    setPlayerState(playerStateFirebase)
    setVideoLengthInSeconds(videoLengthInSecondsFirebase)
    setCurrentVideoPositionInSeconds(currentVideoPositionInSecondsFirebase)
    setVolume(volumeFirebase)
    setScrubbarTimerState(scrubbarTimerStateFirebase)
    setBufferState(isBufferingFirebase)
    setSeekingState(seekToValueInSecondsFirebase !== null)
    setMuteState(muteStateFirebase)
  }, [session?.propertyGallery])

  const enableEnlargedView = React.useMemo(
    () =>
      (galleryData || []).filter((item) => item.id !== NO_IMAGE_OBJECT.id)
        .length >= 1,
    [galleryData]
  )

  React.useEffect(() => {
    updateSyncState()
  }, [enableEnlargedView, showEnlargedView])

  return !!lotGallery && lotGallery.length > 0 ? (
    <>
      <CarouselHandler
        carousel={{
          label: '',
          items: galleryData || [],
        }}
        imgClass="object-contain"
        hasBlurredEffect
        activeIndex={activeSlideIndex}
        showPlay={playerState === PlayerState.Stop}
        onItemClick={() => setShowEnlargedView(true)}
        onChangeActiveItemIndex={handleChangeActiveItemIndex}
        onPlayClick={handlePlayButtonClick}
      />
      {!showEnlargedView && hasVideo ? (
        <div
          className={`absolute bottom-0 z-40 w-full pb-4 transition-all duration-300 ${
            playerState !== PlayerState.Stop
              ? 'translate-y-0 opacity-100'
              : 'pointer-events-none fixed translate-y-full opacity-0'
          }`}
        >
          <VideoPlayer
            scrubbarTimerState={scrubbarTimerState}
            isBuffering={isBuffering}
            isMuted={isMuted}
            volume={volume}
            isLooping={false}
            playerState={playerState}
            videoLengthInSeconds={videoLengthInSeconds}
            currentVideoPositionInSeconds={currentVideoPositionInSeconds}
            isSeeking={isSeeking}
            handleStopClick={handleStopClick}
            handlePauseClick={handlePauseClick}
            handlePlayClick={handlePlayClick}
            handleMuteClick={handleMuteClick}
            handleVolumeUpClick={handleVolumeUpClick}
            handleVolumeDownClick={handleVolumeDownClick}
            handleSeekTo={handleSeekTo}
            showLoopOption={false}
          />
        </div>
      ) : null}
      {enableEnlargedView && showEnlargedView ? (
        <CarouselOverlay
          carouselData={{
            index: activeSlideIndex,
            item: activeItem,
          }}
          carouselItems={galleryData || []}
          onItemClick={handleChangeActiveItemIndex}
          showPlay={playerState === PlayerState.Stop}
          onPlayClick={handlePlayButtonClick}
          showEnlargedView={showEnlargedView}
          onOverlayClick={() => setShowEnlargedView(false)}
        >
          {hasVideo ? (
            <div
              className={`absolute bottom-0 z-40 w-full px-20 pb-4 transition-all duration-300 ${
                playerState !== PlayerState.Stop
                  ? 'translate-y-0 opacity-100'
                  : 'pointer-events-none fixed translate-y-full opacity-0'
              }`}
            >
              <VideoPlayer
                scrubbarTimerState={scrubbarTimerState}
                isBuffering={isBuffering}
                isMuted={isMuted}
                volume={volume}
                isLooping={false}
                playerState={playerState}
                videoLengthInSeconds={videoLengthInSeconds}
                currentVideoPositionInSeconds={currentVideoPositionInSeconds}
                isSeeking={isSeeking}
                handleStopClick={handleStopClick}
                handlePauseClick={handlePauseClick}
                handlePlayClick={handlePlayClick}
                handleMuteClick={handleMuteClick}
                handleVolumeUpClick={handleVolumeUpClick}
                handleVolumeDownClick={handleVolumeDownClick}
                handleSeekTo={handleSeekTo}
                showLoopOption={false}
              />
            </div>
          ) : null}
        </CarouselOverlay>
      ) : null}
    </>
  ) : (
    <DeselectedView type={lastActiveOptionType || ''} />
  )
}
export default connect(
  ({ projectIdentity, firestore: { session } }: RootStateTypeExtra) => ({
    session,
    projectIdentity,
  })
)(LotGallery)
