import React from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { connect } from 'react-redux'
import { Route, Switch, useHistory, useLocation } from 'react-router-dom'

import { TokenInterface } from '@store/actionSlices/token'
import { NavigationMenuKey, RouteKey } from '@store/constants'
import { ProjectIdentity, RootStateTypeExtra } from '@store/types'

import AbsoluteContainer from '@components/container/absolute-container'
import ErrorBoundaryFallback from '@components/error-boundary-fallback'
import ErrorBoundaryFallbackV2 from '@components/error-boundary-fallback/v2'
import Navigation from '@components/navigation'
import MENU_ITEMS from '@components/navigation/menu-items'
import NavigationV2 from '@components/navigation/v2'
import {
  ProtectedRoutes,
  PublicRoutes,
  SessionActiveRoutes,
} from '@components/route-guards'
import TemplateHandler from '@components/template-handler'
import WithPageTransition from '@components/transition'

import PageNotFound from '@pages/404'
import {
  AreaViewApartments,
  AreaViewApartmentsV2,
  AreaViewHouseAndLand,
  AreaViewHouseAndLandV2,
} from '@pages/area-view'
import { Brochure, BrochureV2 } from '@pages/brochure'
import Dashboard from '@pages/dashboard'
import EnvisionVR from '@pages/envision-vr'
import { ExternalViews, ExternalViewsV2 } from '@pages/external-views'
import { Gallery, GalleryV2 } from '@pages/gallery'
import { InteractiveMap, InteractiveMapV2 } from '@pages/interactive-map'
import Location from '@pages/location'
import Login from '@pages/login'
import LotView from '@pages/lot-view'
import { Panoramic, PanoramicV2 } from '@pages/panoramic'
import {
  BuildingViewApartments,
  BuildingViewApartmentsV2,
  StageViewHouseAndLand,
  StageViewHouseAndLandV2,
} from '@pages/properties-view'
import Shortlist from '@pages/shortlist'
import ShortlistV2 from '@pages/shortlist/v2'
import Snaploader from '@pages/snaploader'
import Team from '@pages/team'
import TeamV2 from '@pages/team/v2'
import VideoGallery from '@pages/video-gallery'
import VideoGalleryV2 from '@pages/video-gallery/v2'
import { Vision, VisionV2 } from '@pages/vision'
import {
  LevelViewApartments,
  LevelViewApartmentsV2,
  PrecinctViewHouseAndLand,
  PrecinctViewHouseAndLandV2,
} from '@pages/zones-view'

import { getQueryStringParams } from '@utilities/helper'
import useCurrentPage from '@utilities/location-util'
import { hasToken } from '@utilities/token-helper'

import FirebaseControlQuery from '@firebaseUtil/firebase-control-query'
import { SessionMap } from '@firebaseUtil/types'

import ADThreeDView from './pages/3d'

interface RouteProps {
  session: SessionMap | undefined
  token: TokenInterface
  projectIdentity: ProjectIdentity
}

function Routes({ session, token, projectIdentity }: RouteProps) {
  const { navigationSettings, isSnaploaderActive, isEnvisionVRActive } =
    projectIdentity

  const firebaseControlQuery = FirebaseControlQuery({
    ...projectIdentity,
    sessionKey: projectIdentity.sessionId,
  })

  const history = useHistory()
  const location = useLocation()
  const [currentPage] = useCurrentPage()

  const IGNORE_ROUTER_PUSHER = [`/${RouteKey.LOT_VIEW}`]

  const [fullScreenMode, setFullScreenMode] = React.useState<boolean>()

  const disableNavigation = React.useMemo(
    () =>
      !hasToken(token) ||
      currentPage === '' ||
      currentPage === RouteKey.VISION ||
      currentPage === RouteKey.DASHBOARD,
    [currentPage, token]
  )

  const disableNavigationV2 = React.useMemo(
    () =>
      !hasToken(token) ||
      currentPage === '' ||
      currentPage === RouteKey.DASHBOARD,
    [currentPage, token]
  )

  React.useEffect(() => {
    const activeRouteFirebase = session?.activeRoute
      ? `/${session.activeRoute}`
      : ''

    if (activeRouteFirebase) {
      if (IGNORE_ROUTER_PUSHER.includes(location.pathname)) {
        return
      }
      if (
        !session?.connected &&
        activeRouteFirebase === `/${RouteKey.VISION}`
      ) {
        return
      }
      if (
        location.pathname === `/${RouteKey.SHORTLIST}` &&
        activeRouteFirebase === `/${RouteKey.STAGES}`
      ) {
        return
      }
      if (activeRouteFirebase !== location.pathname) {
        history.push({
          pathname: activeRouteFirebase,
          state: { from: location.pathname },
        })
      }
      if (activeRouteFirebase !== `/${RouteKey.INTERACTIVE_MAP}`) {
        firebaseControlQuery.update({
          interactiveMap: [],
        })
      }
    }
  }, [session])

  const getRouteActiveStatus = (key: NavigationMenuKey): boolean => {
    const nav_items =
      navigationSettings.length > 0 ? navigationSettings : MENU_ITEMS
    const route = nav_items.find((item) => item.key === key)
    return route?.active ?? false
  }

  const template = React.useMemo(() => {
    const searchParams = getQueryStringParams(location.search)
    return searchParams?.template
  }, [location.search])

  return (
    <>
      <TemplateHandler
        v1={disableNavigation ? null : <Navigation type="left-navigation" />}
        v2={
          disableNavigationV2 ? null : (
            <NavigationV2 fullScreenMode={fullScreenMode} />
          )
        }
      />

      <ErrorBoundary
        FallbackComponent={
          template === 'v2' ? ErrorBoundaryFallbackV2 : ErrorBoundaryFallback
        }
      >
        <WithPageTransition>
          <Switch location={location}>
            <SessionActiveRoutes
              exact
              path={`/${RouteKey.GALLERY}`}
              authenticationPath="/"
              returnPath={`/${RouteKey.DASHBOARD}`}
              isRouteEnabled={getRouteActiveStatus(NavigationMenuKey.GALLERY)}
            >
              <TemplateHandler
                v1={
                  <AbsoluteContainer>
                    <Gallery />
                  </AbsoluteContainer>
                }
                v2={
                  <AbsoluteContainer>
                    <GalleryV2 />
                  </AbsoluteContainer>
                }
              />
            </SessionActiveRoutes>
            <SessionActiveRoutes
              exact
              path={`/${RouteKey.VIDEO_GALLERY}`}
              authenticationPath="/"
              returnPath={`/${RouteKey.DASHBOARD}`}
              isRouteEnabled={getRouteActiveStatus(NavigationMenuKey.VIDEOS)}
            >
              <TemplateHandler
                v1={
                  <AbsoluteContainer>
                    <VideoGallery />
                  </AbsoluteContainer>
                }
                v2={
                  <AbsoluteContainer>
                    <VideoGalleryV2
                      fullScreenToggle={fullScreenMode}
                      onFullScreenToggle={setFullScreenMode}
                    />
                  </AbsoluteContainer>
                }
              />
            </SessionActiveRoutes>
            <SessionActiveRoutes
              exact
              path={`/${RouteKey.EXTERNAL_VIEWS}`}
              authenticationPath="/"
              returnPath={`/${RouteKey.DASHBOARD}`}
              isRouteEnabled={getRouteActiveStatus(
                NavigationMenuKey.EXTERNAL_ROUTES
              )}
            >
              <TemplateHandler
                v1={
                  <AbsoluteContainer>
                    <ExternalViews />
                  </AbsoluteContainer>
                }
                v2={
                  <AbsoluteContainer>
                    <ExternalViewsV2 />
                  </AbsoluteContainer>
                }
              />
            </SessionActiveRoutes>
            <SessionActiveRoutes
              exact
              path={`/${RouteKey.BROCHURE}`}
              authenticationPath="/"
              returnPath={`/${RouteKey.DASHBOARD}`}
              isRouteEnabled={getRouteActiveStatus(NavigationMenuKey.BROCHURE)}
            >
              <TemplateHandler
                v1={
                  <AbsoluteContainer>
                    <Brochure />
                  </AbsoluteContainer>
                }
                v2={
                  <AbsoluteContainer>
                    <BrochureV2 />
                  </AbsoluteContainer>
                }
              />
            </SessionActiveRoutes>
            <SessionActiveRoutes
              exact
              path={`/${RouteKey.LOCATION}`}
              authenticationPath="/"
              returnPath={`/${RouteKey.DASHBOARD}`}
            >
              <AbsoluteContainer>
                <Location />
              </AbsoluteContainer>
            </SessionActiveRoutes>
            <SessionActiveRoutes
              exact
              path={`/${RouteKey.INTERACTIVE_MAP}`}
              authenticationPath="/"
              returnPath={`/${RouteKey.DASHBOARD}`}
              isRouteEnabled={getRouteActiveStatus(
                NavigationMenuKey.INTERACTIVE_MAP
              )}
            >
              <TemplateHandler
                v1={
                  <AbsoluteContainer>
                    <InteractiveMap />
                  </AbsoluteContainer>
                }
                v2={
                  <AbsoluteContainer>
                    <InteractiveMapV2 />
                  </AbsoluteContainer>
                }
              />
            </SessionActiveRoutes>
            <SessionActiveRoutes
              exact
              path={`/${RouteKey.LEVEL_VIEW}`}
              authenticationPath="/"
              returnPath={`/${RouteKey.DASHBOARD}`}
              isRouteEnabled={getRouteActiveStatus(
                NavigationMenuKey.RESIDENCES
              )}
            >
              <TemplateHandler
                v1={
                  <AbsoluteContainer>
                    <LevelViewApartments />
                  </AbsoluteContainer>
                }
                v2={
                  <AbsoluteContainer>
                    <LevelViewApartmentsV2 />
                  </AbsoluteContainer>
                }
              />
            </SessionActiveRoutes>
            <SessionActiveRoutes
              exact
              path={`/${RouteKey.SHORTLIST}`}
              authenticationPath="/"
              returnPath={`/${RouteKey.DASHBOARD}`}
              isRouteEnabled={getRouteActiveStatus(NavigationMenuKey.SHORTLIST)}
            >
              <TemplateHandler
                v1={
                  <AbsoluteContainer>
                    <Shortlist />
                  </AbsoluteContainer>
                }
                v2={
                  <AbsoluteContainer>
                    <ShortlistV2 />
                  </AbsoluteContainer>
                }
              />
            </SessionActiveRoutes>
            <SessionActiveRoutes
              exact
              path={`/${RouteKey.AREA_VIEW}`}
              authenticationPath="/"
              returnPath={`/${RouteKey.DASHBOARD}`}
              isRouteEnabled={getRouteActiveStatus(
                NavigationMenuKey.RESIDENCES
              )}
            >
              <TemplateHandler
                v1={
                  <AbsoluteContainer>
                    <AreaViewApartments />
                  </AbsoluteContainer>
                }
                v2={
                  <AbsoluteContainer>
                    <AreaViewApartmentsV2 />
                  </AbsoluteContainer>
                }
              />
            </SessionActiveRoutes>
            <SessionActiveRoutes
              exact
              path={`/${RouteKey.AREA_VIEW_HOUSE_AND_LAND}`}
              authenticationPath="/"
              returnPath={`/${RouteKey.DASHBOARD}`}
              isRouteEnabled={getRouteActiveStatus(
                NavigationMenuKey.RESIDENCES
              )}
            >
              <TemplateHandler
                v1={
                  <AbsoluteContainer>
                    <AreaViewHouseAndLand />
                  </AbsoluteContainer>
                }
                v2={
                  <AbsoluteContainer>
                    <AreaViewHouseAndLandV2 />
                  </AbsoluteContainer>
                }
              />
            </SessionActiveRoutes>
            <SessionActiveRoutes
              exact
              path={`/${RouteKey.PRECINCT}`}
              authenticationPath="/"
              returnPath={`/${RouteKey.DASHBOARD}`}
              isRouteEnabled={getRouteActiveStatus(
                NavigationMenuKey.RESIDENCES
              )}
            >
              <TemplateHandler
                v1={
                  <AbsoluteContainer>
                    <PrecinctViewHouseAndLand />
                  </AbsoluteContainer>
                }
                v2={
                  <AbsoluteContainer>
                    <PrecinctViewHouseAndLandV2 />
                  </AbsoluteContainer>
                }
              />
            </SessionActiveRoutes>
            <SessionActiveRoutes
              exact
              path={`/${RouteKey.STAGES}`}
              authenticationPath="/"
              returnPath={`/${RouteKey.DASHBOARD}`}
              isRouteEnabled={getRouteActiveStatus(
                NavigationMenuKey.RESIDENCES
              )}
            >
              <TemplateHandler
                v1={
                  <AbsoluteContainer>
                    <StageViewHouseAndLand />
                  </AbsoluteContainer>
                }
                v2={
                  <AbsoluteContainer>
                    <StageViewHouseAndLandV2
                      onFullScreenToggle={setFullScreenMode}
                    />
                  </AbsoluteContainer>
                }
              />
            </SessionActiveRoutes>
            <SessionActiveRoutes
              exact
              path={`/${RouteKey.BUILDING}`}
              authenticationPath="/"
              returnPath={`/${RouteKey.DASHBOARD}`}
              isRouteEnabled={getRouteActiveStatus(
                NavigationMenuKey.RESIDENCES
              )}
            >
              <TemplateHandler
                v1={
                  <AbsoluteContainer>
                    <BuildingViewApartments />
                  </AbsoluteContainer>
                }
                v2={
                  <AbsoluteContainer>
                    <BuildingViewApartmentsV2
                      onFullScreenToggle={setFullScreenMode}
                    />
                  </AbsoluteContainer>
                }
              />
            </SessionActiveRoutes>
            <SessionActiveRoutes
              exact
              path={`/${RouteKey.LOT_VIEW}`}
              authenticationPath="/"
              returnPath={`/${RouteKey.DASHBOARD}`}
              isRouteEnabled={getRouteActiveStatus(
                NavigationMenuKey.RESIDENCES
              )}
            >
              <AbsoluteContainer>
                <LotView />
              </AbsoluteContainer>
            </SessionActiveRoutes>
            <SessionActiveRoutes
              exact
              path={`/${RouteKey.VISION}`}
              authenticationPath="/"
              returnPath={`/${RouteKey.DASHBOARD}`}
            >
              <TemplateHandler
                v1={
                  <AbsoluteContainer>
                    <Vision />
                  </AbsoluteContainer>
                }
                v2={
                  <AbsoluteContainer>
                    <VisionV2 />
                  </AbsoluteContainer>
                }
              />
            </SessionActiveRoutes>
            <SessionActiveRoutes
              exact
              path={`/${RouteKey.TEAMS}`}
              authenticationPath="/"
              returnPath={`/${RouteKey.DASHBOARD}`}
              isRouteEnabled={getRouteActiveStatus(NavigationMenuKey.TEAM)}
            >
              <TemplateHandler
                v1={
                  <AbsoluteContainer>
                    <Team />
                  </AbsoluteContainer>
                }
                v2={
                  <AbsoluteContainer>
                    <TeamV2 />
                  </AbsoluteContainer>
                }
              />
            </SessionActiveRoutes>
            <SessionActiveRoutes
              exact
              path={`/${RouteKey.PANORAMIC}`}
              authenticationPath="/"
              returnPath={`/${RouteKey.DASHBOARD}`}
            >
              <TemplateHandler
                v1={
                  <AbsoluteContainer>
                    <Panoramic />
                  </AbsoluteContainer>
                }
                v2={
                  <AbsoluteContainer>
                    <PanoramicV2 />
                  </AbsoluteContainer>
                }
              />
            </SessionActiveRoutes>
            <SessionActiveRoutes
              path={`/${RouteKey.SNAPLOADER_VIEW}`}
              authenticationPath="/"
              returnPath={`/${RouteKey.DASHBOARD}`}
              isRouteEnabled={isSnaploaderActive}
            >
              <AbsoluteContainer>
                <Snaploader />
              </AbsoluteContainer>
            </SessionActiveRoutes>
            <SessionActiveRoutes
              path={`/${RouteKey.THREE_D_BUILDING}`}
              authenticationPath="/"
              returnPath={`/${RouteKey.DASHBOARD}`}
              isRouteEnabled={isEnvisionVRActive}
            >
              <AbsoluteContainer>
                <EnvisionVR />
              </AbsoluteContainer>
            </SessionActiveRoutes>
            <SessionActiveRoutes
              exact
              path={`/${RouteKey.ADTHREED}`}
              authenticationPath="/"
              returnPath={`/${RouteKey.DASHBOARD}`}
            >
              <AbsoluteContainer>
                <ADThreeDView />
              </AbsoluteContainer>
            </SessionActiveRoutes>
            <ProtectedRoutes
              exact
              path={`/${RouteKey.DASHBOARD}`}
              authenticationPath="/"
              redirectSessionPath={`/${RouteKey.VISION}`}
            >
              <AbsoluteContainer>
                <Dashboard />
              </AbsoluteContainer>
            </ProtectedRoutes>
            <PublicRoutes
              exact
              path="/:projectId"
              authenticatedPath={`/${RouteKey.DASHBOARD}`}
            >
              <Login />
            </PublicRoutes>
            <PublicRoutes
              exact
              path="/"
              authenticatedPath={`/${RouteKey.DASHBOARD}`}
            >
              <Login />
            </PublicRoutes>
            <Route path="*">
              <PageNotFound />
            </Route>
          </Switch>
        </WithPageTransition>
      </ErrorBoundary>
    </>
  )
}

export default connect(
  ({ firestore: { session }, projectIdentity, token }: RootStateTypeExtra) => ({
    session,
    projectIdentity,
    token,
  })
)(Routes)
