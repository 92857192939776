import React from 'react'
import { connect } from 'react-redux'

import { ProjectIdentity, RootStateTypeExtra } from '@store/types'

import Container from '@components/container'
import DataHandler from '@components/data-handler'
import { InfoModal } from '@components/modals'

import {
  ExternalViewsData,
  selectFromResult as selectFromExternalViewsResult,
  useGetExternalViewsQuery,
} from '@api/external-views'

import FirebaseControlQuery from '@firebaseUtil/firebase-control-query'
import { SessionMap } from '@firebaseUtil/types'

import ExternalViewsSkeleton from './externel-views-skeleton'

interface PagePropsInterface {
  projectIdentity: ProjectIdentity
  session: SessionMap | undefined
}

function ExternalViews({ projectIdentity, session }: PagePropsInterface) {
  const firebaseControlQuery = FirebaseControlQuery({
    ...projectIdentity,
    sessionKey: projectIdentity.sessionId,
  })
  const [infoModalVisibility, setInfoModalVisibility] = React.useState(false)
  const [activeViewIndex, setActiveViewIndex] = React.useState(0)

  const externalViewsPayload = useGetExternalViewsQuery(
    { projectName: projectIdentity.projectId },
    { selectFromResult: selectFromExternalViewsResult }
  )

  const handleTrigger = async (index: number) => {
    await firebaseControlQuery.update({
      [`externalView`]: index,
    })
  }

  React.useEffect(() => {
    if (session) {
      const { externalView } = session
      setActiveViewIndex(externalView)
    }
  }, [session])

  React.useEffect(() => {
    setInfoModalVisibility(true)
  }, [])

  return (
    <Container>
      <DataHandler
        payloads={{
          ...externalViewsPayload,
          data: externalViewsPayload.externalViewsData,
        }}
        skeletonFrame={<ExternalViewsSkeleton />}
      >
        <div className="h-full w-full px-4">
          <div className="flex h-top-bar w-full items-center justify-center text-title font-medium text-neutralColour">
            External Views
          </div>
          <div className="no-scrollbar h-page-body w-full overflow-auto">
            <div className="grid grid-cols-2 gap-4">
              {externalViewsPayload.externalViewsData.map(
                (item: ExternalViewsData, index: number) => (
                  <button
                    className={`overflow-hidden text-ellipsis whitespace-nowrap rounded-lg px-20 py-12 text-heading font-medium shadow-standard ${
                      activeViewIndex !== index
                        ? 'cursor-hand bg-secondaryColour text-mainColour'
                        : 'cursor-default bg-gradient text-secondaryColour'
                    }`}
                    onClick={() => handleTrigger(index)}
                    type="button"
                    tabIndex={index}
                    disabled={activeViewIndex === index}
                    key={item.title}
                    title={item.title}
                  >
                    <span>{item.title}</span>
                  </button>
                )
              )}
            </div>
          </div>
        </div>
        <InfoModal
          isVisible={infoModalVisibility}
          toggleModal={setInfoModalVisibility}
          title="View selected"
          message="An external site has been launched. Please use the main Showcase to continue navigating."
        />
      </DataHandler>
    </Container>
  )
}

export default connect(
  ({ projectIdentity, firestore: { session } }: RootStateTypeExtra) => ({
    session,
    projectIdentity,
  })
)(ExternalViews)
