import React from 'react'
import { connect } from 'react-redux'

import {
  MenuItemInterface,
  ProjectIdentity,
  RootStateTypeExtra,
} from '@store/types'

import MENU_ITEMS from '@components/navigation/menu-items'

interface ComponentProps {
  projectIdentity: ProjectIdentity
}

function Settings({ projectIdentity }: ComponentProps) {
  const settingsMenuItems = React.useMemo(() => {
    const footerMenus = MENU_ITEMS.filter(
      (item: MenuItemInterface) => item.active && item.type === 'footer-menu'
    )

    return footerMenus.map((footerMenu: MenuItemInterface) => {
      const myItem = projectIdentity.navigationSettings.find(
        (navItem: MenuItemInterface) => navItem.key === footerMenu.key
      )
      return { ...footerMenu, label: myItem?.label || footerMenu.label }
    })
  }, [projectIdentity.navigationSettings])

  return (
    <div className="text-md flex cursor-pointer space-x-3 rounded-lg font-semibold text-mainColour opacity-85">
      {settingsMenuItems.map(
        (item: MenuItemInterface) =>
          item.render &&
          item.render({
            menu: item,
            key: item.key,
            className: 'rounded-full bg-neutralColour px-3 py-1 drop-shadow-40',
          })
      )}
    </div>
  )
}

export default connect(({ projectIdentity }: RootStateTypeExtra) => ({
  projectIdentity,
}))(Settings)
