import { ExtendedUnitFilterOptions } from '@store/types'

function countFilterChanges(
  oldObject: ExtendedUnitFilterOptions,
  newObject: ExtendedUnitFilterOptions
): number {
  let changes = 0
  const skipKeys = ['anyLevel', 'apply', 'levelsShowLabels', 'unitsShowLabels']

  const isObject = (val: unknown): val is Record<string, unknown> =>
    val !== null && typeof val === 'object'

  const normalizeObject = (obj: Record<string, unknown>): string =>
    JSON.stringify(
      Object.keys(obj)
        .sort()
        .reduce<Record<string, unknown>>((acc, key) => {
          acc[key] = obj[key]
          return acc
        }, {})
    )

  const countChangesInObject = (
    oldObj: Record<string, unknown>,
    newObj: Record<string, unknown>
  ): void => {
    Object.keys(oldObj).forEach((key) => {
      const oldValue = oldObj[key]
      const newValue = newObj[key]

      if (isObject(oldValue) && isObject(newValue)) {
        if (normalizeObject(oldValue) !== normalizeObject(newValue)) {
          changes += 1
        }
      } else if (oldValue !== newValue) {
        changes += 1
      }
    })
  }

  Object.keys(oldObject).forEach((key) => {
    if (skipKeys.includes(key)) return

    const oldValue = oldObject[key as keyof ExtendedUnitFilterOptions]
    const newValue = newObject[key as keyof ExtendedUnitFilterOptions]

    if (Array.isArray(newValue) && newValue.length > 0) {
      changes += 1
    } else if (isObject(oldValue) && isObject(newValue)) {
      if (normalizeObject(oldValue) !== normalizeObject(newValue)) {
        countChangesInObject(oldValue, newValue)
      }
    } else if (oldValue !== newValue) {
      changes += 1
    }
  })

  return changes
}

export default countFilterChanges
