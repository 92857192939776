import React from 'react'
import { connect, useDispatch } from 'react-redux'

import { setWhiteLabel } from '@store/actionSlices/whiteLabel'
import { ProjectIdentity, RootStateTypeExtra } from '@store/types'

import {
  selectFromWhiteLabelResult,
  useGetWhiteLabelsQuery,
} from '@api/white-label'

import AssetHandler from '@adUtilities/asset-handler/asset-handler'
import { setBackground } from '@adUtilities/asset-handler/asset-util'

export interface ContainerV2Props {
  children?: React.ReactNode
  projectIdentity: ProjectIdentity
  style?: React.CSSProperties
  background?: {
    url: string
    type: 'legacy' | 'new'
    noSpliceUrl: boolean
    staticUrl?: boolean
  }
  gradiant?: number
  className?: string
  disableNavigation?: boolean
  fullScreenMode?: boolean
}

function Container({
  children,
  projectIdentity,
  background,
  gradiant = 0.5,
  style,
  className = '',
  disableNavigation = false,
  fullScreenMode,
}: ContainerV2Props) {
  const dispatch = useDispatch()

  const {
    whiteLabelData,
    isLoaded: isWhiteLabelLoaded,
    isError: whiteLabelHasError,
  } = useGetWhiteLabelsQuery(
    { projectName: projectIdentity.projectId },
    {
      selectFromResult: selectFromWhiteLabelResult,
      skip: !projectIdentity.projectId,
    }
  )

  React.useEffect(() => {
    if (isWhiteLabelLoaded && !whiteLabelHasError) {
      dispatch(setWhiteLabel(whiteLabelData))
    }
  }, [isWhiteLabelLoaded])

  React.useEffect(() => {
    const rootElem = document.getElementById('root')
    if (rootElem) {
      rootElem.style.backgroundColor = ''
      rootElem.classList.add('bg-neutralColour')
    }
  }, [])

  return (
    <div
      className={`background-cover-no-scroll relative bg-secondaryColour transition-all duration-300 ${
        !disableNavigation && !fullScreenMode
          ? 'top-9 h-page-container rounded-t-3xl'
          : 'top-0 h-screen'
      } ${className}`}
      style={{
        ...setBackground(background ? AssetHandler(background) : '', {
          gradiant,
        }),
        ...style,
      }}
    >
      {children}
    </div>
  )
}

export default connect(({ projectIdentity }: RootStateTypeExtra) => ({
  projectIdentity,
}))(Container)
