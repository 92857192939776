import React from 'react'
import { connect } from 'react-redux'

import type {
  LotFilterInterface,
  ProjectIdentity,
  RootStateTypeExtra,
} from '@store/types'

import { CanvasInteractive } from '@components/showcase-canvas'
import { CanvasRefInterface } from '@components/showcase-canvas/canvas-interactive'
import { MarkerType } from '@components/showcase-canvas/constants'
import { Polygon } from '@components/showcase-canvas/types'

import { LotInterface } from '@api/houseAndLand'
import { MapContent } from '@api/interactive-plan'

import analyzePixels from '@utilities/analyze-pixels'
import styleUtil from '@utilities/style-util'

import FirebaseControlQuery from '@firebaseUtil/firebase-control-query'
import { SessionMap } from '@firebaseUtil/types'

import { CloseSvg, FullScreenSvg } from '@svg/react'

import AssetHandler from '@adUtilities/asset-handler/asset-handler'
import { slugify } from '@adUtilities/helper'

export interface StageCanvasProps {
  projectIdentity: ProjectIdentity
  stagePlan: MapContent
  originalLots: Array<LotInterface>
  filteredLots: Array<LotInterface>
  activePrecinctId: string
  activeStageId: string
  isFullWidth: boolean
  session: SessionMap | undefined
  lotFilter: LotFilterInterface
  ratio?: string
  fullScreenToggle: boolean
  setFullScreenToggle: (arg: boolean) => void
}

function StageCanvas({
  projectIdentity,
  stagePlan,
  originalLots,
  filteredLots,
  lotFilter,
  session,
  activePrecinctId,
  activeStageId,
  isFullWidth: isFullWidthProp,
  ratio = 'max',
  fullScreenToggle,
  setFullScreenToggle,
}: StageCanvasProps) {
  const themeData = styleUtil()

  const firebaseControlQuery = FirebaseControlQuery({
    ...projectIdentity,
    sessionKey: projectIdentity.sessionId,
  })

  const {
    disableSoldProperty: disableSoldLot,
    statusLabels,
    statusColors,
  } = projectIdentity || {}

  const prevReference = React.useRef<{
    activeStageId: string
    activePrecinctId: string
    activeImage: string
  }>({
    activeStageId,
    activePrecinctId,
    activeImage: '',
  })

  const canvasContainerRef = React.useRef<HTMLDivElement>(null)
  const canvasRef = React.useRef<CanvasRefInterface>()

  const [isStagePlanImageLoaded, setImageLoaded] = React.useState(false)
  const [renderCanvas, setRenderCanvas] = React.useState(false)
  const [isFullWidth, setIsFullWidth] = React.useState(isFullWidthProp)
  const [backgroundColor, setBackgroundColor] =
    React.useState('rgb(255, 255, 255)')

  const [theme, setTheme] = React.useState({
    font: '',
    mainColour: '',
  })

  const [stagePlanMap, setStagePlanMap] = React.useState<MapContent>()

  const arrowMarkerFilterLogic = React.useCallback(
    (lot: LotInterface) => {
      const { apply } = lotFilter

      if (!apply) {
        return false
      }

      const foundLot =
        filteredLots.find((item) => item.id === lot.id) !== undefined

      return !foundLot
    },
    [lotFilter, filteredLots]
  )

  const badgeAndNumberedFilterLogic = React.useCallback(
    (lot: LotInterface) => {
      const { apply } = lotFilter

      if (!apply) {
        return false
      }

      const foundLot =
        filteredLots.find((item) => item.id === lot.id) !== undefined

      return foundLot
    },
    [lotFilter, filteredLots]
  )

  const checkLotStatus = (lot: LotInterface, type: string) => {
    if (disableSoldLot && lot.status !== statusLabels?.available) {
      return true
    }
    if (type === MarkerType.arrowSmall || type === MarkerType.arrow) {
      return arrowMarkerFilterLogic(lot)
    }
    return badgeAndNumberedFilterLogic(lot)
  }

  const isSoldLotDisabled = (lot: LotInterface) => {
    if (disableSoldLot && lot.status !== statusLabels?.available) {
      return true
    }
    return !lot
  }

  const setLotColor = (status: string, poly: Polygon) => {
    if (poly.type === MarkerType.arrowSmall) {
      return poly?.color
    }
    if (status === statusLabels?.available) {
      return poly?.color || '#0abec4'
    }
    return '#8F8E8E'
  }

  const getMarkerColour = (status: string) =>
    statusColors?.[slugify(status)] || '#EF4444'

  const handleLotData = (poly: Polygon) => {
    const lot = originalLots.find(
      (res: LotInterface) =>
        res.name.replace(/\s/g, '') === poly?.groupId?.replace(/\s/g, '')
    )
    return {
      disabled: lot && isSoldLotDisabled(lot),
      postFix: lot?.status,
      color: setLotColor(lot?.status || '', poly),
      markerColor: getMarkerColour(lot?.status || ''),
      activeByDefault: lot && checkLotStatus(lot, poly?.type || ''),
    }
  }

  const getGroupIdByLotId = React.useCallback(
    (lotId: string): string => {
      if (originalLots.length === 0) {
        return ''
      }

      const foundLot = originalLots.find((item) => item.name === lotId)
      if (!foundLot) {
        return ''
      }

      return foundLot.name
    },
    [originalLots]
  )

  const mapStagePlanActions = React.useCallback(() => {
    if (!stagePlan) {
      return
    }
    setStagePlanMap({
      ...stagePlan,
      image: stagePlan.image,
      polygons: stagePlan.polygons
        .map((poly: Polygon) => ({
          ...poly,
          ...handleLotData(poly),
          noArrow: true,
          onClick: () => {},
          onComplete: () => {
            if (poly.groupId) {
              firebaseControlQuery.update({
                [`houseAndLand.activeLotId`]: poly.groupId,
              })
            }
          },
        }))
        .filter((res: Polygon) =>
          originalLots.find(
            (lotItem: LotInterface) =>
              lotItem.name.replace(/\s/g, '') ===
              res?.groupId?.replace(/\s/g, '')
          )
        ),
    })
  }, [stagePlan, lotFilter, originalLots, statusLabels])

  const handleFullScreenToggle = () => {
    setFullScreenToggle(!fullScreenToggle)
    setImageLoaded(false)
    requestAnimationFrame(() => {
      canvasRef?.current?.setCanvas()
    })
  }

  const handleBackgroundColor = React.useCallback(() => {
    if (stagePlan) {
      const url = AssetHandler({
        url: stagePlan.image,
        type: 'new',
        noSpliceUrl: true,
      })
      const dynamicImage = document.createElement('img')
      dynamicImage.crossOrigin = 'Anonymous'
      dynamicImage.src = url
      dynamicImage.onload = () => {
        const color = analyzePixels(dynamicImage)
        const rgb = `rgb(${color[0]}, ${color[1]}, ${color[2]})`
        setBackgroundColor(rgb)
      }
    }
  }, [stagePlan])

  React.useEffect(() => {
    if (stagePlan && canvasContainerRef && originalLots.length > 0) {
      mapStagePlanActions()
      setRenderCanvas(true)
    }
  }, [stagePlan, lotFilter, originalLots, statusLabels])

  const handleCanvasItems = () => {
    canvasRef?.current?.setPolyActive()
  }

  React.useEffect(() => {
    if (!stagePlanMap) {
      return
    }
    if (
      activeStageId !== prevReference.current.activeStageId ||
      activePrecinctId !== prevReference.current.activePrecinctId ||
      stagePlanMap.image !== prevReference.current.activeImage
    ) {
      setTimeout(() => canvasRef?.current?.setCanvas(), 200)
    }
    prevReference.current = {
      ...prevReference.current,
      activeStageId,
      activePrecinctId,
      activeImage: stagePlanMap.image,
    }
    handleCanvasItems()
  }, [stagePlanMap])

  React.useEffect(() => {
    if (isFullWidth !== isFullWidthProp) {
      requestAnimationFrame(() => {
        canvasRef?.current?.setCanvas()
      })
      setIsFullWidth(isFullWidthProp)
    }
  }, [isFullWidthProp])

  React.useEffect(() => {
    if (session) {
      const {
        houseAndLand: { activeLotId: activeLotIdFirebase },
      } = session
      if (activeLotIdFirebase) {
        const groupId = getGroupIdByLotId(activeLotIdFirebase)
        if (groupId) {
          canvasRef?.current?.artificialTrigger(groupId)
        }
      }
    }
  }, [session?.connected, session?.houseAndLand.activeLotId])

  React.useLayoutEffect(() => {
    handleBackgroundColor()
  }, [stagePlan])

  React.useEffect(() => {
    const themeFromStorage = JSON.parse(
      localStorage.getItem('themeObject') || '{}'
    )
    if (themeFromStorage) {
      setTheme(themeFromStorage)
    }
  }, [])

  return (
    <div
      className="relative h-full w-full bg-white"
      style={{ backgroundColor }}
    >
      <div
        className={`absolute left-0 z-10 p-4 ${
          isFullWidth && !fullScreenToggle ? 'top-8' : 'top-0'
        }`}
      >
        <button
          type="button"
          onClick={handleFullScreenToggle}
          className="rounded bg-[#747474] p-2"
        >
          {fullScreenToggle ? (
            <CloseSvg className="h-6 w-6 text-white" />
          ) : (
            <FullScreenSvg className="h-6 w-6 text-white" />
          )}
        </button>
      </div>
      <div className="h-full w-full">
        <div
          className={`relative flex aspect-video h-full w-screen items-center justify-center transition ease-in-out ${
            isStagePlanImageLoaded ? 'opacity-100' : 'opacity-0'
          }`}
          style={
            !fullScreenToggle && !isFullWidth
              ? { maxWidth: 'calc(100vw - 297px)' }
              : undefined
          }
          ref={canvasContainerRef}
        >
          {stagePlanMap && renderCanvas && (
            <CanvasInteractive
              ref={canvasRef}
              id="stage-canvas"
              canvasData={stagePlanMap}
              parentRef={canvasContainerRef}
              hasLabel
              labelPrefix="Lot:"
              ratio={ratio}
              theme={{
                brandColour: theme?.mainColour || themeData.mainColour || '',
                font: theme?.font || themeData.font || '',
              }}
              adjustCanvasSizeWithContainer
              setImageLoaded={() => {
                setTimeout(() => {
                  setImageLoaded(true)
                }, 300)
              }}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default connect(
  ({
    projectIdentity,
    lotFilter,
    firestore: { session },
  }: RootStateTypeExtra) => ({
    projectIdentity,
    lotFilter,
    activePrecinctId: session?.houseAndLand?.activePrecinctId || '',
    activeStageId: session?.houseAndLand?.activeStageId || '',
  })
)(StageCanvas)
