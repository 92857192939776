import React, { MouseEvent } from 'react'
import { connect } from 'react-redux'

import {
  GalleryControlInterface,
  GalleryItemInterface,
  PlayerControlInterface,
  PlayerState,
  ProjectIdentity,
  RootStateTypeExtra,
} from '@store/types'

import { FloorPlateGalleryItemInterface } from '@api/floor-plate-gallery'

import useTabVisibility from '@utilities/tab-visibility'
import VideoPlayerFirebaseService from '@utilities/video-player-firebase-service'

import FirebaseControlQuery from '@firebaseUtil/firebase-control-query'
import { SessionMap } from '@firebaseUtil/types'

import { FileType } from '@adUtilities/constants/common'

import GalleryItem from '../gallery-item'
import VideoPlayer from '../video-player/video-player'

export interface FloorGalleryControlSessionInterface {
  galleryControl: GalleryControlInterface
  playerControl: PlayerControlInterface
}

export interface ComponentPropsInterface {
  projectIdentity: ProjectIdentity
  session: SessionMap | undefined
  floorGalleryItems: Array<FloorPlateGalleryItemInterface>
}

const ROUTES_TO_EXCLUDE = ['building', 'stages']

function FloorPlateGallery({
  projectIdentity,
  session,
  floorGalleryItems,
}: ComponentPropsInterface) {
  const firebaseControlQuery = FirebaseControlQuery({
    ...projectIdentity,
    sessionKey: projectIdentity.sessionId,
  })

  const videoPlayerFirebaseService = VideoPlayerFirebaseService({
    galleryName: 'floorGallery',
    projectIdentity,
  })

  const activeTabRef = React.useRef<number | undefined>()
  const activeRouteRef = React.useRef<string | undefined>()

  const [activeSlideIndex, setActiveSlideIndex] = React.useState(0)
  const [isBuffering, setBufferState] = React.useState(false)
  const [scrubbarTimerState, setScrubbarTimerState] = React.useState(false)
  const [playerState, setPlayerState] = React.useState<PlayerState>(
    PlayerState.Stop
  )
  const [isMuted, setMuteState] = React.useState(true)
  const [volume, setVolume] = React.useState<number>(0)
  const [videoLengthInSeconds, setVideoLengthInSeconds] = React.useState(0)
  const [currentVideoPositionInSeconds, setCurrentVideoPositionInSeconds] =
    React.useState(0)
  const [isSeeking, setSeekingState] = React.useState(false)

  const slides = React.useMemo((): Array<GalleryItemInterface> => {
    if (floorGalleryItems.length === 0) {
      return []
    }

    return floorGalleryItems.map((item: FloorPlateGalleryItemInterface) => ({
      id: item.id,
      title: item.title,
      imageSource: item.imageSrc || '',
      videoSource: item.videoSrc,
      assetType: 'legacy',
    }))
  }, [floorGalleryItems])

  const videoItemCount = React.useMemo(
    () =>
      floorGalleryItems.filter((item) => item.type === FileType.Video).length,
    [floorGalleryItems]
  )

  const handleOnItemClick = React.useCallback(
    async (event: MouseEvent<HTMLDivElement>, index: number) => {
      event.stopPropagation()
      setActiveSlideIndex(index)
      setPlayerState(PlayerState.Stop)
      setScrubbarTimerState(false)
      await firebaseControlQuery.update({
        [`floorGallery.galleryControlV2.activeSlideIndex`]: index,
        [`floorGallery.playerControlV2.playerState`]: PlayerState.Stop,
        [`floorGallery.playerControlV2.scrubbarTimerState`]: false,
        [`floorGallery.playerControlV2.videoLengthInSeconds`]: 0,
        [`floorGallery.playerControlV2.currentVideoPositionInSeconds`]: 0,
      })
    },
    []
  )

  const handleOnPlayButtClick = React.useCallback(
    async (event: MouseEvent<HTMLButtonElement>, index: number) => {
      event.stopPropagation()
      setActiveSlideIndex(index)
      setPlayerState(PlayerState.Play)
      setScrubbarTimerState(false)
      await firebaseControlQuery.update({
        [`floorGallery.galleryControlV2.activeSlideIndex`]: index,
        [`floorGallery.playerControlV2.playerState`]: PlayerState.Play,
        [`floorGallery.playerControlV2.scrubbarTimerState`]: false,
      })
    },
    []
  )

  const handlePlayClick = React.useCallback(async () => {
    setPlayerState(PlayerState.Play)
    setScrubbarTimerState(false)
    await videoPlayerFirebaseService.updatePlayState()
  }, [videoPlayerFirebaseService])

  const handlePauseClick = React.useCallback(async () => {
    setPlayerState(PlayerState.Pause)
    setScrubbarTimerState(false)
    await videoPlayerFirebaseService.updatePauseState()
  }, [videoPlayerFirebaseService])

  const handleStopClick = React.useCallback(async () => {
    setScrubbarTimerState(false)
    setPlayerState(PlayerState.Stop)
    setVideoLengthInSeconds(0)
    setCurrentVideoPositionInSeconds(0)
    await videoPlayerFirebaseService.updateStopState()
  }, [videoPlayerFirebaseService])

  const handleSeekTo = React.useCallback(
    async (argInSeconds: number) => {
      setScrubbarTimerState(false)
      setSeekingState(true)
      await videoPlayerFirebaseService.updateSeekState(argInSeconds)
    },
    [videoPlayerFirebaseService]
  )

  const handleMuteClick = React.useCallback(
    async (arg: boolean) => {
      setMuteState(arg)
      await videoPlayerFirebaseService.updateMuteState(arg)
    },
    [videoPlayerFirebaseService]
  )

  const handleVolumeDownClick = React.useCallback(async () => {
    if (volume > 0) {
      const newVolume = volume - 1

      setMuteState(false)
      setVolume(newVolume)
      await videoPlayerFirebaseService.updateVolumeState(newVolume)
    }
  }, [volume, videoPlayerFirebaseService])

  const handleVolumeUpClick = React.useCallback(async () => {
    if (volume < 10) {
      setMuteState(false)
      const newVolume = volume + 1
      setVolume(newVolume)
      await videoPlayerFirebaseService.updateVolumeState(newVolume)
    }
  }, [volume, videoPlayerFirebaseService])

  const requestForVideoSync = React.useCallback(async () => {
    await videoPlayerFirebaseService.updateSyncState()
  }, [videoPlayerFirebaseService])

  React.useEffect(() => {
    if (!session) {
      return
    }

    const {
      activeRoute: activeRouteFirebase,
      floorGallery: {
        galleryControlV2: {
          activeSlideIndex: activeSlideIndexFirebase,
          activeTabIndex: activeTabIndexFirebase,
        },
        playerControlV2: {
          playerState: playerStateFirebase,
          videoLengthInSeconds: videoLengthInSecondsFirebase,
          currentVideoPositionInSeconds: currentVideoPositionInSecondsFirebase,
          volume: volumeFirebase,
          scrubbarTimerState: scrubbarTimerStateFirebase,
          seekToValueInSeconds: seekToValueInSecondsFirebase,
          isBuffering: isBufferingFirebase,
          muted: muteStateFirebase,
        },
      },
    } = session

    activeTabRef.current = activeTabIndexFirebase
    activeRouteRef.current = activeRouteFirebase
    setActiveSlideIndex(activeSlideIndexFirebase)
    setPlayerState(playerStateFirebase)
    setVideoLengthInSeconds(videoLengthInSecondsFirebase)
    setCurrentVideoPositionInSeconds(currentVideoPositionInSecondsFirebase)
    setVolume(volumeFirebase)
    setScrubbarTimerState(scrubbarTimerStateFirebase)
    setBufferState(isBufferingFirebase)
    setSeekingState(seekToValueInSecondsFirebase !== null)
    setMuteState(muteStateFirebase)
  }, [session?.floorGallery])

  useTabVisibility(
    React.useCallback(async () => {
      if (playerState === PlayerState.Play) {
        requestForVideoSync()
      }
    }, [playerState])
  )

  React.useEffect(() => {
    if (videoItemCount > 0 && playerState === PlayerState.Play) {
      requestForVideoSync()
    }
  }, [videoItemCount, playerState])

  React.useEffect(
    () => () => {
      if (
        playerState === PlayerState.Play &&
        (activeTabRef.current === 0 ||
          !ROUTES_TO_EXCLUDE.includes(activeRouteRef.current || ''))
      ) {
        videoPlayerFirebaseService.resetPlayerState()
      }
    },
    [
      videoPlayerFirebaseService,
      playerState,
      activeTabRef.current,
      activeRouteRef.current,
    ]
  )

  if (floorGalleryItems.length === 0) {
    return null
  }

  return (
    <div className="no-scrollbar relative h-page-body w-full overflow-auto">
      <div className="no-scrollbar h-full overflow-y-auto">
        <div className="grid grid-cols-3 gap-4 px-4">
          {slides.map((item, index) => (
            <GalleryItem
              key={index as number}
              item={item}
              isActive={index === activeSlideIndex}
              playerState={playerState}
              onItemClick={(event: MouseEvent<HTMLDivElement>) =>
                handleOnItemClick(event, index)
              }
              onPlayStopClick={(
                event: MouseEvent<HTMLButtonElement>,
                action: PlayerState
              ) => {
                if (action === PlayerState.Stop) {
                  handleStopClick()
                } else {
                  handleOnPlayButtClick(event, index)
                }
              }}
            />
          ))}
        </div>
      </div>

      {videoItemCount > 0 && (
        <div
          className={`absolute bottom-0 z-40 w-full pb-4 transition-all duration-300 ${
            playerState !== PlayerState.Stop
              ? 'translate-y-0 opacity-100'
              : 'pointer-events-none fixed translate-y-full opacity-0'
          }`}
        >
          <VideoPlayer
            scrubbarTimerState={scrubbarTimerState}
            isBuffering={isBuffering}
            isMuted={isMuted}
            volume={volume}
            isLooping={false}
            playerState={playerState}
            videoLengthInSeconds={videoLengthInSeconds}
            currentVideoPositionInSeconds={currentVideoPositionInSeconds}
            isSeeking={isSeeking}
            handleStopClick={handleStopClick}
            handlePauseClick={handlePauseClick}
            handlePlayClick={handlePlayClick}
            handleMuteClick={handleMuteClick}
            handleVolumeUpClick={handleVolumeUpClick}
            handleVolumeDownClick={handleVolumeDownClick}
            handleSeekTo={handleSeekTo}
            showLoopOption={false}
          />
        </div>
      )}
    </div>
  )
}

const mapStateToProps = ({
  projectIdentity,
  firestore: { session },
}: RootStateTypeExtra) => ({
  projectIdentity,
  session,
})

export default connect(mapStateToProps)(FloorPlateGallery)
