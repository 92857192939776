import React from 'react'

import { PackageConfigurationInterface } from '@store/types'

import { LotInterface, PackageInterface } from '@api/houseAndLand'

interface ParseLotPackageConfigurationArgs {
  configuration?: PackageConfigurationInterface
  lot: LotInterface
  package?: PackageInterface
  projectId?: string
}

const useParseLotPackageConfiguration = () => {
  const parser = React.useCallback(
    ({
      configuration,
      lot,
      package: lotPackage,
      projectId,
    }: ParseLotPackageConfigurationArgs) => {
      const matchedFloorplanOption = lotPackage?.floorplanOptions?.find(
        (i) => i.id === configuration?.floorplanOption
      )
      const matchedFacade = lotPackage?.facades?.find(
        (i) => i.id === configuration?.facade
      )
      const matchedFacadeColour = matchedFacade?.facadeColors?.find(
        (i) => i.id === configuration?.facadeColor
      )
      const matchedInternalTheme = lotPackage?.internalThemes?.find(
        (i) => i.id === configuration?.internalTheme
      )
      const matchedInternalColourSchema =
        matchedInternalTheme?.internalColorSchemes?.find(
          (i) => i.id === configuration?.internalColorScheme
        )

      const titles: string[] = lotPackage
        ? [
            lotPackage.name,
            ...(matchedFloorplanOption ? [matchedFloorplanOption.name] : []),
            ...(matchedFacade ? [matchedFacade.name] : []),
            ...(matchedFacadeColour ? [matchedFacadeColour.name] : []),
            ...(matchedInternalTheme ? [matchedInternalTheme.name] : []),
            ...(matchedInternalColourSchema
              ? [matchedInternalColourSchema.name]
              : []),
          ]
        : []

      let photo: {
        url: string
        config: {
          type: 'new' | 'legacy'
          staticUrl: boolean
          noSpliceUrl: boolean
        }
      } = {
        url: '',
        config: {
          type: 'new',
          staticUrl: true,
          noSpliceUrl: false,
        },
      }

      if (
        !lotPackage &&
        (lot?.assets?.viewLinesImage?.length || 0) > 0 &&
        projectId
      ) {
        const viewImagePath = `${projectId}/views/${lot?.assets?.viewLinesImage?.[0]?.url}`
        photo = {
          url: viewImagePath,
          config: {
            type: 'legacy',
            staticUrl: false,
            noSpliceUrl: true,
          },
        }
      }

      if (!lotPackage && lot?.assets?.floorImage && projectId) {
        const floorImagePath = `${projectId}/floorplans/preview/${lot?.assets?.floorImage?.url}`
        photo = {
          url: floorImagePath,
          config: {
            type: 'legacy',
            staticUrl: false,
            noSpliceUrl: true,
          },
        }
      }

      if (lotPackage) {
        photo.url = lotPackage?.galleries?.[0]?.url || ''
      }

      const price =
        (lot.price || 0) +
          (lotPackage?.housePrice || 0) +
          (matchedFloorplanOption?.price || 0) +
          (matchedFacade?.price || 0) +
          (matchedFacadeColour?.price || 0) +
          (matchedInternalTheme?.price || 0) +
          (matchedInternalColourSchema?.price || 0) || lot.price

      return {
        configurationTitle: titles.join(', '),
        photo,
        price,
      }
    },
    []
  )

  return [parser] as const
}

export default useParseLotPackageConfiguration
