import React, { useEffect, useMemo, useRef, useState } from 'react'
import { connect } from 'react-redux'

import {
  InteractivePlanType,
  ProjectIdentity,
  RootStateTypeExtra,
} from '@store/types'

import Container from '@components/container/v2'
import { Status } from '@components/data-handler/data-handler'
import DataHandler from '@components/data-handler/v2'
import FILTER_INITIAL_STATE_HOUSE_AND_LAND from '@components/filter/filterStateHouseAndLand'
import ImageHandler from '@components/image-handler'
import { CanvasInteractive } from '@components/showcase-canvas'
import { CanvasRefInterface } from '@components/showcase-canvas/canvas-interactive'
import { Polygon } from '@components/showcase-canvas/types'

import {
  InteractivePlanData,
  MapContent,
  selectFromResult,
  useGetInteractivePlanQuery,
} from '@api/interactive-plan'

import styleUtil, { BaseThemeConfig } from '@utilities/style-util'

import FirebaseControlQuery from '@firebaseUtil/firebase-control-query'
import { SessionMap } from '@firebaseUtil/types'

import AreaHouseAndLandSkeleton from './area-view-skeleton'

const ARROW_TYPE = 'arrow'
const ARROW_SMALL_TYPE = 'arrow-small'

export interface AreaViewHouseAndLandProps {
  projectIdentity: ProjectIdentity
  session: SessionMap | undefined
}

function AreaViewHouseAndLand({
  projectIdentity,
  session,
}: AreaViewHouseAndLandProps) {
  const firebaseControlQuery = FirebaseControlQuery({
    ...projectIdentity,
    sessionKey: projectIdentity.sessionId,
  })

  const themeData: BaseThemeConfig = styleUtil()

  const canvasContainerRef = useRef<HTMLDivElement>(null)
  const canvasRef = useRef<CanvasRefInterface>()

  const [isCanvasImageLoaded, setImageLoaded] = useState<boolean>(false)

  const [interactivePlan, setInteractivePlan] = useState<InteractivePlanData>({
    areaView: {
      image: '',
      polygons: [],
    },
    blocks: {},
    floorplan: {},
    precinct: {},
    stages: {},
  })
  const [theme, setTheme] = useState({
    font: '',
    mainColour: '',
  })
  const [area, setArea] = useState<MapContent>({
    image: '',
    polygons: [],
  })

  const interactivePlanPayload = useGetInteractivePlanQuery(
    {
      projectName: projectIdentity.projectName,
      type: InteractivePlanType.AreaView,
    },
    { selectFromResult }
  )

  const apiStatus = useMemo(() => {
    const { FULFILLED, REJECTED, PENDING } = Status
    const { status: interactivePayloadStatus } = interactivePlanPayload

    if (interactivePayloadStatus === FULFILLED) {
      return FULFILLED
    }

    if (interactivePayloadStatus === REJECTED) {
      return REJECTED
    }

    return PENDING
  }, [interactivePlanPayload.status])

  const updateTriggerBuilding = (state: boolean) =>
    firebaseControlQuery.update({
      [`areaView.triggerBuilding`]: state,
    })

  const resetHouseAndLandMap = () =>
    firebaseControlQuery.update({
      [`houseAndLand.activePrecinctId`]: '',
      [`houseAndLand.activeStageId`]: '',
      [`houseAndLand.activeLotId`]: '',
    })

  const resetHouseAndLandView = () =>
    firebaseControlQuery.update({
      [`building.sidePanelFolded`]: true,
      [`houseAndLand.lotFilter`]: FILTER_INITIAL_STATE_HOUSE_AND_LAND,
    })

  const getLabel = (poly: Polygon) => {
    const POLY_TYPE = poly.type || ''
    if (POLY_TYPE === ARROW_TYPE || POLY_TYPE === ARROW_SMALL_TYPE) {
      return poly.label || poly.groupId
    }
    return poly.label
  }

  const setInteractiveAction = (areaViewMap: MapContent) => ({
    ...areaViewMap,
    polygons: areaViewMap.polygons.map((poly) => ({
      ...poly,
      label: getLabel(poly),
      onClick: async () => {
        await firebaseControlQuery.update({
          [`houseAndLand.activePrecinctId`]: poly.groupId,
        })

        await updateTriggerBuilding(true)
      },
      markerColour: projectIdentity?.markerColourSettings?.area,
    })),
  })

  useEffect(() => {
    if (interactivePlan.areaView?.image) {
      setArea(setInteractiveAction(interactivePlan.areaView))
    }
  }, [interactivePlan])

  useEffect(() => {
    const { maps } = interactivePlanPayload
    if (!interactivePlan.areaView?.image && maps.areaView) {
      setInteractivePlan(maps)
    }
  }, [interactivePlanPayload, interactivePlan])

  useEffect(() => {
    const themeFromStorage = JSON.parse(
      localStorage.getItem('themeObject') || '{}'
    )
    if (themeFromStorage) {
      setTheme(themeFromStorage)
    }
  }, [])

  useEffect(() => {
    if (!session) {
      return
    }

    const {
      areaView: { triggerBuilding },
      houseAndLand: { activeStageId, activeLotId },
    } = session

    if (activeStageId || activeLotId) {
      resetHouseAndLandMap().catch((err) => console.error(err))
    }

    if (!triggerBuilding) {
      return
    }

    setTimeout(async () => {
      await updateTriggerBuilding(false)
      await resetHouseAndLandView()
      await firebaseControlQuery.updateRoute('precinct')
    }, 1000)
  }, [session])

  return (
    <Container>
      <DataHandler
        payload={{
          ...interactivePlanPayload,
          data: interactivePlan?.areaView?.image,
          status: apiStatus,
        }}
        skeletonFrame={<AreaHouseAndLandSkeleton />}
      >
        {area.polygons.length > 0 ? (
          <>
            <ImageHandler
              key={area.image}
              url={area.image}
              type="new"
              className="background-cover image-blur absolute inset-0 z-2"
              noSpliceUrl
              showFallbackImage={false}
              bgProps={{
                gradiant: 0.5,
              }}
            />

            <div className="absolute inset-0 z-3">
              <div
                key="area-canvas"
                data-testid="area-canvas"
                ref={canvasContainerRef}
                className={`relative flex h-full w-full items-center justify-center overflow-hidden ${
                  isCanvasImageLoaded ? 'opacity-100' : 'opacity-0'
                }`}
              >
                <CanvasInteractive
                  ref={canvasRef}
                  id="area-canvas"
                  canvasData={area}
                  parentRef={canvasContainerRef}
                  theme={{
                    brandColour:
                      theme?.mainColour || themeData.mainColour || '',
                    font: theme?.font || themeData.font || '',
                  }}
                  adjustCanvasSizeWithContainer
                  setImageLoaded={setImageLoaded}
                />
              </div>
            </div>
          </>
        ) : (
          <p>No polygons</p>
        )}
      </DataHandler>
    </Container>
  )
}

export default connect(
  ({ projectIdentity, firestore: { session } }: RootStateTypeExtra) => ({
    projectIdentity,
    session,
  })
)(AreaViewHouseAndLand)
