import React from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { CSSTransition, TransitionGroup } from 'react-transition-group'

import { ProjectIdentity, type RootStateTypeExtra } from '@store/types'

import { PlaySvg, StopSvg } from '@components/adgroup-svg/react'
import Container from '@components/container/v2'
import DataHandler from '@components/data-handler/v2'
import FadeContainer from '@components/fade-container'

import { selectFromResult, useGetGalleryQuery } from '@api/gallery'

import FirebaseControlQuery from '@firebaseUtil/firebase-control-query'
import { SessionMap } from '@firebaseUtil/types'

import {
  CarouselProvider,
  Tab,
} from '@adUtilities/components/carousel-provider'
import { CarouselControlType } from '@adUtilities/constants/carousel'
import { BucketSource, FileType } from '@adUtilities/constants/common'

import GallerySkeleton from './gallery-skeleton'

export interface GalleryProps {
  session: SessionMap | undefined
  projectIdentity: ProjectIdentity
}
const GALLERY_PAGE_ROUTE = '/gallery'

function Gallery({ session, projectIdentity }: GalleryProps) {
  const history = useHistory()

  const firebaseControlQuery = FirebaseControlQuery({
    ...projectIdentity,
    sessionKey: projectIdentity.sessionId,
  })

  const [toggleControls, setToggleControls] = React.useState(true)
  const [activeTabIndex, setActiveTabIndex] = React.useState(0)
  const [activeSlideIndex, setActiveSlideIndex] = React.useState(0)
  const [autoPlayState, setAutoPlayState] = React.useState(false)

  const designPayload = useGetGalleryQuery(
    { projectName: projectIdentity.projectId },
    { selectFromResult }
  )

  const slides = React.useMemo(
    () =>
      designPayload.galleries.length > 0
        ? designPayload.galleries?.[activeTabIndex].items.map((item) => ({
            id: item.id,
            label: item.label,
            src: item.imageSource,
            thumbnail: item.imageSource,
            bucketType: BucketSource.Legacy,
            fileType: FileType.Image,
            noSpliceUrl: false,
          }))
        : [],
    [designPayload.galleries, activeTabIndex]
  )

  const handleCarouselProviderClick = () => setToggleControls((state) => !state)

  const handleOnTabChange = React.useCallback(
    async (index: number) => {
      setActiveTabIndex(index)
      setActiveSlideIndex(0)
      await firebaseControlQuery.update({
        'design.galleryControlV2.activeTabIndex': index,
        'design.galleryControlV2.activeSlideIndex': 0,
      })
    },
    [firebaseControlQuery]
  )

  const handleOnSlideShowClick = React.useCallback(async () => {
    await firebaseControlQuery.update({
      [`design.galleryControlV2.autoPlayState`]: !autoPlayState,
    })
  }, [autoPlayState, firebaseControlQuery])

  const handleOnItemClick = React.useCallback(
    async (index: number) => {
      setActiveSlideIndex(index)
      await firebaseControlQuery.update({
        'design.galleryControlV2.activeSlideIndex': index,
      })
    },
    [firebaseControlQuery]
  )

  React.useEffect(() => {
    if (!session) {
      return
    }

    const {
      design: {
        galleryControlV2: {
          activeTabIndex: activeTabIndexFirebase,
          activeSlideIndex: activeSlideIndexFirebase,
          autoPlayState: autoPlayStateFirebase,
        },
      },
    } = session

    setActiveTabIndex(activeTabIndexFirebase)
    setActiveSlideIndex(activeSlideIndexFirebase)
    setAutoPlayState(autoPlayStateFirebase)
  }, [session?.design.galleryControlV2])

  React.useEffect(
    () => () => {
      const currentRoute = history.location.pathname || ''
      if (currentRoute !== GALLERY_PAGE_ROUTE) {
        firebaseControlQuery.update({
          'design.galleryControlV2.activeTabIndex': 0,
          'design.galleryControlV2.activeSlideIndex': 0,
          'design.galleryControlV2.autoPlayState': false,
        })
      }
    },
    [firebaseControlQuery]
  )

  return (
    <Container>
      <DataHandler
        payload={{ ...designPayload, data: designPayload.galleries }}
        skeletonFrame={<GallerySkeleton />}
      >
        <div
          className={`absolute top-8 z-2 transition-opacity ${toggleControls ? 'opacity-100' : 'pointer-events-none opacity-0'}`}
        >
          {!autoPlayState && (
            <FadeContainer show={toggleControls}>
              <Tab
                items={designPayload.galleries?.map((item) => ({
                  text: item.label || '',
                }))}
                handleOnItemClick={handleOnTabChange}
                activeItemIndex={activeTabIndex}
                toggleControls={!toggleControls}
              />
            </FadeContainer>
          )}
        </div>

        {slides.length > 1 && (
          <div
            className={`absolute right-6 top-8 z-2 transition-opacity ${toggleControls ? 'opacity-100' : 'pointer-events-none opacity-0'}`}
          >
            <button
              type="button"
              className="inline-flex items-center rounded bg-[#747474] px-3 py-1 text-xs font-semibold uppercase tracking-widest text-white"
              onClick={handleOnSlideShowClick}
            >
              {!autoPlayState ? (
                <>
                  <PlaySvg className="mr-1 h-3 w-3" /> Start Slideshow
                </>
              ) : (
                <>
                  <StopSvg className="mr-1 h-5 w-5" /> Stop Slideshow
                </>
              )}
            </button>
          </div>
        )}

        <TransitionGroup className="h-full">
          <CSSTransition
            key={activeTabIndex}
            classNames="transition-fade"
            timeout={500}
          >
            <CarouselProvider
              gallerySettings={{
                effectType: projectIdentity.gallerySettings.type,
                autoPlayIntervalInSeconds:
                  projectIdentity.gallerySettings.intervalInSeconds,
              }}
              slides={slides}
              activeSlideIndex={activeSlideIndex}
              onContainerClick={handleCarouselProviderClick}
              showControl
              controlType={CarouselControlType.Thumbnail}
              getCurrentSlideIndex={(index: number) => handleOnItemClick(index)}
              mousewheel={false}
              autoPlay={autoPlayState}
            ></CarouselProvider>
          </CSSTransition>
        </TransitionGroup>
      </DataHandler>
    </Container>
  )
}

const mapStateToProps = ({
  firestore: { session },
  projectIdentity,
}: RootStateTypeExtra) => ({
  session,
  projectIdentity,
})

export default connect(mapStateToProps)(Gallery)
