import React from 'react'
import { connect, useDispatch } from 'react-redux'

import { ShortlistState, removeShortlist } from '@store/actionSlices/shortlist'
import { ProjectIdentity, RootStateTypeExtra } from '@store/types'

import { notifySuccess } from '@utilities/adgroup-utilities/notifier'

import FirebaseControlQuery from '@firebaseUtil/firebase-control-query'

import { EllipsisHorizontalSvg } from '@svg/react'

interface ComponentProps {
  projectIdentity: ProjectIdentity
  shortlist: ShortlistState
  disable?: boolean
}

function ActionsButton({
  projectIdentity,
  shortlist,
  disable = false,
}: ComponentProps) {
  const dispatch = useDispatch()
  const firebaseControlQuery = FirebaseControlQuery({
    ...projectIdentity,
    sessionKey: projectIdentity.sessionId,
  })
  const divRef = React.useRef<HTMLDivElement>(null)
  const [toggle, setToggle] = React.useState(false)

  const handleClearSelections = React.useCallback(() => {
    if (shortlist.shortlists.length) {
      notifySuccess('Your selections have been cleared.')
    }
    dispatch(removeShortlist())
    firebaseControlQuery.update({
      'shortlist.properties': JSON.parse(JSON.stringify([])),
    })
    setToggle(false)
  }, [shortlist.shortlists])

  const handleOutsideClick = React.useCallback(
    (event: Event) => {
      if (
        toggle &&
        divRef.current &&
        !divRef.current.contains(event.target as Node)
      ) {
        setToggle(false)
      }
    },
    [toggle]
  )

  React.useEffect(() => {
    document.addEventListener('click', handleOutsideClick)
    return () => {
      document.removeEventListener('click', handleOutsideClick)
    }
  }, [toggle])

  return (
    <div className="relative" ref={divRef}>
      <button
        type="button"
        onClick={() => {
          if (!disable) {
            setToggle(!toggle)
          }
        }}
        className="rounded-full border-2 border-zinc-400 p-1"
      >
        <EllipsisHorizontalSvg className="h-5 w-5 text-zinc-400" />
      </button>

      {toggle && (
        <div className="absolute top-10 z-20 w-[130px] rounded bg-white px-2 text-lg shadow-md">
          <button
            type="button"
            onClick={handleClearSelections}
            className="py-3 font-medium text-red-700"
          >
            Clear selections
          </button>
        </div>
      )}
    </div>
  )
}

export default connect(
  ({ projectIdentity, shortlist }: RootStateTypeExtra) => ({
    projectIdentity,
    shortlist,
  })
)(ActionsButton)
